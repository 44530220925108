import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import axios from "axios";
import ReloadList from "./reload/ReloadList";
import PurchaseHistory from "./reload/PurchaseHistory";
import { useTranslation } from "react-i18next";
import { currency } from "../../../dynamicController";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Modal } from "react-bootstrap";

import cleanproReloadBear from "../../../assets/images/icons/icn-credit.png"

import sha1 from "js-sha1";

const apiUrl = process.env.REACT_APP_API_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
const STORETYPE = process.env.REACT_APP_STORETYPE;

const mySwal = withReactContent(Swal);

const Reload = ({ history, user, token, theme }) => {
	const [selected/*, setSelect*/] = useState("reload");
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(null);
	const [rm, setRm] = useState(false);
	const [scan2pay, setScan2Pay] = useState(false);
	const [kiple, setKiple] = useState(false);
	const [kipleId, setKipleId] = useState(null);
	const [kipleHashValue, setKipleHashValue] = useState(null);
	const [amount, setAmount] = useState("");
	const [transId, setTransId] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [cleanproModal, setCleanproModal] = useState(false);
	const [selectedPkg, setSelectedPkg] = useState(null);

	const kipleButtonClick = useRef();

	const { t } = useTranslation();

	useEffect(() => {
		if (!user) history.push("/");
		else
			axios
				.get(`/api/token/fetchPackages?operatorId=${user.store}`, { headers: { "Content-Type": "application/json", "auth-token": token } })
				.then(res => {
					setData(res.data.tokenPackages);
					setKiple(res.data.kiple);
					setRm(res.data.rm);
					setScan2Pay(res.data.scan2pay);
					setLoading(false);
				})
				.catch(err => {
					mySwal.fire("Error", err.response.data.error, "error");
					setLoading(false);
				});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (kipleHashValue) {
			kipleButtonClick.current.click();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [kipleHashValue]);

	// const selectColor = val => (val === selected ? "selected-button" : null);

	const rmPurchase = () => {
		// pay with revenue monster
		const userId = user.id;
		const pkgId = selectedPkg.id;
		const sendThis = {
			userId,
			pkgId
		};
		setLoading(true);
		axios
			.post("/api/token/purchase", sendThis, { headers: { "Content-Type": "application/json", "auth-token": token } })
			.then(res => {
				setLoading(false);
				if (!res.data.data) {
					history.push("/");
				} else {
					window.location.href = res.data.data;
				}
			})
			.catch(err => {
				mySwal.fire("Error", err.response.data.error, "error");
				setLoading(false);
			});
	}

	const kiplePurchase = () => {
		// pay with kiplepay
		let ord_totalamt = parseFloat(selectedPkg.price).toFixed(2)
		setAmount(ord_totalamt)
		setLoading(true);
		axios
			.post("/api/token/kiplePurchase", { userId: user.id, pkgId: selectedPkg.id }, { headers: { "Content-Type": "application/json", "auth-token": token } })
			.then(res => {
				setKipleId(res.data.kipleId);
				setTransId(res.data.purchaseId);
				// $amount = str_replace(['.', '|'], '', $ord_totalamt);
				let amount = ord_totalamt.replace(/[.|]/g, '');
				// sha1($merchant_secret . $ord_mercID . $ord_mercref . $amount);
				let merchant_hashvalue = sha1(res.data.kipleSecret + res.data.kipleId + res.data.purchaseId + amount)
				if (environment === "development") {
					merchant_hashvalue = sha1("123456" + "80000155" + res.data.purchaseId + amount)
				}
				setKipleHashValue(merchant_hashvalue);
				setLoading(false);
			})
			.catch(err => {
				mySwal.fire("Error", err.response.data.error, "error");
				setLoading(false);
			});
	}

	const scan2payPurchase = () => {
		// pay with Scan2Pay
		setLoading(true);
		axios
			.post("/api/token/scan2payPurchase", { userId: user.id, pkgId: selectedPkg.id }, { headers: { "Content-Type": "application/json", "auth-token": token } })
			.then(res => {
				setLoading(false);
				if (!res.data.data) {
					history.push("/");
				} else {
					window.location.href = res.data.data;
				}
			})
			.catch(err => {
				mySwal.fire("Error", err.response.data.error, "error");
				setLoading(false);
			});
	}

	const cancelPurchase = () => {
		setShowModal(false);
		setCleanproModal(false);
		setSelectedPkg(null)
	}

	const handleSubmit = pkg => {
		setSelectedPkg(pkg)
		if (STORETYPE === "cleanpro") {
			setCleanproModal(true)
		} else {
			setShowModal(true)
		}
		// mySwal
		// 	.fire({
		// 		title: t("Buy this package?"),
		// 		html: `
		//         ${t("Purchase")} ${pkg.token} ${t("e-Tokens for")} RM ${parseFloat(pkg.price).toFixed(2)}?
		//     `,
		// 		icon: "question",
		// 		showCancelButton: scan2pay,
		// 		showDenyButton: kiple,
		// 		showConfirmButton: rm,
		// 		cancelButtonText: t("Pay with Scan2Pay"),
		// 		confirmButtonText: t("Purchase"),
		// 		denyButtonText: t("Pay with kiplePay"),
		// 		denyButtonColor: "#67b668",
		// 		customClass: {
		// 			confirmButton: 'purchase-token',
		// 			denyButton: "kiplepay",
		// 			cancelButton: "scan2pay"
		// 		},
		// 	})
		// 	.then(click => {
		// 		if (click.isConfirmed && click.value) {
		// 			// pay with revenue monster
		// 			const userId = user.id;
		// 			const pkgId = pkg.id;
		// 			const sendThis = {
		// 				userId,
		// 				pkgId
		// 			};
		// 			setLoading(true);
		// 			axios
		// 				.post("/api/token/purchase", sendThis, { headers: { "Content-Type": "application/json", "auth-token": token } })
		// 				.then(res => {
		// 					setLoading(false);
		// 					if (!res.data.data) {
		// 						history.push("/");
		// 					} else {
		// 						window.location.href = res.data.data;
		// 					}
		// 				})
		// 				.catch(err => {
		// 					mySwal.fire("Error", err.response.data.error, "error");
		// 					setLoading(false);
		// 				});
		// 		} else if (click.isDenied) {
		// 			// pay with kiplepay
		// 			let ord_totalamt = parseFloat(pkg.price).toFixed(2)
		// 			setAmount(ord_totalamt)
		// 			setLoading(true);
		// 			axios
		// 				.post("/api/token/kiplePurchase", { userId: user.id, pkgId: pkg.id }, { headers: { "Content-Type": "application/json", "auth-token": token } })
		// 				.then(res => {
		// 					setKipleId(res.data.kipleId);
		// 					setTransId(res.data.purchaseId);
		// 					// $amount = str_replace(['.', '|'], '', $ord_totalamt);
		// 					let amount = ord_totalamt.replace(/[.|]/g, '');
		// 					// sha1($merchant_secret . $ord_mercID . $ord_mercref . $amount);
		// 					let merchant_hashvalue = sha1(res.data.kipleSecret + res.data.kipleId + res.data.purchaseId + amount)
		// 					if (environment === "development") {
		// 						merchant_hashvalue = sha1("123456" + "80000155" + res.data.purchaseId + amount)
		// 					}
		// 					setKipleHashValue(merchant_hashvalue);
		// 					setLoading(false);
		// 				})
		// 				.catch(err => {
		// 					mySwal.fire("Error", err.response.data.error, "error");
		// 					setLoading(false);
		// 				});
		// 		} else if (click.isDismissed && click.dismiss === "cancel") {
		// 			// pay with Scan2Pay
		// 			setLoading(true);
		// 			axios
		// 				.post("/api/token/scan2payPurchase", { userId: user.id, pkgId: pkg.id }, { headers: { "Content-Type": "application/json", "auth-token": token } })
		// 				.then(res => {
		// 					setLoading(false);
		// 					if (!res.data.data) {
		// 						history.push("/");
		// 					} else {
		// 						window.location.href = res.data.data;
		// 					}
		// 				})
		// 				.catch(err => {
		// 					mySwal.fire("Error", err.response.data.error, "error");
		// 					setLoading(false);
		// 				});
		// 		}
		// 	});
	};

	const renderPage = selected === "reload" ? <ReloadList data={data} loading={loading} handleSubmit={handleSubmit} /> : <PurchaseHistory purchaseHistory={user.tokenPurchaseHistory} />;

	return (
		<div id="reload-page">
			<div id="user-header" className={STORETYPE === "cleanpro" ? "d-flex justify-content-between" : ""}>
				<h5>
					<i onClick={e => history.goBack()} className="bi bi-chevron-left me-3"></i> <strong>{STORETYPE !== "cleanpro" ? t("Reload Tokens") : t("Back")}</strong>
				</h5>

				{
					STORETYPE !== "cleanpro" ? (
						<div id="token-info-container">
							<div id="token-image-container">
								<i className="fas bi-coin"></i>
							</div>

							<div id="token-info">
								<h3>{user?.name || ""}</h3>
								<h6>
									{t("Current e-Token")}: <span id="user-token">{user?.token || 0}</span>
								</h6>
							</div>
						</div>
					) : (
						<p className="font-weight-bold" style={{ fontSize: "17px" }}>
							{t("Balance")}: {user?.token || 0} {t("credits")}
						</p>
					)
				}
			</div>

			<div style={{ padding: STORETYPE === "cleanpro" ? "45px 0" : "" }} id="user-body" className={STORETYPE === "cleanpro" ? "" : "mt-5"}>
				{/* <div id="type-selector">
					<button id="reload" onClick={e => setSelect("reload")} className={selectColor("reload")}>
						{t("Reload")}
					</button>
					<button id="history" onClick={e => setSelect("history")} className={selectColor("history")}>
						{t("History")}
					</button>
				</div> */}

				<div /*className="mt-3"*/>{renderPage}</div>
			</div>

			{
				kipleId ? (
					<div>
						<form action={environment === "development" ? "https://uat.kiplepay.com/wcgatewayinit.php" : "https://kiplepay.com/wcgatewayinit.php"} method="post">
							<input type="hidden" name="ord_date" value={new Date().toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' })} />
							<input type="hidden" name="ord_totalamt" value={amount} />
							<input type="hidden" name="ord_gstamt" value="0.00" />
							<input type="hidden" name="ord_shipname" value={user.name} />
							<input type="hidden" name="ord_shipcountry" value="Malaysia" />
							<input type="hidden" name="ord_mercref" value={transId} />
							<input type="hidden" name="ord_telephone" value={user.number} />
							{user.email ? <input type="hidden" name="ord_email" value={user.email} /> : null}
							<input type="hidden" name="ord_delcharges" value="0.00" />
							<input type="hidden" name="ord_svccharges" value="0.00" />
							<input type="hidden" name="ord_mercID" value={environment === "development" ? "80000155" : kipleId} />
							<input type="hidden" name="version" value="2.0" />
							<input type="hidden" name="merchant_hashvalue" value={kipleHashValue} />
							<input type="hidden" name="ord_returnURL" value={apiUrl + `/api/token/kiplePayment/success`} />

							<input type="submit" name="submit" value="Pay with kiplePay" hidden ref={kipleButtonClick} />
						</form>
					</div>
				) : null
			}

			<Modal
				show={showModal}
				onHide={() => setShowModal(false)}
				centered
			>
				<Modal.Body>
					<div className={theme}>
						<h2 className="text-center">{t("Buy this package?")}</h2>
						{selectedPkg ? <p className="text-center fs-5 mt-3">{t("Purchase")} {selectedPkg.token} {t("e-Tokens for")} {currency()} {parseFloat(selectedPkg.price).toFixed(2)}?</p> : null}
						{
							loading ? (
								<div className="text-center">
									<div className={`spinner-border text-${process.env.REACT_APP_STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
										<span className="sr-only">Loading...</span>
									</div>
								</div>
							) : (
								<div className="text-center">
									{rm ? <button id="purchase-rm-token" type="button" className="btn btn-primary mt-2 mx-2" onClick={() => rmPurchase()}>{t("Purchase")}</button> : null}
									{kiple ? <button id="purchase-kiple-token" type="button" className="btn btn-success mt-2 mx-2" onClick={() => kiplePurchase()}>{t("Pay with KiplePay")}</button> : null}
									{scan2pay ? <button id="purchase-scan2pay-token" type="button" className="btn btn-secondary mt-2 mx-2" onClick={() => scan2payPurchase()}>{t("Pay with Scan2Pay")}</button> : null}
									<button id="cancel-purchase-token" type="button" className="btn btn-danger mt-2 mx-2" onClick={() => cancelPurchase()}>{t("Cancel")}</button>
								</div>
							)
						}
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={cleanproModal}
				onHide={() => setCleanproModal(false)}
				centered
			>
				<Modal.Body>
					<div className={theme}>
						<img
							className="d-block"
							src={cleanproReloadBear}
							style={{ width: "30%", margin: "0 auto" }}
							alt=""
						/>
						<h2 className="text-center pt-3">{t("Topup credit")}</h2>
						{selectedPkg ? <p className="text-center fs-5 mt-3">{t("Are you sure to purchase")} {selectedPkg.token} {t("credits for")} {currency()} {parseFloat(selectedPkg.price).toFixed(2)}?</p> : null}
						{
							loading ? (
								<div className="text-center">
									<div className={`spinner-border text-${process.env.REACT_APP_STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
										<span className="sr-only">Loading...</span>
									</div>
								</div>
							) : (
								<div className="text-center">
									<button id="cancel-purchase-token" type="button" className="btn secondary-button mt-4 mx-2" style={{ height: "50px", width: "45%" }} onClick={() => cancelPurchase()}>{t("Cancel")}</button>
									{rm ? <button id="purchase-rm-token" type="button" className="btn big-button mt-4 mx-2" style={{ width: "45%" }} onClick={() => rmPurchase()}>{t("Pay now")}</button> : null}
									{kiple ? <button id="purchase-kiple-token" type="button" className="btn big-button mt-3 mx-2" style={{ width: "75%" }} onClick={() => kiplePurchase()}>{t("Pay with KiplePay")}</button> : null}
									{scan2pay ? <button id="purchase-scan2pay-token" type="button" className="btn btn-secondary mt-2 mx-2" onClick={() => scan2payPurchase()}>{t("Pay with Scan2Pay")}</button> : null}
								</div>
							)
						}
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.user.user,
		token: state.user.token,
		theme: state.theme.theme
	};
};

export default connect(mapStateToProps, null)(Reload);
