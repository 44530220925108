import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import './index.css';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.min.css";
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import ThemeController from './theme/themeController';

import { Provider } from 'react-redux';
import store from './redux/store/store';
import { SW_INIT, SW_UPDATE } from './redux/actions/type';

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ThemeController>
        <App />
      </ThemeController>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onSuccess : () => store.dispatch({type : SW_INIT}),
  onUpdate : reg => store.dispatch({type : SW_UPDATE , payload : reg})
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();