import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";
import Loading from "../../admin/components/Loading";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Tabs, Tab } from 'react-bootstrap';

const mySwal = withReactContent(Swal);

class RewardHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
        };
    }

    componentDidMount() {
        if (!this.props.client || !this.props.client.user_reward) this.props.history.push("/client");
        else {
            axios
                .post("/api/admin/setting/reward/history", { operatorId: this.props.client.admin.map(admin => admin.id) }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        data: res.data,
                        loading: false
                    });
                })
                .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
        }
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    render() {
        let allTableData = {
            columns: [
                {
                    label: "Reward Date",
                    field: "date",
                    sort: "asc"
                },
                {
                    label: "Reward By",
                    field: "rewardBy",
                    sort: "asc"
                },
                {
                    label: "Distributor",
                    field: "distributorName",
                    sort: "asc"
                },
                {
                    label: "User Name",
                    field: "name",
                    sort: "asc"
                },
                {
                    label: "Phone Number",
                    field: "number",
                    sort: "asc"
                },
                {
                    label: "Member ID",
                    field: "memberId",
                    sort: "asc"
                },
                {
                    label: "Reward Type",
                    field: "rewardType",
                    sort: "asc"
                },
                {
                    label: "Remark",
                    field: "remark",
                    sort: "asc"
                },
                {
                    label: "Voucher ID",
                    field: "voucherId",
                    sort: "asc"
                },
                {
                    label: "Voucher Type",
                    field: "voucherType",
                    sort: "asc"
                },
                {
                    label: "Voucher Amount",
                    field: "voucherAmount",
                    sort: "asc"
                },
            ],
            rows: this.state.data
        };
        let voucherTableData = {
            columns: [
                {
                    label: "Reward Date",
                    field: "date",
                    sort: "asc"
                },
                {
                    label: "Reward By",
                    field: "rewardBy",
                    sort: "asc"
                },
                {
                    label: "Distributor",
                    field: "distributorName",
                    sort: "asc"
                },
                {
                    label: "User Name",
                    field: "name",
                    sort: "asc"
                },
                {
                    label: "Phone Number",
                    field: "number",
                    sort: "asc"
                },
                {
                    label: "Member ID",
                    field: "memberId",
                    sort: "asc"
                },
                {
                    label: "Reward Type",
                    field: "rewardType",
                    sort: "asc"
                },
                {
                    label: "Remark",
                    field: "remark",
                    sort: "asc"
                },
                {
                    label: "Voucher ID",
                    field: "voucherId",
                    sort: "asc"
                },
                {
                    label: "Voucher Type",
                    field: "voucherType",
                    sort: "asc"
                },
                {
                    label: "Voucher Amount",
                    field: "voucherAmount",
                    sort: "asc"
                },
            ],
            rows: this.state.data.filter(data => data.rewardType === "Voucher")
        };
        let stampTableData = {
            columns: [
                {
                    label: "Reward Date",
                    field: "date",
                    sort: "asc"
                },
                {
                    label: "Reward By",
                    field: "rewardBy",
                    sort: "asc"
                },
                {
                    label: "Distributor",
                    field: "distributorName",
                    sort: "asc"
                },
                {
                    label: "User Name",
                    field: "name",
                    sort: "asc"
                },
                {
                    label: "Phone Number",
                    field: "number",
                    sort: "asc"
                },
                {
                    label: "Member ID",
                    field: "memberId",
                    sort: "asc"
                },
                {
                    label: "Reward Type",
                    field: "rewardType",
                    sort: "asc"
                },
                {
                    label: "Remark",
                    field: "remark",
                    sort: "asc"
                },
                {
                    label: "Washer Stamp",
                    field: "washerStamp",
                    sort: "asc"
                },
                {
                    label: "Dryer Stamp",
                    field: "dryerStamp",
                    sort: "asc"
                },
            ],
            rows: this.state.data.filter(data => data.rewardType === "Stamp")
        };
        let tokenTableData = {
            columns: [
                {
                    label: "Reward Date",
                    field: "date",
                    sort: "asc"
                },
                {
                    label: "Reward By",
                    field: "rewardBy",
                    sort: "asc"
                },
                {
                    label: "Distributor",
                    field: "distributorName",
                    sort: "asc"
                },
                {
                    label: "User Name",
                    field: "name",
                    sort: "asc"
                },
                {
                    label: "Phone Number",
                    field: "number",
                    sort: "asc"
                },
                {
                    label: "Member ID",
                    field: "memberId",
                    sort: "asc"
                },
                {
                    label: "Reward Type",
                    field: "rewardType",
                    sort: "asc"
                },
                {
                    label: "Remark",
                    field: "remark",
                    sort: "asc"
                },
                {
                    label: "e-Token Amount",
                    field: "tokenAmount",
                    sort: "asc"
                },
            ],
            rows: this.state.data.filter(data => data.rewardType === "Token")
        };

        if (this.props.client?.stamp) {
            allTableData.columns.push(
                {
                    label: "Washer Stamp",
                    field: "washerStamp",
                    sort: "asc"
                },
                {
                    label: "Dryer Stamp",
                    field: "dryerStamp",
                    sort: "asc"
                },
            );
        }

        if (this.props.client?.token) {
            allTableData.columns.push(
                {
                    label: "e-Token Amount",
                    field: "tokenAmount",
                    sort: "asc"
                },
            );
        }

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between">
                                <h5 className="card-title">Reward History</h5>
                            </div>

                            <div className="card-body text-center">{this.state.loading ? <Loading /> : (
                                <Tabs defaultActiveKey="all">
                                    <Tab eventKey="all" title="All">
                                        <MDBDataTable data={allTableData} noBottomColumns striped hover responsive bordered />
                                    </Tab>
                                    <Tab eventKey="voucher" title="Voucher">
                                        <MDBDataTable data={voucherTableData} noBottomColumns striped hover responsive bordered />
                                    </Tab>
                                    {
                                        this.props.client.stamp ? (
                                            <Tab eventKey="stamp" title="Stamp">
                                                <MDBDataTable data={stampTableData} noBottomColumns striped hover responsive bordered />
                                            </Tab>
                                        ) : null
                                    }
                                    {
                                        this.props.client.token ? (
                                            <Tab eventKey="token" title="Token">
                                                <MDBDataTable data={tokenTableData} noBottomColumns striped hover responsive bordered />
                                            </Tab>
                                        ) : null
                                    }
                                </Tabs>
                            )}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        client: state.client.client,
        token: state.client.token
    };
};

export default connect(mapStateToProps, null)(RewardHistory);
