import { useTranslation } from "react-i18next";

const STORETYPE = process.env.REACT_APP_STORETYPE;

const SettingDetails = ({
  selected,
  handleBack,
  handleChange,
  handleLang,
  handleSubmit,
  name,
  email /* , oldPassword*/,
  newPassword,
  language,
  loading,
  birthdayDate,
  handleBirthday
}) => {
  const { t } = useTranslation();

  const renderPage = () => {
    if (selected === "name") {
      return (
        <form id="name-form" onSubmit={handleSubmit}>
          <div className="form-group">
            {STORETYPE !== "cleanpro" ? <label htmlFor="name">{t("Name")}</label> : null}
            <input
              type="text"
              id="name"
              name="name"
              placeholder=""
              value={name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mt-5">
            <input
              id="change-name"
              type="submit"
              style={{ textTransform: STORETYPE === "cleanpro" && "none" }}
              value={STORETYPE === "cleanpro" ? t("Update name") : t("SUBMIT")}
              className="btn big-button form-control"
            />
          </div>
        </form>
      );
    } else if (selected === "email") {
      return (
        <form id="email-form" onSubmit={handleSubmit}>
          <div className="form-group">
            {STORETYPE !== "cleanpro" ? <label htmlFor="email">{t("Email")}</label> : null}
            <input
              type="email"
              id="email"
              name="email"
              placeholder=""
              value={email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mt-5">
            <input
              id="change-email"
              type="submit"
              style={{ textTransform: STORETYPE === "cleanpro" && "none" }}
              value={STORETYPE === "cleanpro" ? t("Update email address") : t("SUBMIT")}
              className="btn big-button form-control"
            />
          </div>
        </form>
      );
    } else if (selected === "password") {
      return (
        <form id="password-form" onSubmit={handleSubmit}>
          {/* <div className="form-group">
                        <label htmlFor="oldPassword">Old Password</label>
                        <input type="text" id="oldPassword" name="oldPassword" placeholder="" value={oldPassword} onChange={handleChange} required />
                    </div> */}

          <div className="form-group">
            {STORETYPE !== "cleanpro" ? <label htmlFor="newPassword">{t("New Password")}</label> : null}
            <input
              type="text"
              id="newPassword"
              name="newPassword"
              placeholder=""
              value={newPassword}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mt-5">
            {loading ? (
              <div className="text-center">
                <div
                  className={STORETYPE === "cuci" ? "spinner-border text-warning" : "spinner-border text-primary"}
                  role="status"
                >
                  <span className="sr-only">Loading ...</span>
                </div>
              </div>
            ) : (
              <input
                id="change-password"
                type="submit"
                style={{ textTransform: STORETYPE === "cleanpro" && "none" }}
                value={STORETYPE === "cleanpro" ? t("Change password") : t("SUBMIT")}
                className="btn big-button form-control"
              />
            )}
          </div>
        </form>
      );
    } else if (selected === "language") {
      return (
        <form id="language-form" onSubmit={handleSubmit}>
          <div className="form-group">
            {STORETYPE !== "cleanpro" ? (
              <>
                <label htmlFor="language">{t("Language")}</label>
                <select
                  name="language"
                  id="language"
                  className="browser-default form-select"
                  value={language}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    {t("Select language")}
                  </option>
                  <option value="en">English</option>
                  <option value="ms">Malay</option>
                  <option value="zh">Chinese</option>
                </select>
              </>
            ) : (
              <>
                <input
                  onClick={handleLang}
                  id="language"
                  name="en"
                  type="button"
                  value="English"
                  className={`d-block full-width my-3 btn big-button ${language === "en" ? "highlight-btn" : "lowlight-btn"}`}
                />

                <input
                  onClick={handleLang}
                  id="language"
                  type="button"
                  name="ms"
                  value="Malay"
                  className={`d-block full-width my-3 btn big-button ${language === "ms" ? "highlight-btn" : "lowlight-btn"}`}
                />
                <input
                  onClick={handleLang}
                  id="language"
                  type="button"
                  name="zh"
                  value="Chinese"
                  className={`d-block full-width my-3 btn big-button ${language === "zh" ? "highlight-btn" : "lowlight-btn"}`}
                />
              </>
            )}
          </div>

          <div className="mt-5">
            <input
              id="change-language"
              type="submit"
              style={{ textTransform: STORETYPE === "cleanpro" && "none" }}
              value={STORETYPE === "cleanpro" ? t("Change language") : t("SUBMIT")}
              className="btn big-button form-control"
            />
          </div>
        </form>
      );
    } else if (selected === "birthday") {
      return (
        <form id="birthday-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="date"
              id="birthdayDate"
              name="birthdayDate"
              placeholder={t("Select your birth date")}
              value={birthdayDate}
              onChange={handleBirthday}
              required
            />
          </div>

          <div className="mt-5">
            <input
              id="update-birthday"
              type="submit"
              style={{ textTransform: STORETYPE === "cleanpro" && "none" }}
              value={STORETYPE === "cleanpro" ? t("Update date of birth") : t("SUBMIT")}
              className="btn big-button form-control"
            />
          </div>
        </form>
      )
    }
  };

  return (
    <div className="m-0 p-0">
      <div id="user-header">
        <h5>
          <i
            onClick={(e) => handleBack()}
            className="bi bi-chevron-left me-3"
          ></i>
          <strong>
            {
              STORETYPE === "cleanpro" ?
                selected === "name" ? t("Update name") :
                  selected === "email" ? t("Update email address") :
                    selected === "password" ? t("Change password") :
                      selected === "language" ? t("Change language") :
                        selected === "birthday" ? t("Update birth date") : ""
                : t("Back")
            }
          </strong>
        </h5>
      </div>

      <div className="p-5">{renderPage()}</div>
    </div>
  );
};

export default SettingDetails;
