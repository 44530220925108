import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import Sidebar from '../../components/Sidebar';
import Topbar from '../../components/Topbar';
import Loading from '../../components/Loading';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { MDBDataTable } from 'mdbreact';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { CSVLink } from "react-csv";
import { currency } from '../../../../dynamicController';

const mySwal = withReactContent(Swal);

class StorePerformance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            data: null,
            loading: true,
            outletId: "",
            startDate: "",
            endDate: ""
        };
    };
    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            if (!this.props.admin.isAuthenticated || !this.props.admin.admin) {
                return this.props.history.push('/admin/dashboard');
            } else {
                const { operatorId } = this.props.admin.admin;
                axios.post('/api/admin/report/store', { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
                    .then(res => {
                        this.setState({
                            ...this.state,
                            loading: false,
                            data: res.data.data
                        });
                    })
                    .catch(err => {
                        this.setState({
                            ...this.state,
                            loading: false
                        });
                        mySwal.fire("Error", err.response.data.error, "error");
                    });
            }
        }
    };

    componentWillUnmount() { this._isMounted = false; };

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleChange = e => this.setState({
        ...this.state,
        [e.target.id]: e.target.value
    });

    handleDate = dates => {
        const [start, end] = dates;
        console.log({ start, end });
        this.setState({
            ...this.state,
            startDate: start,
            endDate: end
        });
    };

    handleFilter = e => {
        const { outletId, startDate, endDate } = this.state;
        let sendThis = {
            operatorId: this.props.admin.admin.operatorId
        };

        if (outletId) {
            sendThis.outletId = outletId;
        }
        if (startDate && endDate) {
            sendThis.startDate = new Date(startDate);
            sendThis.endDate = new Date(endDate).setHours(23, 59, 59, 999);
        }
        this.setState({
            ...this.state,
            loading: true
        });
        axios.post('/api/admin/report/store', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
            .then(res => {
                this.setState({
                    ...this.state,
                    loading: false,
                    data: res.data.data
                });
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                mySwal.fire("Error", err.response.data.error, "error");
            });
    };

    handleReset = e => {
        this.setState({
            ...this.state,
            outletId: "",
            startDate: "",
            endDate: "",
            loading: true
        });
        const operatorId = this.props.admin.admin.operatorId;
        axios.post('/api/admin/report/store', { operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
            .then(res => {
                this.setState({
                    ...this.state,
                    loading: false,
                    data: res.data.data
                });
            })
            .catch(err => {
                this.setState({
                    ...this.state,
                    loading: false
                });
                mySwal.fire("Error", err.response.data.error, "error");
            });
    };

    render() {
        let data = {
            columns: [
                {
                    label: "Store",
                    field: "outletName",
                    sort: "asc"
                },
                {
                    label: "Total Registered Users",
                    field: "totalUser",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    User registered but no transaction record
                                </Tooltip>
                            }
                        >
                            <span>Registered</span>
                        </OverlayTrigger>
                    ),
                    field: "registered",
                    sort: "asc"
                },
                {
                    label: "Registered (%)",
                    field: "registeredPercentage",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    User spending is less than {currency()}50
                                </Tooltip>
                            }
                        >
                            <span>Active</span>
                        </OverlayTrigger>
                    ),
                    field: "active",
                    sort: "asc"
                },
                {
                    label: "Active (%)",
                    field: "activePercentage",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    User spending is from {currency()}50 to {currency()}199
                                </Tooltip>
                            }
                        >
                            <span>Tier 1</span>
                        </OverlayTrigger>
                    ),
                    field: "tier1",
                    sort: "asc"
                },
                {
                    label: "Tier 1 (%)",
                    field: "tier1Percentage",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    User spending is {currency()}200 and above
                                </Tooltip>
                            }
                        >
                            <span>Tier 2</span>
                        </OverlayTrigger>
                    ),
                    field: "tier2",
                    sort: "asc"
                },
                {
                    label: "Tier 2 (%)",
                    field: "tier2Percentage",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Average spending in each transaction
                                </Tooltip>
                            }
                        >
                            <span>Average Spending ({currency()})</span>
                        </OverlayTrigger>
                    ),
                    field: "averageSpending",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Total number of transactions
                                </Tooltip>
                            }
                        >
                            <span>Total Transactions</span>
                        </OverlayTrigger>
                    ),
                    field: "totalTransactions",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Number of vouchers used in all the transactions
                                </Tooltip>
                            }
                        >
                            <span>Voucher Claimed</span>
                        </OverlayTrigger>
                    ),
                    field: "voucherClaimed",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Total number of washer machine transactions
                                </Tooltip>
                            }
                        >
                            <span>Washer Usage</span>
                        </OverlayTrigger>
                    ),
                    field: "washerUsed",
                    sort: "asc"
                },
                {
                    label: (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    Total number of dryer machine transactions
                                </Tooltip>
                            }
                        >
                            <span>Dryer Usage</span>
                        </OverlayTrigger>
                    ),
                    field: "dryerUsed",
                    sort: "asc"
                }
            ],
            rows: this.state.data ?? []
        };

        const csvData = {
            headers: [
                { label: "Store", key: "outletName" },
                { label: "Total Registered Users", key: "totalUser" },
                { label: "Registered", key: "registered" },
                { label: "Registered (%)", key: "registeredPercentage" },
                { label: "Active", key: "active" },
                { label: "Active (%)", key: "activePercentage" },
                { label: "Tier 1", key: "tier1" },
                { label: "Tier 1 (%)", key: "tier1Percentage" },
                { label: "Tier 2", key: "tier2" },
                { label: "Tier 2 (%)", key: "tier2Percentage" },
                { label: "Average Spending", key: "averageSpending" },
                { label: "Total Transactions", key: "totalTransactions" },
                { label: "Voucher Claimed", key: "voucherClaimed" },
                { label: "Washer Usage", key: "washerUsed" },
                { label: "Dryer Usage", key: "dryerUsed" },
            ],
            data: this.state.data
        };


        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />
                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Store Performance</h5>
                            </div>

                            <div className="card-body">
                                <div className="mb-5">
                                    <h6>Filter</h6>
                                    <div>
                                        <label htmlFor="outletId">Select Outlet: </label>
                                        <select id="outletId" name="outletId" className="browser-default form-select" value={this.state.outletId} onChange={this.handleChange}>
                                            <option disabled value="">Select Outlet</option>
                                            {
                                                this.props.admin?.admin?.outlets?.map(outlet => <option key={outlet.id} value={outlet.id}>{outlet.outlet_name}</option>)
                                            }
                                        </select>
                                    </div>

                                    <div>
                                        <label htmlFor="date">Select Transaction Date: </label>
                                        <DatePicker value={`${this.state.startDate ? new Date(this.state.startDate).toLocaleDateString() : ""} - ${this.state.endDate ? new Date(this.state.endDate).toLocaleDateString() : ""}`} onChange={this.handleDate} startDate={this.state.startDate} endDate={this.state.endDate} selectsRange shouldCloseOnSelect={false} />
                                    </div>

                                    <div className="d-flex">
                                        <button onClick={this.handleFilter} className="btn btn-primary btn-inline form-control me-3">Filter</button>
                                        <button onClick={this.handleReset} className="btn btn-secondary btn-inline form-control ms-3">Reset</button>
                                    </div>
                                </div>
                                <div className="text-center mt-5">
                                    {
                                        this.state.loading ? <Loading /> : (
                                            <div>
                                                <MDBDataTable data={data} noBottomColumns striped hover responsive bordered />
                                                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                    <CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename="storePerformance.csv" target="_blank">
                                                        Download Report
                                                    </CSVLink>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        admin: state.admin
    };
};

export default connect(mapStateToProps, null)(StorePerformance);