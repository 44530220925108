import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import axios from "axios";

import paymentWasher from "../../../assets/images/machines/paymentWasher.png";
import paymentDryer from "../../../assets/images/machines/paymentDryer.png";
import vendingMachine from "../../../assets/images/machines/vendingMachine.png";

// cleanpro plus ui
import cpPlusLogo from "../../../assets/images/logo/Logo_Cleanproplus-white.png";
import cpPlusWasher from "../../../assets/images/machines/icn-washer.png";
import cpPlusDryer from "../../../assets/images/machines/icn-dryer.png";
import locationIcon from "../../../assets/images/icons/location.png";
import cpPlusBlueLogo from "../../../assets/images/logo/Logo_Cleanproplus-blue.png";

import WasherPayment from "./payment/WasherPayment";
import DryerPayment from "./payment/DryerPayment";
import VendingMachine from "./payment/VendingMachine";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { changeNav, changePage } from "../../../redux/actions/userActions";
import { scannedCode, saveCreds, savePayment, saveTransactionId } from "../../../redux/actions/paymentActions";

import { Modal } from "react-bootstrap";

const mySwal = withReactContent(Swal);

const STORETYPE = process.env.REACT_APP_STORETYPE;

const Payment = ({
  payment,
  user,
  history,
  changePage,
  location,
  scanned,
  saveCredentials,
  operatorId,
  changeNav,
  savePayment,
  saveTransactionId,
  theme
}) => {
  const { t } = useTranslation();

  let { machineNo, outletCode, opId, operatorCode } = queryString.parse(location.search);

  // washstudio hardcode outlet
  if (outletCode === "84482b2d573d9f5a397db6a6f2064566a8b3bf1a6ac9e220" && opId === "87597e2b1e71d05a706dbdadb41a4374acf8ee107e88b323") {
    outletCode = "811c267b4525c7537c35a4a2e2121f29b7efbc5365c8e672f99a30ab72109b7e"
    opId = "c31d7b7a132b96573031e5f9b8111426efb2be59628fec20"
  }

  const [duration, setDuration] = useState(0);
  const [cleanproNonUserModal, setCleanproNonUserModal] = useState(false);

  useEffect(() => {
    if (!payment.machine) {
      if (machineNo && outletCode && opId) {
        axios
          .post("/api/user/getMachine", {
            machineNo,
            outletCode,
            operatorCode: opId,
            operatorId: operatorCode || operatorId,
          })
          .then((res) => {
            scanned(res.data.data);
            saveCredentials({ machineNo, outletCode, operatorCode: opId });
          })
          .catch((err) => {
            mySwal.fire("Error", err.response.data.error, "error");
          });
      } else {
        history.push("/");
      }
    }

    if (!user) {
      if (STORETYPE === "cleanpro") {
        setCleanproNonUserModal(true)
      } else {
        mySwal
          .fire({
            title: t("Join as a member now!"),
            html: t("Join as a loyalty member now to get more benefits such as vouchers and free machine usage!<br /><br /><strong>Register as loyalty member?</strong>"),
            icon: "info",
            showCancelButton: true,
            cancelButtonText: t("Maybe Next Time"),
            confirmButtonText: t("Yes, Register Now"),
            cancelButtonColor: "#d33",
            customClass: {
              confirmButton: "register-now",
              cancelButton: "register-next-time",
            },
          })
          .then((click) => {
            if (click.isConfirmed && click.value) {
              changePage("register");
              history.goBack();
            }
          });

        return function cleanup() {
          mySwal.close();
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="payment-page">
      <div id="user-header">
        {STORETYPE === "cleanpro" ? (
          <div>
            <div className="d-flex justify-content-between pt-2">
              <img className="plus-logo" src={cpPlusLogo} alt="" />
              {
                user ? (
                  <h5 className="mt-2">
                    <i
                      onClick={(e) => {
                        changeNav("camera");
                        history.push("/")
                      }}
                      className="bi bi-chevron-left me-3"
                    ></i>{" "}
                    {t("Scan QR")}
                  </h5>
                ) : null
              }
            </div>
            <div className="d-flex pb-3">
              <div>
                <img src={locationIcon} alt="" />
              </div>
              <p className="ml-1" style={{ fontSize: "11px", marginTop: "auto" }}>{payment.machine && payment.machine.outlet_fullname ? payment.machine.outlet_fullname : ""}</p>
            </div>
          </div>
        ) : user ? (
          <h5>
            <i
              onClick={(e) => history.push("/")}
              className="bi bi-chevron-left me-3"
            ></i>{" "}
            <strong>
              {payment.machine ? t(payment.machine.type + " Payment") : ""}
            </strong>
          </h5>
        ) : null}

        <div id="machine-info-container">
          <div id="machine-image-container">
            {STORETYPE === "cleanpro" && (
              <>
                {payment.machine ? (
                  <h3>
                    {payment.machine.type === "Washer" ? t("Wash")
                      : payment.machine.type === "Dryer" ? t("Dry")
                        : payment.machine.type === "Vending Machine" ? t("Vending")
                          : ""}
                  </h3>
                ) : null}
              </>
            )}
            {payment.machine ? (
              payment.machine.type === "Washer" ? (
                <img src={STORETYPE === "cleanpro" ? cpPlusWasher : paymentWasher} alt="washer" className="img-fluid" />
              ) : payment.machine.type === "Dryer" ? (
                <img src={STORETYPE === "cleanpro" ? cpPlusDryer : paymentDryer} alt="dryer" className="img-fluid" />
              ) : (
                <img src={vendingMachine} alt="dryer" className="img-fluid" />
              )
            ) : null}
          </div>

          <div id="machine-info">
            {payment.machine ? (
              payment.machine.type === "Vending Machine" ? (
                <>
                  <h5 className="m-0 p-0">{payment.machine.name}</h5>
                  <h6 className="m-0 p-0">{t(payment.machine.type)}</h6>
                  <div id="badge-container" className="mt-2">
                    <div
                      className="status-badge"
                      style={
                        payment.machine.online && payment.machine.outletStatus === "online"
                          ? { backgroundColor: process.env.REACT_APP_STORETYPE === "cuci" ? "#ffd109" : "#8be78b" }
                          : { backgroundColor: "#d33" }
                      }
                    >
                      <strong>{payment.machine.online && payment.machine.outletStatus ? t("Online") : t("Offline")}</strong>
                    </div>

                    <div
                      className="status-badge"
                      style={payment.machine.running ? { backgroundColor: "#d33" } : null}
                    >
                      <strong>
                        {payment.machine.running ? t("Running") : t("Vacant")}
                      </strong>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <h5 className="m-0 p-0">
                    {STORETYPE !== "cleanpro" && `${t(payment.machine.type)} -`} {payment.machine.name}
                  </h5>
                  <h6 className="m-0 p-0">
                    {STORETYPE !== "cleanpro" && payment.machine.capacity}
                  </h6>
                  {STORETYPE !== "cleanpro" ? (
                    <div id="badge-container" className="mt-2">
                      <div
                        className="status-badge"
                        style={
                          payment.machine.online &&
                            payment.machine.outletStatus === "online"
                            ? {
                              backgroundColor:
                                process.env.REACT_APP_STORETYPE === "cuci"
                                  ? "#ffd109"
                                  : "#8be78b",
                            }
                            : { backgroundColor: "#d33" }
                        }
                      >
                        <strong>
                          {payment.machine.online &&
                            payment.machine.outletStatus === "online"
                            ? t("Online")
                            : t("Offline")}
                        </strong>
                      </div>

                      <div
                        className="status-badge"
                        style={
                          payment.machine.running
                            ? { backgroundColor: "#d33" }
                            : null
                        }
                      >
                        <strong>
                          {payment.machine.running ? t("Running") : t("Vacant")}
                        </strong>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-2" id="badge-container">
                      <div>
                        <p style={{ fontSize: "12px", color: "#525252" }}>{t("Capacity")}</p>
                        <strong style={{ fontSize: "16px" }}>{payment.machine.capacity}</strong>
                      </div>
                      <div>
                        <p style={{ fontSize: "12px", color: "#525252" }}>{t("Duration")}</p>
                        <strong style={{ fontSize: "16px" }}>
                          {duration} {t("min")}
                        </strong>
                      </div>
                    </div>
                  )}
                </>
              )
            ) : null}
          </div>
        </div>
      </div>

      <div id="user-body">
        {payment.machine ? (
          payment.machine.type === "Washer" ? (
            <WasherPayment
              setDuration={setDuration}
              savePayment={savePayment}
              machine={payment.machine}
              outlet={payment.outlet}
              user={user}
              history={history}
              saveTransactionId={saveTransactionId}
              theme={theme}
            />
          ) : payment.machine.type === "Dryer" ? (
            <DryerPayment
              setDuration={setDuration}
              savePayment={savePayment}
              machine={payment.machine}
              outlet={payment.outlet}
              user={user}
              history={history}
              saveTransactionId={saveTransactionId}
              theme={theme}
            />
          ) : (
            <VendingMachine
              machine={payment.machine}
              outlet={payment.outlet}
              user={user}
              history={history}
            />
          )
        ) : null}
      </div>
      <Modal
        show={cleanproNonUserModal}
        onHide={(e) => setCleanproNonUserModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <img src={cpPlusBlueLogo} alt="logo" style={{ width: "80%" }} />
        </Modal.Header>
        <Modal.Body>
          <div className={theme}>
            <p style={{ fontSize: "22px", fontWeight: 500, lineHeight: "120%" }}>{t("Register as a member to enjoy more benefits!")}</p>
            <ul style={{ listStyleType: "square", listStylePosition: "inside", fontSize: "16px", fontWeight: 300 }} className="pt-3">
              <li style={{ listStyleType: "square" }}>{t("Get FREE 3 credits per new user")}</li>
              <li style={{ listStyleType: "square" }}>{t("Enjoy exclusive promotions")}</li>
              <li style={{ listStyleType: "square" }}>{t("FREE voucher on your birthday")}</li>
              <li style={{ listStyleType: "square" }}>{t("and more")}</li>
            </ul>
            <div className="d-flex justify-content-around">
              <button id="cleanpro-sign-up" onClick={() => { changePage("register"); history.goBack(); }} style={{ fontSize: "16px", fontWeight: 500, width: "35%" }} className="btn big-button">
                {t("Sign up")}
              </button>
              <button id="cleanpro-continue-to-pay" onClick={() => setCleanproNonUserModal(false)} style={{ height: "50px", width: "60%" }} className="btn secondary-button">
                {t("Continue to pay")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    payment: state.payment,
    user: state.user.user,
    operatorId: state.user.operatorId,
    theme: state.theme.theme
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePage: (data) => dispatch(changePage(data)),
    scanned: (data) => dispatch(scannedCode(data)),
    saveCredentials: (data) => dispatch(saveCreds(data)),
    changeNav: (nav) => dispatch(changeNav(nav)),
    savePayment: (data) => dispatch(savePayment(data)),
    saveTransactionId: (data) => dispatch(saveTransactionId(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
