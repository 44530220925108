import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import Loading from "../../admin/components/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { MDBDataTable } from "mdbreact";
import { Modal } from "react-bootstrap";
import axios from "axios";

const mySwal = withReactContent(Swal);

const UserMigration = ({ token, distributor }) => {

    const history = useHistory();

    const [toggled, setToggled] = useState(false);
    const [uploadData, setUploadData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [fileUploadLoading, setFileUploadLoading] = useState(false);

    useEffect(() => {
        if (!distributor || !distributor.user_migration) {
            history.push('/distributor')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleToggle = () => {
        setToggled(!toggled);
    }

    const handleFile = e => {
        const file = e.target.files[0];

        if (file) {
            if (file.type !== "text/csv") {
                mySwal.fire({ title: "Error", text: "Only CSV file is accepted", icon: "error" })
                e.target.value = null;
            } else {
                setFileUploadLoading(true);

                const formData = new FormData();
                formData.append("file", file);

                axios.post('/api/client/userMigrationFileUpload', formData, { headers: { "Content-Type": "application/json", "auth-token": token } })
                    .then(res => {
                        setUploadData(res.data.data);
                        setShowModal(true);
                        setFileUploadLoading(false);
                    })
                    .catch(err => {
                        setFileUploadLoading(false);
                        mySwal.fire("Error", err.response.data.error, "error");
                    });
            }
        }
    }

    const handleClose = () => {
        setUploadData([]);
        setShowModal(false);
    }

    const submitMigration = () => {
        setFileUploadLoading(true);

        axios.post('/api/client/userMigration', { data: uploadData }, { headers: { "Content-Type": "application/json", "auth-token": token } })
            .then(res => {
                setUploadData([]);
                setShowModal(false);
                setFileUploadLoading(false);
                mySwal.fire("Success", "The user and the balance have updated", "success");
            })
            .catch(err => {
                setUploadData([]);
                setShowModal(false);
                setFileUploadLoading(false);
                mySwal.fire("Error", err.response.data.error, "error");
            });
    }

    let data = {
        columns: [
            {
                field: "number",
                label: "Phone Number"
            },
            {
                field: "name",
                label: "User Name"
            },
            {
                field: "email",
                label: "Email"
            },
            {
                field: "createdAt",
                label: "Register Time"
            },
            {
                field: "balance",
                label: "Balance"
            },
        ],
        rows: uploadData
    }

    return (
        <div className="admin-page-container">
            <Sidebar toggled={toggled} setToggled={handleToggle} />

            <div className="admin-page">
                <Topbar handleToggle={handleToggle} />

                <div className="admin-content">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">User Migration</h5>
                        </div>

                        <div className="card-body">
                            <div>
                                <h5>Upload User CSV file:</h5>
                                <p>Sample CSV file: <small>(Please follow the CSV file format)</small></p>
                                <table className="table table-bordered mt-3">
                                    <thead>
                                        <tr>
                                            <th scope="col">Phone Number</th>
                                            <th scope="col">User Name</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Register Time</th>
                                            <th scope="col">Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>0123214521</td>
                                            <td>John</td>
                                            <td>john@gmail.com</td>
                                            <td>2023-04-03</td>
                                            <td>20</td>
                                        </tr>
                                        <tr>
                                            <td>0129568745</td>
                                            <td>Max</td>
                                            <td>max@gmail.com</td>
                                            <td>2023-04-05</td>
                                            <td>55</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {
                                fileUploadLoading ? <Loading /> : (
                                    <div className="form-group">
                                        <label htmlFor="file">User CSV file : <small><strong>Only CSV file (comma delimited) is support now</strong></small></label><br />
                                        <input type="file" id="file" name="file" accept=".csv" onChange={handleFile} />
                                    </div>
                                )
                            }
                        </div>

                    </div>
                </div>
            </div>
            <Modal
                show={showModal}
                onHide={handleClose}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Confirm uploaded data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MDBDataTable data={data} hover responsive bordered small noBottomColumns />
                    {
                        fileUploadLoading ? (
                            <Loading />
                        ) : (
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn btn-danger" onClick={handleClose}>Cancel</button>
                                <button type="button" className="btn btn-primary ms-4" onClick={submitMigration}>Confirm</button>
                            </div>
                        )
                    }
                </Modal.Body>
            </Modal>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        distributor: state.distributor.distributor,
        token: state.distributor.token
    };
};

export default connect(mapStateToProps, null)(UserMigration);