import React, { Component } from 'react';
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";

class TopUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: "Spending"
        };
    };

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });
    };

    render() {

        let data = {
            columns: [],
            rows: []
        }

        if (this.state.filter === "Stamps") {

            data.columns = [
                {
                    label: "Top Users",
                    field: "topUsers",
                    sort: "asc"
                },
                {
                    label: "User name",
                    field: "username",
                    sort: "asc"
                },
                {
                    label: "Number",
                    field: "number",
                    sort: "asc"
                },
                {
                    label: "Stamps",
                    field: "stamps",
                    sort: "asc"
                }
            ]

            data.rows = this.props.topUserData.sortByStamps

        } else if (this.state.filter === "Spending") {

            data.columns = [
                {
                    label: "Top Users",
                    field: "topUsers",
                    sort: "asc"
                },
                {
                    label: "User name",
                    field: "username",
                    sort: "asc"
                },
                {
                    label: "Number",
                    field: "number",
                    sort: "asc"
                },
                {
                    label: "Spending",
                    field: "spending",
                    sort: "asc"
                }
            ]

            data.rows = this.props.topUserData.sortBySpending
        }


        return (
            <div>
                <div>
                    <h6 className="text-center">Top Users</h6>
                    <div>
                        <label htmlFor="filter">Sort by: </label>
                        <select id="filter" name="filter" className="browser-default form-select" value={this.state.filter} onChange={this.handleChange}>
                            <option value="Spending">Spending</option>
                            {this.props.admin.admin.stamp ? <option value="Stamps">Stamps</option> : null}
                        </select>
                    </div>
                </div>

                <div className="text-center mt-3"><MDBDataTable data={data} paging={false} searching={false} sortable={false} noBottomColumns striped hover responsive bordered /></div>

            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        admin: state.admin
    };
};

export default connect(mapStateToProps, null)(TopUser);