import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import { cleanproRegister, changePage } from "../../../redux/actions/userActions";
import OtpInput from 'react-otp-input';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { useTranslation } from "react-i18next";

import cpPlusLogoBlue from "../../../assets/images/logo/Logo_Cleanproplus-blue.png";
import signInBear from "../../../assets/images/logo/cleanproWelcomeLogo.png";

const mySwal = withReactContent(Swal);

const CleanproRegister = ({
    query,
    register,
    isLoading,
    error,
    change,
    operatorId,
    machine,
    outletId,
}) => {
    const [show, setShow] = useState(false);
    const [number, setNumber] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState("");
    const [otp, setOtp] = useState("");
    const [referId, setReferId] = useState("");
    const [loading, setLoading] = useState(true);
    const [referral, setReferral] = useState(false);
    const [step, setStep] = useState(1);
    const [err, setErr] = useState("");

    const { t } = useTranslation();

    useEffect(() => {
        axios
            .post("/api/nonuser/getReferralSetting", { operatorId: operatorId })
            .then((res) => {
                setReferral(res.data.data);
                if (queryString.parse(query).referralCode) {
                    setReferId(queryString.parse(query).referralCode);
                }
                setLoading(false);
            })
            .catch((err) => {
                mySwal.fire(t("Error"), t(err.response.data.error), "error");
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resendOtp = () => {
        const { operatorCode } = queryString.parse(query);
        const operatorID = operatorCode || operatorId;
        setLoading(true);
        axios
            .post("/api/user/auth/sendOtp", { number, operatorId: operatorID })
            .then((res) => {
                setLoading(false);
            })
            .catch((err) => {
                mySwal.fire(t("Error"), t(err.response.data.error), "error");
                setLoading(false);
            });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const numberRegex = /^\d+$/;
        const emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
        const { operatorCode } = queryString.parse(query);
        const operatorID = operatorCode || operatorId;

        // console.log({name, email , month , day , password , referId , storeId});
        // number , name , email , month , day , password , referId , storeId

        if (!operatorID) {
            mySwal.fire(t("Info"), t("Unable to register without a QRCode"), "info");
        } else if (step === 1) {
            if (!numberRegex.test(number) || number.length <= 9 || number.length >= 12 || number.substr(0, 2) !== "01") {
                mySwal.fire({
                    title: t("Error"),
                    icon: "error",
                    html: t(`Please enter the correct phone number format. <br /><br /> <small>Example: 0129465628</small>`),
                });
            } else {
                setLoading(true);
                let sendThis = {
                    operatorId: operatorID,
                    number
                }

                if (machine) {
                    sendThis.outletId = machine.outletId;
                }

                if (outletId) {
                    sendThis.outletId = outletId;
                }
                axios
                    .post("/api/user/auth/checkUser", sendThis)
                    .then((res) => {
                        setStep(2)
                        setLoading(false);
                    })
                    .catch((err) => {
                        setErr(err.response.data.error)
                        setLoading(false);
                    });
            }
        } else if (step === 2) {
            setLoading(true);
            axios
                .post("/api/user/auth/verifyOtp", { number, otp, operatorId: operatorID })
                .then((res) => {
                    setStep(3)
                    setLoading(false);
                })
                .catch((err) => {
                    mySwal.fire(t("Error"), t(err.response.data.error), "error");
                    setLoading(false);
                });
        } else if (step === 3) {
            if (!name || !email || !password) {
                mySwal.fire(t("Error"), t("Please fill in all the required fields before submit."), "error");
            } else if (!emailRegex.test(email)) {
                mySwal.fire(t("Error"), t("Invalid Email Address"), "error");
            } else if (password.length < 6) {
                mySwal.fire(t("Info"), t("Password length must be at least 6 characters"), "info");
            } else {
                register({ number, operatorId: operatorID, name, email, password, referId })
            }
        }
    };

    return (
        <div className="d-flex flex-column justify-content-center" style={{ minHeight: "100vh" }}>
            <div id="logo-container" className="d-flex flex-column" style={{ height: "initial" }}>
                <img src={cpPlusLogoBlue} alt="" style={{ marginBottom: "5%" }} />
                {step === 3 ? <img src={signInBear} alt="logo" className="img-fluid" /> : null}
            </div>

            <form onSubmit={handleSubmit} className="px-5">
                {
                    step === 1 ? (
                        <div>
                            <p className="cleanpro-register-title">{t("Let's get started!")}</p>
                            <div className="form-group mt-5">
                                <div className="input-field col">
                                    <input
                                        type="tel"
                                        id="number"
                                        name="number"
                                        className="form-control"
                                        placeholder={t("Enter your mobile number")}
                                        onChange={e => setNumber(e.target.value)}
                                        value={number}
                                        autoFocus
                                        required
                                    />
                                    <span className="form-prepend">
                                        <i className="bi bi-telephone-fill"></i>
                                    </span>
                                </div>
                            </div>
                            {
                                isLoading || loading ? (
                                    <div className="text-center">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="pt-3">
                                        <button type="submit" id="cleanpro-check-user" className="btn big-button form-control">{t("Proceed")}</button>
                                    </div>
                                )
                            }
                            {
                                err || error ? (
                                    <div className="text-center">
                                        <p style={{ color: "red" }} className="m-0 p-0">
                                            <small>
                                                <strong>{t(err) || t(error)}</strong>
                                            </small>
                                        </p>
                                    </div>
                                ) : null
                            }
                            <div className="text-center mt-3">
                                <p style={{ color: "black" }}>{t("Already have an account?")} <span style={{ color: "#004FC1" }} id="cleanpro-login-now" onClick={() => change("login")}>{t("Login now")}</span></p>
                            </div>
                        </div>
                    ) : step === 2 ? (
                        <div>
                            <p className="cleanpro-register-title">{t("Enter OTP number")}</p>
                            <p className="cleanpro-register-subtitle pt-2">{t("You will receive a six-digit OTP via SMS sent to")} +6{number}</p>
                            <OtpInput
                                value={otp}
                                onChange={(e) => setOtp(e)}
                                numInputs={6}
                                renderInput={(props) => <input {...props} className="px-1 mx-1" type="number" />}
                                containerStyle={{
                                    textAlign: "center",
                                    margin: "auto",
                                    justifyContent: "center",
                                    marginBottom: "5%",
                                    marginTop: "5%"
                                }}
                                inputStyle={{
                                    width: "30px",
                                    height: "35px",
                                    // fontSize: "30px",
                                    fontStyle: "bold",
                                    color: "black",
                                    // boxShadow: "inset 0px 0px 4px 2px rgba(0, 0, 0, 0.4)",
                                    borderRadius: "10px",
                                    background: "white",
                                    border: "1px solid #999999",
                                }}
                            />
                            {
                                isLoading || loading ? (
                                    <div className="text-center">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="pt-3">
                                            <button type="submit" id="cleanpro-verify-otp" className="btn big-button form-control" disabled={otp.length < 6}>{t("Verify")}</button>
                                        </div>
                                        <p className="cleanpro-register-subtitle mt-5" id="cleanpro-resend-otp" onClick={() => resendOtp()}>{t("Resend OTP")} &gt;</p>
                                    </div>
                                )
                            }
                            {
                                error ? (
                                    <div className="text-center m-0 p-0">
                                        <p style={{ color: "red" }} className="m-0 p-0">
                                            <small>
                                                <strong>{t(error)}</strong>
                                            </small>
                                        </p>
                                    </div>
                                ) : null
                            }
                        </div>
                    ) : step === 3 ? (
                        <div>
                            <p className="cleanpro-register-title">{t("Welcome onboard!")}</p>
                            <p className="cleanpro-register-subtitle pt-2">{t("Please fill in the info below")}</p>
                            <div className="form-group mt-3">
                                <div className="input-field col">
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        className="form-control"
                                        onChange={e => setName(e.target.value)}
                                        value={name}
                                        placeholder={t("Enter your name*")}
                                        required
                                    />
                                    <span className="form-prepend">
                                        <i className="bi bi-person-vcard-fill"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="form-group mt-3">
                                <div className="input-field col">
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        className="form-control"
                                        onChange={e => setEmail(e.target.value)}
                                        value={email}
                                        placeholder={t("Enter your email address*")}
                                        required
                                    />
                                    <span className="form-prepend">
                                        <i className="bi bi-envelope"></i>
                                    </span>
                                </div>
                            </div>
                            <div className="form-group mt-3">
                                <div className="input-field col">
                                    <input
                                        type={show ? "text" : "password"}
                                        id="password"
                                        name="password"
                                        className="form-control"
                                        onChange={e => setPassword(e.target.value)}
                                        value={password}
                                        minLength="6"
                                        placeholder={t("Create a password*")}
                                        required
                                    />
                                    <span onClick={() => setShow(!show)} className="form-prepend">
                                        <i className={show ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"}></i>
                                    </span>
                                </div>
                            </div>
                            {
                                referral ? (
                                    <div className="form-group mt-3">
                                        <div className="input-field col">
                                            <input
                                                type="text"
                                                id="referId"
                                                name="referId"
                                                className="form-control"
                                                onChange={(e) => setReferId(e.target.value)}
                                                value={referId}
                                                placeholder={t("Enter referral code (if got any)")}
                                            />
                                            <span className="form-prepend" style={{ color: "black" }}>
                                                <i className="bi bi-people-fill"></i>
                                            </span>
                                        </div>
                                    </div>
                                ) : null
                            }
                            {
                                isLoading || loading ? (
                                    <div className="text-center">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="py-3">
                                        <button type="submit" className="btn big-button form-control" id="cleanpro-register" disabled={!name || !email || !password}>{t("Get started")}</button>
                                    </div>
                                )
                            }
                            {
                                error ? (
                                    <div className="text-center m-0 p-0">
                                        <p style={{ color: "red" }} className="m-0 p-0">
                                            <small>
                                                <strong>{t(error)}</strong>
                                            </small>
                                        </p>
                                    </div>
                                ) : null
                            }
                        </div>
                    ) : null
                }
            </form>

            <p style={{ fontSize: "12px", color: "#999999" }} className="link-text">
                {t("Copyright © 2023 Cleanpro Laundry Holdings Sdn Bhd (821842-P) All rights reserved.")}
            </p>

        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        isLoading: state.user.isLoading,
        error: state.user.error,
        operatorId: state.user.operatorId,
        machine: state.payment.machine,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        register: (data) => dispatch(cleanproRegister(data)),
        change: (page) => dispatch(changePage(page)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CleanproRegister);
