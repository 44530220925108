import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const translationEn = {
  "LOGIN": "LOGIN",
  "REGISTER": "REGISTER",
  "SKIP TO PAYMENT": "SKIP TO PAYMENT",
  "WELCOME": "WELCOME",
  "Error": "Error",
  "Please enter the correct phone number format. <br /><br /> <small>Example: 0129465628</small>": "Please enter the correct phone number format. <br /><br /> <small>Example: 0129465628</small>",
  "Info": "Info",
  "Please scan QRCode to Login": "Please scan QRCode to Login",
  "Forgot Password": "Forgot Password",
  "Seems like you have forgotten your password<br />Create a temporary password?": "Seems like you have forgotten your password<br />Create a temporary password?",
  "Cancel": "Cancel",
  "Confirm": "Confirm",
  "Reset Your Password": "Reset Your Password",
  "Enter Your Phone Number<br /><small>(e.g: 012xxxxxxx)</small>": "Enter Your Phone Number<br /><small>(e.g: 012xxxxxxx)</small>",
  "Enter Your Phone Number<br /><small>(e.g: 09xxxxxxxx)</small>": "Enter Your Phone Number<br /><small>(e.g: 09xxxxxxxx)</small>",
  "Submit": "Submit",
  "Please enter a valid phone number": "Please enter a valid phone number",
  "Success!": "Success!",
  "You may login using your one time password now.": "You may login using your one time password now.",
  "Phone Number": "Phone Number",
  "Password": "Password",
  "Don't have an account?": "Don't have an account?",
  "Forgot password?": "Forgot password?",
  "Unable to register without a QRCode": "Unable to register without a QRCode",
  "Please read and agree to the privacy policies, terms and conditions": "Please read and agree to the privacy policies, terms and conditions",
  "Password length must be at least 6 characters": "Password length must be at least 6 characters",
  "Please enter the confirmation code that was sent to your phone": "Please enter the confirmation code that was sent to your phone",
  "Example: 012xxxxxxx": "Example: 012xxxxxxx",
  "Example: 09xxxxxxxx": "Example: 09xxxxxxxx",
  "Name": "Name",
  "Email": "Email",
  "optional": "optional",
  "Birth Year": "Birth Year",
  "Year": "Year",
  "Birth Month": "Birth Month",
  "Month": "Month",
  "Jan": "Jan",
  "Feb": "Feb",
  "Mar": "Mar",
  "April": "April",
  "May": "May",
  "June": "June",
  "July": "July",
  "Aug": "Aug",
  "Sept": "Sept",
  "Oct": "Oct",
  "Nov": "Nov",
  "Dec": "Dec",
  "Birth Day": "Birth Day",
  "Day": "Day",
  "OTP Confirmation": "OTP Confirmation",
  "I agree to the": "I agree to the",
  "Privacy Policy": "Privacy Policy",
  "and": "and",
  "Terms": "Terms",
  "Conditions": "Conditions",
  "SEND CODE": "SEND CODE",
  "Already have an account? Login": "Already have an account? Login",
  "Didn't receive a code?": "Didn't receive a code?",
  "Resend Code": "Resend Code",
  "collected": "collected",
  "Collect": "Collect",
  "stamps": "stamps",
  "get 1 voucher": "get 1 voucher",
  "Minimum": "Minimum",
  "spending to collect each washer stamp": "spending to collect each washer stamp",
  "spending to collect each dryer stamp": "spending to collect each dryer stamp",
  "spending to collect each stamp": "spending to collect each stamp",
  "Device don't have a camera": "Device don't have a camera",
  "This is not a valid QRCode": "This is not a valid QRCode",
  "Select Camera": "Select Camera",
  "Switch": "Switch",
  "Camera": "Camera",
  "Loading": "Loading",
  "Scan the QR code on the machine.": "Scan the QR code on the machine.",
  "Switch Camera": "Switch Camera",
  "Off touch light": "Off touch light",
  "On touch light": "On touch light",
  "switch camera if your camera is not clear": "switch camera if your camera is not clear",
  "Switch your camera if the display is not clear": "Switch your camera if the display is not clear",
  "Please allow this webapp to access your camera": "Please allow this webapp to access your camera",
  "DISCOUNT": "DISCOUNT",
  "Expired Date": "Expired Date",
  "Voucher": "Voucher",
  "Something went wrong": "Something went wrong",
  "Stamping Collection": "Stamping Collection",
  "Total e-Token": "Total e-Token",
  "Total Wash": "Total Wash",
  "Total Dry": "Total Dry",
  "Reload": "Reload",
  "Washer": "Washer",
  "Dryer": "Dryer",
  "Congratulations!": "Congratulations!",
  "You have completed your Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "You have completed your Stamp collection.<br />Granted a voucher and stamp collection is refreshed",
  "You have completed Washer Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "You have completed Washer Stamp collection.<br />Granted a voucher and stamp collection is refreshed",
  "You have completed Dryer Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "You have completed Dryer Stamp collection.<br />Granted a voucher and stamp collection is refreshed",
  "Latest News": "Latest News",
  "Transaction History": "Transaction History",
  "Stamping": "Stamping",
  "Transaction": "Transaction",
  "News": "News",
  "Confirmation": "Confirmation",
  "Logout Session?": "Logout Session?",
  "NO": "NO",
  "OK": "OK",
  "Account": "Account",
  "FAQs": "FAQs",
  "Feedback": "Feedback",
  "LOGOUT": "LOGOUT",
  "Logout": "Logout",
  "Are you sure you want to submit your feedback?": "Are you sure you want to submit your feedback?",
  "No": "No",
  "Yes": "Yes",
  "Success": "Success",
  "Failed": "Failed",
  "Your feedback is submitted successfully": "Your feedback is submitted successfully",
  "Please fill in all the required fields before submit.": "Please fill in all the required fields before submit.",
  "Give us your feedback!": "Give us your feedback!",
  "Type your name here (optional)": "Type your name here (optional)",
  "required": "required",
  "Type your number here": "Type your number here",
  "Outlet": "Outlet",
  "Select an outlet": "Select an outlet",
  "Outlet Cleanliness": "Outlet Cleanliness",
  "Washer Machine Performance": "Washer Machine Performance",
  "Dryer Machine Performance": "Dryer Machine Performance",
  "Customer Service": "Customer Service",
  "Comment": "Comment",
  "Type your comment here (optional)": "Type your comment here (optional)",
  "SUBMIT": "SUBMIT",
  "Join as a member now!": "Join as a member now!",
  "Join as a loyalty member now to get more benefits such as vouchers and free machine usage!<br /><br /><strong>Register as loyalty member?</strong>": "Join as a loyalty member now to get more benefits such as vouchers and free machine usage!<br /><br /><strong>Register as loyalty member?</strong>",
  "Maybe Next Time": "Maybe Next Time",
  "Yes, Register Now": "Yes, Register Now",
  "Washer Payment": "Washer Payment",
  "Dryer Payment": "Dryer Payment",
  "Vending Machine Payment": "Vending Machine Payment",
  "Online": "Online",
  "Offline": "Offline",
  "Running": "Running",
  "Vacant": "Vacant",
  "Vending Machine": "Vending Machine",
  "Discount": "Discount",
  "No Vouchers Available": "No Vouchers Available",
  "Payment Method": "Payment Method",
  "Would you like to pay with?": "Would you like to pay with?",
  "e-Token": "e-Token",
  "E-TOKEN": "E-TOKEN",
  "E-Wallet": "E-Wallet",
  "Pay": "Pay",
  "to": "to",
  "You may under utilized the voucher.": "You may under utilized the voucher.",
  "Proceed to pay": "Proceed to pay",
  "Processing ...": "Processing ...",
  "You can't pay with e-token if voucher is in used, please remove your voucher to pay with e-token.": "You can't pay with e-token if voucher is in used, please remove your voucher to pay with e-token.",
  "Insufficient e-Tokens": "Insufficient e-Tokens",
  "Reload your e-token now?": "Reload your e-token now?",
  "Reload now!": "Reload now!",
  "You have": "You have",
  "e-Tokens": "e-Tokens",
  "Tokens to": "Tokens to",
  "This machine is currently offline, please try again later.": "This machine is currently offline, please try again later.",
  "MACHINE": "MACHINE",
  "Machine": "Machine",
  "TYPE": "TYPE",
  "CAPACITY": "CAPACITY",
  "DURATION": "DURATION",
  "Select Duration": "Select Duration",
  "AMOUNT TO PAY": "AMOUNT TO PAY",
  "PRICE": "PRICE",
  "VOUCHER": "VOUCHER",
  "Select Voucher >": "Select Voucher >",
  "TOTAL": "TOTAL",
  "PAY": "PAY",
  "Remove Voucher": "Remove Voucher",
  "Select Your Voucher": "Select Your Voucher",
  "min": "min",
  "MIN": "MIN",
  "Select Payment Amount": "Select Payment Amount",
  "Select Water Temperature": "Select Water Temperature",
  "Select temperature": "Select temperature",
  "Cold": "Cold",
  "Warm": "Warm",
  "Hot": "Hot",
  "e-Tokens to": "e-Tokens to",
  "No Purchase History": "No Purchase History",
  "e-tokens": "e-tokens",
  "No Promotions": "No Promotions",
  "New Password": "New Password",
  "Back": "Back",
  "Buy this package?": "Buy this package?",
  "Purchase": "Purchase",
  "e-Tokens for": "e-Tokens for",
  "Reload Tokens": "Reload Tokens",
  "Current e-Token": "Current e-Token",
  "History": "History",
  "Your profile picture has been updated!": "Your profile picture has been updated!",
  "Please only use png, jpg or jpeg file extension type": "Please only use png, jpg or jpeg file extension type",
  "Please only use image type file": "Please only use image type file",
  "User name has updated successfully!": "User name has updated successfully!",
  "Email Address updated successfully": "Email Address updated successfully",
  "Password must be at least 6 characters": "Password must be at least 6 characters",
  "Password updated successfully": "Password updated successfully",
  "User Settings": "User Settings",
  "Profile Picture": "Profile Picture",
  "Change Password": "Change Password",
  "Do you want to submit your feedback?": "Do you want to submit your feedback?",
  "Thank You!": "Thank You!",
  "Thank you for using our services.": "Thank you for using our services.",
  "BACK TO HOMEPAGE": "BACK TO HOMEPAGE",
  "How to pay?": "How to pay?",
  "Step 1": "Step 1",
  "After scan the QR code on the machine, select either register new account, login your account or skip to payment.": "After scan the QR code on the machine, select either register new account, login your account or skip to payment.",
  "Step 2-1": "Step 2-1",
  "For Dryer – Select your desired duration. Different duration will have different prices.": "For Dryer – Select your desired duration. Different duration will have different prices.",
  "Step 2-2": "Step 2-2",
  "For Washer – Select your desired water temperature. Different water temperature will have different prices.": "For Washer – Select your desired water temperature. Different water temperature will have different prices.",
  "Step 3": "Step 3",
  "For login user, Click on ‘Select Voucher’ to select your voucher.": "For login user, Click on ‘Select Voucher’ to select your voucher.",
  "Step 4": "Step 4",
  "Click on ‘PAY’ button to pay. For login user, select your payment method to pay.": "Click on ‘PAY’ button to pay. For login user, select your payment method to pay.",
  "Step 5": "Step 5",
  "After confirm the price and machine details, click on ‘OK’ button to pay.": "After confirm the price and machine details, click on ‘OK’ button to pay.",
  "How to reload your e-Token?": "How to reload your e-Token?",
  'Click on the "Reload" button.': 'Click on the "Reload" button.',
  "Select the package that you wish to purchase.": "Select the package that you wish to purchase.",
  'Click on "Purchase" as confirmation before payment page.': 'Click on "Purchase" as confirmation before payment page.',
  "Why can't I applied my voucher?": "Why can't I applied my voucher?",
  "When there is a Happy Hour Promotion is on going, you are unable to apply your voucher temporarily.": "When there is a Happy Hour Promotion is on going, you are unable to apply your voucher temporarily.",
  "How to apply my voucher?": "How to apply my voucher?",
  'Click on the "Select Voucher" button.': 'Click on the "Select Voucher" button.',
  "Select the voucher that you wish to apply.": "Select the voucher that you wish to apply.",
  'Click on "Pay" once you have verified the voucher is successfully applied.': 'Click on "Pay" once you have verified the voucher is successfully applied.',
  "Step 2": "Step 2",
  "Language has updated successfully!": "Language has updated successfully!",
  "Change Language": "Change Language",
  "Language": "Language",
  "Select language": "Select language",
  "Referral Code": "Referral Code",
  "Copied!": "Copied!",
  "Inviting friends to get more benefits!": "Inviting friends to get more benefits!",
  "Would you like to share your referral code to your friends & family?": "Would you like to share your referral code to your friends & family?",
  "Refer a friend and get": "Refer a friend and get",
  "Share the love to your friends & family?": "Share the love to your friends & family?",
  "voucher": "voucher",
  "token": "token",
  "stamp": "stamp",
  "washer stamp": "washer stamp",
  "dryer stamp": "dryer stamp",
  "Learn More": "Learn More",
  "Welcome Gift": "Welcome Gift",
  "Share Your Referral Code Now": "Share Your Referral Code Now",
  "Birthday Gift for You": "Birthday Gift for You",
  "Let's Pay with E-Token": "Let's Pay with E-Token",
  "Collect 10 Stamps and Get Voucher": "Collect 10 Stamps and Get Voucher",
  "The voucher will be given if you registered your account successfully.": "The voucher will be given if you registered your account successfully.",
  "The voucher is valid for ": "The voucher is valid for ",
  "days from that day you registered.": "days from that day you registered.",
  "There is no expiration date for this voucher.": "There is no expiration date for this voucher.",
  "The voucher can be used for dryer machine only.": "The voucher can be used for dryer machine only.",
  "The voucher can be used for washer machine only.": "The voucher can be used for washer machine only.",
  "The voucher can be used for dryer or washer machine.": "The voucher can be used for dryer or washer machine.",
  "When you applied the voucher during payment, it will give you": "When you applied the voucher during payment, it will give you",
  "discount.": "discount.",
  "When promotion is on going, the voucher is not applicable.": "When promotion is on going, the voucher is not applicable.",
  "The voucher cannot be used if your payment method is E-Token.": "The voucher cannot be used if your payment method is E-Token.",
  "Your referral code can be found in user settings page.": "Your referral code can be found in user settings page.",
  "You can get": "You can get",
  "stamp for the reward.": "stamp for the reward.",
  "e-token for the reward.": "e-token for the reward.",
  "You can get a voucher for the reward": "You can get a voucher for the reward",
  "which have": "which have",
  "it can be used for dryer machine only.": "it can be used for dryer machine only.",
  "it can be used for washer machine only.": "it can be used for washer machine only.",
  "it can be used for dryer or washer machine.": "it can be used for dryer or washer machine.",
  "it is valid for ": "it is valid for ",
  " days from that day the voucher given.": " days from that day the voucher given.",
  "washer stamp for the reward.": "washer stamp for the reward.",
  "dryer stamp for the reward.": "dryer stamp for the reward.",
  "washer stamp and": "washer stamp and",
  "cleanproBirthdayShortDesc_one": "Redeem {{ birthdayVoucherAmount }} credit birthday voucher on your special day with Cleanpro Plus. Tap to learn more.",
  "cleanproBirthdayShortDesc_other": "Redeem {{ birthdayVoucherAmount }} credits birthday voucher on your special day with Cleanpro Plus. Tap to learn more.",
  'When you click on "PAY" button on the payment page, you can choose either using E-wallet or E-token to pay.': 'When you click on "PAY" button on the payment page, you can choose either using E-wallet or E-token to pay.',
  "When you using your voucher during payment, you will be unable to pay with E-token.": "When you using your voucher during payment, you will be unable to pay with E-token.",
  "You can reload your E-token by clicking the reload button in the stamping page and select the package to purchase.": "You can reload your E-token by clicking the reload button in the stamping page and select the package to purchase.",
  "You can reload your E-token by clicking the reload button in the home page and select the package to purchase.": "You can reload your E-token by clicking the reload button in the home page and select the package to purchase.",
  "You also can view your token purchase history in transaction page.": "You also can view your token purchase history in transaction page.",
  "Using either dryer machine or washer machine with the minimum spending of": "Using either dryer machine or washer machine with the minimum spending of",
  "to collect one stamp.": "to collect one stamp.",
  "The voucher will be given if you have collected 10 stamps.": "The voucher will be given if you have collected 10 stamps.",
  "Using dryer machine with the minimum spending of": "Using dryer machine with the minimum spending of",
  "Using washer machine with the minimum spending of": "Using washer machine with the minimum spending of",
  "to collect one dryer stamp.": "to collect one dryer stamp.",
  "to collect one washer stamp.": "to collect one washer stamp.",
  "The voucher will be given if you have collected 10 dryer stamps or 10 washer stamps.": "The voucher will be given if you have collected 10 dryer stamps or 10 washer stamps.",
  "For the voucher given by the dryer stamps,": "For the voucher given by the dryer stamps,",
  "It is valid for ": "It is valid for ",
  "For the voucher given by the washer stamps,": "For the voucher given by the washer stamps,",
  "When promotion is on going, all the vouchers is not applicable.": "When promotion is on going, all the vouchers is not applicable.",
  "All the vouchers cannot be used if your payment method is E-Token.": "All the vouchers cannot be used if your payment method is E-Token.",
  "Description": "Description",
  "Refer Friends": "Refer Friends",
  "View our guideline": "View our guideline",
  "When a friend / family register using your referral code, and completed a transaction with a minimum spending of": "When a friend / family register using your referral code, and completed a transaction with a minimum spending of",
  ", you will be rewarded automatically.": ", you will be rewarded automatically.",
  "One user only allow to have": "One user only allow to have",
  "referrals.": "referrals.",
  "Confirmation Password": "Confirmation Password",
  "Password and confirmation password should be same.": "Password and confirmation password should be same.",
  "You haven't applied your voucher": "You haven't applied your voucher",
  "Would you like to continue your payment without using your voucher?": "Would you like to continue your payment without using your voucher?",
  "Continue": "Continue",
  "Apply voucher": "Apply voucher",
  "Transfer": "Transfer",
  "Transfer Tokens": "Transfer Tokens",
  "Please fill in the phone number": "Please fill in the phone number",
  "Please enter the phone number that you want to transfer to": "Please enter the phone number that you want to transfer to",
  "Please fill in the transfer amount": "Please fill in the transfer amount",
  "Transfer amount should be more than 0 and less than your current e-Token": "Transfer amount should be more than 0 and less than your current e-Token",
  "Please enter your password": "Please enter your password",
  "Member ID": "Member ID",
  "E-Token Transfer Amount": "E-Token Transfer Amount",
  "Please confirm the details before proceeding": "Please confirm the details before proceeding",
  "Transfer From": "Transfer From",
  "Transfer To": "Transfer To",
  "Transfer Amount": "Transfer Amount",
  "Transfer Successfully!": "Transfer Successfully!",
  "Transfer Time": "Transfer Time",
  "Token": "Token",
  "Done": "Done",
  "Next": "Next",
  "Enter Password": "Enter Password",
  "Transfer E-Token Amount": "Transfer E-Token Amount",
  "User not found": "User not found",
  "Password is incorrect": "Password is incorrect",
  "Please go to your account setting and reset your password": "Please go to your account setting and reset your password",
  "Only up to last 3 months transaction history": "Only up to last 3 months transaction history",
  "Reload History": "Reload History",
  "Transfer History": "Transfer History",
  "Only up to last 3 months reload history": "Only up to last 3 months reload history",
  "Only up to last 3 months transfer history": "Only up to last 3 months transfer history",
  "If require urgent response, please contact us through whatapp": "If require urgent response, please contact us through whatapp",
  "Please scan the QR code again": "Please scan the QR code again",
  "Home": "Home",
  "Quick": "Quick",
  "Super": "Super",
  "Premium": "Premium",
  "All users will have infinite referrals.": "All users will have infinite referrals.",
  "Collect 10 stamps to get 1 Voucher": "Collect 10 stamps to get 1 Voucher",
  "The voucher is only for customer use only, not for sale.": "The voucher is only for customer use only, not for sale.",
  "Outlet is currently offline, please try again later": "Outlet is currently offline, please try again later",
  "Price is not set, please contact the admin": "Price is not set, please contact the admin",
  "The voucher will be given if you completed a transaction with a minimum spending of": "The voucher will be given if you completed a transaction with a minimum spending of",
  "The birthday voucher will be given on the first day of your birthday month.": "The birthday voucher will be given on the first day of your birthday month.",
  "The voucher only will be given if you have exceed spending amount of": "The voucher only will be given if you have exceed spending amount of",
  "Pay with KiplePay": "Pay with KiplePay",
  "Pay with Scan2Pay": "Pay with Scan2Pay",
  "DISCOUNT CODE": "DISCOUNT CODE",
  "Enter code >": "Enter code >",
  "Enter Discount Code": "Enter Discount Code",
  "Remove Code": "Remove Code",
  "Discount Code": "Discount Code",
  "Close": "Close",
  "Please fill in your email address": "Please fill in your email address",
  "Notification": "Notification",
  "Do you want to receive notification when wash cycle done?": "Do you want to receive notification when wash cycle done?",
  "pay extra": "pay extra",
  "Email Address": "Email Address",
  "Please fill in your phone number": "Please fill in your phone number",
  "Invalid Phone Number": "Invalid Phone Number",
  "Washing Complete": "Washing Complete",
  "Drying Complete": "Drying Complete",
  "The voucher will be given if you registered your account successfully, but it only can be used when your total transaction spending reach": "The voucher will be given if you registered your account successfully, but it only can be used when your total transaction spending reach",
  "and above.": "and above.",
  "This voucher is not applicable": "This voucher is not applicable",
  "Finish by": "Finish by",
  "We'll let you know when it's ready to pick up": "We'll let you know when it's ready to pick up",
  // cleanpro plus en
  "Wash": "Wash",
  "Dry": "Dry",
  "Scan QR": "Scan QR",
  "Capacity": "Capacity",
  "Duration": "Duration",
  "Custom duration": "Custom duration",
  "Choose duration": "Choose duration",
  "Select the correct amount on the machine after payment.": "Select the correct amount on the machine after payment.",
  "Offers": "Offers",
  "An easier way to pay": "An easier way to pay",
  "Create account": "Create account",
  "Login": "Login",
  "By signing up, you agree to": "By signing up, you agree to",
  "Amount to Pay": "Amount to Pay",
  "1 voucher selected": "1 voucher selected",
  "No voucher selected": "No voucher selected",
  "Credits": "Credits",
  "credits": "credits",
  "credit": "credit",
  "Please ensure that the machine door is shut & your laundry is inside.": "Please ensure that the machine door is shut & your laundry is inside.",
  "Insufficient balance": "Insufficient balance",
  "Topup now": "Topup now",
  "Copyright © 2023 Cleanpro Laundry Holdings Sdn Bhd (821842-P) All rights reserved.": "Copyright © 2023 Cleanpro Laundry Holdings Sdn Bhd (821842-P) All rights reserved.",
  "Good Evening!": "Good Evening!",
  "Wallet Balance": "Wallet Balance",
  "Topup": "Topup",
  "More details": "More details",
  "Collect 10 e-stamps to earn x1 FREE wash or dry voucher": "Collect 10 e-stamps to earn x1 FREE wash or dry voucher",
  "For every wash or dry cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "For every wash or dry cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!",
  "For every wash cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "For every wash cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!",
  "For every dry cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "For every dry cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!",
  "Credits OFF": "Credits OFF",
  "% OFF": "% OFF",
  "Valid Till": "Valid Till",
  "Used On": "Used On",
  "Expired On": "Expired On",
  "Available": "Available",
  "Expired": "Expired",
  "Used": "Used",
  "Promotions": "Promotions",
  "Rewards": "Rewards",
  "Inbox": "Inbox",
  "Activities": "Activities",
  "Support": "Support",
  "Active Rewards": "Active Rewards",
  "Past Rewards": "Past Rewards",
  "Get rewarded for your loyalty with Cleanpro Plus Rewards!": "Get rewarded for your loyalty with Cleanpro Plus Rewards!",
  "Earn vouchers and redeem them for discounts, freebies and more. The more you use our services the more you save!": "Earn vouchers and redeem them for discounts, freebies and more. The more you use our services the more you save!",
  "Up to 3 months history": "Up to 3 months history",
  "Filter": "Filter",
  "All Transactions": "All Transactions",
  "Transactions": "Transactions",
  "Select amount to topup": "Select amount to topup",
  "Balance": "Balance",
  "You've just paid": "You've just paid",
  "Need help?": "Need help?",
  "24-Hour Careline Number": "24-Hour Careline Number",
  "Email Us": "Email Us",
  "Call Now": "Call Now",
  "Email Now": "Email Now",
  "Update name": "Update name",
  "Update email address": "Update email address",
  "Change password": "Change password",
  "Change language": "Change language",
  "User ID": "User ID",
  "Let us know your feedback": "Let us know your feedback",
  "Poor": "Poor",
  "Good": "Good",
  "Leave addtional feedback here (optional)": "Leave addtional feedback here (optional)",
  "Submit feedback": "Submit feedback",
  "Pay as guest": "Pay as guest",
  "Pay now": "Pay now",
  "Select voucher to use": "Select voucher to use",
  "Use voucher": "Use voucher",
  "Total": "Total",
  "Transaction Type": "Transaction Type",
  "Credit Topup": "Credit Topup",
  "Credit Transfer": "Credit Transfer",
  "Date/Time": "Date/Time",
  "Reference ID": "Reference ID",
  "Status": "Status",
  "Share Your Referral Code": "Share Your Referral Code",
  "cleanproReferralShortDesc_one": "Get free {{ voucherAmount }} credit when you share your referral code to friends and family after a successful transaction. Tap to learn more.",
  "cleanproReferralShortDesc_other": "Get free {{ voucherAmount }} credits when you share your referral code to friends and family after a successful transaction. Tap to learn more.",
  "Use": "Use",
  "dryer or washer": "dryer or washer",
  "washer": "washer",
  "dryer": "dryer",
  "dryer and washer": "dryer and washer",
  "machine with the minimum spending of": "machine with the minimum spending of",
  "Welcome to Cleanpro Plus": "Welcome to Cleanpro Plus",
  "Only scan, set & pay! An easier, more convenient way to pay with Cleanpro Plus! No need to download an app. Enjoy more rewards when you use Cleanpro Plus today! Tap to learn more.": "Only scan, set & pay! An easier, more convenient way to pay with Cleanpro Plus! No need to download an app. Enjoy more rewards when you use Cleanpro Plus today! Tap to learn more.",
  "Cleanpro Pay Migration Notice": "Cleanpro Pay Migration Notice",
  "Dear valued Cleanpro customers, please be informed that as of 22 June 2023, the Cleanpro Pay app will be upgraded and and no longer available for use. Tap to learn more.": "Dear valued Cleanpro customers, please be informed that as of 22 June 2023, the Cleanpro Pay app will be upgraded and and no longer available for use. Tap to learn more.",
  "Answer simple questions and win credits for your next wash or dry! This contest is only available for selected outlets only. Tap to learn more.": "Answer simple questions and win credits for your next wash or dry! This contest is only available for selected outlets only. Tap to learn more.",
  "The all-new Cleanpro Plus is a new payment method that includes loyalty features built into the app. The more you use our services and pay using Cleanpro Plus, the more you can save. With every transaction, you can earn rewards that will help you save on your next laundry expenses.": "The all-new Cleanpro Plus is a new payment method that includes loyalty features built into the app. The more you use our services and pay using Cleanpro Plus, the more you can save. With every transaction, you can earn rewards that will help you save on your next laundry expenses.",
  "To use Cleanpro Plus, simply scan the QR code on the machines and pay using your preferred credit/debit card or e-wallet. We hope that Cleanpro Plus will help simplify your laundry routine and give you more time for the things that matter.": "To use Cleanpro Plus, simply scan the QR code on the machines and pay using your preferred credit/debit card or e-wallet. We hope that Cleanpro Plus will help simplify your laundry routine and give you more time for the things that matter.",
  "If you have any questions about Cleanpro Plus, please read our FAQ in the menu or contact our careline at +603 2770 0100 or email us at info@cleanprolaundry.com. Thank you for choosing Cleanpro.": "If you have any questions about Cleanpro Plus, please read our FAQ in the menu or contact our careline at +603 2770 0100 or email us at info@cleanprolaundry.com. Thank you for choosing Cleanpro.",
  "Dear valued Cleanpro customers, please be informed that as of 22 June 2023, the existing Cleanpro Pay mobile app will be upgraded and no longer available for use.": "Dear valued Cleanpro customers, please be informed that as of 22 June 2023, the existing Cleanpro Pay mobile app will be upgraded and no longer available for use.",
  "We are migrating to the all-new Cleanpro Plus. It is a new cashless payment method, without the need to download apps.": "We are migrating to the all-new Cleanpro Plus. It is a new cashless payment method, without the need to download apps.",
  "If you are the existing user of Cleanpro Pay, any of your remaining balance in the app will be transferred to your Cleanpro Plus account. You are required to use the same mobile number to sign up for Cleanpro Plus for balance transfer.": "If you are the existing user of Cleanpro Pay, any of your remaining balance in the app will be transferred to your Cleanpro Plus account. You are required to use the same mobile number to sign up for Cleanpro Plus for balance transfer.",
  "If you need help on Cleanpro Plus, please feel free to call our careline at +603 2770 0100 or email us at info@cleanprolaundry.com": "If you need help on Cleanpro Plus, please feel free to call our careline at +603 2770 0100 or email us at info@cleanprolaundry.com",
  "Answer simple questions and win credits for your next wash or dry!": "Answer simple questions and win credits for your next wash or dry!",
  "Step 1 : Scan the Jawab & Gerenti Menang QR code in the participated outlets.": "Step 1 : Scan the Jawab & Gerenti Menang QR code in the participated outlets.",
  "Step 2 : Answer 3 simple questions.": "Step 2 : Answer 3 simple questions.",
  "Step 3 : Submit and stand a chance to win prizes.": "Step 3 : Submit and stand a chance to win prizes.",
  "Grand Prize :": "Grand Prize :",
  "x10 per outlet - 100 credits worth of vouchers in Cleanpro Plus": "x10 per outlet - 100 credits worth of vouchers in Cleanpro Plus",
  "Consolation Prize :": "Consolation Prize :",
  "X25 per outlet - 50 credits worth of vouchers in Cleanpro Plus": "X25 per outlet - 50 credits worth of vouchers in Cleanpro Plus",
  "Participation prize:": "Participation prize:",
  "Every participants are eligible to receive a FREE 3 credits voucher (worth RM3) in Cleanpro Plus.": "Every participants are eligible to receive a FREE 3 credits voucher (worth RM3) in Cleanpro Plus.",
  "If you have any questions please don't hesitate to contact our careline at +603 2770 0100 or email us at info@cleanprolaundry.com. Thank you for choosing Cleanpro.": "If you have any questions please don't hesitate to contact our careline at +603 2770 0100 or email us at info@cleanprolaundry.com. Thank you for choosing Cleanpro.",
  "Payment Confirmation": "Payment Confirmation",
  "This campaign is only eligible for users who use the all-new Cleanpro Plus web application.": "This campaign is only eligible for users who use the all-new Cleanpro Plus web application.",
  "ONE e-stamp will be rewarded for each wash or dry cycle.": "ONE e-stamp will be rewarded for each wash or dry cycle.",
  "ONE e-stamp will be rewarded for each wash cycle.": "ONE e-stamp will be rewarded for each wash cycle.",
  "ONE e-stamp will be rewarded for each dry cycle.": "ONE e-stamp will be rewarded for each dry cycle.",
  "Regardless of the washer or dryer settings, the number of e-stamps being collected is the same.": "Regardless of the washer or dryer settings, the number of e-stamps being collected is the same.",
  "The type of e-stamp collected is based on the services that you have used.": "The type of e-stamp collected is based on the services that you have used.",
  "Washing service will reward washer e-stamp and drying service will reward dryer e-stamp.": "Washing service will reward washer e-stamp and drying service will reward dryer e-stamp.",
  "Washing service will reward washer e-stamp.": "Washing service will reward washer e-stamp.",
  "Drying service will reward dryer e-stamp.": "Drying service will reward dryer e-stamp.",
  "E-stamps will automatically be added to your account once the condition has been fulfilled.": "E-stamps will automatically be added to your account once the condition has been fulfilled.",
  "There is no limit on how many e-stamps can be gathered in a single day. So long as you meet the requirements to collect the e-stamps.": "There is no limit on how many e-stamps can be gathered in a single day. So long as you meet the requirements to collect the e-stamps.",
  "The type of e-stamps that have been collected is final and cannot be changed.": "The type of e-stamps that have been collected is final and cannot be changed.",
  "There is no expiration for the e-stamps; only till the discontinuation of the campaign.": "There is no expiration for the e-stamps; only till the discontinuation of the campaign.",
  "E-stamps do not have any currency value, and they cannot be converted to cash or credits.": "E-stamps do not have any currency value, and they cannot be converted to cash or credits.",
  "This campaign is not applicable with any other ongoing promotions, offers, vouchers, and/or discounts provided by the participating outlets.": "This campaign is not applicable with any other ongoing promotions, offers, vouchers, and/or discounts provided by the participating outlets.",
  "These terms and conditions are subject to change at any time without prior notice.": "These terms and conditions are subject to change at any time without prior notice.",
  "Press START on the machine to begin washing.": "Press START on the machine to begin washing.",
  "Press START on the machine to begin drying.": "Press START on the machine to begin drying.",
  "Reminder": "Reminder",
  "Remember to select the same settings you have paid for on the corresponding machine.": "Remember to select the same settings you have paid for on the corresponding machine.",
  "Please ensure that your laundry is inside the machine and door is properly shut.": "Please ensure that your laundry is inside the machine and door is properly shut.",
  "voucherCreditsOff_one": "{{currentVoucherAmount}} Credit OFF",
  "voucherCreditsOff_other": "{{currentVoucherAmount}} Credits OFF",
  "voucherPercentageOff": "{{currentVoucherAmount}}% OFF",
  "cleanproReferralFlatVoucher_one": "Get free credits when you share your Cleanpro Plus referral code to your friends and family! For each successful referral who completes their first transaction, you’ll get free {{ referralVoucherAmount }} credit voucher.",
  "cleanproReferralFlatVoucher_other": "Get free credits when you share your Cleanpro Plus referral code to your friends and family! For each successful referral who completes their first transaction, you’ll get free {{ referralVoucherAmount }} credits voucher.",
  "cleanproReferralRateVoucher": "Get free credits when you share your Cleanpro Plus referral code to your friends and family! For each successful referral who completes their first transaction, you’ll get free {{ referralVoucherAmount }}% Off voucher.",
  "cleanproReferralTokenReward_one": "Get free credits when you share your Cleanpro Plus referral code to your friends and family! For each successful referral who completes their first transaction, you’ll get free {{ referralToken }} credit.",
  "cleanproReferralTokenReward_other": "Get free credits when you share your Cleanpro Plus referral code to your friends and family! For each successful referral who completes their first transaction, you’ll get free {{ referralToken }} credits.",
  "How to get & use code:": "How to get & use code:",
  'Go to menu = icon and click “Refer friends”': 'Go to menu = icon and click “Refer friends”',
  "Share with everyone who will likely to use Cleanpro Plus.": "Share with everyone who will likely to use Cleanpro Plus.",
  "Referral Terms & Conditions:": "Referral Terms & Conditions:",
  "cleanproReferralMinimumSpend": "When your friend or family register using your referral code, and completed a transaction (a minimum spending of RM{{ referralTransactionAmount }}) , you will be rewarded automatically.",
  "cleanproNumberOfReferralReward": "You may share with as many people as you want, but each user is ONLY able to receive {{ numberOfReferral }} referral reward.",
  "cleanproNumberOfReferral_one": "You may share with as many people as you want, but each user is ONLY able to receive {{ numberOfReferral }} referral voucher.",
  "cleanproNumberOfReferral_other": "You may share with as many people as you want, but each user is ONLY able to receive {{ numberOfReferral }} referral vouchers.",
  "cleanproVoucherExpiry_one": "The voucher is valid for {{ voucherDuration }} day from the day voucher be given.",
  "cleanproVoucherExpiry_other": "The voucher is valid for {{ voucherDuration }} days from the day voucher be given.",
  "The voucher is for customer use only and not for sale.": "The voucher is for customer use only and not for sale.",
  "cleanproBirthdayFlatAmount": "We believe that your special day deserves a little extra sparkle! That's why we're thrilled to introduce our exclusive birthday rewards program just for you! Starting this year, and every year thereafter, we're giving away RM{{ voucherAmount }} credit vouchers to you on your birthday.",
  "cleanproBirthdayRateAmount": "We believe that your special day deserves a little extra sparkle! That's why we're thrilled to introduce our exclusive birthday rewards program just for you! Starting this year, and every year thereafter, we're giving away {{ voucherAmount }}% vouchers to you on your birthday.",
  "Make sure your account details include your birthdate. This way, we'll know exactly when to send you your special birthday surprise.": "Make sure your account details include your birthdate. This way, we'll know exactly when to send you your special birthday surprise.",
  "Birthday Voucher Terms & Conditions:": "Birthday Voucher Terms & Conditions:",
  "The voucher will be given on the first day of your birthday month.": "The voucher will be given on the first day of your birthday month.",
  "cleanproVoucherFlat": "When you applied the voucher during payment, it will give you {{ voucherAmount }} credits (RM{{ voucherAmount }}) discount.",
  "cleanproVoucherRate": "When you applied the voucher during payment, it will give you {{ voucherAmount }}% discount.",
  "All vouchers is not valid with other ongoing promotions, discounts and offers.": "All vouchers is not valid with other ongoing promotions, discounts and offers.",
  "Claim a complimentary wash and dry for every 10 e-stamps collected! We value your loyalty and want to reward you for choosing Cleanpro Plus. Tap to learn more.": "Claim a complimentary wash and dry for every 10 e-stamps collected! We value your loyalty and want to reward you for choosing Cleanpro Plus. Tap to learn more.",
  "cleanproStampFlat_one": "With every 10 e-stamps that you collected, you can claim up to {{ voucherAmount }} credit of free wash or dry session with Cleanpro Plus. Make your laundry routine not just hassle-free, but also rewarding.",
  "cleanproStampFlat_other": "With every 10 e-stamps that you collected, you can claim up to {{ voucherAmount }} credits of free wash or dry session with Cleanpro Plus. Make your laundry routine not just hassle-free, but also rewarding.",
  "cleanproStampRate": "With every 10 e-stamps that you collected, you can claim up to {{ voucherAmount }}% of free wash or dry session with Cleanpro Plus. Make your laundry routine not just hassle-free, but also rewarding.",
  "A voucher will be instantly added to your Rewards page when you reach 10 washes. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "A voucher will be instantly added to your Rewards page when you reach 10 washes. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.",
  "A voucher will be instantly added to your Rewards page when you reach 10 drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "A voucher will be instantly added to your Rewards page when you reach 10 drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.",
  "A voucher will be instantly added to your Rewards page when you reach 10 washes or drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "A voucher will be instantly added to your Rewards page when you reach 10 washes or drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.",
  "Terms & Conditions:": "Terms & Conditions:",
  "cleanproStampTransactionAmount_one": "To earn ONE e-stamp, you must spend at least {{ stampTransactionAmount }} credit (RM{{ stampTransactionAmount }}) per session.",
  "cleanproStampTransactionAmount_other": "To earn ONE e-stamp, you must spend at least {{ stampTransactionAmount }} credits (RM{{ stampTransactionAmount }}) per session.",
  "You will be automatically issued a voucher if you have collected 10 washer stamps.": "You will be automatically issued a voucher if you have collected 10 washer stamps.",
  "You will be automatically issued a voucher if you have collected 10 dryer stamps.": "You will be automatically issued a voucher if you have collected 10 dryer stamps.",
  "You will be automatically issued a voucher if you have collected 10 dryer stamps or 10 washer stamps.": "You will be automatically issued a voucher if you have collected 10 dryer stamps or 10 washer stamps.",
  "You will be automatically issued a voucher if you have collected 10 stamps.": "You will be automatically issued a voucher if you have collected 10 stamps.",
  "The voucher is non-refundable and cannot be exchanged for cash; in part or in full.": "The voucher is non-refundable and cannot be exchanged for cash; in part or in full.",
  "This voucher is valid for single transaction only.": "This voucher is valid for single transaction only.",
  "General questions": "General questions",
  "E-stamps collection": "E-stamps collection",
  "Coin exchange machines": "Coin exchange machines",
  "Washer machines": "Washer machines",
  "Dryer machines": "Dryer machines",
  "Paywave issues": "Paywave issues",
  "Cleanpro franchise": "Cleanpro franchise",
  "How can I wash my laundry using Cleanpro Plus?": "How can I wash my laundry using Cleanpro Plus?",
  "Here are the steps to wash your laundry using Cleanpro Plus:": "Here are the steps to wash your laundry using Cleanpro Plus:",
  "Find an unoccupied washing machine in the store, and insert your laundry in the washer.": "Find an unoccupied washing machine in the store, and insert your laundry in the washer.",
  "Select the desired temperature that you would like to use for your laundry.": "Select the desired temperature that you would like to use for your laundry.",
  "Using the scanner in the app, scan the QR code provided on the washing machine. Then select the same settings that you have pressed on the machine.": "Using the scanner in the app, scan the QR code provided on the washing machine. Then select the same settings that you have pressed on the machine.",
  'Tap on “Pay with credits” to pay the amount shown on the app.': 'Tap on “Pay with credits” to pay the amount shown on the app.',
  "Once the payment has been accepted, press the START button on the washer machine.": "Once the payment has been accepted, press the START button on the washer machine.",
  "After the washing cycle ends, you can just remove your laundry from the machine.": "After the washing cycle ends, you can just remove your laundry from the machine.",
  "How can I dry my laundry using Cleanpro Plus?": "How can I dry my laundry using Cleanpro Plus?",
  "Here are the steps to dry your laundry using Cleanpro Plus:": "Here are the steps to dry your laundry using Cleanpro Plus:",
  "Find an unoccupied drying machine in the store, and insert your laundry in the dryer.": "Find an unoccupied drying machine in the store, and insert your laundry in the dryer.",
  "Select the desired temperature and duration that you would like to use for your laundry.": "Select the desired temperature and duration that you would like to use for your laundry.",
  "Using the scanner in the app, scan the QR code provided on the drying machine. Then select the same settings that you have pressed on the machine.": "Using the scanner in the app, scan the QR code provided on the drying machine. Then select the same settings that you have pressed on the machine.",
  "Once the payment has been accepted, press the START button on the dryer machine.": "Once the payment has been accepted, press the START button on the dryer machine.",
  "After the drying cycle ends, you can just remove your laundry from the machine.": "After the drying cycle ends, you can just remove your laundry from the machine.",
  "Why does the QR code I scanned take me to a different machine?": "Why does the QR code I scanned take me to a different machine?",
  "Here are the steps to solve it:": "Here are the steps to solve it:",
  "Make sure that the number on the QR code (e.g. W1, W2, etc) is the same as the machine that you would like to use.": "Make sure that the number on the QR code (e.g. W1, W2, etc) is the same as the machine that you would like to use.",
  "If the QR code number does not match the machine you want, use the other machine. Please contact our customer service line at 03-27700100 to report this issue.": "If the QR code number does not match the machine you want, use the other machine. Please contact our customer service line at 03-27700100 to report this issue.",
  "How can I add more time to the washer that I am currently using?": "How can I add more time to the washer that I am currently using?",
  "The washing time for our washer is fixed and cannot add any additional time.": "The washing time for our washer is fixed and cannot add any additional time.",
  "How can I add more time to the dryer that I am currently using?": "How can I add more time to the dryer that I am currently using?",
  "Here are the steps to add more time to the dryer machine:": "Here are the steps to add more time to the dryer machine:",
  "By using the scanner in the app, scan the QR code provided at the dryer machine.": "By using the scanner in the app, scan the QR code provided at the dryer machine.",
  "Select the amount of time that you would like to add to the machine.": "Select the amount of time that you would like to add to the machine.",
  "After the drying cycle ends, you can just remove the clothes from the machine.": "After the drying cycle ends, you can just remove the clothes from the machine.",
  "REMINDER - It is recommended to add drying time 5 minutes before the cycle ends. And you cannot add tokens for extra times when the machine finishes the drying cycle.": "REMINDER - It is recommended to add drying time 5 minutes before the cycle ends. And you cannot add tokens for extra times when the machine finishes the drying cycle.",
  "Will the timer keep running when I have stopped the drying machine?": "Will the timer keep running when I have stopped the drying machine?",
  "Yes. Even after you stop the machine, the timer will continue to run. If you want to extend the drying time, add drying time 5 minutes before the cycle ends. This prevents the cycle from ending before your clothes are completely dried.": "Yes. Even after you stop the machine, the timer will continue to run. If you want to extend the drying time, add drying time 5 minutes before the cycle ends. This prevents the cycle from ending before your clothes are completely dried.",
  "How do I reload my credits for Cleanpro Plus?": "How do I reload my credits for Cleanpro Plus?",
  "Here are the steps to reload your credits:": "Here are the steps to reload your credits:",
  'Select the "Top Up Credit" button on the homepage.': 'Select the "Top Up Credit" button on the homepage.',
  "Choose the amount you want to reload and the payment method you want to use.": "Choose the amount you want to reload and the payment method you want to use.",
  "You will be taken to the payment page. The screen will return to Cleanpro Plus once the transaction is completed.": "You will be taken to the payment page. The screen will return to Cleanpro Plus once the transaction is completed.",
  "How many credits can I reload in one transaction?": "How many credits can I reload in one transaction?",
  "Reloading credits requires a minimum of RM 10 and a maximum of RM 50.": "Reloading credits requires a minimum of RM 10 and a maximum of RM 50.",
  "Can I pay using credits in the Cleanpro Plus app along with tokens or coins in a single transaction?": "Can I pay using credits in the Cleanpro Plus app along with tokens or coins in a single transaction?",
  "No. Only a single payment method may be chosen when performing any transactions.": "No. Only a single payment method may be chosen when performing any transactions.",
  "The amount I have reloaded in my account was not reflected in the app.": "The amount I have reloaded in my account was not reflected in the app.",
  "Please close and restart the application and check if the new balance has been updated.": "Please close and restart the application and check if the new balance has been updated.",
  "If the amount is still not updated, please contact our customer service line at 03-27700110 to receive further assistance.": "If the amount is still not updated, please contact our customer service line at 03-27700110 to receive further assistance.",
  "Can I cash out the credits I reloaded in my account for this app?": "Can I cash out the credits I reloaded in my account for this app?",
  "No. The credits that you have reloaded will remain in your Cleanpro Plus account.": "No. The credits that you have reloaded will remain in your Cleanpro Plus account.",
  "I have requested a refund, but I still have not received it.": "I have requested a refund, but I still have not received it.",
  "Please contact our customer service line at 03-27700100 to receive further assistance.": "Please contact our customer service line at 03-27700100 to receive further assistance.",
  "I have made the payment, and the app double or triple charged me.": "I have made the payment, and the app double or triple charged me.",
  "The washing machine cannot start even after I have paid using the app.": "The washing machine cannot start even after I have paid using the app.",
  "Ensure that the temperature settings you have selected and paid for on the application are the same as what you have pressed on the washing machine. If not, select the correct temperature settings on the washing machine and try again.": "Ensure that the temperature settings you have selected and paid for on the application are the same as what you have pressed on the washing machine. If not, select the correct temperature settings on the washing machine and try again.",
  "If the machine is still not able to be started, please contact our customer service line at 03-27700100 to receive further assistance.": "If the machine is still not able to be started, please contact our customer service line at 03-27700100 to receive further assistance.",
  "Can I use multiple vouchers together in the same washing or drying cycle?": "Can I use multiple vouchers together in the same washing or drying cycle?",
  "No. Only a single voucher can be used for each washing and drying cycle.": "No. Only a single voucher can be used for each washing and drying cycle.",
  "Why can't I use my free wash voucher that I received after signing up?": "Why can't I use my free wash voucher that I received after signing up?",
  "To redeem or use the voucher, you must complete at least one washing or drying cycle.": "To redeem or use the voucher, you must complete at least one washing or drying cycle.",
  "Can I change my phone number even after registering my account?": "Can I change my phone number even after registering my account?",
  "No. Once registered to an account, the phone number is locked into the account.": "No. Once registered to an account, the phone number is locked into the account.",
  "I have problems in the store, and who can I refer to for help?": "I have problems in the store, and who can I refer to for help?",
  "Do not worry, as you can contact our customer service line at 03-27700100 to receive assistance at any time.": "Do not worry, as you can contact our customer service line at 03-27700100 to receive assistance at any time.",
  "Should I add my own laundry detergent and softeners to the washing machine?": "Should I add my own laundry detergent and softeners to the washing machine?",
  "There is no need for that, as all you have to do is bring your dirty clothes and put them in the washer. The detergent and softeners will be provided by us, and you can rest assured that the laundry detergents we used are certified industrial grade.": "There is no need for that, as all you have to do is bring your dirty clothes and put them in the washer. The detergent and softeners will be provided by us, and you can rest assured that the laundry detergents we used are certified industrial grade.",
  "I would like to add my own laundry detergent and softener to the washing machine.": "I would like to add my own laundry detergent and softener to the washing machine.",
  "We do not recommend our customers add their own detergent, as the mixture of different detergents may affect the wash quality. And you can rest assured that the laundry detergent we used is certified industrial grade.": "We do not recommend our customers add their own detergent, as the mixture of different detergents may affect the wash quality. And you can rest assured that the laundry detergent we used is certified industrial grade.",
  "What time does the store close?": "What time does the store close?",
  "Our self-service laundry operation is open 24 hours a day, which means you can always be sure to go back home with a fresh set of laundry.": "Our self-service laundry operation is open 24 hours a day, which means you can always be sure to go back home with a fresh set of laundry.",
  "Are you open on weekends?": "Are you open on weekends?",
  "Yes. Cleanpro is also open on weekends and even on public holidays.": "Yes. Cleanpro is also open on weekends and even on public holidays.",
  "How many clothes can I fit in the washing or drying machine?": "How many clothes can I fit in the washing or drying machine?",
  "To ensure that your laundry is properly washed or dried, keep the amount of laundry inside the machine lower than the line of guide sticker on the machine.": "To ensure that your laundry is properly washed or dried, keep the amount of laundry inside the machine lower than the line of guide sticker on the machine.",
  "How many e-stamps can I collect in a single day?": "How many e-stamps can I collect in a single day?",
  "There is no limit to the amount of stamps that can be collected in a single day; ONE stamp will be awarded with every wash or dry.": "There is no limit to the amount of stamps that can be collected in a single day; ONE stamp will be awarded with every wash or dry.",
  "When I use the washing service, can I change the collected stamp to a dryer stamp, and vice versa?": "When I use the washing service, can I change the collected stamp to a dryer stamp, and vice versa?",
  "No, the type of stamp collected is only applied to the service that you have used.": "No, the type of stamp collected is only applied to the service that you have used.",
  "What temperatures can I choose when I claim the free washing e-stamp reward?": "What temperatures can I choose when I claim the free washing e-stamp reward?",
  "Any washing temperature is available for the e-stamp reward.": "Any washing temperature is available for the e-stamp reward.",
  "What temperatures can I choose when I claim the free drying e-stamp reward? And how long can I set the time?": "What temperatures can I choose when I claim the free drying e-stamp reward? And how long can I set the time?",
  "Any drying temperature and duration can be selected for the e-stamp reward.": "Any drying temperature and duration can be selected for the e-stamp reward.",
  "I'm unable to change the coin.": "I'm unable to change the coin.",
  "What colour is the light shown on the machine, red or green? If it is a red light, please contact our customer service line at 03-27700100 to receive further assistance.": "What colour is the light shown on the machine, red or green? If it is a red light, please contact our customer service line at 03-27700100 to receive further assistance.",
  "Green Light": "Green Light",
  "Red Light": "Red Light",
  "Machine functions as normal. Please try again.": "Machine functions as normal. Please try again.",
  "(flashing 2x) - There are no coins/tokens in the machine.": "(flashing 2x) - There are no coins/tokens in the machine.",
  "OR": "OR",
  "Note is jammed, please proceed to contact customer service": "Note is jammed, please proceed to contact customer service",
  "(flashing 3x) - Note is jammed, please proceed to contact customer service": "(flashing 3x) - Note is jammed, please proceed to contact customer service",
  "My notes are stuck in the machine.": "My notes are stuck in the machine.",
  "My detergent is not dispensing in the vending machine.": "My detergent is not dispensing in the vending machine.",
  "The water is leaking out of the washing machine.": "The water is leaking out of the washing machine.",
  "Please check the washer machine to see whether there are any clothes that are stuck at the door side.": "Please check the washer machine to see whether there are any clothes that are stuck at the door side.",
  "If yes, here are the steps to solve it:": "If yes, here are the steps to solve it:",
  "Press the emergency stop button (RED Button) for 3 seconds.": "Press the emergency stop button (RED Button) for 3 seconds.",
  "Wait for the sound to stop, and then only open the door. (If after 30 minutes the sound still has not stopped, please contact our customer service line at 03-27700100 to receive further assistance)": "Wait for the sound to stop, and then only open the door. (If after 30 minutes the sound still has not stopped, please contact our customer service line at 03-27700100 to receive further assistance)",
  "Ensure the door is closed tightly, and press the START button.": "Ensure the door is closed tightly, and press the START button.",
  "REMINDER - The emergency stop button can only be used once.": "REMINDER - The emergency stop button can only be used once.",
  "There is no detergent dispensing in the washing machine.": "There is no detergent dispensing in the washing machine.",
  "Ensure that the machine is in operation and that the START button was pressed.": "Ensure that the machine is in operation and that the START button was pressed.",
  "If the detergent is still not dispensing, please contact our customer service department at 03-27700100 for further assistance.": "If the detergent is still not dispensing, please contact our customer service department at 03-27700100 for further assistance.",
  "The price displayed on the machine's screen did not change after inserting the token.": "The price displayed on the machine's screen did not change after inserting the token.",
  "Press the small button (in the centre) to reject the tokens that have been inserted. Press a few times till the coins come out.": "Press the small button (in the centre) to reject the tokens that have been inserted. Press a few times till the coins come out.",
  "If the coins still cannot come out or the button is not functioning, please contact our customer service line at 03-27700110 to receive further assistance.": "If the coins still cannot come out or the button is not functioning, please contact our customer service line at 03-27700110 to receive further assistance.",
  "The machine is not starting, and it displays an error code.": "The machine is not starting, and it displays an error code.",
  "We would like to apologise for any inconvenience caused. Please contact our customer service line at 03-27700100 to receive further assistance.": "We would like to apologise for any inconvenience caused. Please contact our customer service line at 03-27700100 to receive further assistance.",
  "I want to wash the clothes again, but why couldn't the machine start even after I added tokens to the machine?": "I want to wash the clothes again, but why couldn't the machine start even after I added tokens to the machine?",
  "Try opening and closing the door again before starting the machine.": "Try opening and closing the door again before starting the machine.",
  "Can I change the water temperature of the machine?": "Can I change the water temperature of the machine?",
  "Yes, but the washer temperature can only be changed from hot to cold after the machine has been started, and not from cold to hot.": "Yes, but the washer temperature can only be changed from hot to cold after the machine has been started, and not from cold to hot.",
  "Can I stop the machine?": "Can I stop the machine?",
  "Here are the steps to stop the machine:": "Here are the steps to stop the machine:",
  "Wait for the sound to stop, and then only open the door. (If after 30 minutes the sound still has not stopped, please contact our customer service line at to receive further assistance)": "Wait for the sound to stop, and then only open the door. (If after 30 minutes the sound still has not stopped, please contact our customer service line at to receive further assistance)",
  "There is no water supply on the machine used.": "There is no water supply on the machine used.",
  "Why is the washing temperature cold even when I have selected HOT.": "Why is the washing temperature cold even when I have selected HOT.",
  "The water temperature is HOT or WARM only during washing. The water will turn COLD during rinsing.": "The water temperature is HOT or WARM only during washing. The water will turn COLD during rinsing.",
  "The machine has already finished washing, but it does not spin well, and there is water left on the load.": "The machine has already finished washing, but it does not spin well, and there is water left on the load.",
  "Can I put wet laundry into the dryer to dry?": "Can I put wet laundry into the dryer to dry?",
  "No, as the dripping wet laundry might cause the dryer to short circuit.": "No, as the dripping wet laundry might cause the dryer to short circuit.",
  "REMINDER - Refer to the labels on your clothes to know what kind of temperature is best for the kind of fabric that it uses. Then select the temperature on the machine accordingly.": "REMINDER - Refer to the labels on your clothes to know what kind of temperature is best for the kind of fabric that it uses. Then select the temperature on the machine accordingly.",
  "My clothes are still wet even after drying.": "My clothes are still wet even after drying.",
  "How can I stop the dryer when the machine is still running?": "How can I stop the dryer when the machine is still running?",
  "Just open the door and the machine will automatically stop.": "Just open the door and the machine will automatically stop.",
  "I added extra tokens for extra times, but the machine did not update the time.": "I added extra tokens for extra times, but the machine did not update the time.",
  "For the time to be reflected accurately, it is recommended for you to add tokens 5 minutes before the services end.": "For the time to be reflected accurately, it is recommended for you to add tokens 5 minutes before the services end.",
  "REMINDER - You cannot add tokens for extra times when the machine finishes the drying cycle.": "REMINDER - You cannot add tokens for extra times when the machine finishes the drying cycle.",
  "Why is the machine making noise?": "Why is the machine making noise?",
  "Please check the drum to see if there are any coins or other small things inside.": "Please check the drum to see if there are any coins or other small things inside.",
  "I have pressed the START button on the dryer, but it is still not working.": "I have pressed the START button on the dryer, but it is still not working.",
  "Please try to open the door and close it again. Next, press the START button again to start drying.": "Please try to open the door and close it again. Next, press the START button again to start drying.",
  "If the dryer is still not functioning, please contact our customer service line at 03-27700100 to receive further assistance.": "If the dryer is still not functioning, please contact our customer service line at 03-27700100 to receive further assistance.",
  "Paywave has deducted an amount from the card but the machine did not reflect the transaction.": "Paywave has deducted an amount from the card but the machine did not reflect the transaction.",
  "The Paywave transaction has failed but the amount has still been deducted from my card.": "The Paywave transaction has failed but the amount has still been deducted from my card.",
  "Paywave has double/triple charge but the machine cannot start.": "Paywave has double/triple charge but the machine cannot start.",
  "Paywave after double/triple charge machines can't start.": "Paywave after double/triple charge machines can't start.",
  "Paywave is offline": "Paywave is offline",
  "Do Cleanpro Express offer franchise business/selling machines/laundry products?": "Do Cleanpro Express offer franchise business/selling machines/laundry products?",
  "For further inquiries, please email to info@cleanproexpress.com": "For further inquiries, please email to info@cleanproexpress.com",
  "Step 6": "Step 6",
  "User Existed": "User Existed",
  "Invalid Email Address": "Invalid Email Address",
  "Let's get started!": "Let's get started!",
  "Enter your mobile number": "Enter your mobile number",
  "Proceed": "Proceed",
  "Enter OTP number": "Enter OTP number",
  "You will receive a six-digit OTP via SMS sent to": "You will receive a six-digit OTP via SMS sent to",
  "Verify": "Verify",
  "Resend OTP": "Resend OTP",
  "Welcome onboard!": "Welcome onboard!",
  "Please fill in the info below": "Please fill in the info below",
  "Enter your name*": "Enter your name*",
  "Enter your email address*": "Enter your email address*",
  "Create a password*": "Create a password*",
  "Enter referral code (if got any)": "Enter referral code (if got any)",
  "Get started": "Get started",
  "Birthday updated successfully": "Birthday updated successfully",
  "Date of birth": "Date of birth",
  "Enter your date of birth": "Enter your date of birth",
  "Select your birth date": "Select your birth date",
  "Update date of birth": "Update date of birth",
  "Update birth date": "Update birth date",
  "Already have an account?": "Already have an account?",
  "Login now": "Login now",
  "Sign in": "Sign in",
  "Sign up now": "Sign up now",
  "COMPLETED": "COMPLETED",
  "WASHING NOW": "WASHING NOW",
  "DRYING NOW": "DRYING NOW",
  "It's done!": "It's done!",
  "Please collect your fresh, clean laundry in order to leave the machine available for the next person to use!": "Please collect your fresh, clean laundry in order to leave the machine available for the next person to use!",
  "Leave a feedback": "Leave a feedback",
  "Time left": "Time left",
  "Add drying time": "Add drying time",
  "Hi": "Hi",
  "customer": "customer",
  "Thank you for your payment. Don't forget to select the <b>correct temperature</b> and press <b>START</b> when you're ready.": "Thank you for your payment. Don't forget to select the <b>correct temperature</b> and press <b>START</b> when you're ready.",
  "Select how you want to receive a notification when your laundry is finished": "Select how you want to receive a notification when your laundry is finished",
  "Sign up": "Sign up",
  "Continue to pay": "Continue to pay",
  "Register as a member to enjoy more benefits!": "Register as a member to enjoy more benefits!",
  "Get FREE 3 credits per new user": "Get FREE 3 credits per new user",
  "Enjoy exclusive promotions": "Enjoy exclusive promotions",
  "FREE voucher on your birthday": "FREE voucher on your birthday",
  "and more": "and more",
  "Denied camera access": "Denied camera access",
  'Permission to access the camera has been denied. Please try again and click "Allow" to scan the QR code.': 'Permission to access the camera has been denied. Please try again and click "Allow" to scan the QR code.',
  "Okay": "Okay",
  "Oops. You don't have enough balance to make the payment": "Oops. You don't have enough balance to make the payment",
  "Please confirm": "Please confirm",
  "Do you want to proceed with this feedback?": "Do you want to proceed with this feedback?",
  "Are you sure to purchase": "Are you sure to purchase",
  "credits for": "credits for",
  "Topup credit": "Topup credit",
  "Extended duration will be instantly reflected on machine after payment": "Extended duration will be instantly reflected on machine after payment",
  "Machine is currently busy": "Machine is currently busy",
  "Do you want to extend drying time?": "Do you want to extend drying time?",
  "Cuci Hari-Hari Bersama Cleanpro!": "Cuci Hari-Hari Bersama Cleanpro!",
  "Ready for a challenge?": "Ready for a challenge?",
  "Here's one for you - all you need to do is wash your laundry 10 times in 20 days at participating Cleanpro outlets. Stand a chance to win a Limited Edition Cleanpro Designer Laundry Bag worth up to RM40!": "Here's one for you - all you need to do is wash your laundry 10 times in 20 days at participating Cleanpro outlets. Stand a chance to win a Limited Edition Cleanpro Designer Laundry Bag worth up to RM40!",
  "Here's how to participate:": "Here's how to participate:",
  "Step 1: Scan the QR Code on the washer to launch Cleanpro Plus": "Step 1: Scan the QR Code on the washer to launch Cleanpro Plus",
  "Step 2: Pay for your wash with Cleanpro Plus": "Step 2: Pay for your wash with Cleanpro Plus",
  "Step 3: Complete x10 washes within 20 days to win": "Step 3: Complete x10 washes within 20 days to win",
  "Challenge Period: 01 July - 31 August 2023": "Challenge Period: 01 July - 31 August 2023",
  "Drop by your nearest Cleanpro outlet to wash your laundry with us for 20 days straight! Good luck!": "Drop by your nearest Cleanpro outlet to wash your laundry with us for 20 days straight! Good luck!",
  "TERMS & CONDITIONS": "TERMS & CONDITIONS",
  "PARTICIPATION CRITERIA": "PARTICIPATION CRITERIA",
  "1) Participant needs to register an account in Cleanpro Plus web app.": "1) Participant needs to register an account in Cleanpro Plus web app.",
  "2) Participant can use any washer of any capacity in any participating outlets to qualify for entries.": "2) Participant can use any washer of any capacity in any participating outlets to qualify for entries.",
  "3) Payment needs to be made with Cleanpro Plus web app.": "3) Payment needs to be made with Cleanpro Plus web app.",
  "4) Washes must be completed and paid (with no dispute whatsoever) within campaign period.": "4) Washes must be completed and paid (with no dispute whatsoever) within campaign period.",
  "5) Campaign period starts from 1st July 2023 at 00:00 and ends at 31st August 2023 at 23:59.": "5) Campaign period starts from 1st July 2023 at 00:00 and ends at 31st August 2023 at 23:59.",
  "WINNERS & PRIZES": "WINNERS & PRIZES",
  "1) Cleanpro will notify the winners of the campaign via registered WhatsApp number or email address provided, within 4 weeks after the campaign ends.": "1) Cleanpro will notify the winners of the campaign via registered WhatsApp number or email address provided, within 4 weeks after the campaign ends.",
  "2) Any unclaimed prizes after 2 weeks from notification date will not be entertained thereafter.": "2) Any unclaimed prizes after 2 weeks from notification date will not be entertained thereafter.",
  "3) Upon completing the ten (10) washes within twenty (20) days campaign, participants will be a winner and be eligible to win ONE (1) Cleanpro Designer Laundry Bag.": "3) Upon completing the ten (10) washes within twenty (20) days campaign, participants will be a winner and be eligible to win ONE (1) Cleanpro Designer Laundry Bag.",
  "4) Winners are eligible to win more than ONE (1) Cleanpro Designer Laundry Bag in this campaign.": "4) Winners are eligible to win more than ONE (1) Cleanpro Designer Laundry Bag in this campaign.",
  "5) Winners will be given the choice of his/her preferred laundry bag size; but only ONE (1) can be selected for each qualified win.": "5) Winners will be given the choice of his/her preferred laundry bag size; but only ONE (1) can be selected for each qualified win.",
  "A) XL Size - Blue bag with up to 20KG in capacity": "A) XL Size - Blue bag with up to 20KG in capacity",
  "B) L Size - White bag with up to 12KG in capacity": "B) L Size - White bag with up to 12KG in capacity",
  "6) Time and location for collection of prize will be informed via Whatsapp number and/or email address provided.": "6) Time and location for collection of prize will be informed via Whatsapp number and/or email address provided.",
  "OTHER": "OTHER",
  "1) Changes to the terms and conditions will be at Cleanpro's discretion; without prior notice.": "1) Changes to the terms and conditions will be at Cleanpro's discretion; without prior notice.",
  "2) Completed wash is defined by washes performed with Cleanpro's washer and payments within Cleanpro Plus web app are made with no disputes.": "2) Completed wash is defined by washes performed with Cleanpro's washer and payments within Cleanpro Plus web app are made with no disputes.",
  "3) By participating in this campaign automatically indicates that the Participant has agreed to all the campaign's Terms and Conditions as stipulated.": "3) By participating in this campaign automatically indicates that the Participant has agreed to all the campaign's Terms and Conditions as stipulated.",
  "Welcome Voucher": "Welcome Voucher",
  "Welcome to the Cleanpro Plus family! This first gift is just the beginning. Stay tuned for a series of exciting promotions and campaigns, exclusively for Cleanpro Plus users.": "Welcome to the Cleanpro Plus family! This first gift is just the beginning. Stay tuned for a series of exciting promotions and campaigns, exclusively for Cleanpro Plus users.",
  "Terms & Conditions": "Terms & Conditions",
  "Welcome Voucher is valid for one-time use only.": "Welcome Voucher is valid for one-time use only.",
  "There is no minimum spend required for the voucher to be used.": "There is no minimum spend required for the voucher to be used.",
  "Welcome Voucher is auto-credited to the user's account at the point of registration on Cleanpro Plus and valid for 3 months only.": "Welcome Voucher is auto-credited to the user's account at the point of registration on Cleanpro Plus and valid for 3 months only.",
  "Users are eligible to use their Welcome Voucher AFTER the completion of their first wash or dry cycle using the same Cleanpro Plus account.": "Users are eligible to use their Welcome Voucher AFTER the completion of their first wash or dry cycle using the same Cleanpro Plus account.",
  "Welcome Voucher cannot be used together with any ongoing promotions or any other promotional vouchers.": "Welcome Voucher cannot be used together with any ongoing promotions or any other promotional vouchers.",
  "The voucher is final and cannot be refunded in form of cash or credit to the user.": "The voucher is final and cannot be refunded in form of cash or credit to the user.",
  "Cleanpro reserves the right to change the terms and conditions without prior notice.": "Cleanpro reserves the right to change the terms and conditions without prior notice.",
  "Collect 10 e-stamps to earn FREE 5 credits voucher (worth RM5)": "Collect 10 e-stamps to earn FREE 5 credits voucher (worth RM5)",
  "With every 10 e-stamps that you collected, you will be rewarded with a FREE 5 credits voucher (worth RM5). Make your laundry routine not just hassle-free, but also rewarding.": "With every 10 e-stamps that you collected, you will be rewarded with a FREE 5 credits voucher (worth RM5). Make your laundry routine not just hassle-free, but also rewarding.",
  "To earn ONE e-stamp, you must spend at least 5 credits (RM5) per session.": "To earn ONE e-stamp, you must spend at least 5 credits (RM5) per session.",
  "The voucher is valid for 60 days from the day voucher be given.": "The voucher is valid for 60 days from the day voucher be given.",
  "When you applied the voucher during payment, it will give you 5 credits (RM5) discount.": "When you applied the voucher during payment, it will give you 5 credits (RM5) discount.",
  "How to participate:": "How to participate:",
  "Prizes to be won:": "Prizes to be won:",
  "RM10 OFF Welcome Voucher": "RM10 OFF Welcome Voucher",
  "Welcome to the Cleanpro family, here is an appreciation voucher from us. Thank you for attending the COPE Investors Conference.": "Welcome to the Cleanpro family, here is an appreciation voucher from us. Thank you for attending the COPE Investors Conference.",
  "Welcome Voucher | COPE Investors Conference": "Welcome Voucher | COPE Investors Conference",
  "Payment methods": "Payment methods",
  "Tap to proceed with your preferred mode of payment": "Tap to proceed with your preferred mode of payment",
  "Pay now with credits": "Pay now with credits",
  "Pay with e-wallet": "Pay with e-wallet",
  "Go to homepage": "Go to homepage",
}

const translationMs = {
  "LOGIN": "LOG MASUK",
  "REGISTER": "DAFTAR",
  "SKIP TO PAYMENT": "LANGKAU KE BAYARAN",
  "WELCOME": "SELAMAT DATANG",
  "Error": "Ralat",
  "Please enter the correct phone number format. <br /><br /> <small>Example: 0129465628</small>": "Sila masukkan format nombor telefon yang betul. <br /><br /> <small>Contoh: 0129465628</small>",
  "Info": "Maklumat",
  "Please scan QRCode to Login": "Sila mengimbas Kod QR untuk Log Masuk",
  "Forgot Password": "Lupa kata laluan",
  "Seems like you have forgotten your password<br />Create a temporary password?": "Nampaknya anda terlupa kata laluan anda<br />Adakah anda ingin membuat kata laluan sementara?",
  "Cancel": "Batal",
  "Confirm": "Sahkan",
  "Reset Your Password": "Reset kata laluan anda",
  "Enter Your Phone Number<br /><small>(e.g: 012xxxxxxx)</small>": "Masukkan nombor telefon anda<br /><small>(contoh: 012xxxxxxx)</small>",
  "Enter Your Phone Number<br /><small>(e.g: 09xxxxxxxx)</small>": "Masukkan nombor telefon anda<br /><small>(contoh: 09xxxxxxxx)</small>",
  "Submit": "Hantar",
  "Please enter a valid phone number": "Sila masukkan nombor telefon yang sah",
  "Success!": "Berjaya!",
  "You may login using your one time password now.": "Anda boleh log masuk menggunakan kata laluan sekali anda sekarang.",
  "Phone Number": "Nombor telefon",
  "Password": "Kata Laluan",
  "Don't have an account?": "Tidak mempunyai akaun?",
  "Forgot password?": "Lupa kata laluan?",
  "Unable to register without a QRCode": "Tidak dapat mendaftar tanpa Kod QR",
  "Please read and agree to the privacy policies, terms and conditions": "Sila baca dan setuju dengan polisi privasi, terma dan syarat",
  "Password length must be at least 6 characters": "Panjang kata laluan mestilah sekurang-kurangnya 6 huruf",
  "Please enter the confirmation code that was sent to your phone": "Sila masukkan kod pengesahan yang dihantar ke telefon anda",
  "Example: 012xxxxxxx": "Contoh: 012xxxxxxx",
  "Example: 09xxxxxxxx": "Contoh: 09xxxxxxxx",
  "Name": "Nama",
  "Email": "E-mel",
  "optional": "Tidak wajib diisi",
  "Birth Year": "Tahun lahir",
  "Year": "Tahun",
  "Birth Month": "Bulan Lahir",
  "Month": "Bulan",
  "Jan": "Jan",
  "Feb": "Feb",
  "Mar": "Mac",
  "April": "April",
  "May": "Mei",
  "June": "Jun",
  "July": "Julai",
  "Aug": "Ogos",
  "Sept": "Sept",
  "Oct": "Okt",
  "Nov": "Nov",
  "Dec": "Dis",
  "Birth Day": "Hari Lahir",
  "Day": "Hari",
  "OTP Confirmation": "Pengesahan OTP",
  "I agree to the": "Saya setuju dengan",
  "Privacy Policy": "Polisi Privasi",
  "and": "dan",
  "Terms": "Terma",
  "Conditions": "Syarat",
  "SEND CODE": "HANTAR KOD",
  "Already have an account? Login": "Sudah mempunyai akaun? Log masuk",
  "Didn't receive a code?": "Tidak menerima kod?",
  "Resend Code": "Hantar semula Kod",
  "collected": "dikumpulkan",
  "Collect": "Kumpulkan",
  "stamps": "setem",
  "get 1 voucher": "dapatkan 1 baucar",
  "Minimum": "Perbelanjaan minimum",
  "spending to collect each washer stamp": "untuk mengumpulkan setiap setem mesin basuh",
  "spending to collect each dryer stamp": "untuk mengumpulkan setiap setem pengering",
  "spending to collect each stamp": "untuk mengumpulkan setiap setem",
  "Device don't have a camera": "Peranti tidak mempunyai kamera",
  "This is not a valid QRCode": "Ini bukan Kod QR yang sah",
  "Select Camera": "Pilih Kamera",
  "Switch": "Tukar",
  "Camera": "Kamera",
  "Loading": "Memuatkan",
  "Scan the QR code on the machine.": "Imbas Kod QR pada mesin.",
  "Switch Camera": "Tukar Kamera",
  "Off touch light": "Matikan lampu sentuh",
  "On touch light": "Hidupkan lampu sentuh",
  "switch camera if your camera is not clear": "tukar kamera jika kamera anda tidak jelas",
  "Switch your camera if the display is not clear": "Tukar kamera anda jika paparan tidak jelas",
  "Please allow this webapp to access your camera": "Sila berikan kebenaran untuk aplikasi ini mengakses kamera anda",
  "DISCOUNT": "DISKAUN",
  "Expired Date": "Tarikh luput",
  "Voucher": "Baucar",
  "Something went wrong": "Hadapi masalah",
  "Stamping Collection": "Koleksi Setem",
  "Total e-Token": "Jumlah Token",
  "Total Wash": "Jumlah Cuci",
  "Total Dry": "Jumlah Kering",
  "Reload": "Tambah nilai",
  "Washer": "Mesin basuh",
  "Dryer": "Pengering",
  "Congratulations!": "Tahniah!",
  "You have completed your Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "Anda telah melengkapkan koleksi setem anda.<br />Pemberian baucar akan diberikan dan koleksi setem anda telah di set semula",
  "You have completed Washer Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "Anda telah melengkapkan koleksi setem mesin basuh.<br />Pemberian baucar akan diberikan dan koleksi setem anda telah di set semula",
  "You have completed Dryer Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "Anda telah melengkapkan koleksi setem pengering.<br />Pemberian baucar akan diberikan dan koleksi setem anda telah di set semula",
  "Latest News": "Berita Terkini",
  "Transaction History": "Sejarah Transaksi",
  "Stamping": "Setem",
  "Transaction": "Transaksi",
  "News": "Berita",
  "Confirmation": "Pengesahan",
  "Logout Session?": "Sesi Log Keluar?",
  "NO": "TIDAK",
  "OK": "OK",
  "Account": "Akaun",
  "FAQs": "Soalan Lazim",
  "Feedback": "Maklum balas",
  "LOGOUT": "LOG KELUAR",
  "Logout": "Log Keluar",
  "Are you sure you want to submit your feedback?": "Adakah anda pasti mahu menghantar maklum balas anda?",
  "No": "Tidak",
  "Yes": "Ya",
  "Success": "Berjaya",
  "Failed": "Gagal",
  "Your feedback is submitted successfully": "Maklum balas anda berjaya dihantar",
  "Please fill in all the required fields before submit.": "Sila isi semua ruangan yang diperlukan sebelum menghantar.",
  "Give us your feedback!": "Beri kami maklum balas anda!",
  "Type your name here (optional)": "Taipkan nama anda di sini (Tidak wajib diisi)",
  "required": "diperlukan",
  "Type your number here": "Taipkan nombor anda di sini",
  "Outlet": "Kedai",
  "Select an outlet": "Pilih kedai",
  "Outlet Cleanliness": "Kebersihan Kedai",
  "Washer Machine Performance": "Prestasi Mesin Basuh",
  "Dryer Machine Performance": "Prestasi Mesin Pengering",
  "Customer Service": "Khidmat Pelanggan",
  "Comment": "Komen",
  "Type your comment here (optional)": "Taipkan komen anda di sini (Tidak wajib diisi)",
  "SUBMIT": "HANTAR",
  "Join as a member now!": "Sertailah sebagai ahli sekarang!",
  "Join as a loyalty member now to get more benefits such as vouchers and free machine usage!<br /><br /><strong>Register as loyalty member?</strong>": "Sertailah sebagai ahli sekarang untuk mendapatkan lebih banyak faedah seperti baucar dan penggunaan mesin secara percuma!<br /><br /><strong>Daftar sebagai ahli?</strong>",
  "Maybe Next Time": "Mungkin lain kali",
  "Yes, Register Now": "Ya, Daftar Sekarang",
  "Washer Payment": "Bayaran Mesin Basuh",
  "Dryer Payment": "Bayaran Pengering",
  "Vending Machine Payment": "Bayaran Mesin Layan Diri",
  "Online": "Dalam talian",
  "Offline": "Luar talian",
  "Running": "Berjalan",
  "Vacant": "Kosong",
  "Vending Machine": "Mesin Layan Diri",
  "Discount": "Diskaun",
  "No Vouchers Available": " Tidak Ada Baucar",
  "Payment Method": "Kaedah Pembayaran",
  "Would you like to pay with?": "Anda ingin membayar dengan?",
  "e-Token": "e-Token",
  "E-TOKEN": "E-TOKEN",
  "E-Wallet": "E-Dompet",
  "Pay": "Bayar",
  "to": "kepada",
  "You may under utilized the voucher.": "Anda kurang menggunakan baucar.",
  "Proceed to pay": "Teruskan membayar",
  "Processing ...": "Memproses ...",
  "You can't pay with e-token if voucher is in used, please remove your voucher to pay with e-token.": "Anda tidak boleh membayar dengan penggunaan e-token jika menggunakan baucar, sila keluarkan baucar anda untuk membayar dengan e-token.",
  "Insufficient e-Tokens": "E-Token tidak mencukupi",
  "Reload your e-token now?": "Tambah nilai e-token anda sekarang?",
  "Reload now!": "Tambah nilai sekarang!",
  "You have": "Anda ada",
  "e-Tokens": "e-Token",
  "Tokens to": "Token kepada",
  "This machine is currently offline, please try again later.": "Mesin ini tiada dalam talian, sila cuba sebentar lagi.",
  "MACHINE": "MESIN",
  "Machine": "Mesin",
  "TYPE": "JENIS",
  "CAPACITY": "KAPASITI",
  "DURATION": "JANGKA MASA",
  "Select Duration": "Pilih Jangka Masa",
  "AMOUNT TO PAY": "JUMLAH BAYARAN",
  "PRICE": "HARGA",
  "VOUCHER": "BAUCAR",
  "Select Voucher >": "Pilih Baucar >",
  "TOTAL": "JUMLAH",
  "PAY": "BAYAR",
  "Remove Voucher": "Keluarkan Baucar",
  "Select Your Voucher": "Pilih Baucar Anda",
  "min": "min",
  "MIN": "MIN",
  "Select Payment Amount": "Pilih Jumlah Pembayaran",
  "Select Water Temperature": "Pilih Suhu Air",
  "Select temperature": "Pilih suhu",
  "Cold": "Sejuk",
  "Warm": "Suam",
  "Hot": "Panas",
  "e-Tokens to": "e-Token kepada",
  "No Purchase History": "Tiada Sejarah Pembelian",
  "e-tokens": "e-token",
  "No Promotions": "Tiada Promosi",
  "New Password": "Kata laluan baharu",
  "Back": "Kembali",
  "Buy this package?": "Beli pakej ini?",
  "Purchase": "Beli",
  "e-Tokens for": "e-Token dengan",
  "Reload Tokens": "Tambah nilai kepada token",
  "Current e-Token": "E-Token Sekarang",
  "History": "Sejarah",
  "Your profile picture has been updated!": "Gambar profil anda telah dikemas kini!",
  "Please only use png, jpg or jpeg file extension type": "Sila gunakan jenis sambungan fail png, jpg atau jpeg",
  "Please only use image type file": "Sila gunakan fail jenis gambar sahaja",
  "User name has updated successfully!": "Nama pengguna berjaya dikemas kini!",
  "Email Address updated successfully": "E-mel berjaya dikemas kini",
  "Password must be at least 6 characters": "Kata laluan mestilah sekurang-kurangnya 6 huruf",
  "Password updated successfully": "Kata laluan berjaya dikemas kini",
  "User Settings": "Tetapan Pengguna",
  "Profile Picture": "Gambar profil",
  "Change Password": "Tukar kata laluan",
  "Do you want to submit your feedback?": "Adakah anda ingin menghantar maklum balas anda?",
  "Thank You!": "Terima Kasih!",
  "Thank you for using our services.": "Terima kasih kerana menggunakan perkhidmatan kami.",
  "BACK TO HOMEPAGE": "KEMBALI KE HALAMAN UTAMA",
  "How to pay?": "Bagaimana untuk membayar?",
  "Step 1": "Langkah 1",
  "After scan the QR code on the machine, select either register new account, login your account or skip to payment.": "Setelah mengimbas kod QR pada mesin, pilih sama ada daftar akaun baru, log masuk akaun anda atau langkau ke pembayaran.",
  "Step 2-1": "Langkah 2-1",
  "For Dryer – Select your desired duration. Different duration will have different prices.": "Untuk Pengering - Pilih jangka masa yang anda inginkan. Tempoh yang berbeza akan mempunyai harga yang berbeza.",
  "Step 2-2": "Langkah 2-2",
  "For Washer – Select your desired water temperature. Different water temperature will have different prices.": "Untuk Mesin Basuh - Pilih suhu air yang anda inginkan. Suhu air yang berbeza akan mempunyai harga yang berbeza.",
  "Step 3": "Langkah 3",
  "For login user, Click on ‘Select Voucher’ to select your voucher.": "Untuk pengguna log masuk, Klik pada ‘Pilih Baucar’ untuk memilih baucar anda.",
  "Step 4": "Langkah 4",
  "Click on ‘PAY’ button to pay. For login user, select your payment method to pay.": "Klik butang ‘BAYAR’ untuk membayar. Untuk pengguna log masuk, pilih kaedah pembayaran anda untuk membayar.",
  "Step 5": "Langkah 5",
  "After confirm the price and machine details, click on ‘OK’ button to pay.": "Selepas mengesahkan harga dan butiran mesin, klik butang ‘OK’ untuk membayar.",
  "How to reload your e-Token?": "Bagaimana tambah nilai e-Token anda?",
  'Click on the "Reload" button.': 'Klik pada butang "Tambah Nilai".',
  "Select the package that you wish to purchase.": "Pilih pakej yang ingin anda beli.",
  'Click on "Purchase" as confirmation before payment page.': 'Klik pada "Beli" sebagai pengesahan sebelum halaman pembayaran.',
  "Why can't I applied my voucher?": "Mengapakah saya tidak boleh menggunakan baucar saya?",
  "When there is a Happy Hour Promotion is on going, you are unable to apply your voucher temporarily.": "Setiap kali promosi sedang berlangsung, anda tidak dapat menggunakan baucar anda.",
  "How to apply my voucher?": "Bagaimanakah mengguna baucar saya?",
  'Click on the "Select Voucher" button.': 'Klik pada butang "Pilih Baucar".',
  "Select the voucher that you wish to apply.": "Pilih baucar yang ingin anda gunakan.",
  'Click on "Pay" once you have verified the voucher is successfully applied.': 'Klik "Bayar" setelah anda mengesahkan baucar berjaya digunakan.',
  "Step 2": "Langkah 2",
  "Language has updated successfully!": "Bahasa berjaya dikemas kini!",
  "Change Language": "Tukar Bahasa",
  "Language": "Bahasa",
  "Select language": "Pilih Bahasa",
  "Referral Code": "Kod Rujukan",
  "Copied!": "Disalin!",
  "Inviting friends to get more benefits!": "Jemput rakan-rakan untuk mendapatkan lebih banyak faedah!",
  "Would you like to share your referral code to your friends & family?": "Adakah anda ingin berkongsi kod rujukan anda kepada rakan & keluarga anda?",
  "Refer a friend and get": "Rujuk rakan dan dapatkan",
  "Share the love to your friends & family?": "Kongsi kasih sayang kepada rakan & keluarga anda?",
  "voucher": "baucar",
  "token": "token",
  "stamp": "setem",
  "washer stamp": "setem mesin basuh",
  "dryer stamp": "setem pengering",
  "Learn More": "Ketahui Lebih Lanjut",
  "Welcome Gift": "Hadiah Selamat Datang",
  "Share Your Referral Code Now": "Kongsi Kod Rujukan Anda Sekarang",
  "Birthday Gift for You": "Hadiah Hari Lahir untuk Anda",
  "Let's Pay with E-Token": "Marilah Bayar dengan E-Token",
  "Collect 10 Stamps and Get Voucher": "Kumpul 10 Setem dan Dapatkan Baucar",
  "The voucher will be given if you registered your account successfully.": "Baucar akan diberikan jika anda berjaya mendaftar akaun anda.",
  "The voucher is valid for ": "Baucar sah selama ",
  "days from that day you registered.": "hari dari hari itu anda mendaftar.",
  "There is no expiration date for this voucher.": "Tiada tarikh luput bagi baucar ini.",
  "The voucher can be used for dryer machine only.": "Baucar boleh digunakan untuk mesin pengering sahaja.",
  "The voucher can be used for washer machine only.": "Baucar boleh digunakan untuk mesin basuh sahaja.",
  "The voucher can be used for dryer or washer machine.": "Baucar boleh digunakan untuk mesin pengering atau mesin basuh.",
  "When you applied the voucher during payment, it will give you": "Apabila anda menggunakan baucar semasa pembayaran, ia akan memberi anda",
  "discount.": "diskaun.",
  "When promotion is on going, the voucher is not applicable.": "Apabila promosi sedang berjalan, baucar tidak boleh diguna.",
  "The voucher cannot be used if your payment method is E-Token.": "Baucar tidak boleh digunakan jika kaedah pembayaran anda ialah E-Token.",
  "Your referral code can be found in user settings page.": "Kod rujukan anda boleh didapatkan dalam halaman tetapan pengguna.",
  "You can get": "Anda boleh dapatkan",
  "stamp for the reward.": "setem sebagai ganjaran.",
  "e-token for the reward.": "e-token sebagai ganjaran.",
  "You can get a voucher for the reward": "Anda boleh mendapatkan baucar",
  "which have": "yang mempunyai",
  "it can be used for dryer machine only.": "ia boleh digunakan untuk mesin pengering sahaja.",
  "it can be used for washer machine only.": "ia boleh digunakan untuk mesin basuh sahaja.",
  "it can be used for dryer or washer machine.": "ia boleh digunakan untuk pengering atau mesin basuh.",
  "it is valid for ": "ia sah selama ",
  " days from that day the voucher given.": " hari dari hari itu baucar diberikan.",
  "washer stamp for the reward.": "setem mesin basuh sebagai ganjaran.",
  "dryer stamp for the reward.": "setem pengering sebagai ganjaran.",
  "washer stamp and": "setem pencuci dan",
  "cleanproBirthdayShortDesc": "Tebus baucar hari jadi bernilai {{ birthdayVoucherAmount }} kredit pada hari istimewa anda dengan Cleanpro Plus. Klik  untuk maklumat lebih lanjut.",
  'When you click on "PAY" button on the payment page, you can choose either using E-wallet or E-token to pay.': 'Apabila anda mengklik butang "BAYAR" pada halaman pembayaran, anda boleh memilih sama ada menggunakan E-dompet atau E-token untuk membayar.',
  "When you using your voucher during payment, you will be unable to pay with E-token.": "Apabila anda menggunakan baucar anda semasa pembayaran, anda tidak dapat membayar dengan E-token.",
  "You can reload your E-token by clicking the reload button in the stamping page and select the package to purchase.": "Anda boleh tambah nilai E-token anda dengan mengklik butang tambah nilai dalam halaman setem dan pilih pakej untuk dibeli.",
  "You can reload your E-token by clicking the reload button in the home page and select the package to purchase.": "Anda boleh tambah nilai E-token anda dengan mengklik butang tambah nilai dalam laman utama dan pilih pakej untuk dibeli.",
  "You also can view your token purchase history in transaction page.": "Anda juga boleh melihat sejarah pembelian token anda dalam halaman transaksi.",
  "Using either dryer machine or washer machine with the minimum spending of": "Menggunakan sama ada mesin pengering atau mesin basuh dengan perbelanjaan minimum",
  "to collect one stamp.": "untuk mengumpulkan satu setem.",
  "The voucher will be given if you have collected 10 stamps.": "Baucar akan diberikan sekiranya anda telah mengumpulkan 10 keping setem.",
  "Using dryer machine with the minimum spending of": "Menggunakan mesin pengering dengan perbelanjaan minimum",
  "Using washer machine with the minimum spending of": "Menggunakan mesin basuh dengan perbelanjaan minimum",
  "to collect one dryer stamp.": "untuk mengumpulkan satu setem pengering.",
  "to collect one washer stamp.": "untuk mengumpulkan satu setem mesin basuh.",
  "The voucher will be given if you have collected 10 dryer stamps or 10 washer stamps.": "Baucar akan diberikan jika anda telah mengumpulkan 10 setem pengering atau 10 setem mesin basuh.",
  "For the voucher given by the dryer stamps,": "Bagi baucar yang diberikan oleh setem pengering,",
  "It is valid for ": "Ia sah selama ",
  "For the voucher given by the washer stamps,": "Bagi baucar yang diberikan oleh setem mesin basuh,",
  "When promotion is on going, all the vouchers is not applicable.": "Apabila promosi sedang berjalan, semua baucar tidak boleh digunakan.",
  "All the vouchers cannot be used if your payment method is E-Token.": "Semua baucar tidak boleh digunakan jika kaedah pembayaran anda adalah E-Token.",
  "Description": "Penerangan",
  "Refer Friends": "Rujuk Kawan",
  "View our guideline": "Lihat garis panduan kami",
  "When a friend / family register using your referral code, and completed a transaction with a minimum spending of": "Apabila rakan / keluarga mendaftar menggunakan kod rujukan anda, dan menyelesaikan transaksi dengan perbelanjaan minimum",
  ", you will be rewarded automatically.": ", anda akan diberi ganjaran secara automatik.",
  "One user only allow to have": "Setiap pengguna hanya membenarkan untuk memiliki",
  "referrals.": "rujukan.",
  "Confirmation Password": "Pengesahan Kata Laluan",
  "Password and confirmation password should be same.": "Kata laluan dan pengesahan kata laluan hendaklah sama.",
  "You haven't applied your voucher": "Anda mempunyai baucar yang belum digunakan",
  "Would you like to continue your payment without using your voucher?": "Adakah anda pasti ingin meneruskan pembayaran tanpa menggunakan baucar?",
  "Continue": "Teruskan",
  "Apply voucher": "Gunakan baucar",
  "Transfer": "Pemindahan",
  "Transfer Tokens": "Token Penmindahan",
  "Please fill in the phone number": "Sila isikan nombor telefon",
  "Please enter the phone number that you want to transfer to": "Sila masukkan nombor telefon yang anda ingin pindahkan",
  "Please fill in the transfer amount": "Sila isikan jumlah pemindahan",
  "Transfer amount should be more than 0 and less than your current e-Token": "Jumlah pemindahan hendaklah lebih daripada 0 dan kurang daripada e-Token anda",
  "Please enter your password": "Sila masukkan kata laluan anda",
  "Member ID": "ID ahli",
  "E-Token Transfer Amount": "Jumlah Pemindahan E-Token",
  "Please confirm the details before proceeding": "Sila sahkan butiran sebelum meneruskan",
  "Transfer From": "Pemindahan Dari",
  "Transfer To": "Pemindahan Kepada",
  "Transfer Amount": "Jumlah Pemindahan",
  "Transfer Successfully!": "Pemindahan Berjaya!",
  "Transfer Time": "Masa Pemindahan",
  "Token": "Token",
  "Done": "Selesai",
  "Next": "Seterusnya",
  "Enter Password": "Masukkan kata laluan",
  "Transfer E-Token Amount": "Pindahkan Amaun E-Token",
  "User not found": "Pengguna tidak dijumpai",
  "Password is incorrect": "Kata laluan salah",
  "Please go to your account setting and reset your password": "Sila pergi ke tetapan akaun anda dan tetapkan semula kata laluan anda",
  "Only up to last 3 months transaction history": "Hanya sejarah transaksi 3 bulan lepas",
  "Reload History": "Sejarah Tambah Nilai",
  "Transfer History": "Sejarah Pemindahan",
  "Only up to last 3 months reload history": "Hanya sejarah tambah nilai 3 bulan lepas",
  "Only up to last 3 months transfer history": "Hanya sejarah pemindahan 3 bulan lepas",
  "If require urgent response, please contact us through whatapp": "Jika memerlukan maklum balas segera, sila hubungi kami melalui whatsapp",
  "Please scan the QR code again": "Sila imbas kod QR sekali lagi",
  "Home": "Laman Utama",
  "Quick": "Cepat",
  "Super": "Super",
  "Premium": "Premium",
  "All users will have infinite referrals.": "Semua pengguna akan mempunyai rujukan yang tidak terhingga.",
  "Collect 10 stamps to get 1 Voucher": "Kumpulkan 10 setem untuk dapatkan 1 baucar",
  "The voucher is only for customer use only, not for sale.": "Baucar itu hanya untuk kegunaan pelanggan sahaja, bukan untuk dijual.",
  "Outlet is currently offline, please try again later": "Kedai sedang di luar talian, sila cuba sebentar lagi",
  "Price is not set, please contact the admin": "Harga belum ditetapkan, sila hubungi admin",
  "The voucher will be given if you completed a transaction with a minimum spending of": "Baucar akan diberikan jika anda menyelesaikan transaksi dengan perbelanjaan minimum",
  "The birthday voucher will be given on the first day of your birthday month.": "Baucar hari jadi akan diberikan pada hari pertama bulan hari lahir anda.",
  "The voucher only will be given if you have exceed spending amount of": "Baucar hanya akan diberikan jika anda mempunyai jumlah perbelanjaan sebanyak",
  "Pay with KiplePay": "Bayar dengan KiplePay",
  "Pay with Scan2Pay": "Bayar dengan Scan2Pay",
  "DISCOUNT CODE": "KOD DISKAUN",
  "Enter code >": "Masukkan kod >",
  "Enter Discount Code": "Masukkan Kod Diskaun",
  "Remove Code": "Keluarkan kod",
  "Discount Code": "Kod diskaun",
  "Close": "Tutup",
  "Please fill in your email address": "Sila isikan alamat e-mel anda",
  "Notification": "Pemberitahuan",
  "Do you want to receive notification when wash cycle done?": "Adakah anda mahu menerima pemberitahuan apabila kitaran basuh selesai?",
  "pay extra": "bayar tambahan",
  "Email Address": "Alamat emel",
  "Please fill in your phone number": "Sila isikan nombor telefon anda",
  "Invalid Phone Number": "Nombor Telefon Tidak Sah",
  "Washing Complete": "Mencuci Selesai",
  "Drying Complete": "Pengeringan Selesai",
  "The voucher will be given if you registered your account successfully, but it only can be used when your total transaction spending reach": "Baucar akan diberikan jika anda berjaya mendaftar akaun anda, tetapi ia hanya boleh digunakan apabila jumlah perbelanjaan transaksi anda mencapai",
  "and above.": "dan ke atas.",
  "This voucher is not applicable": "Baucar ini tidak berkenaan",
  "Finish by": "Selesai dalam",
  "We'll let you know when it's ready to pick up": "Kami akan memberitahu anda apabila ia sedia untuk diambil",
  // cleanpro plus bm
  "Wash": "Basuh",
  "Dry": "Kering",
  "Scan QR": "Imbas QR",
  "Capacity": "Kapasiti",
  "Duration": "Tempoh",
  "Custom duration": "Ubahsuai masa",
  "Choose duration": "Pilih masa",
  "Select the correct amount on the machine after payment.": "Pilih jumlah masa yang betul pada mesin selepas membuat pembayaran",
  "Offers": "Tawaran",
  "An easier way to pay": "Cara pembayaran yang lebih mudah",
  "Create account": "Buka akaun",
  "Login": "Log masuk",
  "By signing up, you agree to": "Dengan mendaftar anda bersetuju untuk",
  "Amount to Pay": "Jumlah yang Perlu Dibayar",
  "1 voucher selected": "1 Baucar Dipilih",
  "No voucher selected": "Tiada baucar dipilih",
  "Credits": "Kredit",
  "credits": "kredit",
  "credit": "kredit",
  "Please ensure that the machine door is shut & your laundry is inside.": "Pastikan pintu mesin ditutup & pakaian anda ada di dalam.",
  "Insufficient balance": "Baki tidak mencukupi",
  "Topup now": "Topup sekarang",
  "Copyright © 2023 Cleanpro Laundry Holdings Sdn Bhd (821842-P) All rights reserved.": "Hak Cipta © 2023 Cleanpro Laundry Holdings Sdn Bhd (821842-P) Hak cipta terpelihara.",
  "Good Evening!": "Selamat petang!",
  "Wallet Balance": "Baki Wallet",
  "Topup": "Topup",
  "More details": "Maklumat lanjut",
  "Collect 10 e-stamps to earn x1 FREE wash or dry voucher": "Kumpul 10 e-setem untuk dapatkan x1 baucar basuhan atau keringan secara PERCUMA",
  "For every wash or dry cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "Anda akan dapat satu e-setem selepas setiap penggunaan basuh atau kering dengan Cleanpro Plus. Kumpul 10 e-setem dan dapatkan 1 baucar basuhan atau keringan secara percuma! Ini adalah tanda penghargaan kami kerana menggunakan Cleanpro.",
  "For every wash cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "Anda akan dapat satu e-setem selepas setiap penggunaan basuh dengan Cleanpro Plus. Kumpul 10 e-setem dan dapatkan 1 baucar basuhan atau keringan secara percuma! Ini adalah tanda penghargaan kami kerana menggunakan Cleanpro.",
  "For every dry cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "Anda akan dapat satu e-setem selepas setiap penggunaan kering dengan Cleanpro Plus. Kumpul 10 e-setem dan dapatkan 1 baucar basuhan atau keringan secara percuma! Ini adalah tanda penghargaan kami kerana menggunakan Cleanpro.",
  "Credits OFF": "Kredit Potongan",
  "% OFF": "% Potongan",
  "Valid Till": "Sah sehingga",
  "Used On": "Digunakan Pada",
  "Expired On": "Tamat Tempoh Pada",
  "Available": "Tersedia",
  "Expired": "Tamat tempoh",
  "Used": "Terpakai",
  "Promotions": "Promosi",
  "Rewards": "Ganjaran",
  "Inbox": "Peti masuk",
  "Activities": "Aktiviti",
  "Support": "Sokongan",
  "Active Rewards": "Ganjaran Aktif",
  "Past Rewards": "Ganjaran Terdahulu",
  "Get rewarded for your loyalty with Cleanpro Plus Rewards!": "Dapatkan ganjaran kesetiaan anda dengan Cleanpro Plus Rewards!",
  "Earn vouchers and redeem them for discounts, freebies and more. The more you use our services the more you save!": "Dapatkan baucar dan tebus diskaun, hadiah percuma dan banyak lagi. Lebih banyak anda menggunakan servis kami, lebih banyak penjimatan!",
  "Up to 3 months history": "Sejarah 3 bulan lepas",
  "Filter": "Penapis",
  "All Transactions": "Semua Urus Niaga",
  "Transactions": "Transaksi",
  "Select amount to topup": "Pilih jumlah untuk topup",
  "Balance": "Baki",
  "You've just paid": "Awak baru bayar",
  "Need help?": "Perlukan bantuan?",
  "24-Hour Careline Number": "Talian Careline 24 jam",
  "Email Us": "Emel Kami",
  "Call Now": "Hubungi Sekarang",
  "Email Now": "E-mel Sekarang",
  "Update name": "Kemas kini nama",
  "Update email address": "Kemas kini alamat e-mel",
  "Change password": "Tukar kata laluan",
  "Change language": "Tukar bahasa",
  "User ID": "ID Pengguna",
  "Let us know your feedback": "Beritahu kami maklum balas anda",
  "Poor": "Teruk",
  "Good": "Baik",
  "Leave addtional feedback here (optional)": "Tinggalkan maklum balas tambahan di sini (Tidak wajib diisi)",
  "Submit feedback": "Hantar Maklum Balas",
  "Pay as guest": "Bayar sebagai tetamu",
  "Pay now": "Bayar sekarang",
  "Select voucher to use": "Pilih baucar untuk digunakan",
  "Use voucher": "Gunakan baucar",
  "Total": "Jumlah",
  "Transaction Type": "Jenis Transaksi",
  "Credit Topup": "Topup Kredit",
  "Credit Transfer": "Pemindahan Kredit",
  "Date/Time": "Tarikh/Masa",
  "Reference ID": "ID Rujukan",
  "Status": "Status",
  "Share Your Referral Code": "Kongsi Kod Rujukan Anda",
  "cleanproReferralShortDesc": "Dapatkan {{ voucherAmount }} kredit percuma apabila anda berkongsi kod rujukan anda kepada rakan dan keluarga selepas transaksi yang berjaya. Klik untuk maklumat lebih lanjut.",
  "Use": "Gunakan",
  "dryer or washer": "pengering atau mesin basuh",
  "washer": "mesin basuh",
  "dryer": "pengering",
  "dryer and washer": "pengering dan mesin basuh",
  "machine with the minimum spending of": "dengan perbelanjaan minimum",
  "Welcome to Cleanpro Plus": "Selamat datang ke Cleanpro Plus",
  "Only scan, set & pay! An easier, more convenient way to pay with Cleanpro Plus! No need to download an app. Enjoy more rewards when you use Cleanpro Plus today! Tap to learn more.": "Hanya imbas, tetap dan bayar! Cara yang lebih mudah dan senang untuk membayar dengan Cleanpro Plus! Tidak perlu memuat turun aplikasi. Nikmati lebih banyak ganjaran apabila anda menggunakan Cleanpro Plus hari ini! Klik untuk maklumat lebih lanjut.",
  "Cleanpro Pay Migration Notice": "Notis Penghijrahan Cleanpro Pay",
  "Dear valued Cleanpro customers, please be informed that as of 22 June 2023, the Cleanpro Pay app will be upgraded and and no longer available for use. Tap to learn more.": "Pelanggan Cleanpro yang dihormati, sila ambil maklum bahawa mulai 22 Jun 2023, aplikasi Cleanpro Pay akan dinaik taraf dan tidak lagi boleh untuk digunakan. Klik untuk maklumat lebih lanjut.",
  "Answer simple questions and win credits for your next wash or dry! This contest is only available for selected outlets only. Tap to learn more.": "Jawab soalan mudah dan menangi kredit untuk cucian atau pengeringan! Peraduan ini hanya tersedia untuk cawangan terpilih sahaja. Klik untuk maklumat lebih lanjut.",
  "The all-new Cleanpro Plus is a new payment method that includes loyalty features built into the app. The more you use our services and pay using Cleanpro Plus, the more you can save. With every transaction, you can earn rewards that will help you save on your next laundry expenses.": "Cleanpro Plus adalah kaedah pembayaran baharu yang mempunyai program kesetiaan terbina dalam aplikasi. Lebih banyak anda menggunakan dan membayar melalui Cleanpro Plus, lebih banyak anda boleh berjimat. Dengan setiap transaksi, anda akan memperoleh ganjaran yang boleh menjimatkan perbelanjaan dobi anda yang seterusnya.",
  "To use Cleanpro Plus, simply scan the QR code on the machines and pay using your preferred credit/debit card or e-wallet. We hope that Cleanpro Plus will help simplify your laundry routine and give you more time for the things that matter.": "Untuk menggunakan Cleanpro Plus, hanya imbas kod QR pada mesin dan bayar menggunakan kad kredit/debit atau e-dompet pilihan anda. Kami berharap Cleanpro Plus akan membantu memudahkan rutin cucian dan memberi anda lebih masa untuk perkara yang penting.",
  "If you have any questions about Cleanpro Plus, please read our FAQ in the menu or contact our careline at +603 2770 0100 or email us at info@cleanprolaundry.com. Thank you for choosing Cleanpro.": "Jika anda mempunyai sebarang soalan tentang Cleanpro Plus, sila baca FAQ kami di menu atau hubungi talian khidmat pelanggan kami di +603 2770 0100 atau e-mel kami di info@cleanprolaundry.com. Terima kasih kerana memilih Cleanpro.",
  "Dear valued Cleanpro customers, please be informed that as of 22 June 2023, the existing Cleanpro Pay mobile app will be upgraded and no longer available for use.": "Pelanggan Cleanpro yang dihormati, sila ambil maklum bahawa mulai 22 Jun 2023, Cleanpro Pay akan dinaik taraf dan tidak lagi boleh untuk digunakan.",
  "We are migrating to the all-new Cleanpro Plus. It is a new cashless payment method, without the need to download apps.": "Kami akan bertukar ke Cleanpro Plus yang serba baharu. Ini adalah kaedah baru pembayaran tanpa tunai, tanpa perlu memuat turun aplikasi.",
  "If you are the existing user of Cleanpro Pay, any of your remaining balance in the app will be transferred to your Cleanpro Plus account. You are required to use the same mobile number to sign up for Cleanpro Plus for balance transfer.": "Jika anda adalah pengguna Cleanpro Pay, sebarang baki yang tinggal dalam aplikasi  akan dipindahkan ke akaun Cleanpro Plus anda. Anda dikehendaki menggunakan nombor telefon yang sama untuk mendaftar Cleanpro Plus bagi pemindahan baki.",
  "If you need help on Cleanpro Plus, please feel free to call our careline at +603 2770 0100 or email us at info@cleanprolaundry.com": "Jika anda memerlukan bantuan mengenai Cleanpro Plus, sila hubungi talian penjagaan kami di +603 2770 0100 atau e-mel kami di info@cleanprolaundry.com",
  "Answer simple questions and win credits for your next wash or dry!": "Jawab soalan mudah dan menangi kredit untuk cucian atau pengeringan seterusnya!",
  "Step 1 : Scan the Jawab & Gerenti Menang QR code in the participated outlets.": "Langkah 1 : Imbas kod QR Jawab & Gerenti Menang di mana-mana cawangan yang mengambil bahagian.",
  "Step 2 : Answer 3 simple questions.": "Langkah 2 : Jawab 3 soalan mudah.",
  "Step 3 : Submit and stand a chance to win prizes.": "Langkah 3 : Hantar dan anda berpeluang memenangi hadiah.",
  "Grand Prize :": "Hadiah utama :",
  "x10 per outlet - 100 credits worth of vouchers in Cleanpro Plus": "x10 setiap outlet - Kredit bernilai RM100 dalam Cleanpro Plus",
  "Consolation Prize :": "Hadiah saguhati :",
  "X25 per outlet - 50 credits worth of vouchers in Cleanpro Plus": "X25 setiap outlet - Kredit bernilai RM50 dalam Cleanpro Plus",
  "Participation prize:": "Hadiah penyertaan:",
  "Every participants are eligible to receive a FREE 3 credits voucher (worth RM3) in Cleanpro Plus.": "Kredit PERCUMA bernilai RM3 dalam Cleanpro Plus untuk setiap peserta.",
  "If you have any questions please don't hesitate to contact our careline at +603 2770 0100 or email us at info@cleanprolaundry.com. Thank you for choosing Cleanpro.": "Jika anda mempunyai sebarang pertanyaan sila hubungi talian penjagaan kami di +603 2770 0100 atau e-mel kami di info@cleanprolaundry.com. Terima kasih kerana memilih Cleanpro.",
  "Payment Confirmation": "Pengesahan Pembayaran",
  "This campaign is only eligible for users who use the all-new Cleanpro Plus web application.": "Kempen ini hanya layak untuk pengguna yang menggunakan aplikasi web Cleanpro Plus serba baharu.",
  "ONE e-stamp will be rewarded for each wash or dry cycle.": "SATU e-cop akan diberi ganjaran untuk setiap kitaran basuh atau kering.",
  "ONE e-stamp will be rewarded for each wash cycle.": "SATU e-cop akan diberi ganjaran untuk setiap kitaran cucian.",
  "ONE e-stamp will be rewarded for each dry cycle.": "SATU e-cop akan diberi ganjaran untuk setiap kitaran kering.",
  "Regardless of the washer or dryer settings, the number of e-stamps being collected is the same.": "Tidak kira tetapan mesin basuh atau pengering, bilangan e-setem yang dikumpul adalah sama.",
  "The type of e-stamp collected is based on the services that you have used.": "Jenis e-setem yang dikumpul adalah berdasarkan perkhidmatan yang telah anda gunakan.",
  "Washing service will reward washer e-stamp and drying service will reward dryer e-stamp.": "Perkhidmatan mencuci akan memberi ganjaran kepada e-cop mesin basuh dan perkhidmatan pengeringan akan memberi ganjaran kepada e-cop pengering.",
  "Washing service will reward washer e-stamp.": "Perkhidmatan mencuci akan memberi ganjaran kepada e-cop mesin basuh.",
  "Drying service will reward dryer e-stamp.": "Perkhidmatan pengeringan akan memberi ganjaran kepada e-cop pengering.",
  "E-stamps will automatically be added to your account once the condition has been fulfilled.": "E-setem akan ditambahkan secara automatik ke akaun anda setelah syarat telah dipenuhi.",
  "There is no limit on how many e-stamps can be gathered in a single day. So long as you meet the requirements to collect the e-stamps.": "Tiada had berapa banyak e-setem boleh dikumpulkan dalam satu hari. Selagi anda memenuhi syarat untuk mengumpul e-setem.",
  "The type of e-stamps that have been collected is final and cannot be changed.": "Jenis e-setem yang telah dikumpul adalah muktamad dan tidak boleh diubah.",
  "There is no expiration for the e-stamps; only till the discontinuation of the campaign.": "Tiada tamat tempoh untuk e-setem; hanya sehingga pemberhentian kempen.",
  "E-stamps do not have any currency value, and they cannot be converted to cash or credits.": "E-setem tidak mempunyai sebarang nilai mata wang, dan ia tidak boleh ditukar kepada tunai atau kredit.",
  "This campaign is not applicable with any other ongoing promotions, offers, vouchers, and/or discounts provided by the participating outlets.": "Kempen ini tidak terpakai dengan mana-mana promosi, tawaran, baucar dan/atau diskaun berterusan lain yang disediakan oleh cawangan yang mengambil bahagian.",
  "These terms and conditions are subject to change at any time without prior notice.": "Terma dan syarat ini tertakluk kepada perubahan pada bila-bila masa tanpa notis terlebih dahulu.",
  "Press START on the machine to begin washing.": "Tekan START pada mesin untuk mula membasuh.",
  "Press START on the machine to begin drying.": "Tekan START pada mesin untuk mula mengeringkan.",
  "Reminder": "Peringatan",
  "Remember to select the same settings you have paid for on the corresponding machine.": "Ingat untuk memilih tetapan yang sama yang telah anda bayar pada mesin yang sepadan.",
  "Please ensure that your laundry is inside the machine and door is properly shut.": "Sila pastikan pakaian anda berada di dalam mesin dan pintu ditutup dengan betul.",
  "voucherCreditsOff_one": "Potongan {{currentVoucherAmount}} kredit",
  "voucherCreditsOff_other": "Potongan {{currentVoucherAmount}} kredit",
  "voucherPercentageOff": "Potongan {{currentVoucherAmount}}%",
  "cleanproReferralFlatVoucher_one": "Dapatkan kredit percuma apabila anda berkongsi kod rujukan Cleanpro Plus kepada rakan dan keluarga anda! Bagi setiap rujukan yang berjaya selepas transaksi pertama mereka, anda akan mendapat baucar {{ referralVoucherAmount }} kredit percuma.",
  "cleanproReferralFlatVoucher_other": "Dapatkan kredit percuma apabila anda berkongsi kod rujukan Cleanpro Plus kepada rakan dan keluarga anda! Bagi setiap rujukan yang berjaya selepas transaksi pertama mereka, anda akan mendapat baucar {{ referralVoucherAmount }} kredit percuma.",
  "cleanproReferralRateVoucher": "Dapatkan kredit percuma apabila anda berkongsi kod rujukan Cleanpro Plus kepada rakan dan keluarga anda! Bagi setiap rujukan yang berjaya selepas transaksi pertama mereka, anda akan mendapat baucar {{ referralVoucherAmount }}% percuma.",
  "cleanproReferralTokenReward_one": "Dapatkan kredit percuma apabila anda berkongsi kod rujukan Cleanpro Plus kepada rakan dan keluarga anda! Bagi setiap rujukan yang berjaya selepas transaksi pertama mereka, anda akan mendapat {{ referralToken }} kredit percuma.",
  "cleanproReferralTokenReward_other": "Dapatkan kredit percuma apabila anda berkongsi kod rujukan Cleanpro Plus kepada rakan dan keluarga anda! Bagi setiap rujukan yang berjaya selepas transaksi pertama mereka, anda akan mendapat {{ referralToken }} kredit percuma.",
  "How to get & use code:": "Cara dapatkan & gunakan kod:",
  'Go to menu = icon and click “Refer friends”': 'Pergi ke ikon menu = dan klik "Rujuk rakan"',
  "Share with everyone who will likely to use Cleanpro Plus.": "Kongsikan kepada mereka yang mungkin akan menggunakan Cleanpro Plus.",
  "Referral Terms & Conditions:": "Terma & Syarat Rujukan:",
  "cleanproReferralMinimumSpend": "Apabila rakan atau keluarga anda mendaftar menggunakan kod rujukan anda, dan menyelesaikan transaksi (perbelanjaan minimum RM{{ referralTransactionAmount }}), anda akan diberi ganjaran secara automatik.",
  "cleanproNumberOfReferralReward": "Anda boleh berkongsi dengan seberapa ramai yang anda mahu, tetapi setiap pengguna HANYA terhad untuk mendapat {{ numberOfReferral }} ganjaran rujukan sahaja.",
  "cleanproNumberOfReferral_one": "Anda boleh berkongsi dengan seberapa ramai yang anda mahu, tetapi setiap pengguna HANYA terhad untuk mendapat {{ numberOfReferral }} baucar rujukan sahaja.",
  "cleanproNumberOfReferral_other": "Anda boleh berkongsi dengan seberapa ramai yang anda mahu, tetapi setiap pengguna HANYA terhad untuk mendapat {{ numberOfReferral }} baucar rujukan sahaja.",
  "cleanproVoucherExpiry_one": "Baucar sah selama {{ voucherDuration }} hari dari hari baucar diberikan.",
  "cleanproVoucherExpiry_other": "Baucar sah selama {{ voucherDuration }} hari dari hari baucar diberikan.",
  "The voucher is for customer use only and not for sale.": "Baucar adalah untuk kegunaan pelanggan sahaja dan bukan untuk dijual.",
  "cleanproBirthdayFlatAmount": "Kami percaya hari istimewa anda perlu diraikan dengan penuh bermakna. Kami teruja untuk memperkenalkan program ganjaran ekslusif hari lahir hanya untuk anda! Mulai tahun ini, dan tahun berikutnya, kami akan berikan baucar kredit RM{{ voucherAmount }} pada hari lahir anda.",
  "cleanproBirthdayRateAmount": "Kami percaya hari istimewa anda perlu diraikan dengan penuh bermakna. Kami teruja untuk memperkenalkan program ganjaran ekslusif hari lahir hanya untuk anda! Mulai tahun ini, dan tahun berikutnya, kami akan berikan baucar {{ voucherAmount }}% pada hari lahir anda.",
  "Make sure your account details include your birthdate. This way, we'll know exactly when to send you your special birthday surprise.": "Pastikan tarikh lahir diisi dalam butiran akaun anda. Dengan cara ini, kami akan mengetahui dengan tepat hari untuk memberi baucar istimewa hari jadi anda.",
  "Birthday Voucher Terms & Conditions:": "Terma & Syarat Baucar Hari Lahir:",
  "The voucher will be given on the first day of your birthday month.": "Baucar akan diberikan pada hari pertama bulan lahir anda.",
  "cleanproVoucherFlat": "Apabila anda menggunakan baucar semasa pembayaran, ia akan memberi anda diskaun {{ voucherAmount }} kredit (RM{{ voucherAmount }}).",
  "cleanproVoucherRate": "Apabila anda menggunakan baucar semasa pembayaran, ia akan memberi anda diskaun {{ voucherAmount }}%.",
  "All vouchers is not valid with other ongoing promotions, discounts and offers.": "Baucar ini tidak boleh digunakan ketika promosi lain sedang berlangsung.",
  "Claim a complimentary wash and dry for every 10 e-stamps collected! We value your loyalty and want to reward you for choosing Cleanpro Plus. Tap to learn more.": "Tebus basuhan dan keringan percuma untuk setiap 10 e-setem yang terkumpul! Kami menghargai kesetiaan anda dan ingin memberi ganjaran kerana setia memilih Cleanpro Plus. Klik untuk maklumat lebih lanjut.",
  "cleanproStampFlat_one": "Dengan setiap 10 e-setem yang anda kumpulkan, anda boleh menebus sehingga {{ voucherAmount }} kredit untuk sesi basuhan atau keringan secara percuma dengan Cleanpro Plus. Jadikan rutin cucian anda bukan sahaja mudah, tetapi juga bermanfaat.",
  "cleanproStampFlat_other": "Dengan setiap 10 e-setem yang anda kumpulkan, anda boleh menebus sehingga {{ voucherAmount }} kredit untuk sesi basuhan atau keringan secara percuma dengan Cleanpro Plus. Jadikan rutin cucian anda bukan sahaja mudah, tetapi juga bermanfaat.",
  "cleanproStampRate": "Dengan setiap 10 e-setem yang anda kumpulkan, anda boleh menebus sehingga {{ voucherAmount }}% untuk sesi basuhan atau keringan secara percuma dengan Cleanpro Plus. Jadikan rutin cucian anda bukan sahaja mudah, tetapi juga bermanfaat.",
  "A voucher will be instantly added to your Rewards page when you reach 10 washes. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "Baucar akan ditambah serta-merta pada halaman Ganjaran anda apabila anda mencapai 10 basuhan. Meter e-setem anda akan kembali kepada sifar untuk koleksi seterusnya. Dapatkan baucar dalam halaman Imbas QR apabila anda melakukan cucian seterusnya.",
  "A voucher will be instantly added to your Rewards page when you reach 10 drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "Baucar akan ditambah serta-merta pada halaman Ganjaran anda apabila anda mencapai 10 keringan. Meter e-setem anda akan kembali kepada sifar untuk koleksi seterusnya. Dapatkan baucar dalam halaman Imbas QR apabila anda melakukan cucian seterusnya.",
  "A voucher will be instantly added to your Rewards page when you reach 10 washes or drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "Baucar akan ditambah serta-merta pada halaman Ganjaran anda apabila anda mencapai 10 basuhan atau keringan. Meter e-setem anda akan kembali kepada sifar untuk koleksi seterusnya. Dapatkan baucar dalam halaman Imbas QR apabila anda melakukan cucian seterusnya.",
  "Terms & Conditions:": "Terma & Syarat:",
  "cleanproStampTransactionAmount_one": "Untuk mendapatkan SATU e-setem, anda harus berbelanja sekurang-kurangnya {{ stampTransactionAmount }} kredit (RM{{ stampTransactionAmount }}) untuk setiap sesi.",
  "cleanproStampTransactionAmount_other": "Untuk mendapatkan SATU e-setem, anda harus berbelanja sekurang-kurangnya {{ stampTransactionAmount }} kredit (RM{{ stampTransactionAmount }}) untuk setiap sesi.",
  "You will be automatically issued a voucher if you have collected 10 washer stamps.": "Anda akan diberikan baucar secara automatik jika anda berjaya mengumpul 10 setem mesin basuh.",
  "You will be automatically issued a voucher if you have collected 10 dryer stamps.": "Anda akan diberikan baucar secara automatik jika anda berjaya mengumpul 10 e-setem pengering",
  "You will be automatically issued a voucher if you have collected 10 dryer stamps or 10 washer stamps.": "Anda akan diberikan baucar secara automatik jika anda berjaya mengumpul 10 e-setem pengering atau 10 setem mesin basuh.",
  "You will be automatically issued a voucher if you have collected 10 stamps.": "Anda akan diberikan baucar secara automatik jika anda berjaya mengumpul 10 e-setem.",
  "The voucher is non-refundable and cannot be exchanged for cash; in part or in full.": "Baucar tidak boleh dikembalikan dan tidak boleh ditukar dengan wang tunai; sebahagian atau sepenuhnya.",
  "This voucher is valid for single transaction only.": "Baucar ini sah untuk satu transaksi sahaja.",
  "General questions": "Soalan umum",
  "E-stamps collection": "Koleksi e-setem",
  "Coin exchange machines": "Mesin tukar syiling",
  "Washer machines": "Mesin basuh",
  "Dryer machines": "Mesin pengering",
  "Paywave issues": "Isu paywave",
  "Cleanpro franchise": "Francais Cleanpro",
  "How can I wash my laundry using Cleanpro Plus?": "Bagaimanakah saya boleh membasuh pakaian saya menggunakan Cleanpro Plus?",
  "Here are the steps to wash your laundry using Cleanpro Plus:": "Berikut ialah langkah-langkah untuk mencuci pakaian anda menggunakan Cleanpro Plus:",
  "Find an unoccupied washing machine in the store, and insert your laundry in the washer.": "Cari mesin basuh yang tidak berpenghuni di kedai, dan masukkan pakaian anda ke dalam mesin basuh.",
  "Select the desired temperature that you would like to use for your laundry.": "Pilih suhu yang diingini yang anda ingin gunakan untuk cucian anda.",
  "Using the scanner in the app, scan the QR code provided on the washing machine. Then select the same settings that you have pressed on the machine.": "Menggunakan pengimbas dalam apl, imbas kod QR yang disediakan pada mesin basuh. Kemudian pilih tetapan yang sama yang telah anda tekan pada mesin.",
  'Tap on “Pay with credits” to pay the amount shown on the app.': 'Ketik pada "Bayar dengan kredit" untuk membayar jumlah yang ditunjukkan pada apl.',
  "Once the payment has been accepted, press the START button on the washer machine.": "Setelah pembayaran diterima, tekan butang MULA pada mesin basuh.",
  "After the washing cycle ends, you can just remove your laundry from the machine.": "Selepas kitaran basuh tamat, anda hanya boleh mengeluarkan pakaian anda dari mesin.",
  "How can I dry my laundry using Cleanpro Plus?": "Bagaimanakah saya boleh mengeringkan pakaian saya menggunakan Cleanpro Plus?",
  "Here are the steps to dry your laundry using Cleanpro Plus:": "Berikut ialah langkah untuk mengeringkan pakaian anda menggunakan Cleanpro Plus:",
  "Find an unoccupied drying machine in the store, and insert your laundry in the dryer.": "Cari mesin pengering yang tidak berpenghuni di kedai, dan masukkan pakaian anda ke dalam mesin pengering.",
  "Select the desired temperature and duration that you would like to use for your laundry.": "Pilih suhu dan tempoh yang diingini yang anda ingin gunakan untuk cucian anda.",
  "Using the scanner in the app, scan the QR code provided on the drying machine. Then select the same settings that you have pressed on the machine.": "Menggunakan pengimbas dalam apl, imbas kod QR yang disediakan pada mesin pengering. Kemudian pilih tetapan yang sama yang telah anda tekan pada mesin.",
  "Once the payment has been accepted, press the START button on the dryer machine.": "Setelah pembayaran diterima, tekan butang MULA pada mesin pengering.",
  "After the drying cycle ends, you can just remove your laundry from the machine.": "Selepas kitaran pengeringan tamat, anda hanya boleh mengeluarkan pakaian anda dari mesin.",
  "Why does the QR code I scanned take me to a different machine?": "Mengapa kod QR yang saya imbas membawa saya ke mesin lain?",
  "Here are the steps to solve it:": "Berikut adalah langkah-langkah untuk menyelesaikannya:",
  "Make sure that the number on the QR code (e.g. W1, W2, etc) is the same as the machine that you would like to use.": "Pastikan nombor pada kod QR (cth. W1, W2, dll) adalah sama dengan mesin yang anda ingin gunakan.",
  "If the QR code number does not match the machine you want, use the other machine. Please contact our customer service line at 03-27700100 to report this issue.": "Jika nombor kod QR tidak sepadan dengan mesin yang anda mahu, gunakan mesin yang lain. Sila hubungi talian khidmat pelanggan kami di 03-27700100 untuk melaporkan isu ini.",
  "How can I add more time to the washer that I am currently using?": "Bagaimanakah saya boleh menambah lebih banyak masa pada mesin basuh yang sedang saya gunakan?",
  "The washing time for our washer is fixed and cannot add any additional time.": "Masa basuh untuk mesin basuh kami adalah tetap dan tidak boleh menambah sebarang masa tambahan.",
  "How can I add more time to the dryer that I am currently using?": "Bagaimanakah saya boleh menambah lebih banyak masa pada pengering yang sedang saya gunakan?",
  "Here are the steps to add more time to the dryer machine:": "Berikut ialah langkah-langkah untuk menambahkan lebih banyak masa pada mesin pengering:",
  "By using the scanner in the app, scan the QR code provided at the dryer machine.": "Dengan menggunakan pengimbas dalam apl, imbas kod QR yang disediakan di mesin pengering.",
  "Select the amount of time that you would like to add to the machine.": "Pilih jumlah masa yang anda ingin tambahkan pada mesin.",
  "After the drying cycle ends, you can just remove the clothes from the machine.": "Selepas kitaran pengeringan tamat, anda hanya boleh mengeluarkan pakaian dari mesin.",
  "REMINDER - It is recommended to add drying time 5 minutes before the cycle ends. And you cannot add tokens for extra times when the machine finishes the drying cycle.": "PERINGATAN - Adalah disyorkan untuk menambah masa pengeringan 5 minit sebelum kitaran tamat. Dan anda tidak boleh menambah token untuk masa tambahan apabila mesin menamatkan kitaran pengeringan.",
  "Will the timer keep running when I have stopped the drying machine?": "Adakah pemasa akan terus berjalan apabila saya telah menghentikan mesin pengering?",
  "Yes. Even after you stop the machine, the timer will continue to run. If you want to extend the drying time, add drying time 5 minutes before the cycle ends. This prevents the cycle from ending before your clothes are completely dried.": "Ya. Walaupun selepas anda menghentikan mesin, pemasa akan terus berjalan. Jika anda ingin memanjangkan masa pengeringan, tambahkan masa pengeringan 5 minit sebelum kitaran tamat. Ini menghalang kitaran daripada berakhir sebelum pakaian anda kering sepenuhnya.",
  "How do I reload my credits for Cleanpro Plus?": "Bagaimanakah saya boleh memuatkan semula kredit saya untuk Cleanpro Plus?",
  "Here are the steps to reload your credits:": "Berikut ialah langkah-langkah untuk memuat semula kredit anda:",
  'Select the "Top Up Credit" button on the homepage.': 'Pilih butang "Top Up Credit" di halaman utama.',
  "Choose the amount you want to reload and the payment method you want to use.": "Pilih amaun yang ingin anda muat semula dan kaedah pembayaran yang ingin anda gunakan.",
  "You will be taken to the payment page. The screen will return to Cleanpro Plus once the transaction is completed.": "Anda akan dibawa ke halaman pembayaran. Skrin akan kembali ke Cleanpro Plus setelah transaksi selesai.",
  "How many credits can I reload in one transaction?": "Berapa banyak kredit yang boleh saya muatkan semula dalam satu transaksi?",
  "Reloading credits requires a minimum of RM 10 and a maximum of RM 50.": "Muat semula kredit memerlukan minimum RM 10 dan maksimum RM 50.",
  "Can I pay using credits in the Cleanpro Plus app along with tokens or coins in a single transaction?": "Bolehkah saya membayar menggunakan kredit dalam apl Cleanpro Plus bersama-sama dengan token atau syiling dalam satu transaksi?",
  "No. Only a single payment method may be chosen when performing any transactions.": "Tidak. Hanya satu kaedah pembayaran boleh dipilih semasa melakukan sebarang transaksi.",
  "The amount I have reloaded in my account was not reflected in the app.": "Jumlah yang saya muatkan semula dalam akaun saya tidak ditunjukkan dalam apl.",
  "Please close and restart the application and check if the new balance has been updated.": "Sila tutup dan mulakan semula aplikasi dan semak sama ada baki baharu telah dikemas kini.",
  "If the amount is still not updated, please contact our customer service line at 03-27700110 to receive further assistance.": "Jika jumlah masih tidak dikemas kini, sila hubungi talian khidmat pelanggan kami di 03-27700110 untuk menerima bantuan lanjut.",
  "Can I cash out the credits I reloaded in my account for this app?": "Bolehkah saya tunaikan kredit yang saya muatkan semula dalam akaun saya untuk apl ini?",
  "No. The credits that you have reloaded will remain in your Cleanpro Plus account.": "Tidak. Kredit yang telah anda muatkan semula akan kekal dalam akaun Cleanpro Plus anda.",
  "I have requested a refund, but I still have not received it.": "Saya telah meminta bayaran balik, tetapi saya masih belum menerimanya.",
  "Please contact our customer service line at 03-27700100 to receive further assistance.": "Sila hubungi talian khidmat pelanggan kami di 03-27700100 untuk menerima bantuan lanjut.",
  "I have made the payment, and the app double or triple charged me.": "Saya telah membuat pembayaran dan apl itu mengenakan caj dua kali ganda atau tiga kali ganda kepada saya.",
  "The washing machine cannot start even after I have paid using the app.": "Mesin basuh tidak boleh dimulakan walaupun saya telah membayar menggunakan aplikasinya.",
  "Ensure that the temperature settings you have selected and paid for on the application are the same as what you have pressed on the washing machine. If not, select the correct temperature settings on the washing machine and try again.": "Pastikan tetapan suhu yang telah anda pilih dan bayar pada aplikasi adalah sama dengan apa yang telah anda tekan pada mesin basuh. Jika tidak, pilih tetapan suhu yang betul pada mesin basuh dan cuba lagi.",
  "If the machine is still not able to be started, please contact our customer service line at 03-27700100 to receive further assistance.": "Jika mesin masih tidak dapat dihidupkan, sila hubungi talian khidmat pelanggan kami di 03-27700100 untuk menerima bantuan lanjut.",
  "Can I use multiple vouchers together in the same washing or drying cycle?": "Bolehkah saya menggunakan berbilang baucar bersama-sama dalam kitaran basuh atau pengeringan yang sama?",
  "No. Only a single voucher can be used for each washing and drying cycle.": "Tidak. Hanya satu baucar boleh digunakan untuk setiap kitaran pencucian dan pengeringan.",
  "Why can't I use my free wash voucher that I received after signing up?": "Mengapa saya tidak boleh menggunakan baucar basuh percuma saya yang saya terima selepas mendaftar?",
  "To redeem or use the voucher, you must complete at least one washing or drying cycle.": "Untuk menebus atau menggunakan baucar, anda mesti melengkapkan sekurang-kurangnya satu kitaran basuh atau pengeringan.",
  "Can I change my phone number even after registering my account?": "Bolehkah saya menukar nombor telefon saya walaupun selepas mendaftar akaun saya?",
  "No. Once registered to an account, the phone number is locked into the account.": "Tidak. Setelah mendaftar ke akaun, nombor telefon dikunci ke dalam akaun.",
  "I have problems in the store, and who can I refer to for help?": "Saya mempunyai masalah di kedai, dan siapa yang boleh saya rujuk untuk mendapatkan bantuan?",
  "Do not worry, as you can contact our customer service line at 03-27700100 to receive assistance at any time.": "Jangan risau, kerana anda boleh menghubungi talian khidmat pelanggan kami di 03-27700100 untuk menerima bantuan pada bila-bila masa.",
  "Should I add my own laundry detergent and softeners to the washing machine?": "Perlukah saya menambah detergen dan pelembut pakaian saya sendiri pada mesin basuh?",
  "There is no need for that, as all you have to do is bring your dirty clothes and put them in the washer. The detergent and softeners will be provided by us, and you can rest assured that the laundry detergents we used are certified industrial grade.": "Tidak perlu untuk itu, kerana anda hanya perlu membawa pakaian kotor anda dan memasukkannya ke dalam mesin basuh. Detergen dan pelembut akan disediakan oleh kami, dan anda boleh yakin bahawa detergen dobi yang kami gunakan adalah gred industri yang disahkan.",
  "I would like to add my own laundry detergent and softener to the washing machine.": "Saya ingin menambah detergen dan pelembut pakaian saya sendiri pada mesin basuh.",
  "We do not recommend our customers add their own detergent, as the mixture of different detergents may affect the wash quality. And you can rest assured that the laundry detergent we used is certified industrial grade.": "Kami tidak mengesyorkan pelanggan kami menambah detergen mereka sendiri, kerana campuran detergen yang berbeza boleh menjejaskan kualiti cucian. Dan anda boleh yakin bahawa detergen pakaian yang kami gunakan adalah gred industri yang disahkan.",
  "What time does the store close?": "Pukul berapa kedai tutup?",
  "Our self-service laundry operation is open 24 hours a day, which means you can always be sure to go back home with a fresh set of laundry.": "Operasi dobi layan diri kami dibuka 24 jam sehari, yang bermakna anda sentiasa yakin untuk pulang ke rumah dengan set pakaian yang segar.",
  "Are you open on weekends?": "Adakah anda buka pada hujung minggu?",
  "Yes. Cleanpro is also open on weekends and even on public holidays.": "Ya. Cleanpro juga dibuka pada hujung minggu dan juga pada cuti umum.",
  "How many clothes can I fit in the washing or drying machine?": "Berapa banyak pakaian yang boleh saya masukkan ke dalam mesin basuh atau pengering?",
  "To ensure that your laundry is properly washed or dried, keep the amount of laundry inside the machine lower than the line of guide sticker on the machine.": "Untuk memastikan pakaian anda dibasuh atau dikeringkan dengan betul, pastikan jumlah cucian di dalam mesin lebih rendah daripada garis pelekat panduan pada mesin.",
  "How many e-stamps can I collect in a single day?": "Berapa banyak e-setem yang boleh saya kumpulkan dalam satu hari?",
  "There is no limit to the amount of stamps that can be collected in a single day; ONE stamp will be awarded with every wash or dry.": "Tiada had kepada jumlah setem yang boleh dikutip dalam satu hari; SATU cop akan diberikan dengan setiap cucian atau pengeringan.",
  "When I use the washing service, can I change the collected stamp to a dryer stamp, and vice versa?": "Apabila saya menggunakan perkhidmatan mencuci, bolehkah saya menukar setem yang dikumpul kepada setem pengering, dan sebaliknya?",
  "No, the type of stamp collected is only applied to the service that you have used.": "Tidak, jenis setem yang dikumpul hanya digunakan pada perkhidmatan yang telah anda gunakan.",
  "What temperatures can I choose when I claim the free washing e-stamp reward?": "Apakah suhu yang boleh saya pilih apabila saya menuntut ganjaran e-setem cucian percuma?",
  "Any washing temperature is available for the e-stamp reward.": "Sebarang suhu basuhan tersedia untuk ganjaran e-setem.",
  "What temperatures can I choose when I claim the free drying e-stamp reward? And how long can I set the time?": "Apakah suhu yang boleh saya pilih apabila saya menuntut ganjaran e-setem pengeringan percuma? Dan berapa lama saya boleh menetapkan masa?",
  "Any drying temperature and duration can be selected for the e-stamp reward.": "Sebarang suhu dan tempoh pengeringan boleh dipilih untuk ganjaran e-setem.",
  "I'm unable to change the coin.": "Saya tidak dapat menukar syiling.",
  "What colour is the light shown on the machine, red or green? If it is a red light, please contact our customer service line at 03-27700100 to receive further assistance.": "Apakah warna cahaya yang ditunjukkan pada mesin, merah atau hijau? Jika lampu merah, sila hubungi talian khidmat pelanggan kami di 03-27700100 untuk menerima bantuan lanjut.",
  "Green Light": "Lampu hijau",
  "Red Light": "Lampu merah",
  "Machine functions as normal. Please try again.": "Mesin berfungsi seperti biasa. Sila cuba lagi.",
  "(flashing 2x) - There are no coins/tokens in the machine.": "(berkelip 2x) - Tiada syiling/token dalam mesin.",
  "OR": "ATAU",
  "Note is jammed, please proceed to contact customer service": "Nota tersekat, sila teruskan untuk menghubungi perkhidmatan pelanggan",
  "(flashing 3x) - Note is jammed, please proceed to contact customer service": "(berkelip 3x) - Nota tersekat, sila teruskan untuk menghubungi khidmat pelanggan",
  "My notes are stuck in the machine.": "Nota saya tersangkut dalam mesin.",
  "My detergent is not dispensing in the vending machine.": "Detergen saya tidak mendispens dalam mesin layan diri.",
  "The water is leaking out of the washing machine.": "Air keluar dari mesin basuh.",
  "Please check the washer machine to see whether there are any clothes that are stuck at the door side.": "Sila periksa mesin basuh untuk melihat sama ada terdapat pakaian yang tersangkut di bahagian pintu.",
  "If yes, here are the steps to solve it:": "Jika ya, berikut adalah langkah-langkah untuk menyelesaikannya:",
  "Press the emergency stop button (RED Button) for 3 seconds.": "Tekan butang berhenti kecemasan (Butang MERAH) selama 3 saat.",
  "Wait for the sound to stop, and then only open the door. (If after 30 minutes the sound still has not stopped, please contact our customer service line at 03-27700100 to receive further assistance)": "Tunggu bunyi berhenti, dan kemudian buka pintu sahaja. (Jika selepas 30 minit bunyi masih tidak berhenti, sila hubungi talian khidmat pelanggan kami di 03-27700100 untuk menerima bantuan lanjut)",
  "Ensure the door is closed tightly, and press the START button.": "Pastikan pintu ditutup rapat, dan tekan butang MULA.",
  "REMINDER - The emergency stop button can only be used once.": "PERINGATAN - Butang berhenti kecemasan hanya boleh digunakan sekali sahaja.",
  "There is no detergent dispensing in the washing machine.": "Tiada pendispensan detergen dalam mesin basuh.",
  "Ensure that the machine is in operation and that the START button was pressed.": "Pastikan mesin beroperasi dan butang START ditekan.",
  "If the detergent is still not dispensing, please contact our customer service department at 03-27700100 for further assistance.": "Jika detergen masih tidak mengeluarkan, sila hubungi bahagian perkhidmatan pelanggan kami di 03-27700100 untuk bantuan lanjut.",
  "The price displayed on the machine's screen did not change after inserting the token.": "Harga yang dipaparkan pada skrin mesin tidak berubah selepas memasukkan token.",
  "Press the small button (in the centre) to reject the tokens that have been inserted. Press a few times till the coins come out.": "Tekan butang kecil (di tengah) untuk menolak token yang telah dimasukkan. Tekan beberapa kali sehingga syiling keluar.",
  "If the coins still cannot come out or the button is not functioning, please contact our customer service line at 03-27700110 to receive further assistance.": "Jika syiling masih tidak dapat keluar atau butang tidak berfungsi, sila hubungi talian khidmat pelanggan kami di 03-27700110 untuk menerima bantuan lanjut.",
  "The machine is not starting, and it displays an error code.": "Mesin tidak dimulakan, dan ia memaparkan kod ralat.",
  "We would like to apologise for any inconvenience caused. Please contact our customer service line at 03-27700100 to receive further assistance.": "Kami ingin memohon maaf atas sebarang kesulitan yang berlaku. Sila hubungi talian khidmat pelanggan kami di 03-27700100 untuk menerima bantuan lanjut.",
  "I want to wash the clothes again, but why couldn't the machine start even after I added tokens to the machine?": "Saya ingin membasuh pakaian sekali lagi, tetapi mengapa mesin tidak dapat dihidupkan walaupun selepas saya menambah token pada mesin?",
  "Try opening and closing the door again before starting the machine.": "Cuba buka dan tutup pintu sekali lagi sebelum menghidupkan mesin.",
  "Can I change the water temperature of the machine?": "Bolehkah saya menukar suhu air mesin?",
  "Yes, but the washer temperature can only be changed from hot to cold after the machine has been started, and not from cold to hot.": "Ya, tetapi suhu mesin basuh hanya boleh ditukar daripada panas kepada sejuk selepas mesin dihidupkan, dan bukan dari sejuk kepada panas.",
  "Can I stop the machine?": "Bolehkah saya menghentikan mesin?",
  "Here are the steps to stop the machine:": "Berikut ialah langkah-langkah untuk menghentikan mesin:",
  "Wait for the sound to stop, and then only open the door. (If after 30 minutes the sound still has not stopped, please contact our customer service line at to receive further assistance)": "Tunggu bunyi berhenti, dan kemudian buka pintu sahaja. (Jika selepas 30 minit bunyi masih tidak berhenti, sila hubungi talian khidmat pelanggan kami di untuk menerima bantuan lanjut)",
  "There is no water supply on the machine used.": "Tiada bekalan air pada mesin yang digunakan.",
  "Why is the washing temperature cold even when I have selected HOT.": "Mengapa suhu basuh sejuk walaupun saya telah memilih PANAS.",
  "The water temperature is HOT or WARM only during washing. The water will turn COLD during rinsing.": "Suhu air adalah PANAS atau SUAM hanya semasa mencuci. Air akan menjadi SEJUK semasa dibilas.",
  "The machine has already finished washing, but it does not spin well, and there is water left on the load.": "Mesin telah selesai mencuci, tetapi ia tidak berputar dengan baik, dan terdapat air yang tertinggal pada beban.",
  "Can I put wet laundry into the dryer to dry?": "Bolehkah saya memasukkan pakaian basah ke dalam pengering untuk dikeringkan?",
  "No, as the dripping wet laundry might cause the dryer to short circuit.": "Tidak, kerana cucian basah yang menitis mungkin menyebabkan pengering litar pintas.",
  "REMINDER - Refer to the labels on your clothes to know what kind of temperature is best for the kind of fabric that it uses. Then select the temperature on the machine accordingly.": "PERINGATAN - Rujuk label pada pakaian anda untuk mengetahui jenis suhu yang terbaik untuk jenis fabrik yang digunakan. Kemudian pilih suhu pada mesin dengan sewajarnya.",
  "My clothes are still wet even after drying.": "Pakaian saya masih basah walaupun sudah dijemur.",
  "How can I stop the dryer when the machine is still running?": "Bagaimanakah saya boleh menghentikan pengering apabila mesin masih berjalan?",
  "Just open the door and the machine will automatically stop.": "Buka sahaja pintu dan mesin akan berhenti secara automatik.",
  "I added extra tokens for extra times, but the machine did not update the time.": "Saya menambah token tambahan untuk masa tambahan, tetapi mesin tidak mengemas kini masa.",
  "For the time to be reflected accurately, it is recommended for you to add tokens 5 minutes before the services end.": "Untuk masa yang dapat dilihat dengan tepat, anda disyorkan untuk menambah token 5 minit sebelum perkhidmatan tamat.",
  "REMINDER - You cannot add tokens for extra times when the machine finishes the drying cycle.": "PERINGATAN - Anda tidak boleh menambah token untuk masa tambahan apabila mesin menamatkan kitaran pengeringan.",
  "Why is the machine making noise?": "Mengapa mesin membuat bising?",
  "Please check the drum to see if there are any coins or other small things inside.": "Sila periksa dram untuk melihat sama ada terdapat syiling atau benda kecil lain di dalamnya.",
  "I have pressed the START button on the dryer, but it is still not working.": "Saya telah menekan butang MULA pada pengering, tetapi ia masih tidak berfungsi.",
  "Please try to open the door and close it again. Next, press the START button again to start drying.": "Sila cuba buka pintu dan tutup semula. Seterusnya, tekan butang MULA sekali lagi untuk mula mengeringkan.",
  "If the dryer is still not functioning, please contact our customer service line at 03-27700100 to receive further assistance.": "Jika mesin pengering masih tidak berfungsi, sila hubungi talian khidmat pelanggan kami di 03-27700100 untuk menerima bantuan lanjut.",
  "Paywave has deducted an amount from the card but the machine did not reflect the transaction.": "Paywave telah menolak amaun daripada kad tetapi mesin tidak menggambarkan transaksi tersebut.",
  "The Paywave transaction has failed but the amount has still been deducted from my card.": "Transaksi Paywave telah gagal tetapi jumlahnya masih ditolak daripada kad saya.",
  "Paywave has double/triple charge but the machine cannot start.": "Paywave mempunyai caj dua kali/tiga tetapi mesin tidak boleh dihidupkan.",
  "Paywave after double/triple charge machines can't start.": "Gelombang berbayar selepas mesin caj dua/tiga kali ganda tidak boleh dimulakan.",
  "Paywave is offline": "Paywave berada di luar talian",
  "Do Cleanpro Express offer franchise business/selling machines/laundry products?": "Adakah Cleanpro Express menawarkan perniagaan francais/menjual mesin/produk dobi?",
  "For further inquiries, please email to info@cleanproexpress.com": "Untuk pertanyaan lanjut, sila e-mel ke info@cleanproexpress.com",
  "Step 6": "Langkah 6",
  "User Existed": "Pengguna Wujud",
  "Invalid Email Address": "Alamat email tidak sah",
  "Let's get started!": "Jom bermula sekarang!",
  "Enter your mobile number": "Masukkan no telefon anda",
  "Proceed": "Teruskan",
  "Enter OTP number": "Masukkan nombor OTP",
  "You will receive a six-digit OTP via SMS sent to": "Anda akan menerima OTP enam digit melalui SMS yang dihantar kepada",
  "Verify": "Sahkan",
  "Resend OTP": "Hantar semula OTP",
  "Welcome onboard!": "Selamat datang!",
  "Please fill in the info below": "Sila isikan maklumat dibawah",
  "Enter your name*": "Masukkan nama anda*",
  "Enter your email address*": "Masukkan alamat emel anda*",
  "Create a password*": "Cipta kata laluan*",
  "Enter referral code (if got any)": "Masukkan kod rujukan (jika ada)",
  "Get started": "Mulakan",
  "Birthday updated successfully": "Hari lahir berjaya dikemas kini",
  "Date of birth": "Tarikh lahir",
  "Enter your date of birth": "Masukkan tarikh lahir anda",
  "Select your birth date": "Pilih tarikh lahir anda",
  "Update date of birth": "Kemas kini tarikh lahir",
  "Update birth date": "Kemas kini tarikh lahir",
  "Already have an account?": "Sudah mempunyai akaun?",
  "Login now": "Log masuk",
  "Sign in": "Log masuk",
  "Sign up now": "Daftar sekarang",
  "COMPLETED": "SELESAI",
  "WASHING NOW": "MENCUCI SEKARANG",
  "DRYING NOW": "KERING SEKARANG",
  "It's done!": "Sudah selesai!",
  "Please collect your fresh, clean laundry in order to leave the machine available for the next person to use!": "Sila kumpulkan pakaian anda yang segar dan bersih untuk membiarkan mesin tersedia untuk digunakan oleh orang seterusnya!",
  "Leave a feedback": "Tinggalkan maklum balas",
  "Time left": "Masa tinggal",
  "Add drying time": "Tambah masa",
  "Hi": "Hai",
  "customer": "pelanggan",
  "Thank you for your payment. Don't forget to select the <b>correct temperature</b> and press <b>START</b> when you're ready.": "Terima kasih atas pembayaran anda. Jangan lupa untuk memilih <b>suhu yang betul</b> dan tekan <b>MULA</b> apabila anda sudah bersedia.",
  "Select how you want to receive a notification when your laundry is finished": "Pilih cara anda mahu menerima pemberitahuan apabila pakaian anda selesai",
  "Sign up": "Daftar",
  "Continue to pay": "Terus bayar",
  "Register as a member to enjoy more benefits!": "Daftar sebagai ahli untuk menikmati lebih banyak faedah!",
  "Get FREE 3 credits per new user": "Dapatkan 3 kredit PERCUMA bagi setiap pengguna baharu",
  "Enjoy exclusive promotions": "Nikmati promosi eksklusif",
  "FREE voucher on your birthday": "baucar PERCUMA pada hari lahir anda",
  "and more": "dan banyak lagi",
  "Denied camera access": "Akses kamera dinafikan",
  'Permission to access the camera has been denied. Please try again and click "Allow" to scan the QR code.': 'Kebenaran untuk mengakses kamera telah ditolak. Sila cuba lagi dan klik "Benarkan" untuk mengimbas kod QR.',
  "Okay": "Okay",
  "Oops. You don't have enough balance to make the payment": "Anda tidak mempunyai baki yang mencukupi untuk membuat pembayaran",
  "Please confirm": "Tolong sahkan",
  "Do you want to proceed with this feedback?": "Adakah anda mahu meneruskan maklum balas ini?",
  "Are you sure to purchase": "Adakah anda pasti untuk membeli",
  "credits for": "kredit dengan harga",
  "Topup credit": "Kredit tambah nilai",
  "Extended duration will be instantly reflected on machine after payment": "Tempoh lanjutan akan ditunjukkan serta-merta pada mesin selepas pembayaran",
  "Machine is currently busy": "Mesin sedang sibuk",
  "Do you want to extend drying time?": "Adakah anda ingin memanjangkan masa pengeringan?",
  "Cuci Hari-Hari Bersama Cleanpro!": "Cuci Hari-Hari Bersama Cleanpro!",
  "Ready for a challenge?": "Bersedia untuk menyahut cabaran?",
  "Here's one for you - all you need to do is wash your laundry 10 times in 20 days at participating Cleanpro outlets. Stand a chance to win a Limited Edition Cleanpro Designer Laundry Bag worth up to RM40!": "Anda hanya perlu mencuci pakaian anda 10 kali dalam tempoh 20 hari di cawangan Cleanpro yang terlibat. Sertai sekarang dan menangi Beg Dobi Jenama Cleanpro Edisi Terhad bernilai sehingga RM40!",
  "Here's how to participate:": "Cara-cara penyertaan:",
  "Step 1: Scan the QR Code on the washer to launch Cleanpro Plus": "Langkah 1: Imbas Kod QR pada mesin basuh untuk melancarkan Cleanpro Plus",
  "Step 2: Pay for your wash with Cleanpro Plus": "Langkah 2: Bayar cucian anda dengan Cleanpro Plus",
  "Step 3: Complete x10 washes within 20 days to win": "Langkah 3: Ulang 10 cucian dalam masa 20 hari untuk menang",
  "Challenge Period: 01 July - 31 August 2023": "Tempoh penyertaan: 01 Julai - 31 Ogos 2023",
  "Drop by your nearest Cleanpro outlet to wash your laundry with us for 20 days straight! Good luck!": "Cuci pakaian anda di outlet Cleanpro yang berdekatan selama 20 hari berturut-turut! Semoga berjaya!",
  "TERMS & CONDITIONS": "TERMA & SYARAT",
  "PARTICIPATION CRITERIA": "KRITERIA PENYERTAAN",
  "1) Participant needs to register an account in Cleanpro Plus web app.": "1) Peserta perlu mendaftar akaun dalam aplikasi web Cleanpro Plus.",
  "2) Participant can use any washer of any capacity in any participating outlets to qualify for entries.": "2) Peserta boleh menggunakan mana-mana mesin basuh dalam apa jua kapasiti di mana-mana cawangan yang mengambil bahagian untuk melayakkan diri untuk penyertaan.",
  "3) Payment needs to be made with Cleanpro Plus web app.": "3) Pembayaran perlu dibuat dengan aplikasi web Cleanpro Plus.",
  "4) Washes must be completed and paid (with no dispute whatsoever) within campaign period.": "4) Cucian mesti disiapkan dan dibayar (tanpa apa-apa pertikaian) dalam tempoh kempen berlangsung.",
  "5) Campaign period starts from 1st July 2023 at 00:00 and ends at 31st August 2023 at 23:59.": "5) Tempoh kempen bermula dari 1 Julai 2023 jam 00:00 dan berakhir pada 31 Ogos 2023 jam 23:59.",
  "WINNERS & PRIZES": "PEMENANG & HADIAH",
  "1) Cleanpro will notify the winners of the campaign via registered WhatsApp number or email address provided, within 4 weeks after the campaign ends.": "1) Cleanpro akan menghubungi pemenang kempen melalui nombor WhatsApp berdaftar atau alamat e-mel yang diberikan, dalam tempoh 4 minggu selepas kempen tamat.",
  "2) Any unclaimed prizes after 2 weeks from notification date will not be entertained thereafter.": "2) Sebarang hadiah yang tidak dituntut selepas 2 minggu dari tarikh pemberitahuan tidak akan dilayan selepas itu.",
  "3) Upon completing the ten (10) washes within twenty (20) days campaign, participants will be a winner and be eligible to win ONE (1) Cleanpro Designer Laundry Bag.": "3) Setelah menyelesaikan sepuluh (10) cucian dalam tempoh dua puluh (20) hari kempen, peserta akan menjadi pemenang dan layak memenangi SATU (1) Beg Dobi Cleanpro Designer.",
  "4) Winners are eligible to win more than ONE (1) Cleanpro Designer Laundry Bag in this campaign.": "4) Pemenang layak memenangi lebih daripada SATU (1) Beg Dobi Cleanpro Designer dalam kempen ini.",
  "5) Winners will be given the choice of his/her preferred laundry bag size; but only ONE (1) can be selected for each qualified win.": "5) Pemenang akan diberi pilihan saiz beg pakaian pilihannya; tetapi hanya SATU (1) boleh dipilih untuk setiap kemenangan yang layak.",
  "A) XL Size - Blue bag with up to 20KG in capacity": "A) Saiz XL - Beg biru dengan kapasiti sehingga 20KG",
  "B) L Size - White bag with up to 12KG in capacity": "B) Saiz L - Beg putih dengan kapasiti sehingga 12KG",
  "6) Time and location for collection of prize will be informed via Whatsapp number and/or email address provided.": "6) Masa dan lokasi pengambilan hadiah akan dimaklumkan melalui nombor Whatsapp dan/atau alamat emel yang diberikan.",
  "OTHER": "LAIN",
  "1) Changes to the terms and conditions will be at Cleanpro's discretion; without prior notice.": "1) Perubahan kepada terma dan syarat adalah atas budi bicara Cleanpro; tanpa notis awal.",
  "2) Completed wash is defined by washes performed with Cleanpro's washer and payments within Cleanpro Plus web app are made with no disputes.": "2) Kiraan cucian hanya diterima sah oleh cucian dengan mesin basuh Cleanpro dan pembayaran dalam aplikasi web Cleanpro Plus, tanpa sebarang pertikaian.",
  "3) By participating in this campaign automatically indicates that the Participant has agreed to all the campaign's Terms and Conditions as stipulated.": "3) Dengan menyertai kempen ini secara automatik menunjukkan bahawa Peserta telah bersetuju dengan semua Terma dan Syarat kempen seperti yang ditetapkan.",
  "Welcome Voucher": "Baucar Selamat Datang",
  "Welcome to the Cleanpro Plus family! This first gift is just the beginning. Stay tuned for a series of exciting promotions and campaigns, exclusively for Cleanpro Plus users.": "Selamat datang ke keluarga Cleanpro Plus! Ini hanyalah hadiah permulaan. Nantikan lain-lain promosi dan kempen yang menarik, istimewa untuk pengguna Cleanpro Plus.",
  "Terms & Conditions": "Terma & Syarat",
  "Welcome Voucher is valid for one-time use only.": "Baucar Selamat Datang ini hanya sah untuk digunakan sekali sahaja.",
  "There is no minimum spend required for the voucher to be used.": "Tiada perbelanjaan minimum diperlukan untuk menggunakan baucar ini.",
  "Welcome Voucher is auto-credited to the user's account at the point of registration on Cleanpro Plus and valid for 3 months only.": "Baucar Selamat Datang ini dikreditkan secara automatik ke akaun pengguna semasa pendaftaran di Cleanpro Plus dan sah selama 3 bulan sahaja.",
  "Users are eligible to use their Welcome Voucher AFTER the completion of their first wash or dry cycle using the same Cleanpro Plus account.": "Pengguna layak menggunakan Baucar Selamat Datang mereka SETELAH selesai kitaran basuh atau kering pertama mereka menggunakan akaun Cleanpro Plus yang sama.",
  "Welcome Voucher cannot be used together with any ongoing promotions or any other promotional vouchers.": "Baucar Selamat Datang ini tidak boleh digunakan bersama-sama dengan mana-mana promosi yang sedang berjalan atau mana-mana baucar promosi lain.",
  "The voucher is final and cannot be refunded in form of cash or credit to the user.": "Baucar adalah muktamad dan tidak boleh dikembalikan dalam bentuk tunai atau kredit kepada pengguna.",
  "Cleanpro reserves the right to change the terms and conditions without prior notice.": "Cleanpro berhak untuk menukar terma dan syarat tanpa notis awal.",
  "Collect 10 e-stamps to earn FREE 5 credits voucher (worth RM5)": "Kumpul 10 e-setem untuk mendapatkan baucar 5 kredit PERCUMA (bernilai RM5)",
  "With every 10 e-stamps that you collected, you will be rewarded with a FREE 5 credits voucher (worth RM5). Make your laundry routine not just hassle-free, but also rewarding.": "Dengan setiap 10 e-setem yang terkumpul, anda akan menerima baucar PERCUMA 5 kredit (bernilai RM5). Jadikan rutin cucian bukan sahaja mudah, tetapi juga bermanfaat untuk anda.",
  "To earn ONE e-stamp, you must spend at least 5 credits (RM5) per session.": "Untuk mendapatkan SATU e-setem, anda harus berbelanja sekurang-kurangnya 5 kredit (RM5) untuk setiap sesi.",
  "The voucher is valid for 60 days from the day voucher be given.": "Baucar sah selama 60 hari dari hari baucar diberikan.",
  "When you applied the voucher during payment, it will give you 5 credits (RM5) discount.": "Jika anda menggunakan baucar semasa pembayaran, anda akan menerima potongan sebanyak 5 kredit (RM5).",
  "How to participate:": "Cara-cara penyertaan:",
  "Prizes to be won:": "Hadiah untuk dimenangi:",
  "RM10 OFF Welcome Voucher": "DISKAUN RM10 Baucar Selamat Datang",
  "Welcome to the Cleanpro family, here is an appreciation voucher from us. Thank you for attending the COPE Investors Conference.": "Selamat datang ke keluarga Cleanpro, berikut adalah baucar penghargaan daripada kami. Terima kasih kerana menghadiri Persidangan Pelabur COPE.",
  "Welcome Voucher | COPE Investors Conference": "Baucar Selamat Datang | Persidangan Pelabur COPE",
  "Payment methods": "Kaedah pembayaran",
  "Tap to proceed with your preferred mode of payment": "Pilih kaedah yang anda inginkan untuk meneruskan pembayaran",
  "Pay now with credits": "Bayar dengan kredit",
  "Pay with e-wallet": "Bayar dengan e-dompet",
  "Go to homepage": "Pergi ke laman utama",
}

const translationZh = {
  "LOGIN": "登录",
  "REGISTER": "注册",
  "SKIP TO PAYMENT": "直接付款",
  "WELCOME": "欢迎",
  "Error": "错误",
  "Please enter the correct phone number format. <br /><br /> <small>Example: 0129465628</small>": "请输入正确的电话号码格式。 <br /><br /> <small>例如: 0129465628</small>",
  "Info": "信息",
  "Please scan QRCode to Login": "请扫描二维码登录",
  "Forgot Password": "忘记密码",
  "Seems like you have forgotten your password<br />Create a temporary password?": "好像忘记密码了<br />创建临时密码？",
  "Cancel": "取消",
  "Confirm": "确认",
  "Reset Your Password": "重置您的密码",
  "Enter Your Phone Number<br /><small>(e.g: 012xxxxxxx)</small>": "输入您的电话号码<br /><small>(例如: 012xxxxxxx)</small>",
  "Enter Your Phone Number<br /><small>(e.g: 09xxxxxxxx)</small>": "输入您的电话号码<br /><small>(例如: 09xxxxxxxx)</small>",
  "Submit": "提交",
  "Please enter a valid phone number": "请输入正确的电话号码格式",
  "Success!": "成功！",
  "You may login using your one time password now.": "您现在可以使用一次性密码登录。",
  "Phone Number": "电话号码",
  "Password": "密码",
  "Don't have an account?": "还未有账户？",
  "Forgot password?": "忘记密码？",
  "Unable to register without a QRCode": "没有二维码无法注册",
  "Please read and agree to the privacy policies, terms and conditions": "请阅读并同意隐私政策、条款和条件",
  "Password length must be at least 6 characters": "密码长度必须至少为 6 个字符",
  "Please enter the confirmation code that was sent to your phone": "请输入发送到您手机的验证码",
  "Example: 012xxxxxxx": "例如: 012xxxxxxx",
  "Example: 09xxxxxxxx": "例如: 09xxxxxxxx",
  "Name": "姓名",
  "Email": "电子邮件",
  "optional": "选择性",
  "Birth Year": "出生年份",
  "Year": "年",
  "Birth Month": "出生月份",
  "Month": "月",
  "Jan": "一月",
  "Feb": "二月",
  "Mar": "三月",
  "April": "四月",
  "May": "五月",
  "June": "六月",
  "July": "七月",
  "Aug": "八月",
  "Sept": "九月",
  "Oct": "十月",
  "Nov": "十一月",
  "Dec": "十二月",
  "Birth Day": "出生日期",
  "Day": "日",
  "OTP Confirmation": "确认验证码",
  "I agree to the": "我同意",
  "Privacy Policy": "隐私政策",
  "and": "和",
  "Terms": "条款",
  "Conditions": "条件",
  "SEND CODE": "发送验证码",
  "Already have an account? Login": "已经有账户？登录",
  "Didn't receive a code?": "没有收到验证码？",
  "Resend Code": "重新发送验证码",
  "collected": "已收集",
  "Collect": "收集",
  "stamps": "印章",
  "get 1 voucher": "获得1张优惠券",
  "Minimum": "最低消费",
  "spending to collect each washer stamp": "以收集每个洗衣机印章",
  "spending to collect each dryer stamp": "以收集每个烘干机印章",
  "spending to collect each stamp": "以收集每个印章",
  "Device don't have a camera": "设备没有摄像头",
  "This is not a valid QRCode": "这不是有效的二维码",
  "Select Camera": "选择相机",
  "Switch": "切换",
  "Camera": "相机",
  "Loading": "加载中",
  "Scan the QR code on the machine.": "扫描机器上的二维码。",
  "Switch Camera": "切换相机",
  "Off touch light": "关闭手电筒",
  "On touch light": "开启手电筒",
  "switch camera if your camera is not clear": "如果您的相机不清晰，请切换相机",
  "Switch your camera if the display is not clear": "如果显示不清晰请切换相机",
  "Please allow this webapp to access your camera": "请允许此网络应用访问您的相机",
  "DISCOUNT": "折扣",
  "Expired Date": "过期日期",
  "Voucher": "优惠券",
  "Something went wrong": "出了些问题",
  "Stamping Collection": "印章收集",
  "Total e-Token": "总电子代币",
  "Total Wash": "洗衣机次数",
  "Total Dry": "烘干机次数",
  "Reload": "充值",
  "Washer": "洗衣机",
  "Dryer": "烘干机",
  "Congratulations!": "恭喜！",
  "You have completed your Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "您已完成印章收集。<br />优惠券已给出和印章收集已刷新",
  "You have completed Washer Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "您已完成洗衣机印章收集。<br />优惠券已给出和印章收集已刷新",
  "You have completed Dryer Stamp collection.<br />Granted a voucher and stamp collection is refreshed": "您已完成烘干机印章收集。<br />优惠券已给出和印章收集已刷新",
  "Latest News": "最新消息",
  "Transaction History": "交易记录",
  "Stamping": "印章",
  "Transaction": "交易",
  "News": "消息",
  "Confirmation": "确认",
  "Logout Session?": "登出？",
  "NO": "否",
  "OK": "确定",
  "Account": "账户",
  "FAQs": "常见问题",
  "Feedback": "反馈",
  "LOGOUT": "登出",
  "Logout": "登出",
  "Are you sure you want to submit your feedback?": "您确定要提交反馈吗？",
  "No": "否",
  "Yes": "是",
  "Success": "成功",
  "Failed": "失败",
  "Your feedback is submitted successfully": "您的反馈已成功提交",
  "Please fill in all the required fields before submit.": "请在提交前填写所有必填内容。",
  "Give us your feedback!": "您好，请对我们的服务提出宝贵建议，谢谢！",
  "Type your name here (optional)": "在此处输入您的姓名（选择性）",
  "required": "必填",
  "Type your number here": "在此处输入您的电话号码",
  "Outlet": "店铺",
  "Select an outlet": "选择店铺",
  "Outlet Cleanliness": "店铺清洁度",
  "Washer Machine Performance": "洗衣机性能",
  "Dryer Machine Performance": "烘干机性能",
  "Customer Service": "客户服务",
  "Comment": "评论",
  "Type your comment here (optional)": "在此处输入您的评论（选择性）",
  "SUBMIT": "提交",
  "Join as a member now!": "立即加入成为会员！",
  "Join as a loyalty member now to get more benefits such as vouchers and free machine usage!<br /><br /><strong>Register as loyalty member?</strong>": "现在加入会员，即可免费获得优惠券和免费机器使用等福利！<br /><br /><strong>注册成为会员？</strong>",
  "Maybe Next Time": "下一次吧",
  "Yes, Register Now": "是的，立即注册",
  "Washer Payment": "洗衣机支付",
  "Dryer Payment": "烘干机支付",
  "Vending Machine Payment": "自动贩卖机支付",
  "Online": "在线",
  "Offline": "离线",
  "Running": "运行",
  "Vacant": "空置",
  "Vending Machine": "自动贩卖机",
  "Discount": "折扣",
  "No Vouchers Available": "没有可用的优惠券",
  "Payment Method": "付款方法",
  "Would you like to pay with?": "您愿意使用哪种方式付款?",
  "e-Token": "电子代币",
  "E-TOKEN": "电子代币",
  "E-Wallet": "电子钱包",
  "Pay": "付",
  "to": "给",
  "You may under utilized the voucher.": "您尚未充分利用您的优惠券。",
  "Proceed to pay": "继续支付",
  "Processing ...": "支付中 ...",
  "You can't pay with e-token if voucher is in used, please remove your voucher to pay with e-token.": "您不能同时使用优惠劵和电子代币付款，请移除您的优惠卷以使用电子代币付款。",
  "Insufficient e-Tokens": "电子代币不足",
  "Reload your e-token now?": "现在充值您的电子代币？",
  "Reload now!": "现在充值！",
  "You have": "您有",
  "e-Tokens": "电子代币",
  "Tokens to": "代币给",
  "This machine is currently offline, please try again later.": "本机器当前离线，请稍后重试。",
  "MACHINE": "机器",
  "Machine": "机器",
  "TYPE": "类型",
  "CAPACITY": "容量",
  "DURATION": "时长",
  "Select Duration": "选择时长",
  "AMOUNT TO PAY": "支付总额",
  "PRICE": "价格",
  "VOUCHER": "优惠券",
  "Select Voucher >": "选择优惠券 >",
  "TOTAL": "总额",
  "PAY": "付款",
  "Remove Voucher": "移除优惠券",
  "Select Your Voucher": "选择您的优惠券",
  "min": "分钟",
  "MIN": "分钟",
  "Select Payment Amount": "选择支付总额",
  "Select Water Temperature": "选择水温",
  "Select temperature": "选择水温",
  "Cold": "冷",
  "Warm": "温",
  "Hot": "热",
  "e-Tokens to": "电子代币给",
  "No Purchase History": "无购买记录",
  "e-tokens": "电子代币",
  "No Promotions": "没有促销",
  "New Password": "新密码",
  "Back": "返回",
  "Buy this package?": "买这个配套？",
  "Purchase": "购买",
  "e-Tokens for": "电子代币以",
  "Reload Tokens": "充值电子代币",
  "Current e-Token": "当前电子代币",
  "History": "历史记录",
  "Your profile picture has been updated!": "您的头像已更新！",
  "Please only use png, jpg or jpeg file extension type": "请仅使用 png、jpg 或 jpeg 类型文件",
  "Please only use image type file": "请仅使用图像类型文件",
  "User name has updated successfully!": "用户名更新成功！",
  "Email Address updated successfully": "电子邮件更新成功",
  "Password must be at least 6 characters": "密码必须至少6个字符",
  "Password updated successfully": "密码更新成功",
  "User Settings": "用户设置",
  "Profile Picture": "个人头像",
  "Change Password": "更改密码",
  "Do you want to submit your feedback?": "您想提交您的反馈吗？",
  "Thank You!": "谢谢你！",
  "Thank you for using our services.": "感谢您使用我们的服务。",
  "BACK TO HOMEPAGE": "返回主页",
  "How to pay?": "如何付款？",
  "Step 1": "步骤 1",
  "After scan the QR code on the machine, select either register new account, login your account or skip to payment.": "扫描机器上的二维码后，选择注册新帐户、登录您的帐户或直接付款。",
  "Step 2-1": "步骤 2-1",
  "For Dryer – Select your desired duration. Different duration will have different prices.": "对于烘干机 - 选择所需的时长。不同的时间会有不同的价格。",
  "Step 2-2": "步骤 2-2",
  "For Washer – Select your desired water temperature. Different water temperature will have different prices.": "对于洗衣机 - 选择您想要的水温。不同的水温会有不同的价格。",
  "Step 3": "步骤 3",
  "For login user, Click on ‘Select Voucher’ to select your voucher.": "对于所有登录用户，点击“选择优惠劵”以选择您的优惠劵。",
  "Step 4": "步骤 4",
  "Click on ‘PAY’ button to pay. For login user, select your payment method to pay.": "点击“付款”按钮进行支付。对于登录用户，选择您的支付方式进行支付。",
  "Step 5": "步骤 5",
  "After confirm the price and machine details, click on ‘OK’ button to pay.": "确认价格和机器详情后，点击“确定”按钮进行支付。",
  "How to reload your e-Token?": "如何充值您的电子代币？",
  'Click on the "Reload" button.': '点击“充值”按钮。',
  "Select the package that you wish to purchase.": "选择您要购买的配套。",
  'Click on "Purchase" as confirmation before payment page.': '在前往付款页面之前点击“购买”作为确认。',
  "Why can't I applied my voucher?": "为何不能使用我的优惠券？",
  "When there is a Happy Hour Promotion is on going, you are unable to apply your voucher temporarily.": "每当特别促销正在进行时，您暂时无法使用您的优惠劵。",
  "How to apply my voucher?": "如何使用我的优惠券？",
  'Click on the "Select Voucher" button.': '点击“选择优惠券”按钮。',
  "Select the voucher that you wish to apply.": "选择您要使用的优惠券。",
  'Click on "Pay" once you have verified the voucher is successfully applied.': '确认优惠劵已成功使用后，请点击“付款”。',
  "Step 2": "步骤 2",
  "Language has updated successfully!": "语言更新成功！",
  "Change Language": "更改语言",
  "Language": "语言",
  "Select language": "选择语言",
  "Referral Code": "邀请码",
  "Copied!": "已复制!",
  "Inviting friends to get more benefits!": "邀请好友以获取更多福利！",
  "Would you like to share your referral code to your friends & family?": "您要分享您的邀请码给朋友&家人吗？",
  "Refer a friend and get": "推荐朋友并获得",
  "Share the love to your friends & family?": "将爱分享给您的朋友和家人？",
  "voucher": "优惠劵",
  "token": "电子代币",
  "stamp": "印章",
  "washer stamp": "洗衣机印章",
  "dryer stamp": "烘干机印章",
  "Learn More": "了解更多",
  "Welcome Gift": "欢迎礼物",
  "Share Your Referral Code Now": "立即分享您的邀请码",
  "Birthday Gift for You": "给您的生日礼物",
  "Let's Pay with E-Token": "使用电子代币支付",
  "Collect 10 Stamps and Get Voucher": "收集 10 个印章并获得优惠劵",
  "The voucher will be given if you registered your account successfully.": "如果您成功注册了您的帐户，您将获得优惠券。",
  "The voucher is valid for ": "优惠劵有效期为 ",
  "days from that day you registered.": "天从您注册的那一天算起。",
  "There is no expiration date for this voucher.": "此优惠券没有过期日期。",
  "The voucher can be used for dryer machine only.": "优惠券仅可用于烘干机。",
  "The voucher can be used for washer machine only.": "优惠券仅可用于洗衣机。",
  "The voucher can be used for dryer or washer machine.": "优惠券可用于烘干机或洗衣机。",
  "When you applied the voucher during payment, it will give you": "当您在付款期间使用您的优惠券时，它会给您",
  "discount.": "折扣。",
  "When promotion is on going, the voucher is not applicable.": "促销期间，优惠券不可使用。",
  "The voucher cannot be used if your payment method is E-Token.": "如果您的付款方式是电子代币，则不能使用该优惠券。",
  "Your referral code can be found in user settings page.": "您的邀请码可以在用户设置页面中找到。",
  "You can get": "你可以得到",
  "stamp for the reward.": "印章作为奖励。",
  "e-token for the reward.": "电子代币作为奖励。",
  "You can get a voucher for the reward": "您可以获得优惠券作为奖励",
  "which have": "此优惠劵将有",
  "it can be used for dryer machine only.": "它只能用于烘干机。",
  "it can be used for washer machine only.": "它只能用于洗衣机。",
  "it can be used for dryer or washer machine.": "它可用于烘干机或洗衣机。",
  "it is valid for ": "优惠劵有效期为 ",
  " days from that day the voucher given.": " 天从给优惠券那天算起。",
  "washer stamp for the reward.": "洗衣机印章作为奖励。",
  "dryer stamp for the reward.": "烘干机印章作为奖励。",
  "washer stamp and": "洗衣机印章和",
  "cleanproBirthdayShortDesc": "使用 Cleanpro Plus 在您的特殊日子兑换 {{ birthdayVoucherAmount }} 货币的生日礼券。点击以了解更多信息。",
  'When you click on "PAY" button on the payment page, you can choose either using E-wallet or E-token to pay.': '当您在支付页面点击"付款"按钮时，您可以选择使用电子钱包或电子代币进行支付。',
  "When you using your voucher during payment, you will be unable to pay with E-token.": "当您在付款期间使用您的优惠券时，您将无法使用电子代币付款。",
  "You can reload your E-token by clicking the reload button in the stamping page and select the package to purchase.": "您可以通过点击印章页面中的充值按钮并选择要购买的配套来充值您的电子代币。",
  "You can reload your E-token by clicking the reload button in the home page and select the package to purchase.": "您可以通过点击主页中的充值按钮并选择要购买的配套来充值您的电子代币。",
  "You also can view your token purchase history in transaction page.": "您还可以在交易页面中查看您的代币购买的历史记录。",
  "Using either dryer machine or washer machine with the minimum spending of": "使用烘干机或洗衣机，最低消费",
  "to collect one stamp.": "即可收集一个印章。",
  "The voucher will be given if you have collected 10 stamps.": "如果您收集了 10 个印章，将获得优惠券。",
  "Using dryer machine with the minimum spending of": "使用烘干机最低消费",
  "Using washer machine with the minimum spending of": "使用洗衣机最低消费",
  "to collect one dryer stamp.": "即可获得一个烘干机印章。",
  "to collect one washer stamp.": "即可获得一个洗衣机印章。",
  "The voucher will be given if you have collected 10 dryer stamps or 10 washer stamps.": "如果您收集了 10 个烘干机印章或 10 个洗衣机印章，将获得优惠券。",
  "For the voucher given by the dryer stamps,": "对于烘干机印章给出的优惠劵，",
  "It is valid for ": "优惠劵有效期为 ",
  "For the voucher given by the washer stamps,": "对于洗衣机印章给出的优惠劵，",
  "When promotion is on going, all the vouchers is not applicable.": "促销期间，所有优惠券均不可使用。",
  "All the vouchers cannot be used if your payment method is E-Token.": "如果您的付款方式是电子代币，则所有优惠券都不能使用。",
  "Description": "简介",
  "Refer Friends": "推荐给朋友",
  "View our guideline": "查看我们的指南",
  "When a friend / family register using your referral code, and completed a transaction with a minimum spending of": "当朋友/家人使用您的邀请码注册并完成最低消费",
  ", you will be rewarded automatically.": "的交易时，您将自动获得奖励。",
  "One user only allow to have": "一个用户只允许拥有",
  "referrals.": "邀请者。",
  "Confirmation Password": "确认密码",
  "Password and confirmation password should be same.": "密码和确认密码必须相同。",
  "You haven't applied your voucher": "您还没使用优惠劵",
  "Would you like to continue your payment without using your voucher?": "您想在不使用优惠券的情况下继续付款吗？",
  "Continue": "继续",
  "Apply voucher": "使用优惠劵",
  "Transfer": "转账",
  "Transfer Tokens": "转账电子代币",
  "Please fill in the phone number": "请填写电话号码",
  "Please enter the phone number that you want to transfer to": "请输入您要转账的电话号码",
  "Please fill in the transfer amount": "请填写转账总额",
  "Transfer amount should be more than 0 and less than your current e-Token": "转账总额需大于0且小于您当前的电子代币",
  "Please enter your password": "请输入您的密码",
  "Member ID": "会员ID",
  "E-Token Transfer Amount": "电子代币转账总额",
  "Please confirm the details before proceeding": "请在继续之前确认详细信息",
  "Transfer From": "转账从",
  "Transfer To": "转账至",
  "Transfer Amount": "转账总额",
  "Transfer Successfully!": "转账成功！",
  "Transfer Time": "转账时间",
  "Token": "电子代币",
  "Done": "完成",
  "Next": "继续",
  "Enter Password": "输入密码",
  "Transfer E-Token Amount": "电子代币转账金额",
  "User not found": "找不到用户",
  "Password is incorrect": "密码不正确",
  "Please go to your account setting and reset your password": "请前往您的用户设置并重置您的密码",
  "Only up to last 3 months transaction history": "仅最近3个月的交易记录",
  "Reload History": "充值记录",
  "Transfer History": "转账记录",
  "Only up to last 3 months reload history": "仅最近3个月的充值记录",
  "Only up to last 3 months transfer history": "仅最近3个月的转账记录",
  "If require urgent response, please contact us through whatapp": "如果需要紧急回应，请通过whatsapp与我们联系",
  "Please scan the QR code again": "请再次扫描二维码",
  "Home": "主页",
  "Quick": "快速",
  "Super": "超级",
  "Premium": "高级",
  "All users will have infinite referrals.": "所有用户都将获得无限邀请者。",
  "Collect 10 stamps to get 1 Voucher": "收集10个印章获得1张优惠劵",
  "The voucher is only for customer use only, not for sale.": "优惠券仅供客户使用，不用于销售。",
  "Outlet is currently offline, please try again later": "店铺目前处于离线状态，请稍后再试",
  "Price is not set, please contact the admin": "价格未定，请联系管理员",
  "The voucher will be given if you completed a transaction with a minimum spending of": "您将获得优惠劵如果您完成交易以最低消费",
  "The birthday voucher will be given on the first day of your birthday month.": "生日券将在您生日月份的第一天发放。",
  "The voucher only will be given if you have exceed spending amount of": "优惠劵只会发放当您的消费超过",
  "Pay with KiplePay": "使用 KiplePay 付款",
  "Pay with Scan2Pay": "使用 Scan2Pay 付款",
  "DISCOUNT CODE": "优惠码",
  "Enter code >": "输入优惠码 >",
  "Enter Discount Code": "输入优惠码",
  "Remove Code": "移除优惠码",
  "Discount Code": "优惠码",
  "Close": "关闭",
  "Please fill in your email address": "请填写您的电子邮件地址",
  "Notification": "通知",
  "Do you want to receive notification when wash cycle done?": "您想在洗涤周期完成时收到通知吗？",
  "pay extra": "额外付费",
  "Email Address": "电子邮件地址",
  "Please fill in your phone number": "请填写您的电话号码",
  "Invalid Phone Number": "无效的电话号码",
  "Washing Complete": "洗涤完成",
  "Drying Complete": "烘干完成",
  "The voucher will be given if you registered your account successfully, but it only can be used when your total transaction spending reach": "成功注册账户即可获赠礼券，但仅能使用当交易总额达",
  "and above.": "或以上。",
  "This voucher is not applicable": "此优惠券不适用",
  "Finish by": "完成于",
  "We'll let you know when it's ready to pick up": "当准备好取衣物时，我们会通知您",
  // cleanpro plus zh
  "Wash": "洗涤",
  "Dry": "烘干",
  "Scan QR": "扫描二维码",
  "Capacity": "容量",
  "Duration": "时长",
  "Custom duration": "自定义持续时间",
  "Choose duration": "选择持续时间",
  "Select the correct amount on the machine after payment.": "付款后在机器上选择正确的金额。",
  "Offers": "优惠",
  "An easier way to pay": "更简单的支付方式",
  "Create account": "创建账户",
  "Login": "登录",
  "By signing up, you agree to": "注册即表示您同意",
  "Amount to Pay": "支付总额",
  "1 voucher selected": "已选择 1 张代金券",
  "No voucher selected": "未选择代金券",
  "credit": "点数",
  "Credits": "点数",
  "credits": "点数",
  "Please ensure that the machine door is shut & your laundry is inside.": "请确保机器门已关闭并且您的衣物在里面。",
  "Insufficient balance": "余额不足",
  "Topup now": "立即充值",
  "Copyright © 2023 Cleanpro Laundry Holdings Sdn Bhd (821842-P) All rights reserved.": "版权所有 © 2023 Cleanpro Laundry Holdings Sdn Bhd (821842-P) 保留所有权利。",
  "Good Evening!": "下午好！",
  "Wallet Balance": "钱包余额",
  "Topup": "充值",
  "More details": "更多细节",
  "Collect 10 e-stamps to earn x1 FREE wash or dry voucher": "集齐 10 个电子印章即可获得 x1 免费洗或干代金券",
  "For every wash or dry cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "您在我们这里完成的每一次洗涤或烘干循环，都将获得一个电子印章。集齐 10 个，您就可以兑换一次免费的洗涤或烘干周期。这是我们感谢您选择 Cleanpro 的方式！",
  "For every wash cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "您在我们这里完成的每一次洗涤循环，都将获得一个电子印章。集齐 10 个，您就可以兑换一次免费的洗涤或烘干周期。这是我们感谢您选择 Cleanpro 的方式！",
  "For every dry cycle you complete with us, you'll earn one e-stamp. Collect 10 and you'll be able to redeem a FREE wash or dry cycle. It's our way of saying thank you for choosing Cleanpro!": "您在我们这里完成的每一次烘干循环，都将获得一个电子印章。集齐 10 个，您就可以兑换一次免费的洗涤或烘干周期。这是我们感谢您选择 Cleanpro 的方式！",
  "Credits OFF": "点数折扣",
  "% OFF": "％ 折扣",
  "Valid Till": "有效期至",
  "Used On": "用于",
  "Expired On": "过期日期",
  "Available": "可用的",
  "Expired": "已过期",
  "Used": "已使用",
  "Promotions": "促销活动",
  "Rewards": "奖励",
  "Inbox": "收件箱",
  "Activities": "活动",
  "Support": "支持",
  "Active Rewards": "活跃奖励",
  "Past Rewards": "过往奖励",
  "Get rewarded for your loyalty with Cleanpro Plus Rewards!": "通过 Cleanpro Plus 奖励获得忠诚奖励！",
  "Earn vouchers and redeem them for discounts, freebies and more. The more you use our services the more you save!": "赚取代金券并将其兑换为折扣、免费赠品等。您使用我们的服务越多，您节省的越多！",
  "Up to 3 months history": "最近3个月的历史记录",
  "Filter": "筛选",
  "All Transactions": "所有交易",
  "Transactions": "交易",
  "Select amount to topup": "选择充值金额",
  "Balance": "余额",
  "You've just paid": "你刚刚付款",
  "Need help?": "需要帮忙？",
  "24-Hour Careline Number": "24 小时服务热线号码",
  "Email Us": "给我们发电子邮件",
  "Call Now": "现在打电话",
  "Email Now": "立即发送电子邮件",
  "Update name": "更新名称",
  "Update email address": "更新电子邮件地址",
  "Change password": "更改密码",
  "Change language": "改变语言",
  "User ID": "用户号",
  "Let us know your feedback": "让我们知道您的反馈",
  "Poor": "糟糕",
  "Good": "好的",
  "Leave addtional feedback here (optional)": "在此处留下其他反馈（可选)",
  "Submit feedback": "提交反馈",
  "Pay as guest": "以访客身份付款",
  "Pay now": "现在付款",
  "Select voucher to use": "选择要使用的代金券",
  "Use voucher": "使用代金券",
  "Total": "总共",
  "Transaction Type": "交易类型",
  "Credit Topup": "点数充值",
  "Credit Transfer": "点数转账",
  "Date/Time": "日期/时间",
  "Reference ID": "参考编号",
  "Status": "状态",
  "Share Your Referral Code": "分享您的邀请码",
  "cleanproReferralShortDesc": "将您的邀请码分享给亲朋好友，当完成交易后可免费获得 {{ voucherAmount }} 积分。点击以了解更多信息。",
  "Use": "使用",
  "dryer or washer": "烘干机或洗衣机",
  "washer": "洗衣机",
  "dryer": "烘干机",
  "dryer and washer": "烘干机和洗衣机",
  "machine with the minimum spending of": "以最低消费",
  "Welcome to Cleanpro Plus": "欢迎来到 Cleanpro Plus",
  "Only scan, set & pay! An easier, more convenient way to pay with Cleanpro Plus! No need to download an app. Enjoy more rewards when you use Cleanpro Plus today! Tap to learn more.": "只需扫描、设置和支付！使用 Cleanpro Plus 支付更简单、更方便的方式！无需下载应用程序。立即使用 Cleanpro Plus，享受更多奖励！点击以了解更多信息。",
  "Cleanpro Pay Migration Notice": "Cleanpro Pay 迁移通知",
  "Dear valued Cleanpro customers, please be informed that as of 22 June 2023, the Cleanpro Pay app will be upgraded and and no longer available for use. Tap to learn more.": "尊敬的 Cleanpro 客户，请注意，自 2023 年 6 月 22 日起，Cleanpro Pay 应用程序将升级并不再可用。点击以了解更多信息。",
  "Answer simple questions and win credits for your next wash or dry! This contest is only available for selected outlets only. Tap to learn more.": "回答简单的问题并为您的下一次洗涤或烘干赢得点数！此竞赛仅适用于指定的商店。点击以了解更多信息。",
  "The all-new Cleanpro Plus is a new payment method that includes loyalty features built into the app. The more you use our services and pay using Cleanpro Plus, the more you can save. With every transaction, you can earn rewards that will help you save on your next laundry expenses.": "全新的 Cleanpro Plus 是一种新的支付方式，包括内置于应用程序中的忠诚度功能。您使用我们的服务和使用 Cleanpro Plus 支付的次数越多，您节省的费用就越多。每笔交易，您都可以获得奖励，帮助您节省下一次洗衣费用。",
  "To use Cleanpro Plus, simply scan the QR code on the machines and pay using your preferred credit/debit card or e-wallet. We hope that Cleanpro Plus will help simplify your laundry routine and give you more time for the things that matter.": "要使用 Cleanpro Plus，只需扫描机器上的二维码并使用您首选的信用卡/借记卡或电子钱包付款。我们希望 Cleanpro Plus 能够帮助简化您的洗衣程序，让您有更多时间处理重要的事情。",
  "If you have any questions about Cleanpro Plus, please read our FAQ in the menu or contact our careline at +603 2770 0100 or email us at info@cleanprolaundry.com. Thank you for choosing Cleanpro.": "如果您对 Cleanpro Plus 有任何疑问，请阅读菜单中的常见问题解答或致电 +603 2770 0100 联系我们的客服热线或发送电子邮件至 info@cleanprolaundry.com。感谢您选择 Cleanpro。",
  "Dear valued Cleanpro customers, please be informed that as of 22 June 2023, the existing Cleanpro Pay mobile app will be upgraded and no longer available for use.": "尊敬的 Cleanpro 客户，请注意，自 2023 年 6 月 22 日起，现有的 Cleanpro Pay 移动应用程序将升级并不再可用。",
  "We are migrating to the all-new Cleanpro Plus. It is a new cashless payment method, without the need to download apps.": "我们正在迁移到全新的 Cleanpro Plus。这将是一种新的无现金支付方式，无需下载应用程序。",
  "If you are the existing user of Cleanpro Pay, any of your remaining balance in the app will be transferred to your Cleanpro Plus account. You are required to use the same mobile number to sign up for Cleanpro Plus for balance transfer.": "如果您是 Cleanpro Pay 的现有用户，您在应用程序中的任何余额都将转移到您的 Cleanpro Plus 帐户。您需要使用相同的手机号码注册 Cleanpro Plus 进行余额转移。",
  "If you need help on Cleanpro Plus, please feel free to call our careline at +603 2770 0100 or email us at info@cleanprolaundry.com": "如果您需要有关 Cleanpro Plus 的帮助，请随时拨打我们的服务热线 +603 2770 0100 或发送电子邮件至 info@cleanprolaundry.com",
  "Answer simple questions and win credits for your next wash or dry!": "回答简单的问题并为您的下一次洗涤或烘干赢得点数！",
  "Step 1 : Scan the Jawab & Gerenti Menang QR code in the participated outlets.": "第 1 步：在指定的商店里扫描Jawab & Gerenti Menang 二维码。",
  "Step 2 : Answer 3 simple questions.": "第 2 步：回答 3 个简单问题。",
  "Step 3 : Submit and stand a chance to win prizes.": "第 3 步：提交并有机会赢取奖品。",
  "Grand Prize :": "大奖：",
  "x10 per outlet - 100 credits worth of vouchers in Cleanpro Plus": "每个分店 x10 - Cleanpro Plus 中的 RM100 点数优惠劵",
  "Consolation Prize :": "安慰奖：",
  "X25 per outlet - 50 credits worth of vouchers in Cleanpro Plus": "每个分店 X25 - Cleanpro Plus 中的 RM50 点数优惠劵",
  "Participation prize:": "参与奖：",
  "Every participants are eligible to receive a FREE 3 credits voucher (worth RM3) in Cleanpro Plus.": "每位参与者都有资格在 Cleanpro Plus 中免费获得 3 个点数代金券（价值 RM3）。",
  "If you have any questions please don't hesitate to contact our careline at +603 2770 0100 or email us at info@cleanprolaundry.com. Thank you for choosing Cleanpro.": "如果您有任何疑问，请随时拨打我们的服务热线 +603 2770 0100 或发送电子邮件至 info@cleanprolaundry.com。感谢您选择 Cleanpro。",
  "Payment Confirmation": "付款确认",
  "This campaign is only eligible for users who use the all-new Cleanpro Plus web application.": "此活动仅适用于使用全新 Cleanpro Plus 网络应用程序的用户。",
  "ONE e-stamp will be rewarded for each wash or dry cycle.": "每次洗涤或干燥循环将奖励一个电子印章。",
  "ONE e-stamp will be rewarded for each wash cycle.": "每个洗涤循环将奖励一个电子印章。",
  "ONE e-stamp will be rewarded for each dry cycle.": "每个干燥循环将奖励一个电子印章。",
  "Regardless of the washer or dryer settings, the number of e-stamps being collected is the same.": "无论洗衣机或烘干机设置如何，收集的电子印章数量都是相同的。",
  "The type of e-stamp collected is based on the services that you have used.": "您所收集的电子印章种类取决于你所使用的服务而定。",
  "Washing service will reward washer e-stamp and drying service will reward dryer e-stamp.": "洗涤服务将奖励洗衣机电子印章，烘干服务将奖励烘干机电子印章。",
  "Washing service will reward washer e-stamp.": "洗涤服务将奖励洗衣机电子印章。",
  "Drying service will reward dryer e-stamp.": "烘干服务将奖励烘干机电子印章。",
  "E-stamps will automatically be added to your account once the condition has been fulfilled.": "满足条件后，电子印章将自动添加到您的帐户中。",
  "There is no limit on how many e-stamps can be gathered in a single day. So long as you meet the requirements to collect the e-stamps.": "只要您符合收集电子印章的条件，您将获得电子印章，一天内无收集电子印章数量限制",
  "The type of e-stamps that have been collected is final and cannot be changed.": "已收集的电子印章类型是最终的，不能更改。",
  "There is no expiration for the e-stamps; only till the discontinuation of the campaign.": "电子印章不会过期；直到活动停止为止。",
  "E-stamps do not have any currency value, and they cannot be converted to cash or credits.": "电子印章没有任何货币价值，也不能兑换成现金或点数。",
  "This campaign is not applicable with any other ongoing promotions, offers, vouchers, and/or discounts provided by the participating outlets.": "此活动不适用于参与商店提供的任何其他正在进行的促销、优惠、代金券和/或折扣。",
  "These terms and conditions are subject to change at any time without prior notice.": "这些条款和条件可能随时更改，恕不另行通知。",
  "Press START on the machine to begin washing.": "按机器上的START开始洗涤。",
  "Press START on the machine to begin drying.": "按机器上的START开始烘干。",
  "Reminder": "提醒",
  "Remember to select the same settings you have paid for on the corresponding machine.": "请记住在相应的机器上选择您已支付的相同设置。",
  "Please ensure that your laundry is inside the machine and door is properly shut.": "请确保您的衣物在机器内并且门已正确关闭。",
  "voucherCreditsOff_one": "折扣 {{currentVoucherAmount}} 点数",
  "voucherCreditsOff_other": "折扣 {{currentVoucherAmount}} 点数",
  "voucherPercentageOff": "折扣 {{currentVoucherAmount}}%",
  "cleanproReferralFlatVoucher_one": "将您的 Cleanpro Plus 邀请码分享给您的朋友和家人，即可获得免费优惠劵！对于每个完成第一笔交易的成功推荐，您将获得一张价值折扣 {{ referralVoucherAmount }} 货币的优惠劵。",
  "cleanproReferralFlatVoucher_other": "邀请码分享给您的朋友和家人，即可获得免费优惠劵！对于每个完成第一笔交易的成功推荐，您将获得一张价值折扣 {{ referralVoucherAmount }} 货币的优惠劵。",
  "cleanproReferralRateVoucher": "邀请码分享给您的朋友和家人，即可获得免费优惠劵！对于每个完成第一笔交易的成功推荐，您将获得一张价值 {{ referralVoucherAmount }}% 折扣的优惠劵。",
  "cleanproReferralTokenReward_one": "将您的 Cleanpro Plus 邀请码分享给您的朋友和家人，即可获得免费货币！对于每个完成第一笔交易的成功推荐，您将获得免费的 {{ referralToken }} 货币。",
  "cleanproReferralTokenReward_other": "将您的 Cleanpro Plus 邀请码分享给您的朋友和家人，即可获得免费货币！对于每个完成第一笔交易的成功推荐，您将获得免费的 {{ referralToken }} 货币。",
  "How to get & use code:": "如何获取和使用邀请码：",
  'Go to menu = icon and click “Refer friends”': '在菜单 = 图标，然后单击“推荐给朋友”',
  "Share with everyone who will likely to use Cleanpro Plus.": "与所有可能使用 Cleanpro Plus 的人分享。",
  "Referral Terms & Conditions:": "推荐条款和条件：",
  "cleanproReferralMinimumSpend": "当您的朋友或家人使用您的邀请码注册并完成交易（最低消费 RM{{ referralTransactionAmount }}）时，您将自动获得奖励。",
  "cleanproNumberOfReferralReward": "您可以与任意数量的人分享，但每个用户只能获得 {{ numberOfReferral }} 次推荐奖励。",
  "cleanproNumberOfReferral_one": "您可以与任意数量的人分享，但每个用户只能收到 {{ numberOfReferral }} 张推荐优惠券。",
  "cleanproNumberOfReferral_other": "您可以与任意数量的人分享，但每个用户只能收到 {{ numberOfReferral }} 张推荐优惠券。",
  "cleanproVoucherExpiry_one": "优惠券自发放之日起 {{ voucherDuration }} 天内有效。",
  "cleanproVoucherExpiry_other": "优惠券自发放之日起 {{ voucherDuration }} 天内有效。",
  "The voucher is for customer use only and not for sale.": "优惠券仅供客户使用，不得出售。",
  "cleanproBirthdayFlatAmount": "我们相信您的特殊日子值得额外闪耀！这就是为什么我们很高兴为您推出独家生日奖励计划！从今年开始，之后的每一年，我们都会在您生日月份第一天赠送 RM{{ voucherAmount }} 优惠券。",
  "cleanproBirthdayRateAmount": "我们相信您的特殊日子值得额外闪耀！这就是为什么我们很高兴为您推出独家生日奖励计划！从今年开始，之后的每一年，我们都会在您生日月份第一天赠送 {{ voucherAmount }}% 优惠券。",
  "Make sure your account details include your birthdate. This way, we'll know exactly when to send you your special birthday surprise.": "请确保您的账户已填写详细信息包括您的生日。这样，我们就能准确知道何时向您发送特别的生日惊喜。",
  "Birthday Voucher Terms & Conditions:": "生日礼券条款和条件：",
  "The voucher will be given on the first day of your birthday month.": "优惠券将在您生日月份的第一天发放。",
  "cleanproVoucherFlat": "当您在付款时使用优惠劵，它会给您 {{ voucherAmount }} 货币（RM{{ voucherAmount }}）折扣。",
  "cleanproVoucherRate": "当您在付款时使用优惠劵，它会给您 {{ voucherAmount }} %折扣。",
  "All vouchers is not valid with other ongoing promotions, discounts and offers.": "所有优惠券均不适用于当正在进行的促销、折扣和优惠。",
  "Claim a complimentary wash and dry for every 10 e-stamps collected! We value your loyalty and want to reward you for choosing Cleanpro Plus. Tap to learn more.": "每收集 10 张电子印章，即可获得免费清洗和烘干服务！我们重视您的忠诚度，并希望奖励您选择 Cleanpro Plus。点击以了解更多信息。",
  "cleanproStampFlat_one": "每收集 10 个电子印章，您就可以使用 Cleanpro Plus 申请高达 {{ voucherAmount }} 的免费洗或烘干货币。让您的洗衣程序不仅轻松无忧，而且收获颇丰。",
  "cleanproStampFlat_other": "每收集 10 个电子印章，您就可以使用 Cleanpro Plus 申请高达 {{ voucherAmount }} 的免费洗或烘干货币。让您的洗衣程序不仅轻松无忧，而且收获颇丰。",
  "cleanproStampRate": "每收集 10 个电子印章，您就可以使用 Cleanpro Plus 申请高达 {{ voucherAmount }}% 的折扣。让您的洗衣程序不仅轻松无忧，而且收获颇丰。",
  "A voucher will be instantly added to your Rewards page when you reach 10 washes. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "当您收集到 10 个洗涤印章时，优惠券将立即添加到您的奖励页面。您的电子印章计价器将在下次收集时归零。下次洗衣时，在扫描二维码页面领取优惠券。",
  "A voucher will be instantly added to your Rewards page when you reach 10 drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "当您收集到 10 个烘干印章时，优惠券将立即添加到您的奖励页面。您的电子印章计价器将在下次收集时归零。下次洗衣时，在扫描二维码页面领取优惠券。",
  "A voucher will be instantly added to your Rewards page when you reach 10 washes or drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.": "当您收集到 10 个洗涤或10个烘干印章时，优惠券将立即添加到您的奖励页面。您的电子印章计价器将在下次收集时归零。下次洗衣时，在扫描二维码页面领取优惠券。",
  "Terms & Conditions:": "条款和条件：",
  "cleanproStampTransactionAmount_one": "要赚取一个电子印章，您必须在每次会话中花费至少 {{ stampTransactionAmount }} 货币（RM{{ stampTransactionAmount }}）。",
  "cleanproStampTransactionAmount_other": "要赚取一个电子印章，您必须在每次会话中花费至少 {{ stampTransactionAmount }} 货币（RM{{ stampTransactionAmount }}）。",
  "You will be automatically issued a voucher if you have collected 10 washer stamps.": "如果您收集了 10 张洗衣机印章，您将自动获得一张优惠券。",
  "You will be automatically issued a voucher if you have collected 10 dryer stamps.": "如果您收集了 10 张烘干机印章，您将自动获得一张优惠券。",
  "You will be automatically issued a voucher if you have collected 10 dryer stamps or 10 washer stamps.": "如果您收集了 10 张洗衣机或10 张烘干机印章，您将自动获得一张优惠券。",
  "You will be automatically issued a voucher if you have collected 10 stamps.": "如果您收集了 10 张印章，您将自动获得一张优惠券。",
  "The voucher is non-refundable and cannot be exchanged for cash; in part or in full.": "此礼券不可退款，亦不可兑换现金；部分或全部。",
  "This voucher is valid for single transaction only.": "此优惠劵仅适用于单次交易。",
  "General questions": "常见问题",
  "E-stamps collection": "电子印章",
  "Coin exchange machines": "硬币兑换机",
  "Washer machines": "洗衣机",
  "Dryer machines": "烘干机",
  "Paywave issues": "Paywave问题",
  "Cleanpro franchise": "Cleanpro特许经营权",
  "How can I wash my laundry using Cleanpro Plus?": "如何使用 Cleanpro Plus 洗涤衣物？",
  "Here are the steps to wash your laundry using Cleanpro Plus:": "以下是使用 Cleanpro Plus 洗涤衣物的步骤：",
  "Find an unoccupied washing machine in the store, and insert your laundry in the washer.": "在商店里找一台无人使用的洗衣机，然后将要洗的衣服放入洗衣机。",
  "Select the desired temperature that you would like to use for your laundry.": "选择您想用于洗衣的所需温度。",
  "Using the scanner in the app, scan the QR code provided on the washing machine. Then select the same settings that you have pressed on the machine.": "使用应用程序中的扫描仪，扫描洗衣机上提供的二维码。然后选择您在机器上按下的相同设置。",
  'Tap on “Pay with credits” to pay the amount shown on the app.': '点击“使用点数支付”以支付应用程序上显示的金额。',
  "Once the payment has been accepted, press the START button on the washer machine.": "付款被接受后，按下洗衣机上的开始按钮。",
  "After the washing cycle ends, you can just remove your laundry from the machine.": "洗涤周期结束后，您只需将衣物从机器中取出即可。",
  "How can I dry my laundry using Cleanpro Plus?": "如何使用 Cleanpro Plus 烘干衣物？",
  "Here are the steps to dry your laundry using Cleanpro Plus:": "以下是使用 Cleanpro Plus 烘干衣物的步骤：",
  "Find an unoccupied drying machine in the store, and insert your laundry in the dryer.": "在商店里找一台无人使用的干衣机，然后将要烘干的衣服放入干衣机中。",
  "Select the desired temperature and duration that you would like to use for your laundry.": "选择您想用于洗衣的所需温度和持续时间。",
  "Using the scanner in the app, scan the QR code provided on the drying machine. Then select the same settings that you have pressed on the machine.": "使用应用程序中的扫描仪，扫描干衣机上提供的二维码。然后选择您在机器上按下的相同设置。",
  "Once the payment has been accepted, press the START button on the dryer machine.": "付款被接受后，按下干衣机上的开始按钮。",
  "After the drying cycle ends, you can just remove your laundry from the machine.": "烘干周期结束后，您只需将衣物从机器中取出即可。",
  "Why does the QR code I scanned take me to a different machine?": "为什么我扫描的二维码会将我带到另一台机器上？",
  "Here are the steps to solve it:": "以下是解决它的步骤：",
  "Make sure that the number on the QR code (e.g. W1, W2, etc) is the same as the machine that you would like to use.": "确保二维码上的数字（例如 W1、W2 等）与您要使用的机器相同。",
  "If the QR code number does not match the machine you want, use the other machine. Please contact our customer service line at 03-27700100 to report this issue.": "如果 QR 代码编号与您想要的机器不匹配，请使用其他机器。请联系我们的客户服务热线03-27700100报告此问题。",
  "How can I add more time to the washer that I am currently using?": "如何为当前使用的洗衣机增加更多使用时间？",
  "The washing time for our washer is fixed and cannot add any additional time.": "我们洗衣机的洗涤时间是固定的，不能增加任何额外的时间。",
  "How can I add more time to the dryer that I am currently using?": "如何为当前使用的干衣机增加更多使用时间？",
  "Here are the steps to add more time to the dryer machine:": "以下是为烘干机增加更多时间的步骤：",
  "By using the scanner in the app, scan the QR code provided at the dryer machine.": "使用应用程序中的扫描仪，扫描干衣机上提供的二维码。",
  "Select the amount of time that you would like to add to the machine.": "选择您要增加的时间到机器上。",
  "After the drying cycle ends, you can just remove the clothes from the machine.": "烘干周期结束后，您可以将衣物从机器中取出。",
  "REMINDER - It is recommended to add drying time 5 minutes before the cycle ends. And you cannot add tokens for extra times when the machine finishes the drying cycle.": "提醒 - 建议在循环结束前 5 分钟增加干燥时间。当机器完成干燥周期时，您不能增加额外时间。",
  "Will the timer keep running when I have stopped the drying machine?": "当我停止烘干机时，定时器会继续运行吗？",
  "Yes. Even after you stop the machine, the timer will continue to run. If you want to extend the drying time, add drying time 5 minutes before the cycle ends. This prevents the cycle from ending before your clothes are completely dried.": "是的。即使在您停止机器后，计时器仍会继续运行。如果您想延长干燥时间，请在循环结束前 5 分钟增加干燥时间。这可以防止您的衣服完全干燥之前结束烘干。",
  "How do I reload my credits for Cleanpro Plus?": "我如何为 Cleanpro Plus 充值？",
  "Here are the steps to reload your credits:": "以下是充值点数的步骤：",
  'Select the "Top Up Credit" button on the homepage.': '选择主页上的“充值”按钮。',
  "Choose the amount you want to reload and the payment method you want to use.": "选择您要充值的金额和您要使用的付款方式。",
  "You will be taken to the payment page. The screen will return to Cleanpro Plus once the transaction is completed.": "您将被带到付款页面。交易完成后，屏幕将返回 Cleanpro Plus。",
  "How many credits can I reload in one transaction?": "我可以在一次交易中充值多少点数？",
  "Reloading credits requires a minimum of RM 10 and a maximum of RM 50.": "充值点数需要最低 RM 10 和最高 RM 50。",
  "Can I pay using credits in the Cleanpro Plus app along with tokens or coins in a single transaction?": "我可以在单笔交易中使用 Cleanpro Plus 应用程序中的点数以及代币或硬币进行支付吗？",
  "No. Only a single payment method may be chosen when performing any transactions.": "不能。进行任何交易时只能选择一种付款方式。",
  "The amount I have reloaded in my account was not reflected in the app.": "我在我的账户中充值的金额没有反映在应用程序中。",
  "Please close and restart the application and check if the new balance has been updated.": "请关闭并重新启动应用程序并检查新余额是否已更新。",
  "If the amount is still not updated, please contact our customer service line at 03-27700110 to receive further assistance.": "如果金额仍未更新，请联系我们的客户服务热线03-27700110以获得进一步的帮助。",
  "Can I cash out the credits I reloaded in my account for this app?": "我可以兑现我为这个应用程序中充值的点数吗？",
  "No. The credits that you have reloaded will remain in your Cleanpro Plus account.": "不能。您充值的点数将保留在您的 Cleanpro Plus 账户中。",
  "I have requested a refund, but I still have not received it.": "我已申请退款，但仍未收到。",
  "Please contact our customer service line at 03-27700100 to receive further assistance.": "请联系我们的客户服务热线03-27700100以获得进一步的帮助。",
  "I have made the payment, and the app double or triple charged me.": "我已经付款，但应用程序向我收取了两倍或三倍的费用。",
  "The washing machine cannot start even after I have paid using the app.": "使用应用程序付款后洗衣机无法启动。",
  "Ensure that the temperature settings you have selected and paid for on the application are the same as what you have pressed on the washing machine. If not, select the correct temperature settings on the washing machine and try again.": "确保您在应用程序上选择和支付的温度设置与您在洗衣机上按下的温度设置相同。如果不是，请在洗衣机上选择正确的温度设置并重试。",
  "If the machine is still not able to be started, please contact our customer service line at 03-27700100 to receive further assistance.": "若机器仍无法启动，请联络我们的客服热线03-27700100以获得进一步协助。",
  "Can I use multiple vouchers together in the same washing or drying cycle?": "我可以在同一个洗涤或烘干程序中同时使用多张优惠券吗？",
  "No. Only a single voucher can be used for each washing and drying cycle.": "不能。每个洗涤和烘干周期只能使用一张优惠券。",
  "Why can't I use my free wash voucher that I received after signing up?": "为什么我不能使用注册后收到的免费洗涤券？",
  "To redeem or use the voucher, you must complete at least one washing or drying cycle.": "要兑换或使用优惠券，您必须至少完成一次洗涤或烘干程序。",
  "Can I change my phone number even after registering my account?": "我可以在注册账户后更改我的电话号码吗？",
  "No. Once registered to an account, the phone number is locked into the account.": "不能。一旦注册到帐户，电话号码就会被锁定到该账户中。",
  "I have problems in the store, and who can I refer to for help?": "我在店里遇到问题，我可以向谁寻求帮助？",
  "Do not worry, as you can contact our customer service line at 03-27700100 to receive assistance at any time.": "别担心，您可以随时拨打我们的客服热线03-27700100寻求帮助。",
  "Should I add my own laundry detergent and softeners to the washing machine?": "我应该在洗衣机中添加自己的洗衣液和柔顺剂吗？",
  "There is no need for that, as all you have to do is bring your dirty clothes and put them in the washer. The detergent and softeners will be provided by us, and you can rest assured that the laundry detergents we used are certified industrial grade.": "没有必要，因为您只需带上脏衣服并将它们放入洗衣机即可。洗涤剂和柔顺剂由我们提供，您可以放心，我们使用的洗衣液都是经过认证的工业级洗涤剂。",
  "I would like to add my own laundry detergent and softener to the washing machine.": "我想在洗衣机中添加自己的洗衣液和柔顺剂。",
  "We do not recommend our customers add their own detergent, as the mixture of different detergents may affect the wash quality. And you can rest assured that the laundry detergent we used is certified industrial grade.": "我们不建议客户自行添加洗涤剂，因为不同洗涤剂的混合可能会影响洗涤质量。您可以放心，我们使用的洗衣液是经过认证的工业级洗涤剂。",
  "What time does the store close?": "商店几点关门？",
  "Our self-service laundry operation is open 24 hours a day, which means you can always be sure to go back home with a fresh set of laundry.": "我们的自助洗衣店全天 24 小时开放，这意味着您回家时一定可以洗干净一整套新衣服。",
  "Are you open on weekends?": "你们周末营业吗？",
  "Yes. Cleanpro is also open on weekends and even on public holidays.": "是的。 Cleanpro 在周末甚至公共假期也营业。",
  "How many clothes can I fit in the washing or drying machine?": "我可以在洗衣机或烘干机中放入多少件衣服？",
  "To ensure that your laundry is properly washed or dried, keep the amount of laundry inside the machine lower than the line of guide sticker on the machine.": "为确保您的衣物得到正确洗涤或烘干，请使机器内的衣物量低于机器上的引导标签线。",
  "How many e-stamps can I collect in a single day?": "我一天可以收集多少个电子印章？",
  "There is no limit to the amount of stamps that can be collected in a single day; ONE stamp will be awarded with every wash or dry.": "一天内可以收集的印章数量没有限制；每次洗涤或烘干都会获得一枚印章。",
  "When I use the washing service, can I change the collected stamp to a dryer stamp, and vice versa?": "当我使用洗涤服务时，我可以将收集的印章换成烘干机印章，反之亦然吗？",
  "No, the type of stamp collected is only applied to the service that you have used.": "不能，所收集的印章类型只适用于您使用过的服务。",
  "What temperatures can I choose when I claim the free washing e-stamp reward?": "领取免费洗印章奖励时，我可以选择什么温度？",
  "Any washing temperature is available for the e-stamp reward.": "任何洗涤温度均可获得电子印章奖励。",
  "What temperatures can I choose when I claim the free drying e-stamp reward? And how long can I set the time?": "领取免费烘干印章奖励时，我可以选择什么温度？我可以设置多长时间？",
  "Any drying temperature and duration can be selected for the e-stamp reward.": "可以选择任何干燥温度和持续时间作为电子印章奖励。",
  "I'm unable to change the coin.": "我无法兑换硬币。",
  "What colour is the light shown on the machine, red or green? If it is a red light, please contact our customer service line at 03-27700100 to receive further assistance.": "机器上显示的灯是什么颜色，红色还是绿色？如果是红灯，请联系我们的客户服务热线03-27700100以获得进一步的帮助。",
  "Green Light": "绿灯",
  "Red Light": "红灯",
  "Machine functions as normal. Please try again.": "机器功能正常。请再试一次。",
  "(flashing 2x) - There are no coins/tokens in the machine.": "（闪烁 2 次）- 机器中没有硬币/代币。",
  "OR": "或者",
  "Note is jammed, please proceed to contact customer service": "纸币卡纸，请联系客服",
  "(flashing 3x) - Note is jammed, please proceed to contact customer service": "（闪烁 3 次）- 纸币卡住，请继续联系客户服务",
  "My notes are stuck in the machine.": "我的纸币卡在机器里了。",
  "My detergent is not dispensing in the vending machine.": "我的洗涤剂没有在自动售货机中分配。",
  "The water is leaking out of the washing machine.": "水从洗衣机中漏出。",
  "Please check the washer machine to see whether there are any clothes that are stuck at the door side.": "请检查洗衣机是否有衣服卡在门边。",
  "If yes, here are the steps to solve it:": "如果是，这里是解决它的步骤：",
  "Press the emergency stop button (RED Button) for 3 seconds.": "按下紧急停止按钮（红色按钮）3 秒钟。",
  "Wait for the sound to stop, and then only open the door. (If after 30 minutes the sound still has not stopped, please contact our customer service line at 03-27700100 to receive further assistance)": "等声音停止，才打开门。 (若30分钟后声音仍未停止，请联络我们的客服热线03-27700100以获得进一步协助)",
  "Ensure the door is closed tightly, and press the START button.": "确保门紧闭，然后按下启动按钮。",
  "REMINDER - The emergency stop button can only be used once.": "提醒 - 紧急停止按钮只能使用一次。",
  "There is no detergent dispensing in the washing machine.": "洗衣机中没有洗涤剂配给。",
  "Ensure that the machine is in operation and that the START button was pressed.": "确保机器正在运行并且按下了启动按钮。",
  "If the detergent is still not dispensing, please contact our customer service department at 03-27700100 for further assistance.": "如果洗涤剂仍未配出，请致电03-27700100与我们的客户服务部联系以获得进一步的帮助。",
  "The price displayed on the machine's screen did not change after inserting the token.": "插入硬币后，机器屏幕上显示的价格没有变化。",
  "Press the small button (in the centre) to reject the tokens that have been inserted. Press a few times till the coins come out.": "按小按钮（在中心）拒绝已插入的硬币。按几次直到硬币出来。",
  "If the coins still cannot come out or the button is not functioning, please contact our customer service line at 03-27700110 to receive further assistance.": "若仍无法出币或按钮无法使用，请联络我们的客服热线03-27700110以获得进一步协助。",
  "The machine is not starting, and it displays an error code.": "机器未启动，并显示错误代码。",
  "We would like to apologise for any inconvenience caused. Please contact our customer service line at 03-27700100 to receive further assistance.": "对于由此造成的任何不便，我们深表歉意。请联系我们的客户服务热线03-27700100以获得进一步的帮助。",
  "I want to wash the clothes again, but why couldn't the machine start even after I added tokens to the machine?": "我又想洗衣服，为什么我给机器加了硬币后机器还是启动不了？",
  "Try opening and closing the door again before starting the machine.": "在启动机器之前再次尝试打开和关闭门。",
  "Can I change the water temperature of the machine?": "我可以改变机器的水温吗？",
  "Yes, but the washer temperature can only be changed from hot to cold after the machine has been started, and not from cold to hot.": "是的，但洗衣机温度只能在机器启动后由热变冷，而不能由冷变热。",
  "Can I stop the machine?": "我可以停止机器吗？",
  "Here are the steps to stop the machine:": "以下是停止机器的步骤：",
  "Wait for the sound to stop, and then only open the door. (If after 30 minutes the sound still has not stopped, please contact our customer service line at to receive further assistance)": "等声音停止，才打开门。 （若30分钟后声音仍未停止，请联系我们的客服热线获取进一步帮助）",
  "There is no water supply on the machine used.": "使用的机器上没有供水。",
  "Why is the washing temperature cold even when I have selected HOT.": "为什么我选择了热，洗涤温度还是很冷。",
  "The water temperature is HOT or WARM only during washing. The water will turn COLD during rinsing.": "水温仅在洗涤期间为热或温。冲洗时水会变冷。",
  "The machine has already finished washing, but it does not spin well, and there is water left on the load.": "机器已经完成洗涤，但旋转不畅，衣物上还有水。",
  "Can I put wet laundry into the dryer to dry?": "我可以将湿衣物放入烘干机中烘干吗？",
  "No, as the dripping wet laundry might cause the dryer to short circuit.": "不能，因为滴水的湿衣物可能会导致干衣机短路。",
  "REMINDER - Refer to the labels on your clothes to know what kind of temperature is best for the kind of fabric that it uses. Then select the temperature on the machine accordingly.": "提醒 - 请参阅衣服上的标签，了解哪种温度最适合所使用的面料。然后相应地选择机器上的温度。",
  "My clothes are still wet even after drying.": "我的衣服烘干后还是湿的。",
  "How can I stop the dryer when the machine is still running?": "机器仍在运行时如何停止烘干机？",
  "Just open the door and the machine will automatically stop.": "只要打开门，机器就会自动停止。",
  "I added extra tokens for extra times, but the machine did not update the time.": "我为额外的时间添加了额外的货币，但机器没有更新时间。",
  "For the time to be reflected accurately, it is recommended for you to add tokens 5 minutes before the services end.": "为了准确反映时间，建议您在服务结束前5分钟添加货币。",
  "REMINDER - You cannot add tokens for extra times when the machine finishes the drying cycle.": "提醒 - 当机器完成烘干循环时，您不能为额外时间添加货币。",
  "Why is the machine making noise?": "为什么机器会发出噪音？",
  "Please check the drum to see if there are any coins or other small things inside.": "请检查滚筒，看看里面是否有任何硬币或其他小东西。",
  "I have pressed the START button on the dryer, but it is still not working.": "我已经按下烘干机上的启动按钮，但它仍然无法正常工作。",
  "Please try to open the door and close it again. Next, press the START button again to start drying.": "请尝试打开并再次关闭门。接下来，再次按下开始按钮开始干燥。",
  "If the dryer is still not functioning, please contact our customer service line at 03-27700100 to receive further assistance.": "如果干衣机仍然无法正常工作，请联系我们的客户服务热线 03-27700100 以获得进一步的帮助。",
  "Paywave has deducted an amount from the card but the machine did not reflect the transaction.": "Paywave 已从卡中扣除一笔金额，但机器未反映这笔交易。",
  "The Paywave transaction has failed but the amount has still been deducted from my card.": "Paywave 交易失败，但金额仍从我的卡中扣除。",
  "Paywave has double/triple charge but the machine cannot start.": "Paywave 有双倍/三倍收费但机器无法启动。",
  "Paywave after double/triple charge machines can't start.": "双倍/三倍收费后的 Paywave 机器无法启动。",
  "Paywave is offline": "Paywave 已离线",
  "Do Cleanpro Express offer franchise business/selling machines/laundry products?": "Cleanpro Express 是否提供特许经营/销售机器/洗衣产品？",
  "For further inquiries, please email to info@cleanproexpress.com": "如需进一步查询，请发送电子邮件至 info@cleanproexpress.com",
  "Step 6": "步骤 6",
  "User Existed": "用户已存在",
  "Invalid Email Address": "无效的邮件地址",
  "Let's get started!": "让我们开始吧！",
  "Enter your mobile number": "输入你的手机号码",
  "Proceed": "继续",
  "Enter OTP number": "输入一次性密码",
  "You will receive a six-digit OTP via SMS sent to": "您将收到一个六位数的一次性密码短信在",
  "Verify": "验证",
  "Resend OTP": "重新发送一次性密码",
  "Welcome onboard!": "欢迎！",
  "Please fill in the info below": "请填写以下资料",
  "Enter your name*": "输入你的名字*",
  "Enter your email address*": "输入你的电子邮箱地址*",
  "Create a password*": "创建密码*",
  "Enter referral code (if got any)": "输入推荐码（如果有）",
  "Get started": "开始",
  "Birthday updated successfully": "生日更新成功",
  "Date of birth": "出生日期",
  "Enter your date of birth": "输入你的出生日期",
  "Select your birth date": "选择您的出生日期",
  "Update date of birth": "更新出生日期",
  "Update birth date": "更新出生日期",
  "Already have an account?": "已经有账户？",
  "Login now": "立即登录",
  "Sign in": "登入",
  "Sign up now": "立即注册",
  "COMPLETED": "完成",
  "WASHING NOW": "洗涤中",
  "DRYING NOW": "烘干中",
  "It's done!": "完成了！",
  "Please collect your fresh, clean laundry in order to leave the machine available for the next person to use!": "请收集您清新、干净的衣物，以便让机器可供下一个人使用！",
  "Leave a feedback": "留下反馈",
  "Time left": "剩余时间",
  "Add drying time": "增加烘干时间",
  "Hi": "你好",
  "customer": "顾客",
  "Thank you for your payment. Don't forget to select the <b>correct temperature</b> and press <b>START</b> when you're ready.": "谢谢您的付款。不要忘记选择<b>正确的温度</b>，并在准备就绪后按<b>开始</b>。",
  "Select how you want to receive a notification when your laundry is finished": "选择洗衣完成后您希望如何接收通知",
  "Sign up": "注册",
  "Continue to pay": "继续付款",
  "Register as a member to enjoy more benefits!": "注册成为会员，享受更多优惠！",
  "Get FREE 3 credits per new user": "每个新用户免费获得 3 个货币",
  "Enjoy exclusive promotions": "享受独家促销",
  "FREE voucher on your birthday": "生日当天可获赠免费优惠券",
  "and more": "和更多",
  "Denied camera access": "拒绝摄像头访问",
  'Permission to access the camera has been denied. Please try again and click "Allow" to scan the QR code.': '访问摄像头的权限已被拒绝。请重试并点击“允许”扫描二维码。',
  "Okay": "好的",
  "Oops. You don't have enough balance to make the payment": "您没有足够的余额来付款",
  "Please confirm": "请确认",
  "Do you want to proceed with this feedback?": "您想继续处理此反馈吗？",
  "Are you sure to purchase": "您确定购买",
  "credits for": "货币以",
  "Topup credit": "充值货币",
  "Extended duration will be instantly reflected on machine after payment": "付款后，延长的时间将立即反映在机器上",
  "Machine is currently busy": "机器当前正忙",
  "Do you want to extend drying time?": "您想延长烘干时间吗？",
  "Cuci Hari-Hari Bersama Cleanpro!": "Cuci Hari-Hari Bersama Cleanpro!",
  "Ready for a challenge?": "准备好迎接挑战了吗？",
  "Here's one for you - all you need to do is wash your laundry 10 times in 20 days at participating Cleanpro outlets. Stand a chance to win a Limited Edition Cleanpro Designer Laundry Bag worth up to RM40!": "挑战如下 - 你只需在20天内于任何一家Cleanpro门店里洗衣10次，即可赢得一款价值高达RM40的限量版Cleanpro设计师系列洗衣袋。",
  "Here's how to participate:": "参与方式：",
  "Step 1: Scan the QR Code on the washer to launch Cleanpro Plus": "步骤1：扫描QR Code来启动Cleanpro Plus应用并注册账户。",
  "Step 2: Pay for your wash with Cleanpro Plus": "步骤2：选择您需要充值的额度并支付。",
  "Step 3: Complete x10 washes within 20 days to win": "步骤3：在20天内完成10次洗衣。",
  "Challenge Period: 01 July - 31 August 2023": "挑战期：2023年7月1日 - 8月31日",
  "Drop by your nearest Cleanpro outlet to wash your laundry with us for 20 days straight! Good luck!": "前往最近的Cleanpro门店，与我们一起“天天喜衣”！祝您好运！",
  "TERMS & CONDITIONS": "条款及细则",
  "PARTICIPATION CRITERIA": "参与条件",
  "1) Participant needs to register an account in Cleanpro Plus web app.": "1）参与者需要在Cleanpro Plus应用上注册一个账户。",
  "2) Participant can use any washer of any capacity in any participating outlets to qualify for entries.": "2）参与者在任何一间指定门店使用任何容量的洗衣机即符合资格。",
  "3) Payment needs to be made with Cleanpro Plus web app.": "3）款项需通过Cleanpro Plus进行支付。",
  "4) Washes must be completed and paid (with no dispute whatsoever) within campaign period.": "4）清洗及结账必须在活动期间内完成（绝无争议）。",
  "5) Campaign period starts from 1st July 2023 at 00:00 and ends at 31st August 2023 at 23:59.": "5）活动将从2023年7月1日00:00开始，至2023年8月31日23:59结束。",
  "WINNERS & PRIZES": "获奖者及奖品",
  "1) Cleanpro will notify the winners of the campaign via registered WhatsApp number or email address provided, within 4 weeks after the campaign ends.": "1）Cleanpro将在活动结束后的4周内，通过注册的WhatsApp号码或所提供的电子邮件箱通知获奖者。",
  "2) Any unclaimed prizes after 2 weeks from notification date will not be entertained thereafter.": "2）从通知日期起直至2周后仍未前来领取的奖品将不予理会。",
  "3) Upon completing the ten (10) washes within twenty (20) days campaign, participants will be a winner and be eligible to win ONE (1) Cleanpro Designer Laundry Bag.": "3）参与者若在20天的活动期间内完成十（10）次洗衣次数后，将赢得一（1）款Cleanpro设计师系列洗衣袋。",
  "4) Winners are eligible to win more than ONE (1) Cleanpro Designer Laundry Bag in this campaign.": "4）获奖者有资格在此活动中赢得多于一（1）个Cleanpro设计师系列洗衣袋。",
  "5) Winners will be given the choice of his/her preferred laundry bag size; but only ONE (1) can be selected for each qualified win.": "5）获奖者将可选择自己喜欢的洗衣袋尺寸；但每次完成挑战只能在奖品中选择一（1）个。",
  "A) XL Size - Blue bag with up to 20KG in capacity": "A）XL尺寸 - 蓝色洗衣袋，容量高达20公斤",
  "B) L Size - White bag with up to 12KG in capacity": "B）L尺寸 - 白色洗衣袋，容量高达12公斤",
  "6) Time and location for collection of prize will be informed via Whatsapp number and/or email address provided.": "6）奖品领取的时间和地点将通过参与者所提供的WhatsApp号码和/或电子邮件箱通知。",
  "OTHER": "其他",
  "1) Changes to the terms and conditions will be at Cleanpro's discretion; without prior notice.": "1）Cleanpro有权不经通知修改本条款和细则。",
  "2) Completed wash is defined by washes performed with Cleanpro's washer and payments within Cleanpro Plus web app are made with no disputes.": "2）完整的洗衣过程意指使用Cleanpro的洗衣机进行洗涤，并在Cleanpro Plus网页应用程序内进行付款 (绝无争议)。",
  "3) By participating in this campaign automatically indicates that the Participant has agreed to all the campaign's Terms and Conditions as stipulated.": "3）参与此活动即表示参与者已同意所有活动条款及细则。",
  "Welcome Voucher": "“欢迎”礼券",
  "Welcome to the Cleanpro Plus family! This first gift is just the beginning. Stay tuned for a series of exciting promotions and campaigns, exclusively for Cleanpro Plus users.": "欢迎加入 Cleanpro Plus的大家庭！ 这份见面礼只是一个开始。 请继续关注 Cleanpro Plus以获得我们独家提供给用户的一系列激动人心的促销和活动。",
  "Terms & Conditions": "条款及细则",
  "Welcome Voucher is valid for one-time use only.": "“欢迎”礼券仅供一次性使用。",
  "There is no minimum spend required for the voucher to be used.": "使用礼券时，没有最低消费要求。",
  "Welcome Voucher is auto-credited to the user's account at the point of registration on Cleanpro Plus and valid for 3 months only.": "“欢迎”礼券在 Cleanpro Plus 注册时将会自动存入用户的帐户，有效期仅为 3 个月。",
  "Users are eligible to use their Welcome Voucher AFTER the completion of their first wash or dry cycle using the same Cleanpro Plus account.": "用户需使用同一个 Cleanpro Plus 帐户完成第一次洗涤或烘干后，才能使用“欢迎”礼券。",
  "Welcome Voucher cannot be used together with any ongoing promotions or any other promotional vouchers.": "“欢迎”礼券不能与任何正在进行的促销活动或任何其他促销礼券一起使用。",
  "The voucher is final and cannot be refunded in form of cash or credit to the user.": "该礼券不能以现金或点数形式退还给用户。",
  "Cleanpro reserves the right to change the terms and conditions without prior notice.": "Cleanpro 保留更改条款和条件的权利，恕不另行通知。",
  "Collect 10 e-stamps to earn FREE 5 credits voucher (worth RM5)": "收集 10 个电子邮票即可免费获得 5 点数礼券（价值 RM5）",
  "With every 10 e-stamps that you collected, you will be rewarded with a FREE 5 credits voucher (worth RM5). Make your laundry routine not just hassle-free, but also rewarding.": "每收集 10 个电子印章，您将获得一张免费 5 点数礼券（价值 RM5）。让您的洗衣程序不仅轻松无忧，而且收获颇丰。",
  "To earn ONE e-stamp, you must spend at least 5 credits (RM5) per session.": "要获得一张电子印章，您每次会话必须花费至少 5 个点数 (RM5)。",
  "The voucher is valid for 60 days from the day voucher be given.": "优惠券自发放之日起 60 天内有效。",
  "When you applied the voucher during payment, it will give you 5 credits (RM5) discount.": "当您在付款时使用优惠券时，它将给您5积分（RM5）折扣。",
  "How to participate:": "参与方式：",
  "Prizes to be won:": "将赢得的奖品：",
  "RM10 OFF Welcome Voucher": "RM10 折扣欢迎礼券",
  "Welcome to the Cleanpro family, here is an appreciation voucher from us. Thank you for attending the COPE Investors Conference.": "欢迎加入 Cleanpro 大家庭，这是我们的感谢券。感谢您参加 COPE 投资者大会。",
  "Welcome Voucher | COPE Investors Conference": "欢迎券 | COPE投资者大会",
  "Payment methods": "付款方式",
  "Tap to proceed with your preferred mode of payment": "选择您喜欢的付款方式",
  "Pay now with credits": "使用点数付款",
  "Pay with e-wallet": "使用电子钱包付款",
  "Go to homepage": "前往主页",
}

const resources = {
  en: {
    translation: translationEn,
  },
  ms: {
    translation: translationMs,
  },
  zh: {
    translation: translationZh,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng:
    localStorage.getItem("language") ||
    window.navigator.language.substring(0, 2),
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
