import React, { Component } from 'react';
import axios from "axios";
import { connect } from "react-redux";
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import Loading from "../../admin/components/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const mySwal = withReactContent(Swal);

class EditFaq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: false,
            question: "",
            questionBm: null,
            questionCh: null,
            answer: "",
            answerBm: null,
            answerCh: null,
            image: null,
            loading: true
        };
    };

    componentDidMount() {
        if (!this.props.client || !this.props.location.state || !this.props.client.faq_setting) {
            this.props.history.push('/client');
        } else {
            axios
                .post("/api/admin/setting/getEditFaq", { faqId: this.props.location.state.faqId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        loading: false,
                        question: res.data.question,
                        questionBm: res.data.question_bm,
                        questionCh: res.data.question_ch,
                        answer: res.data.answer,
                        answerBm: res.data.answer_bm,
                        answerCh: res.data.answer_ch,
                    });
                })
                .catch(err => mySwal.fire("Error", err.response.data.error, "error"));
        }
    }

    handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

    handleChange = e => {

        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });
    };

    handleImage = e => {

        const file = e.target.files[0];

        const imageRegex = /image\//g;
        const jpgRegex = /.jpg/g;
        const pngRegex = /.png/g;
        const jpegRegex = /.jpeg/g;

        if (file) {
            if (imageRegex.test(file.type)) {
                if (jpgRegex.test(file.name) || pngRegex.test(file.name) || jpegRegex.test(file.name)) {

                    if (file.size > 2097152) {
                        mySwal.fire("Info", "The image size is over 2MB", "info");
                        e.target.value = "";
                    } else {
                        this.setState({
                            ...this.state,
                            image: file,
                        });
                    }

                } else {
                    mySwal.fire("Info", "Please only use png, jpg or jpeg file extension type", "info");
                    e.target.value = "";
                }
            } else {
                mySwal.fire("Info", "Please only use image type file", "info");
                e.target.value = "";
            }
        }
    };

    handleSubmit = e => {
        e.preventDefault();

        const { question, questionBm, questionCh, answer, answerBm, answerCh, image } = this.state;
        const { faqId } = this.props.location.state;

        if (question && answer && faqId) {
            mySwal.fire({
              title: "Confirmation",
                text: "Are you sure you want to edit FAQ?",
                icon: "question",
                showCancelButton: true,
                cancelButtonText: "No",
                confirmButtonText: "Yes",
                cancelButtonColor: "#d33"
            })
                .then(click => {

                    if (click.isConfirmed && click.value) {
                        this.setState({
                            ...this.state,
                            loading: true
                        });

                        const sendThis = {
                            question,
                            questionBm,
                            questionCh,
                            answer,
                            answerBm,
                            answerCh,
                            faqId
                        };

                        axios.post('/api/admin/setting/editFaq', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                            .then(res => {

                                if (image) {
                                    const formData = new FormData();
                                    formData.append("file", image);
                                    formData.append("id", faqId);

                                    axios.post('/api/admin/setting/addFaqImage', formData, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
                                        .then(res => {
                                            this.setState({
                                                ...this.state,
                                                image: null,
                                                loading: false,
                                            });

                                            mySwal.fire("Success", "FAQ updated successfully", "success");
                                            this.props.history.push("/client/faq", { distributorId: this.props.location.state.distributorId })
                                        })
                                        .catch(err => {
                                            this.setState({
                                                ...this.state,
                                                loading: false
                                            });
                                            mySwal.fire("Error", err.response.data.error, "error");
                                        });
                                } else {
                                    this.setState({
                                        ...this.state,
                                        loading: false,
                                    });

                                    mySwal.fire("Success", "FAQ updated successfully", "success");
                                    this.props.history.push("/client/faq", { distributorId: this.props.location.state.distributorId })
                                }

                            })
                            .catch(err => {
                                this.setState({
                                    ...this.state,
                                    loading: false
                                });
                                mySwal.fire("Error", err.response.data.error, "error");
                            });
                    }
                })
        }
    };

    render() {

        return (
            <div className="admin-page-container">
                <Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />

                <div className="admin-page">
                    <Topbar handleToggle={this.handleToggle} />

                    <div className="admin-content">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Edit FAQ</h5>
                            </div>

                            <div className="card-body">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="row">
                                        <div className="form-group col">
                                            <label htmlFor="question">Question (English): * </label>
                                            <textarea id="question" name="question" className="form-control" value={this.state.question} onChange={this.handleChange} required></textarea>
                                        </div>
                                        <div className="form-group col">
                                            <label htmlFor="answer">Answer (English) : * </label>
                                            <textarea id="answer" name="answer" className="form-control" value={this.state.answer} onChange={this.handleChange} required></textarea>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col">
                                            <label htmlFor="questionBm">Question (Malay): </label>
                                            <textarea id="questionBm" name="questionBm" className="form-control" value={this.state.questionBm} onChange={this.handleChange}></textarea>
                                        </div>

                                        <div className="form-group col">
                                            <label htmlFor="answerBm">Answer (Malay) : </label>
                                            <textarea id="answerBm" name="answerBm" className="form-control" value={this.state.answerBm} onChange={this.handleChange}></textarea>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col">
                                            <label htmlFor="questionCh">Question (Chinese): </label>
                                            <textarea id="questionCh" name="questionCh" className="form-control" value={this.state.questionCh} onChange={this.handleChange}></textarea>
                                        </div>

                                        <div className="form-group col">
                                            <label htmlFor="answerCh">Answer (Chinese) : </label>
                                            <textarea id="answerCh" name="answerCh" className="form-control" value={this.state.answerCh} onChange={this.handleChange}></textarea>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="image">Image : (max: 2MB)</label><br />
                                        <input type="file" id="image" name="image" accept="image/*" onChange={this.handleImage} />
                                    </div>

                                    <div>
                                        {
                                            this.state.loading
                                                ? <Loading />
                                                : (
                                                    <div>
                                                        <input type="submit" value="SUBMIT" className="btn btn-primary form-control font-weight-bold mt-5" />
                                                        <button onClick={() => this.props.history.push("/client/faq", { distributorId: this.props.location.state.distributorId })} className="btn btn-danger form-control font-weight-bold mt-2">Cancel</button>
                                                    </div>
                                                )
                                        }
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        client: state.client.client,
        token: state.client.token
    };
};

export default connect(mapStateToProps, null)(EditFaq);