import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CSVLink } from "react-csv";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { currency } from "../../../dynamicController";

const mySwal = withReactContent(Swal);

class Giveaway extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			data: [],
			washerStamp: 0,
			dryerStamp: 0
		};
	}

	componentDidMount() {
		if (!this.props.admin) this.props.history.push("/admin/dashboard");
		else {
			axios
				.post("/api/admin/setting/user/reward", { operatorId: this.props.admin.operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
				.then(res => {
					this.setState({
						...this.state,
						data: res.data,
						loading: false
					});
				})
				.catch(err => mySwal.fire("Error", err.response.data.error, "error"));
		}
	}

	handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

	handleAction = id => {
		mySwal
			.fire({
				title: "Reward Giveaway",
				text: "Which type of reward you want to give to customer?",
				icon: "question",
				showCancelButton: this.props.admin.stamp ? true : false,
				showDenyButton: this.props.admin.token ? true : false,
				confirmButtonText: "Voucher",
				cancelButtonText: "Stamp",
				denyButtonText: "e-Token",
				cancelButtonColor: "lime",
				denyButtonColor: "gold"
			})
			.then(clicked => {
				if (clicked.isConfirmed) {
					mySwal
						.fire({
							title: "Voucher",
							html: `
							<div class="text-left mt-4">
								<div class="form-group">
									<label for="title">Title</label>
									<input type="text" id="title" name="title" value="" class="form-control" required />
								</div>

								<div class="form-group">
									<label for="description">Description</label>
									<input type="text" id="description" name="description" value="" class="form-control" required />
								</div>

								<p class="mt-5 p-0 mb-0">For machine uses (required)</p>
								<div class="d-flex justify-content-around mt-3 mb-2">
									<div class="form-group">
										<label>
											<input type="checkbox" id="washerUse" name="washerUse" />
											<span>Washer User</span>
										</label>
									</div>

									<div class="form-group">
										<label>
											<input type="checkbox" id="dryerUse" name="dryerUse" />
											<span>Dryer User</span>
										</label>
									</div>
								</div>

								<div class="form-group">
									<label for="duration">Voucher lifespan (days)</label>
									<input type="text" id="duration" name="duration" value="" class="form-control" placeholder="leave blank if no expiry date" />
								</div>

								<div class="form-group">
									<label for="type">Discount Type</label>
									<select id="type" name="type" class="browser-default form-select" required>
										<option value="Flat" selected>Flat (${currency()})</option>
										<option value="Rate">Rate (%)</option>
									</select>
								</div>

								<div class="form-group">
									<label for="amount">Voucher Amount</label>
									<input type="number" id="amount" name="amount" class="form-control" value="0" min="0" max="100" required />
								</div>

								<div class="form-group">
									<label for="remark">Remarks</label>
									<select id="remark" name="remark" class="browser-default form-select">
										<option value="Reward" selected>Reward</option>
										<option value="Refund">Refund</option>
									</select>
								</div>
							</div>
						`,
							showCancelButton: true,
							allowOutsideClick: () => !mySwal.isLoading(),
							preConfirm: () => {
								// call axios
								const title = document.querySelector("#title").value;
								const description = document.querySelector("#description").value;
								const duration = document.querySelector("#duration").value;
								const type = document.querySelector("#type").value;
								const amount = document.querySelector("#amount").value;
								const washerUse = document.querySelector("#washerUse").checked;
								const dryerUse = document.querySelector("#dryerUse").checked;
								const remark = document.querySelector("#remark").value;

								if (!title || !description || !amount || parseFloat(amount) <= 0) {
									mySwal.fire("Missing Field", "Please fill in all the required field. (Title, description, and amount must be more than 0)", "info");
								} else if (!washerUse && !dryerUse) {
									mySwal.fire("Checkbox required", "Must select voucher can be used for washer or dryer", "info");
								} else {
									const sendThis = {
										id,
										rewardBy: this.props.admin.operatorId,
										rewardType: "Voucher",
										title,
										description,
										duration,
										type,
										amount,
										washerUse,
										dryerUse,
										remark,
										role: "operator"
									};

									return axios
										.post("/api/admin/setting/giveaway", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
										.then(res => res.data)
										.catch(err => mySwal.fire("Error", err.response.data.error, "error"));
								}
							}
						})
						.then(result => {
							if (result.value && result.value.data === "success") {
								this.setState({
									...this.state,
									loading: true
								});
								axios
									.post("/api/admin/setting/user/reward", { operatorId: this.props.admin.operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
									.then(res => {
										this.setState({
											...this.state,
											data: res.data,
											loading: false
										});
									})
									.catch(err => mySwal.fire("Error", err.response.data.error, "error"));
							}
						});
				} else if (clicked.isDenied) {
					mySwal
						.fire({
							title: "E-TOKEN",
							text: "How many e-token?",
							icon: "question",
							// input: "number",
							// inputLabel: "Number of e-Token",
							// inputValue: 0,
							html: `
							<div class="text-left mt-4">
								<div class="form-group">
									<label for="token">Number of e-Token</label>
									<input type="number" id="token" name="token" class="form-control" value="0" min="0" required />
								</div>

								<div class="form-group">
									<label for="remark">Remarks</label>
									<select id="remark" name="remark" class="browser-default form-select">
										<option value="Reward" selected>Reward</option>
										<option value="Refund">Refund</option>
									</select>
								</div>
							</div>
							`,
							showCancelButton: true,
							// inputValidator: val => {
							// 	if (!val || parseFloat(val) <= 0) {
							// 		return "This field is required and value must be more than 0";
							// 	}
							// },
							showLoaderOnConfirm: true,
							allowOutsideClick: () => !mySwal.isLoading(),
							// preConfirm: token => {
							// 	// call axios
							// 	const sendThis = {
							// 		id,
							// 		rewardType: "Token",
							// 		token,
							// 		operatorId: this.props.admin.operatorId
							// 	};
							// 	return axios
							// 		.post("/api/admin/setting/giveaway", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
							// 		.then(res => res.data)
							// 		.catch(err => mySwal.fire("Error", err.response.data.error, "error"));
							// 	// return token;
							// }
							preConfirm: () => {
								// call axios
								const token = document.querySelector("#token").value;
								const remark = document.querySelector("#remark").value;

								if (parseFloat(token) <= 0) {
									mySwal.fire("Error", "Token value must be more than 0", "error");
								} else {
									const sendThis = {
										id,
										rewardType: "Token",
										token,
										rewardBy: this.props.admin.operatorId,
										remark,
										role: "operator"
									};
									return axios
										.post("/api/admin/setting/giveaway", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
										.then(res => res.data)
										.catch(err => mySwal.fire("Error", err.response.data.error, "error"));
								}
							}
						})
						.then(result => {
							if (result.value && result.value.data === "success") {
								this.setState({
									...this.state,
									loading: true
								});
								axios
									.post("/api/admin/setting/user/reward", { operatorId: this.props.admin.operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
									.then(res => {
										this.setState({
											...this.state,
											data: res.data,
											loading: false
										});
									})
									.catch(err => mySwal.fire("Error", err.response.data.error, "error"));
							}
						});
				} else if (clicked.isDismissed && clicked.dismiss === "cancel") {
					mySwal
						.fire({
							title: "STAMP",
							html: `
							<div class="text-left mt-4">
								<div class="form-group">
									<label for="washerStamp">Washer Stamp</label>
									<input type="number" id="washerStamp" name="washerStamp" value="0" class="form-control" min="0" max="10" />
								</div>

								<div class="form-group">
									<label for="dryerStamp">Dryer Stamp</label>
									<input type="number" id="dryerStamp" name="dryerStamp" value="0" class="form-control" min="0" max="10" />
								</div>

								<div class="form-group">
									<label for="remark">Remarks</label>
									<select id="remark" name="remark" class="browser-default form-select">
										<option value="Reward" selected>Reward</option>
										<option value="Refund">Refund</option>
									</select>
								</div>
							</div>
						`,
							showCancelButton: true,
							showLoaderOnConfirm: true,
							allowOutsideClick: () => !mySwal.isLoading(),
							preConfirm: () => {
								// call axios
								const washerStamp = document.querySelector("#washerStamp").value;
								const dryerStamp = document.querySelector("#dryerStamp").value;
								const remark = document.querySelector("#remark").value;

								if (!parseInt(washerStamp) && !parseInt(dryerStamp)) {
									mySwal.fire("Error", "Please fill in at least one of the required field", "error");
								} else if (parseInt(washerStamp) < 0 || parseInt(dryerStamp) < 0) {
									mySwal.fire("Error", "The value must be more than 0", "error");
								} else {
									const sendThis = {
										id,
										rewardBy: this.props.admin.operatorId,
										washerStamp,
										dryerStamp,
										rewardType: "Stamp",
										remark,
										role: "operator"
									};
									return axios
										.post("/api/admin/setting/giveaway", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
										.then(res => res.data)
										.catch(err => mySwal.fire("Error", err.response.data.error, "error"));
								}
							}
						})
						.then(result => {
							if (result.value && result.value.data === "success") {
								this.setState({
									...this.state,
									loading: true
								});
								axios
									.post("/api/admin/setting/user/reward", { operatorId: this.props.admin.operatorId }, { headers: { "Content-Type": "application/json", "auth-token": this.props.token } })
									.then(res => {
										this.setState({
											...this.state,
											data: res.data,
											loading: false
										});
									})
									.catch(err => mySwal.fire("Error", err.response.data.error, "error"));
							}
						});
				}
			});
	};

	render() {
		let csv = [];

		if (this.state.data) {
			for (let i = 0; i < this.state.data.length; i++) {
				let data = {
					name: this.state.data[i].name,
					number: `=""${this.state.data[i].number}""`,
					memberId: this.state.data[i].memberId,
					rewardTimes: this.state.data[i].rewardedTimes,
					voucherReward: this.state.data[i].voucherReward,
					stampReward: this.state.data[i].stampReward,
					washerStamp: this.state.data[i].washerStamp,
					dryerStamp: this.state.data[i].dryerStamp,
					tokenReward: this.state.data[i].tokenReward,
					tokenGranted: this.state.data[i].tokenGranted,
				}
				csv.push(data);
			}
		}

		const csvData = {
			headers: [
				{ label: "User Name", key: "name" },
				{ label: "Phone Number", key: "number" },
				{ label: "Member ID", key: "memberId" },
				{ label: "Rewarded Times", key: "rewardTimes" },
				{ label: "Voucher Rewarded (times)", key: "voucherReward" }
			],
			data: csv
		};

		let tableData = {
			columns: [
				{
					label: "Action",
					field: "action"
				},
				{
					label: "User Name",
					field: "name",
					sort: "asc"
				},
				{
					label: "Phone Number",
					field: "number",
					sort: "asc"
				},
				{
					label: "Member ID",
					field: "memberId",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									How many times rewarded to user
								</Tooltip>
							}
						>
							<span>Rewarded Times</span>
						</OverlayTrigger>
					),
					field: "rewardTimes",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									How many times rewarded voucher to user
								</Tooltip>
							}
						>
							<span>Voucher Rewarded (times)</span>
						</OverlayTrigger>
					),
					field: "voucherReward",
					sort: "asc"
				}
			],
			rows: []
		};

		if (this.props.admin?.stamp) {
			tableData.columns.push(
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									How many times rewarded stamp to user
								</Tooltip>
							}
						>
							<span>Stamps Rewarded (times)</span>
						</OverlayTrigger>
					),
					field: "stampReward",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									Total number of washer stamps rewarded to user
								</Tooltip>
							}
						>
							<span>Washer Stamps Granted (stamp)</span>
						</OverlayTrigger>
					),
					field: "washerStamp",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									Total number of dryer stamps rewarded to user
								</Tooltip>
							}
						>
							<span>Dryer Stamps Grant (stamp)</span>
						</OverlayTrigger>
					),
					field: "dryerStamp",
					sort: "asc"
				}
			);

			csvData.headers.push(
				{ label: "Stamps Rewarded (times)", key: "stampReward" },
				{ label: "Washer Stamps Granted (stamp)", key: "washerStamp" },
				{ label: "Dryer Stamps Grant (stamp)", key: "dryerStamp" }
			)
		}

		if (this.props.admin?.token) {
			tableData.columns.push(
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									How many times rewarded e-Token to user
								</Tooltip>
							}
						>
							<span>e-Token Rewarded (times)</span>
						</OverlayTrigger>
					),
					field: "tokenReward",
					sort: "asc"
				},
				{
					label: (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip>
									Total number of e-Token rewarded to user
								</Tooltip>
							}
						>
							<span>Total e-Token Granted (token)</span>
						</OverlayTrigger>
					),
					field: "tokenGranted",
					sort: "asc"
				}
			);

			csvData.headers.push(
				{ label: "e-Token Rewarded (times)", key: "tokenReward" },
				{ label: "Total e-Token Granted (token)", key: "tokenGranted" }
			)
		}

		if (this.state.data.length) {
			for (let i = 0; i < this.state.data.length; i++) {
				const dataObject = {
					action: (
						<button type="button" className="btn btn-primary" onClick={() => this.handleAction(this.state.data[i].id)}>
							Reward
						</button>
					),
					name: this.state.data[i].name,
					number: this.state.data[i].number,
					memberId: this.state.data[i].memberId,
					rewardTimes: this.state.data[i].rewardedTimes,
					voucherReward: this.state.data[i].voucherReward,
					stampReward: this.state.data[i].stampReward,
					washerStamp: this.state.data[i].washerStamp,
					dryerStamp: this.state.data[i].dryerStamp,
					tokenReward: this.state.data[i].tokenReward,
					tokenGranted: this.state.data[i].tokenGranted
				};
				tableData.rows.push(dataObject);
			}
		}

		return (
			<div className="admin-page-container">
				<Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
				<div className="admin-page">
					<Topbar handleToggle={this.handleToggle} />
					<div className="admin-content">
						<div className="card">
							<div className="card-header d-flex justify-content-between">
								<h5 className="card-title">Reward Giveaway</h5>
								<Link className="btn btn-primary" to="/admin/giveaway/history">
									Reward History
								</Link>
							</div>

							<div className="card-body text-center">
								{
									this.state.loading ? <Loading /> : (
										<div>
											<MDBDataTable data={tableData} noBottomColumns striped hover responsive bordered />
											<div className="d-grid gap-2 d-md-flex justify-content-md-end">
												<CSVLink className="btn btn-primary" data={csvData.data} headers={csvData.headers} filename="reward.csv" target="_blank">
													Download Report
												</CSVLink>
											</div>
										</div>
									)
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		admin: state.admin.admin,
		token: state.admin.token
	};
};

export default connect(mapStateToProps, null)(Giveaway);
