import { ProSidebar, Menu, SubMenu, MenuItem, SidebarHeader, SidebarContent } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import logo1 from '../../../assets/images/logo/logo1.png';
// import laundroLogo1 from '../../../assets/images/logo/laundroLogo1.png';
import { logo1Controller } from "../../../dynamicController";

const Sidebar = ({ toggled, setToggled, themeLogo, admin }) => {
	return (
		<ProSidebar breakPoint="xl" toggled={toggled} onToggle={val => setToggled(val)} id="react-pro-sidebar">
			<SidebarHeader id="react-pro-sidebar-header">
				<Link to="/admin/dashboard">
					<img src={logo1Controller(themeLogo)} alt="logo" className="img-fluid" />
				</Link>
			</SidebarHeader>

			<SidebarContent id="react-pro-sidebar-content">
				<Menu id="react-pro-sidebar-menu" iconShape="circle" popperArrow={true}>
					<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-house-door-fill"></i>}>
						Dashboard <Link to="/admin/dashboard" />
					</MenuItem>

					{
						admin && (admin.store_performance || admin.user_performance || admin.voucher_redemption || admin.token_report || admin.sms_report) ? (
							<SubMenu title="Report" icon={<i className="bi bi-file-bar-graph-fill"></i>}>
								{
									admin.store_performance ? (
										<MenuItem>
											Store Performance <Link to="/admin/outlet/performance" />
										</MenuItem>
									) : <></>
								}
								{
									admin.user_performance ? (
										<MenuItem>
											User Performance <Link to="/admin/user/performance" />
										</MenuItem>
									) : <></>
								}
								{
									admin.voucher_redemption ? (
										<MenuItem>
											Voucher Redemption <Link to="/admin/voucher/redemption" />
										</MenuItem>
									) : <></>
								}
								{
									admin.token && admin.token_report ? (
										<MenuItem>
											Token Report <Link to="/admin/token/report" />
										</MenuItem>
									) : <></>
								}
								{
									process.env.REACT_APP_STORETYPE !== "cuci" && admin.sms_report ? (
										<MenuItem>
											SMS Report <Link to="/admin/sms/report" />
										</MenuItem>
									) : <></>
								}
							</SubMenu>
						) : <></>
					}

					{
						admin && admin.user_detail ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-person-lines-fill"></i>}>
								User Details <Link to="/admin/user/details" />
							</MenuItem>
						) : <></>
					}

					{
						admin && admin.user_reward ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-gift-fill"></i>}>
								User Compensation <Link to="/admin/giveaway" />
							</MenuItem>
						) : <></>
					}

					{
						admin && admin.voucher_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-gear-fill"></i>}>
								Voucher Setting <Link to="/admin/voucher/setting" />
							</MenuItem>
						) : <></>
					}

					{
						admin && admin.token_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-coin"></i>}>
								Token Setting <Link to="/admin/token/setting" />
							</MenuItem>
						) : <></>
					}

					{
						admin && admin.referral_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-people-fill"></i>}>
								Referral Setting <Link to="/admin/referral" />
							</MenuItem>
						) : <></>
					}

					{
						admin && admin.discount_code ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-percent"></i>}>
								Discount Code <Link to="/admin/discountCode" />
							</MenuItem>
						) : <></>
					}

					{
						process.env.REACT_APP_STORETYPE !== "cuci" && admin && admin.customize_color ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-palette-fill"></i>}>
								Customize Color <Link to="/admin/setting/customize" />
							</MenuItem>
						) : <></>
					}

					{
						admin && admin.faq_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-question-circle-fill"></i>}>
								FAQs <Link to="/admin/faq" />
							</MenuItem>
						) : <></>
					}

					{
						admin && admin.news_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-newspaper"></i>}>
								News <Link to="/admin/news" />
							</MenuItem>
						) : <></>
					}

					{/* <SubMenu defaultOpen={false} title="Outlets" icon={<i className="fas fa-cogs"></i>}>
                        {renderOutlets()}
                    </SubMenu> */}
				</Menu>
			</SidebarContent>
		</ProSidebar>
	);
};

const mapStateToProps = state => {
	return {
		themeLogo: state.theme.logo,
		admin: state.admin.admin
	};
};

export default connect(mapStateToProps, null)(Sidebar);
