import React, { useState, useEffect } from "react";

import washer1 from "../../../../assets/images/machines/washer1.png";
import washer2 from "../../../../assets/images/machines/washer2.png";
import washer3 from "../../../../assets/images/machines/washer3.png";
import washer4 from "../../../../assets/images/machines/washer4.png";
import promotionBanner from "../../../../assets/images/logo/promotion.png";

// cleanpro icon
import temp from "../../../../assets/images/icons/icn-temperature.png";
import insufficientToken from "../../../../assets/images/icons/icn-creditInsufficient.png";
import ticket from "../../../../assets/images/icons/icn-voucher.png";
import creditBear from "../../../../assets/images/icons/icn-creditGrey.png";

import axios from "axios";
import { useTranslation, Trans } from "react-i18next";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { currency } from "../../../../dynamicController";

import { Modal } from "react-bootstrap";

const mySwal = withReactContent(Swal);
const STORETYPE = process.env.REACT_APP_STORETYPE;
const LOCATION = process.env.REACT_APP_LOCATION;

const WasherPayment = ({ machine, user, history, outlet, saveTransactionId, theme, savePayment, setDuration }) => {
  // STATES
  const [amount, setAmount] = useState(
    machine.priceData.defaultmode === "hot"
      ? !machine.promo.status || (machine.promo.status && machine.promo.priceData.washerRate) || (!user && !machine.non_member_promotion)
        ? parseFloat(machine.priceData.hotPrice)
        : parseFloat(machine.promo.priceData.hp)
      : machine.priceData.defaultmode === "warm"
        ? !machine.promo.status || (machine.promo.status && machine.promo.priceData.washerRate) || (!user && !machine.non_member_promotion)
          ? parseFloat(machine.priceData.warmPrice)
          : parseFloat(machine.promo.priceData.wp)
        : !machine.promo.status || (machine.promo.status && machine.promo.priceData.washerRate) || (!user && !machine.non_member_promotion)
          ? parseFloat(machine.priceData.coldPrice)
          : parseFloat(machine.promo.priceData.cp)
  );
  const [isOpen, setOpen] = useState(false);
  const [voucher, setVoucher] = useState(null);
  const [type, setType] = useState(null);
  const [voucherAmount, setvoucherAmount] = useState(null);
  const [voucherId, setVoucherId] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState(
    machine.priceData.defaultmode === "hot"
      ? !machine.promo.status || (!user && !machine.non_member_promotion)
        ? parseFloat(machine.priceData.hotPrice)
        : machine.promo.status && machine.promo.priceData.washerRate
          ? parseFloat(machine.priceData.hotPrice) - ((parseFloat(machine.priceData.hotPrice) * parseFloat(machine.promo.priceData.washerRate)) / 100)
          : parseFloat(machine.promo.priceData.hpd)
      : machine.priceData.defaultmode === "warm"
        ? !machine.promo.status || (!user && !machine.non_member_promotion)
          ? parseFloat(machine.priceData.warmPrice)
          : machine.promo.status && machine.promo.priceData.washerRate
            ? parseFloat(machine.priceData.warmPrice) - ((parseFloat(machine.priceData.warmPrice) * parseFloat(machine.promo.priceData.washerRate)) / 100)
            : parseFloat(machine.promo.priceData.wpd)
        : !machine.promo.status || (!user && !machine.non_member_promotion)
          ? parseFloat(machine.priceData.coldPrice)
          : machine.promo.status && machine.promo.priceData.washerRate
            ? parseFloat(machine.priceData.coldPrice) - ((parseFloat(machine.priceData.coldPrice) * parseFloat(machine.promo.priceData.washerRate)) / 100)
            : parseFloat(machine.promo.priceData.cpd)
  );
  const [discountCode, setDiscountCode] = useState("");
  const [discountCodeInput, setDiscountCodeInput] = useState("");
  const [discountCodeModal, setDiscountCodeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [promotion, setPromotion] = useState(!user && !machine.non_member_promotion ? false : machine.promo.status);
  const [insufficient, setInsufficient] = useState(false);
  const [id, setId] = useState(null);
  const [notificationType, setNotificationType] = useState(STORETYPE === "cleanpro" && machine.notification && !machine.running ? "Email" : "");
  const [email, setEmail] = useState(user && user.email ? user.email : "");
  const [number, setNumber] = useState(user && user.number ? user.number : "")

  const { t } = useTranslation();

  // FUNCTIONS
  const buttonStyle = (val) => {
    if (!machine.promo.status || (machine.promo.status && machine.promo.priceData.washerRate) || (!user && !machine.non_member_promotion)) {
      if (parseFloat(amount) === parseFloat(machine.priceData[`${val}Price`])) {
        return "selected-temp";
      } else {
        return null;
      }
    } else {
      const jVar = val === "cold" ? "cp" : val === "warm" ? "wp" : "hp";
      if (parseFloat(amount) === parseFloat(machine.promo.priceData[jVar])) {
        return "selected-temp";
      } else {
        return null;
      }
    }
  };

  const renderPromotionPrize = () => {
    if (machine.promo.status && promotion) {
      if (machine.promo.priceData.washerRate) {
        return `${machine.promo.priceData.washerRate} %`
      } else {
        return amount === machine.promo.priceData.cp
          ? `${currency()} ${machine.promo.priceData.cp - machine.promo.priceData.cpd}`
          : amount === machine.promo.priceData.wp
            ? `${currency()} ${machine.promo.priceData.wp - machine.promo.priceData.wpd}`
            : `${currency()} ${machine.promo.priceData.hp - machine.promo.priceData.hpd}`;
      }
    } else {
      return null;
    }
  };

  const getPaymentAmount = () => {
    let payAmount = parseFloat(paymentAmount)
    if (notificationType === "SMS") {
      payAmount += parseFloat(machine.sms_notification_amount)
    }
    return payAmount
  }

  const checkDiscountCode = (e) => {
    e.preventDefault();

    setLoading(true);
    axios
      .post("/api/user/checkDiscountCode", {
        discountCode: discountCodeInput,
        outletId: machine.outletId,
        machineType: machine.type,
        userId: user.id,
      })
      .then((res) => {
        let typ = res.data.discountType;
        let amt = res.data.discountAmount;
        setDiscountCode(discountCodeInput);
        const voucher = `${typ === "Flat" ? currency() : ""} ${amt}${typ === "Rate" ? "%" : ""}`;
        if (typ === "Flat") {
          const afterDiscount = parseFloat(amount) - amt;
          setType(typ);
          setvoucherAmount(amt);
          setVoucherId(null);
          setVoucher(voucher);
          setPaymentAmount(afterDiscount < 0 ? 0 : afterDiscount);
        } else if (typ === "Rate") {
          const rate = (parseFloat(amount) * amt) / 100;
          const afterDiscount = (parseFloat(amount) - rate).toFixed(2);
          setType(typ);
          setvoucherAmount(amt);
          setVoucherId(null);
          setVoucher(voucher);
          setPaymentAmount(afterDiscount);
        }
        setDiscountCodeModal(false);
        setLoading(false);
      })
      .catch((err) => {
        setDiscountCodeInput("");
        setLoading(false);
        mySwal.fire("Error", err.response.data.error, "error");
      });
  };

  const useVoucher = (e) => {
    let foundVoucher = user.vouchers.filter((v) => v.id === id)[0];
    if (user && !machine.promo.status) {
      const voucher = `${foundVoucher.type === "Flat" ? currency() : ""} ${foundVoucher.amount} ${foundVoucher.type === "Rate" ? "%" : ""}`;
      if (foundVoucher.type === "Flat") {
        const afterDiscount = parseFloat(amount) - foundVoucher.amount;
        setType(foundVoucher.type);
        setvoucherAmount(foundVoucher.amount);
        setVoucherId(foundVoucher.id);
        setVoucher(voucher);
        setPaymentAmount(afterDiscount < 0 ? 0 : afterDiscount);
      } else if (foundVoucher.type === "Rate") {
        const rate = (parseFloat(amount) * foundVoucher.amount) / 100;
        const afterDiscount = (parseFloat(amount) - rate).toFixed(2);
        setType(foundVoucher.type);
        setvoucherAmount(foundVoucher.amount);
        setVoucherId(foundVoucher.id);
        setVoucher(voucher);
        setPaymentAmount(afterDiscount);
      }
      setOpen(false);
    }
  };

  const chooseVoucher = (typ, amt, id) => {
    if (user && !machine.promo.status) {
      // console.log({typ , amt , id , amount});
      const voucher = `${typ === "Flat" ? currency() : ""} ${amt} ${typ === "Rate" ? "%" : ""}`;
      // console.log({voucher});
      if (typ === "Flat") {
        const afterDiscount = parseFloat(amount) - amt;
        // console.log({afterDiscount});
        // console.log(afterDiscount < 0 ? 0 : afterDiscount);
        setType(typ);
        setvoucherAmount(amt);
        setVoucherId(id);
        setVoucher(voucher);
        setPaymentAmount(afterDiscount < 0 ? 0 : afterDiscount);
      } else if (typ === "Rate") {
        const rate = (parseFloat(amount) * amt) / 100;
        const afterDiscount = (parseFloat(amount) - rate).toFixed(2);
        // console.log({amount , rate , afterDiscount});
        setType(typ);
        setvoucherAmount(amt);
        setVoucherId(id);
        setVoucher(voucher);
        setPaymentAmount(afterDiscount);
      }
      setOpen(false);
    }
  };

  const removeVoucher = () => {
    if (user) {
      setVoucher(null);
      setOpen(false);
      setType(null);
      setvoucherAmount(null);
      setVoucherId(null);
      setPaymentAmount(amount);
      setDiscountCode("");
      setDiscountCodeInput("");
      setDiscountCodeModal(false);
    }
  };

  const renderDuration = () => {
    if (machine.priceData.hotruntime && parseFloat(amount) === parseFloat(machine.priceData.hotPrice)) {
      return machine.priceData.hotruntime
    } else if (machine.priceData.warmruntime && parseFloat(amount) === parseFloat(machine.priceData.warmPrice)) {
      return machine.priceData.warmruntime
    } else if (machine.priceData.coldruntime) {
      return machine.priceData.coldruntime
    } else {
      if (machine.priceData.runTime) {
        return machine.priceData.runTime
      } else {
        return 23
      }
    }
  }

  useEffect(() => {
    setDuration(renderDuration());
  }, [renderDuration]);

  const changeTemp = (amt, pd) => {
    if (user) {
      if (!machine.promo.status) {
        // no promotion
        if (type && voucherAmount) {
          if (type === "Flat") {
            const discountValue = parseFloat(amt) - voucherAmount;
            // console.log({discountValue});
            setAmount(amt);
            setPaymentAmount(discountValue < 0 ? 0 : discountValue);
          } else if (type === "Rate") {
            const rate = (parseFloat(amt) * voucherAmount) / 100;
            const discountValue = (parseFloat(amt) - rate).toFixed(2);
            // console.log({rate , discountValue});
            setAmount(amt);
            setPaymentAmount(discountValue);
          }
        } else {
          setPaymentAmount(amt);
          setAmount(amt);
        }
      } else if (machine.promo.status && machine.promo.priceData.washerRate) {
        // rate promotion
        setPromotion(true);
        setAmount(amt);
        setPaymentAmount(parseFloat(amt) - ((parseFloat(amt) * parseFloat(machine.promo.priceData.washerRate)) / 100));
      } else {
        // flat promotion
        setPromotion(true);
        setAmount(amt);
        setPaymentAmount(machine.promo.priceData[pd]);
      }
    } else {
      if (!machine.promo.status || (!user && !machine.non_member_promotion)) {
        setAmount(amt);
        setPaymentAmount(amt);
      } else if (machine.promo.status && machine.promo.priceData.washerRate) {
        setPromotion(true);
        setAmount(amt);
        setPaymentAmount(parseFloat(amt) - ((parseFloat(amt) * parseFloat(machine.promo.priceData.washerRate)) / 100));
      } else {
        setPromotion(true);
        setAmount(amt);
        setPaymentAmount(machine.promo.priceData[pd]);
      }
    }
  };

  const minusAmount = () => {
    if (machine.isWashup) {
      // no step, fix to 3 price
      if (user) {
        if (!machine.promo.status) {
          // no promotion
          let amt = machine.priceData.coldPrice;
          if (parseFloat(amount) === parseFloat(machine.priceData.warmPrice)) {
            amt = machine.priceData.coldPrice;
          } else if (parseFloat(amount) === parseFloat(machine.priceData.hotPrice)) {
            amt = machine.priceData.warmPrice;
          }
          if (type && voucherAmount) {
            if (type === "Flat") {
              const discountValue = parseFloat(amt) - voucherAmount;
              // console.log({discountValue});
              setAmount(amt);
              setPaymentAmount(discountValue < 0 ? 0 : discountValue);
            } else if (type === "Rate") {
              const rate = (parseFloat(amt) * voucherAmount) / 100;
              const discountValue = (parseFloat(amt) - rate).toFixed(2);
              // console.log({rate , discountValue});
              setAmount(amt);
              setPaymentAmount(discountValue);
            }
          } else {
            setPaymentAmount(amt);
            setAmount(amt);
          }
        } else if (machine.promo.status && machine.promo.priceData.washerRate) {
          // rate promotion
          if (parseFloat(amount) === parseFloat(machine.priceData.warmPrice)) {
            setPromotion(true);
            setAmount(machine.priceData.coldPrice);
            setPaymentAmount((parseFloat(machine.priceData.coldPrice) - (parseFloat(machine.priceData.coldPrice) * parseFloat(machine.promo.priceData.washerRate)) / 100));
          } else if (parseFloat(amount) === parseFloat(machine.priceData.hotPrice)) {
            setPromotion(true);
            setAmount(machine.priceData.warmPrice);
            setPaymentAmount(parseFloat(machine.priceData.warmPrice) - ((parseFloat(machine.priceData.warmPrice) * parseFloat(machine.promo.priceData.washerRate)) / 100));
          }
        } else {
          // flat promotion
          if (parseFloat(amount) === parseFloat(machine.promo.priceData.wp)) {
            setPromotion(true);
            setAmount(machine.promo.priceData.cp);
            setPaymentAmount(machine.promo.priceData["cpd"]);
          } else if (parseFloat(amount) === parseFloat(machine.promo.priceData.hp)) {
            setPromotion(true);
            setAmount(machine.promo.priceData.wp);
            setPaymentAmount(machine.promo.priceData["wpd"]);
          }
        }
      } else {
        // non user
        if (!machine.promo.status || (!user && !machine.non_member_promotion)) {
          // no promotion
          if (parseFloat(amount) === parseFloat(machine.priceData.warmPrice)) {
            setAmount(machine.priceData.coldPrice);
            setPaymentAmount(machine.priceData.coldPrice);
          } else if (parseFloat(amount) === parseFloat(machine.priceData.hotPrice)) {
            setAmount(machine.priceData.warmPrice);
            setPaymentAmount(machine.priceData.warmPrice);
          }
        } else if (machine.promo.status && machine.promo.priceData.washerRate) {
          // rate promotion
          if (parseFloat(amount) === parseFloat(machine.priceData.warmPrice)) {
            setPromotion(true);
            setAmount(machine.priceData.coldPrice);
            setPaymentAmount(parseFloat(machine.priceData.coldPrice) - ((parseFloat(machine.priceData.coldPrice) * parseFloat(machine.promo.priceData.washerRate)) / 100));
          } else if (parseFloat(amount) === parseFloat(machine.priceData.hotPrice)) {
            setPromotion(true);
            setAmount(machine.priceData.warmPrice);
            setPaymentAmount(parseFloat(machine.priceData.warmPrice) - ((parseFloat(machine.priceData.warmPrice) * parseFloat(machine.promo.priceData.washerRate)) / 100));
          }
        } else {
          // flat promotion
          if (parseFloat(amount) === parseFloat(machine.promo.priceData.wp)) {
            setPromotion(true);
            setAmount(machine.promo.priceData.cp);
            setPaymentAmount(machine.promo.priceData["cpd"]);
          } else if (parseFloat(amount) === parseFloat(machine.promo.priceData.hp)) {
            setPromotion(true);
            setAmount(machine.promo.priceData.wp);
            setPaymentAmount(machine.promo.priceData["wpd"]);
          }
        }
      }
    } else {
      const step = parseFloat(machine.step || 1);
      if (user) {
        if (!machine.promo.status) {
          // no promotion
          if (type && voucherAmount) {
            // if have voucher only can 0
            if (type === "Flat") {
              const afterMinus = parseFloat(paymentAmount) - step;
              if (afterMinus >= 0) {
                const newAmount = parseFloat(amount) - step;
                const newPaymentAmount = newAmount - voucherAmount;
                setAmount(newAmount);
                setPaymentAmount(newPaymentAmount);
              }
            } else if (type === "Rate") {
              const afterMinus = parseFloat(paymentAmount) - step;
              if (afterMinus >= 0) {
                const newAmount = parseFloat(amount) - step;
                const rate = (newAmount * voucherAmount) / 100;
                const newPaymentAmount = parseFloat(newAmount - rate).toFixed(2);
                setAmount(newAmount);
                setPaymentAmount(newPaymentAmount);
              }
            }
          } else {
            // max 1
            const afterMinus = parseFloat(paymentAmount) - step;
            if (afterMinus >= step) {
              const newAmount = parseFloat(amount) - step;
              setAmount(newAmount);
              setPaymentAmount(afterMinus);
            }
          }
        } else if (machine.promo.status && machine.promo.priceData.washerRate) {
          // rate promotion
          const newAmount = parseFloat(amount) - step;
          const newPaymentAmount = parseFloat(newAmount) - ((parseFloat(newAmount) * parseFloat(machine.promo.priceData.washerRate)) / 100)
          if (newAmount >= step) {
            // if (
            //   newAmount !== machine.priceData.coldPrice &&
            //   newAmount !== machine.priceData.warmPrice &&
            //   newAmount !== machine.priceData.hotPrice
            // ) {
            //   setPromotion(false);
            //   setAmount(newAmount);
            //   setPaymentAmount(newAmount);
            // } else {
            setPromotion(true);
            setAmount(newAmount);
            setPaymentAmount(newPaymentAmount);
            // }
          }
        } else {
          // flat promotion
          const newAmount = parseFloat(amount) - step;
          const newPaymentAmount = parseFloat(paymentAmount) - step;
          // console.log({...machine.promo.priceData ,newAmount});
          if (newPaymentAmount >= step) {
            if (
              newAmount !== machine.promo.priceData.cp &&
              newAmount !== machine.promo.priceData.wp &&
              newAmount !== machine.promo.priceData.hp
            ) {
              setPromotion(false);
              setAmount(newAmount);
              setPaymentAmount(newAmount);
            } else {
              setPromotion(true);
              setAmount(newAmount);
              setPaymentAmount(
                newAmount === machine.promo.priceData.cp
                  ? machine.promo.priceData.cpd
                  : newAmount === machine.promo.priceData.wp
                    ? machine.promo.priceData.wpd
                    : machine.promo.priceData.hpd
              );
            }
          }
        }
      } else {
        // no user
        const newAmount = parseFloat(amount) - step;
        const newPaymentAmount = parseFloat(paymentAmount) - step;

        if (!machine.promo.status || (!user && !machine.non_member_promotion)) {
          // no promotion
          if (newPaymentAmount >= step) {
            setAmount(newAmount);
            setPaymentAmount(newPaymentAmount);
          }
        } else if (machine.promo.status && machine.promo.priceData.washerRate) {
          // rate promotion
          if (newAmount >= step) {
            // if (
            //   newAmount !== machine.priceData.coldPrice &&
            //   newAmount !== machine.priceData.warmPrice &&
            //   newAmount !== machine.priceData.hotPrice
            // ) {
            //   setAmount(newAmount);
            //   setPaymentAmount(newAmount);
            //   setPromotion(false);
            // } else {
            setPromotion(true);
            setAmount(newAmount);
            setPaymentAmount(parseFloat(newAmount) - ((parseFloat(newAmount) * parseFloat(machine.promo.priceData.washerRate)) / 100));
            // }
          }
        } else {
          // flat promotion
          if (newPaymentAmount >= step) {
            if (
              newAmount !== machine.promo.priceData.cp &&
              newAmount !== machine.promo.priceData.wp &&
              newAmount !== machine.promo.priceData.hp
            ) {
              setAmount(newAmount);
              setPaymentAmount(newAmount);
              setPromotion(false);
            } else {
              setPromotion(true);
              setAmount(newAmount);
              setPaymentAmount(
                newAmount === machine.promo.priceData.cp
                  ? machine.promo.priceData.cpd
                  : newAmount === machine.promo.priceData.wp
                    ? machine.promo.priceData.wpd
                    : machine.promo.priceData.hpd
              );
            }
          }
        }
      }
    }
  };

  const addAmount = () => {
    if (machine.isWashup) {
      // no step, fix to 3 price
      if (user) {
        if (!machine.promo.status) {
          // no promotion
          let amt = machine.priceData.hotPrice;
          if (parseFloat(amount) === parseFloat(machine.priceData.warmPrice)) {
            amt = machine.priceData.hotPrice;
          } else if (parseFloat(amount) === parseFloat(machine.priceData.coldPrice)) {
            amt = machine.priceData.warmPrice;
          }
          if (type && voucherAmount) {
            if (type === "Flat") {
              const discountValue = parseFloat(amt) - voucherAmount;
              // console.log({discountValue});
              setAmount(amt);
              setPaymentAmount(discountValue < 0 ? 0 : discountValue);
            } else if (type === "Rate") {
              const rate = (parseFloat(amt) * voucherAmount) / 100;
              const discountValue = (parseFloat(amt) - rate).toFixed(2);
              // console.log({rate , discountValue});
              setAmount(amt);
              setPaymentAmount(discountValue);
            }
          } else {
            setPaymentAmount(amt);
            setAmount(amt);
          }
        } else if (machine.promo.status && machine.promo.priceData.washerRate) {
          // rate promotion
          if (parseFloat(amount) === parseFloat(machine.priceData.warmPrice)) {
            setPromotion(true);
            setAmount(machine.priceData.hotPrice);
            setPaymentAmount((parseFloat(machine.priceData.hotPrice) - (parseFloat(machine.priceData.hotPrice) * parseFloat(machine.promo.priceData.washerRate)) / 100));
          } else if (parseFloat(amount) === parseFloat(machine.priceData.coldPrice)) {
            setPromotion(true);
            setAmount(machine.priceData.warmPrice);
            setPaymentAmount(parseFloat(machine.priceData.warmPrice) - ((parseFloat(machine.priceData.warmPrice) * parseFloat(machine.promo.priceData.washerRate)) / 100));
          }
        } else {
          // flat promotion
          if (parseFloat(amount) === parseFloat(machine.promo.priceData.wp)) {
            setPromotion(true);
            setAmount(machine.promo.priceData.hp);
            setPaymentAmount(machine.promo.priceData["hpd"]);
          } else if (parseFloat(amount) === parseFloat(machine.promo.priceData.cp)) {
            setPromotion(true);
            setAmount(machine.promo.priceData.wp);
            setPaymentAmount(machine.promo.priceData["wpd"]);
          }
        }
      } else {
        // non user
        if (!machine.promo.status || (!user && !machine.non_member_promotion)) {
          // no promotion
          if (parseFloat(amount) === parseFloat(machine.priceData.warmPrice)) {
            setAmount(machine.priceData.hotPrice);
            setPaymentAmount(machine.priceData.hotPrice);
          } else if (parseFloat(amount) === parseFloat(machine.priceData.coldPrice)) {
            setAmount(machine.priceData.warmPrice);
            setPaymentAmount(machine.priceData.warmPrice);
          }
        } else if (machine.promo.status && machine.promo.priceData.washerRate) {
          // rate promotion
          if (parseFloat(amount) === parseFloat(machine.priceData.warmPrice)) {
            setPromotion(true);
            setAmount(machine.priceData.hotPrice);
            setPaymentAmount(parseFloat(machine.priceData.hotPrice) - ((parseFloat(machine.priceData.hotPrice) * parseFloat(machine.promo.priceData.washerRate)) / 100));
          } else if (parseFloat(amount) === parseFloat(machine.priceData.coldPrice)) {
            setPromotion(true);
            setAmount(machine.priceData.warmPrice);
            setPaymentAmount(parseFloat(machine.priceData.warmPrice) - ((parseFloat(machine.priceData.warmPrice) * parseFloat(machine.promo.priceData.washerRate)) / 100));
          }
        } else {
          // flat promotion
          if (parseFloat(amount) === parseFloat(machine.promo.priceData.wp)) {
            setPromotion(true);
            setAmount(machine.promo.priceData.hp);
            setPaymentAmount(machine.promo.priceData["hpd"]);
          } else if (parseFloat(amount) === parseFloat(machine.promo.priceData.cp)) {
            setPromotion(true);
            setAmount(machine.promo.priceData.wp);
            setPaymentAmount(machine.promo.priceData["wpd"]);
          }
        }
      }
    } else {
      const step = parseFloat(machine.step || 1);
      if (user) {
        let addedAmount = parseFloat(amount) + step;
        if (addedAmount <= parseFloat(machine.priceData.hotPrice)) {
          if (!machine.promo.status) {
            // no promotion
            if (type && voucherAmount) {
              if (type === "Flat") {
                const newAmount = parseFloat(amount) + step;
                const newPaymentAmount = newAmount - voucherAmount;
                setAmount(newAmount);
                setPaymentAmount(newPaymentAmount < 0 ? 0 : newPaymentAmount);
              } else if (type === "Rate") {
                if (voucherAmount !== 100) {
                  const newAmount = parseFloat(amount) + step;
                  const rate = (newAmount * voucherAmount) / 100;
                  const newPaymentAmount = parseFloat(newAmount - rate).toFixed(2);
                  setAmount(newAmount);
                  setPaymentAmount(newPaymentAmount);
                }
              }
            } else {
              const newAmount = parseFloat(amount) + step;
              const newPaymentAmount = parseFloat(paymentAmount) + step;
              setAmount(newAmount);
              setPaymentAmount(newPaymentAmount);
            }
          } else if (machine.promo.status && machine.promo.priceData.washerRate) {
            // rate promotion
            const newAmount = parseFloat(amount) + step;
            const newPaymentAmount = parseFloat(newAmount) - ((parseFloat(newAmount) * parseFloat(machine.promo.priceData.washerRate)) / 100)
            // if (
            //   newAmount !== machine.priceData.coldPrice &&
            //   newAmount !== machine.priceData.warmPrice &&
            //   newAmount !== machine.priceData.hotPrice
            // ) {
            //   setPromotion(false);
            //   setAmount(newAmount);
            //   setPaymentAmount(newAmount);
            // } else {
            setPromotion(true);
            setAmount(newAmount);
            setPaymentAmount(newPaymentAmount);
            // }
          } else {
            // flat promotion
            const newAmount = parseFloat(amount) + step;
            // const newPaymentAmount = parseFloat(paymentAmount) + 1;
            if (
              newAmount !== machine.promo.priceData.cp &&
              newAmount !== machine.promo.priceData.wp &&
              newAmount !== machine.promo.priceData.hp
            ) {
              setPromotion(false);
              setAmount(newAmount);
              setPaymentAmount(newAmount);
            } else {
              setPromotion(true);
              setAmount(newAmount);
              setPaymentAmount(
                newAmount === machine.promo.priceData.cp
                  ? machine.promo.priceData.cpd
                  : newAmount === machine.promo.priceData.wp
                    ? machine.promo.priceData.wpd
                    : machine.promo.priceData.hpd
              );
            }
          }
        }
      } else {
        // no user
        const newAmount = parseFloat(amount) + step;
        const newPaymentAmount = parseFloat(paymentAmount) + step;
        if (newAmount <= parseFloat(machine.priceData.hotPrice)) {
          if (!machine.promo.status || (!user && !machine.non_member_promotion)) {
            // no promotion
            setAmount(newAmount);
            setPaymentAmount(newPaymentAmount);
          } else if (machine.promo.status && machine.promo.priceData.washerRate) {
            // rate promotion
            // if (
            //   newAmount !== machine.priceData.coldPrice &&
            //   newAmount !== machine.priceData.warmPrice &&
            //   newAmount !== machine.priceData.hotPrice
            // ) {
            //   setAmount(newAmount);
            //   setPaymentAmount(newAmount);
            //   setPromotion(false);
            // } else {
            setPromotion(true);
            setAmount(newAmount);
            setPaymentAmount(parseFloat(newAmount) - ((parseFloat(newAmount) * parseFloat(machine.promo.priceData.washerRate)) / 100));
            // }
          } else {
            // flat promotion
            if (
              newAmount !== machine.promo.priceData.cp &&
              newAmount !== machine.promo.priceData.wp &&
              newAmount !== machine.promo.priceData.hp
            ) {
              setPromotion(false);
              setAmount(newAmount);
              setPaymentAmount(newAmount);
            } else {
              setPromotion(true);
              setAmount(newAmount);
              setPaymentAmount(
                newAmount === machine.promo.priceData.cp
                  ? machine.promo.priceData.cpd
                  : newAmount === machine.promo.priceData.wp
                    ? machine.promo.priceData.wpd
                    : machine.promo.priceData.hpd
              );
            }
          }
        }
      }
    }
  };

  const renderVouchers = () => {
    if (user && !machine.promo.status) {
      if (user.vouchers && user.vouchers.length) {
        return user.vouchers
          .filter((voucher) => voucher.available && voucher.washer_use)
          .map((voucher) => {
            let currentVoucherAmount = voucher.amount
            return (
              <div key={voucher.id} className="payment-voucher">
                {STORETYPE === "cleanpro" ? (
                  <div
                    style={{ borderRadius: "0" }}
                    className="voucher my-3"
                  >
                    <div
                      className="voucher-content center-content"
                      style={{
                        color: "black",
                        position: "relative",
                        zIndex: "99",
                        marginTop: "1em",
                      }}
                    >
                      <div
                        className="d-flex"
                        style={{
                          width: "50%",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignContent: "center",
                          marginLeft: "1em",
                          textAlign: "left",
                        }}
                      >
                        <h2 className="font-weight-bold">
                          {voucher.type === "Flat" ? <Trans i18nKey="voucherCreditsOff" count={currentVoucherAmount}>{{ currentVoucherAmount }} Credits OFF</Trans> : <Trans i18nKey="voucherPercentageOff" count={currentVoucherAmount}>{{ currentVoucherAmount }}% OFF</Trans>}
                        </h2>

                        <small>{voucher.description}</small>

                        {voucher.expired_date ? (
                          <h6 style={{ color: "grey" }} className="m-0 p-0">
                            <small>{t("Valid Till")}: {new Date(voucher.expired_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</small>
                          </h6>
                        ) : null}

                      </div>
                      <div
                        style={{ width: "30%" }}
                        className="center-content"
                      >
                        <input
                          onChange={() => setId(voucher.id)}
                          style={{
                            border: "1px solid grey",
                            cursor: "pointer",
                            opacity: "1",
                            pointerEvents: "fill",
                            position: "relative",
                          }}
                          type="radio"
                          name="paymentVoucher"
                          id={voucher.id}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={(e) => chooseVoucher(voucher.type, voucher.amount, voucher.id)}
                    className="list-group-item"
                  >
                    <p>{voucher.title}</p>
                    <h6>
                      {t("Discount")} :- {voucher.type === "Flat" ? currency() : null}
                      {voucher.amount}
                      {voucher.type === "Rate" ? "%" : null}
                    </h6>
                  </div>
                )}
              </div>
            );
          });
      } else {
        return (
          <div className="mt-4">
            <p style={{ color: "red" }}>{t("No Vouchers Available")}</p>
          </div>
        );
      }
    } else {
      return null;
    }
  };

  const handleSubmit = (e) => {
    e.target.disabled = true;
    let voucherReminder = false;

    if (user && !machine.promo.status) {
      if (user.vouchers && user.vouchers.length) {
        let userVoucher = user.vouchers.filter((voucher) => voucher.available && voucher.washer_use);
        if (userVoucher.length !== 0 && !voucher) {
          voucherReminder = true;
        }
      }
    }

    savePayment(renderDuration())

    const emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
    const numberRegex = /^\d+$/;

    if (notificationType === "Email" && !email) {
      mySwal.fire("", "Please fill in your email address", "info")
      e.target.disabled = false;
    } else if (notificationType === "Email" && !emailRegex.test(email)) {
      mySwal.fire(t("Error"), t("Invalid Email Address"), "error");
      e.target.disabled = false;
    } else if (notificationType === "SMS" && !number) {
      mySwal.fire("", t("Please fill in your phone number"), "info")
      e.target.disabled = false;
    } else if (notificationType === "SMS" && LOCATION === "taiwan" && (!numberRegex.test(number) || number.length !== 10 || number.substring(0, 2) !== "09")) {
      mySwal.fire(t("Error"), t("Invalid Phone Number"), "error");
      e.target.disabled = false;
    } else if (notificationType === "SMS" && LOCATION !== "taiwan" && (!numberRegex.test(number) || number.length <= 9 || number.length > 12 || number.substring(0, 2) !== "01")) {
      mySwal.fire(t("Error"), t("Invalid Phone Number"), "error");
      e.target.disabled = false;
    } else {
      if (voucherReminder) {
        mySwal
          .fire({
            title: t("You haven't applied your voucher"),
            text: t("Would you like to continue your payment without using your voucher?"),
            showCancelButton: true,
            confirmButtonText: t("Continue"),
            cancelButtonText: t("Apply voucher"),
            customClass: {
              confirmButton: "pay-without-voucher",
              cancelButton: "apply-voucher",
            },
          })
          .then((click) => {
            if (click.isConfirmed) {
              if (machine.online && machine.outletStatus === "online" && outlet) {
                if (user && user.tokenSetting && user.epayment) {
                  // E-payment or E-token
                  mySwal
                    .fire({
                      title: STORETYPE === "cleanpro" ? t("Payment methods") : t("Payment Method"),
                      text: STORETYPE === "cleanpro" ? t("Tap to proceed with your preferred mode of payment") : t("Would you like to pay with?"),
                      showDenyButton: true,
                      showCancelButton: true,
                      cancelButtonText: t("Cancel"),
                      denyButtonText: STORETYPE === "cleanpro" ? t("Pay now with credits") : t("e-Token"),
                      confirmButtonText: STORETYPE === "cleanpro" ? t("Pay with e-wallet") : t("E-Wallet"),
                      denyButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#67b668",
                      confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#2778c4",
                      customClass: {
                        confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-primary-btn e-wallet" : "e-wallet",
                        denyButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-primary-btn e-token" : "e-token",
                        cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-secondary-btn cancel-payment" : "cancel-payment"
                      },
                    })
                    .then((click) => {
                      if (click.isConfirmed) {
                        // E WALLET PAYMENT (DEFAULT)
                        const text = () => {
                          if (type && voucherAmount && type === "Flat" && voucherAmount > amount) {
                            return `${t("You may under utilized the voucher.")} ${t("Proceed to pay")} ${currency()} ${parseFloat(getPaymentAmount()).toFixed(2)} ${t("to")} ${machine.name}?`;
                          } else {
                            return `${t("Pay")} ${currency()} ${parseFloat(getPaymentAmount()).toFixed(2)} ${t("to")} ${machine.name}?`;
                          }
                        };

                        let sendThis = {
                          amount,
                          machine,
                          paymentAmount,
                          outlet,
                          duration: renderDuration(),
                          notificationType,
                          email,
                          sms_amount: machine.sms_notification_amount,
                          number
                        };
                        let endPoint = "/api/nonuser/payment";
                        if (user) {
                          sendThis.voucherId = voucherId;
                          sendThis.memberId = user.memberId;
                          sendThis.discountCode = discountCode;
                          endPoint = "/api/user/payment";
                        }
                        // console.log({endPoint, sendThis});
                        mySwal
                          .fire({
                            title: t("Confirmation"),
                            text: text(),
                            icon: "question",
                            showCancelButton: true,
                            cancelButtonText: t("No"),
                            confirmButtonText: t("OK"),
                          })
                          .then((result) => {
                            if (result.isConfirmed) {
                              // check paymentAmount
                              // console.log({amount , voucherId , paymentAmount , user , machine});
                              mySwal
                                .fire({
                                  text: t("Processing ..."),
                                  allowOutsideClick: false,
                                  didOpen: () => {
                                    mySwal.showLoading();
                                    axios
                                      .post(endPoint, sendThis)
                                      .then((res) => {
                                        if (res.status === 200) {
                                          saveTransactionId(res.data.transactionId)
                                          if (res.data.status === "rm") {
                                            e.target.disabled = false;
                                            mySwal.close();
                                            window.location.href = res.data.data;
                                          } else if (res.data.status === "laundro") {
                                            e.target.disabled = false;
                                            mySwal.close();
                                            // console.log(res.data);
                                            history.push("/thankyou");
                                          }
                                        } else {
                                          e.target.disabled = false;
                                          mySwal.fire("Error", "Response Error", "error");
                                        }
                                      })
                                      .catch((err) => {
                                        mySwal
                                          .fire("Error", err.response.data.error, "error")
                                          .then(() => (e.target.disabled = false));
                                      });
                                  },
                                })
                                .then(() => {
                                  e.target.disabled = false;
                                });
                            } else {
                              e.target.disabled = false;
                            }
                          });
                      } else if (click.isDenied) {
                        // TOKEN PAYMENT
                        // if (voucherId) {
                        // 	// if got voucher, prompt message
                        // 	mySwal.fire("", t("You can't pay with e-token if voucher is in used, please remove your voucher to pay with e-token."), "info").then(() => (e.target.disabled = false));
                        // } else {
                        // no voucher, pass
                        if (parseFloat(user.token) < getPaymentAmount()) {
                          if (STORETYPE === "cleanpro") {
                            setInsufficient(true);
                            e.target.disabled = false;
                          }
                          // no token or not enough token
                          else
                            mySwal
                              .fire({
                                title: t("Insufficient e-Tokens"),
                                text: t("Reload your e-token now?"),
                                showCancelButton: true,
                                cancelButtonText: t("Cancel"),
                                confirmButtonText: t("Reload now!"),
                                customClass: {
                                  confirmButton: "reload-token",
                                },
                              })
                              .then((result) => {
                                if (result.isConfirmed && result.value)
                                  history.push("/user/reload");
                                e.target.disabled = false;
                              });
                        } else {
                          mySwal
                            .fire({
                              title: STORETYPE === "cleanpro" ? t("Payment Confirmation") : t("Confirmation"),
                              html: `${t("You have")} <strong>${user.token}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")}.<br />${t("Pay")} <strong>${getPaymentAmount()}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")} ${("to")} <strong>${machine.name}</strong>?`,
                              icon: "question",
                              showCancelButton: true,
                              cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                              confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                            })
                            .then((result) => {
                              if (result.isConfirmed && result.value) {
                                mySwal
                                  .fire({
                                    text: t("Processing ..."),
                                    allowOutsideClick: false,
                                    didOpen: () => {
                                      mySwal.showLoading();
                                      const sendThis = {
                                        amount,
                                        paymentAmount,
                                        memberId: user.memberId,
                                        machine,
                                        token: true,
                                        outlet,
                                        voucherId,
                                        discountCode,
                                        duration: renderDuration(),
                                        notificationType,
                                        email,
                                        sms_amount: machine.sms_notification_amount,
                                        number
                                      };
                                      axios
                                        .post("/api/user/payment", sendThis)
                                        .then((res) => {
                                          if (res.status === 200) {
                                            saveTransactionId(res.data.transactionId)
                                            if (res.data.status === "rm") {
                                              e.target.disabled = false;
                                              mySwal.close();
                                              window.location.href = res.data.data;
                                            } else if (res.data.status === "laundro") {
                                              e.target.disabled = false;
                                              mySwal.close();
                                              history.push("/thankyou");
                                            }
                                          } else {
                                            e.target.disabled = false;
                                            mySwal.fire("Error", "Response Error", "error");
                                          }
                                        })
                                        .catch((err) => {
                                          mySwal
                                            .fire("Error", err.response.data.error, "error")
                                            .then(() => (e.target.disabled = false));
                                        });

                                      e.target.disabled = false;
                                    },
                                  })
                                  .then(() => {
                                    e.target.disabled = false;
                                  });
                              } else {
                                e.target.disabled = false;
                              }
                            });
                        }
                        //}
                      } else {
                        e.target.disabled = false;
                      }
                    });
                } else if (user && user.tokenSetting && !user.epayment) {
                  // Only E-Token
                  if (parseFloat(user.token) < getPaymentAmount()) {
                    if (STORETYPE === "cleanpro") {
                      setInsufficient(true);
                      e.target.disabled = false;
                    }
                    // no token or not enough token
                    else
                      mySwal
                        .fire({
                          title: t("Insufficient e-Tokens"),
                          text: t("Reload your e-token now?"),
                          showCancelButton: true,
                          cancelButtonText: t("Cancel"),
                          confirmButtonText: t("Reload now!"),
                          customClass: {
                            confirmButton: "reload-token",
                          },
                        })
                        .then((result) => {
                          if (result.isConfirmed && result.value)
                            history.push("/user/reload");
                          e.target.disabled = false;
                        });
                  } else {
                    mySwal
                      .fire({
                        title: STORETYPE === "cleanpro" ? t("Payment Confirmation") : t("Confirmation"),
                        html: `${t("You have")} <strong>${user.token}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")}.<br />${t("Pay")} <strong>${getPaymentAmount()}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")} ${("to")} <strong>${machine.name}</strong>?`,
                        icon: "question",
                        showCancelButton: true,
                        cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                        confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                      })
                      .then((result) => {
                        if (result.isConfirmed && result.value) {
                          mySwal
                            .fire({
                              text: t("Processing ..."),
                              allowOutsideClick: false,
                              didOpen: () => {
                                mySwal.showLoading();
                                const sendThis = {
                                  amount,
                                  paymentAmount,
                                  memberId: user.memberId,
                                  machine,
                                  token: true,
                                  outlet,
                                  voucherId,
                                  discountCode,
                                  duration: renderDuration(),
                                  notificationType,
                                  email,
                                  sms_amount: machine.sms_notification_amount,
                                  number
                                };
                                axios
                                  .post("/api/user/payment", sendThis)
                                  .then((res) => {
                                    if (res.status === 200) {
                                      saveTransactionId(res.data.transactionId)
                                      if (res.data.status === "rm") {
                                        e.target.disabled = false;
                                        mySwal.close();
                                        window.location.href = res.data.data;
                                      } else if (res.data.status === "laundro") {
                                        e.target.disabled = false;
                                        mySwal.close();
                                        history.push("/thankyou");
                                      }
                                    } else {
                                      e.target.disabled = false;
                                      mySwal.fire("Error", "Response Error", "error");
                                    }
                                  })
                                  .catch((err) => {
                                    mySwal
                                      .fire("Error", err.response.data.error, "error")
                                      .then(() => (e.target.disabled = false));
                                  });

                                e.target.disabled = false;
                              },
                            })
                            .then(() => {
                              e.target.disabled = false;
                            });
                        } else {
                          e.target.disabled = false;
                        }
                      });
                  }
                } else {
                  // Only E-payment
                  const text = () => {
                    if (type && voucherAmount && type === "Flat" && voucherAmount > amount) {
                      return `${t("You may under utilized the voucher.")} ${t("Proceed to pay")} ${currency()} ${parseFloat(getPaymentAmount()).toFixed(2)} ${t("to")} ${machine.name}?`;
                    } else {
                      return `${t("Pay")} ${currency()} ${parseFloat(getPaymentAmount()).toFixed(2)} ${t("to")} ${machine.name}?`;
                    }
                  };

                  let sendThis = {
                    amount,
                    machine,
                    paymentAmount,
                    outlet,
                    duration: renderDuration(),
                    notificationType,
                    email,
                    sms_amount: machine.sms_notification_amount,
                    number
                  };
                  let endPoint = "/api/nonuser/payment";
                  if (user) {
                    sendThis.voucherId = voucherId;
                    sendThis.memberId = user.memberId;
                    sendThis.discountCode = discountCode;
                    endPoint = "/api/user/payment";
                  }
                  // console.log({endPoint, sendThis});
                  mySwal
                    .fire({
                      title: t("Confirmation"),
                      text: text(),
                      icon: "question",
                      showCancelButton: true,
                      cancelButtonText: t("No"),
                      confirmButtonText: t("OK"),
                    })
                    .then((result) => {
                      if (result.isConfirmed) {
                        // check paymentAmount
                        // console.log({amount , voucherId , paymentAmount , user , machine});
                        mySwal
                          .fire({
                            text: t("Processing ..."),
                            allowOutsideClick: false,
                            didOpen: () => {
                              mySwal.showLoading();
                              axios
                                .post(endPoint, sendThis)
                                .then((res) => {
                                  if (res.status === 200) {
                                    saveTransactionId(res.data.transactionId)
                                    if (res.data.status === "rm") {
                                      e.target.disabled = false;
                                      mySwal.close();
                                      window.location.href = res.data.data;
                                    } else if (res.data.status === "laundro") {
                                      e.target.disabled = false;
                                      mySwal.close();
                                      // console.log(res.data);
                                      history.push("/thankyou");
                                    }
                                  } else {
                                    e.target.disabled = false;
                                    mySwal.fire("Error", "Response Error", "error");
                                  }
                                })
                                .catch((err) => {
                                  mySwal
                                    .fire("Error", err.response.data.error, "error")
                                    .then(() => {
                                      e.target.disabled = false;
                                      if (err.response.data.error === "Please log in to make the payment") {
                                        history.push("/");
                                      }
                                    });
                                });
                            },
                          })
                          .then(() => {
                            e.target.disabled = false;
                          });
                      } else {
                        e.target.disabled = false;
                      }
                    });
                }
              } else {
                mySwal
                  .fire(t("Error"), t("This machine is currently offline, please try again later."), "error")
                  .then(() => (e.target.disabled = false));
              }
            } else {
              e.target.disabled = false;
              setOpen(true);
            }
          });
      } else {
        if (machine.online && machine.outletStatus === "online" && outlet) {
          if (user && user.tokenSetting && user.epayment) {
            // E-payment or E-token
            mySwal
              .fire({
                title: STORETYPE === "cleanpro" ? t("Payment methods") : t("Payment Method"),
                text: STORETYPE === "cleanpro" ? t("Tap to proceed with your preferred mode of payment") : t("Would you like to pay with?"),
                showDenyButton: true,
                showCancelButton: true,
                cancelButtonText: t("Cancel"),
                denyButtonText: STORETYPE === "cleanpro" ? t("Pay now with credits") : t("e-Token"),
                confirmButtonText:  STORETYPE === "cleanpro" ? t("Pay with e-wallet") : t("E-Wallet"),
                denyButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#67b668",
                confirmButtonColor: STORETYPE === "cleanpro" ? "#004FC1" : "#2778c4",
                customClass: {
                  confirmButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-primary-btn e-wallet" : "e-wallet",
                  denyButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-primary-btn e-token" : "e-token",
                  cancelButton: STORETYPE === "cleanpro" ? "cleanpro-swal-big-secondary-btn cancel-payment" : "cancel-payment"
                },
              })
              .then((click) => {
                if (click.isConfirmed) {
                  // E WALLET PAYMENT (DEFAULT)
                  const text = () => {
                    if (type && voucherAmount && type === "Flat" && voucherAmount > amount) {
                      return `${t("You may under utilized the voucher.")} ${t("Proceed to pay")} ${currency()} ${parseFloat(getPaymentAmount()).toFixed(2)} ${t("to")} ${machine.name}?`;
                    } else {
                      return `${t("Pay")} ${currency()} ${parseFloat(getPaymentAmount()).toFixed(2)} ${t("to")} ${machine.name}?`;
                    }
                  };

                  let sendThis = {
                    amount,
                    machine,
                    paymentAmount,
                    outlet,
                    duration: renderDuration(),
                    notificationType,
                    email,
                    sms_amount: machine.sms_notification_amount,
                    number
                  };
                  let endPoint = "/api/nonuser/payment";
                  if (user) {
                    sendThis.voucherId = voucherId;
                    sendThis.memberId = user.memberId;
                    sendThis.discountCode = discountCode;
                    endPoint = "/api/user/payment";
                  }
                  // console.log({endPoint, sendThis});
                  mySwal
                    .fire({
                      title: t("Confirmation"),
                      text: text(),
                      icon: "question",
                      showCancelButton: true,
                      cancelButtonText: t("No"),
                      confirmButtonText: t("OK"),
                    })
                    .then((result) => {
                      if (result.isConfirmed) {
                        // check paymentAmount
                        // console.log({amount , voucherId , paymentAmount , user , machine});
                        mySwal
                          .fire({
                            text: t("Processing ..."),
                            allowOutsideClick: false,
                            didOpen: () => {
                              mySwal.showLoading();
                              axios
                                .post(endPoint, sendThis)
                                .then((res) => {
                                  if (res.status === 200) {
                                    saveTransactionId(res.data.transactionId)
                                    if (res.data.status === "rm") {
                                      e.target.disabled = false;
                                      mySwal.close();
                                      window.location.href = res.data.data;
                                    } else if (res.data.status === "laundro") {
                                      e.target.disabled = false;
                                      mySwal.close();
                                      // console.log(res.data);
                                      history.push("/thankyou");
                                    }
                                  } else {
                                    e.target.disabled = false;
                                    mySwal.fire("Error", "Response Error", "error");
                                  }
                                })
                                .catch((err) => {
                                  mySwal
                                    .fire("Error", err.response.data.error, "error")
                                    .then(() => (e.target.disabled = false));
                                });
                            },
                          })
                          .then(() => {
                            e.target.disabled = false;
                          });
                      } else {
                        e.target.disabled = false;
                      }
                    });
                } else if (click.isDenied) {
                  // TOKEN PAYMENT
                  // if (voucherId) {
                  // 	// if got voucher, prompt message
                  // 	mySwal.fire("", t("You can't pay with e-token if voucher is in used, please remove your voucher to pay with e-token."), "info").then(() => (e.target.disabled = false));
                  // } else {
                  // no voucher, pass
                  if (parseFloat(user.token) < getPaymentAmount()) {
                    if (STORETYPE === "cleanpro") {
                      setInsufficient(true);
                      e.target.disabled = false;
                    }
                    // no token or not enough token
                    else
                      mySwal
                        .fire({
                          title: t("Insufficient e-Tokens"),
                          text: t("Reload your e-token now?"),
                          showCancelButton: true,
                          cancelButtonText: t("Cancel"),
                          confirmButtonText: t("Reload now!"),
                          customClass: {
                            confirmButton: "reload-token",
                          },
                        })
                        .then((result) => {
                          if (result.isConfirmed && result.value)
                            history.push("/user/reload");
                          e.target.disabled = false;
                        });
                  } else {
                    mySwal
                      .fire({
                        title: STORETYPE === "cleanpro" ? t("Payment Confirmation") : t("Confirmation"),
                        html: `${t("You have")} <strong>${user.token}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")}.<br />${t("Pay")} <strong>${getPaymentAmount()}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")} ${("to")} <strong>${machine.name}</strong>?`,
                        icon: "question",
                        showCancelButton: true,
                        cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                        confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                      })
                      .then((result) => {
                        if (result.isConfirmed && result.value) {
                          mySwal
                            .fire({
                              text: t("Processing ..."),
                              allowOutsideClick: false,
                              didOpen: () => {
                                mySwal.showLoading();
                                const sendThis = {
                                  amount,
                                  paymentAmount,
                                  memberId: user.memberId,
                                  machine,
                                  token: true,
                                  outlet,
                                  voucherId,
                                  discountCode,
                                  duration: renderDuration(),
                                  notificationType,
                                  email,
                                  sms_amount: machine.sms_notification_amount,
                                  number
                                };
                                axios
                                  .post("/api/user/payment", sendThis)
                                  .then((res) => {
                                    if (res.status === 200) {
                                      saveTransactionId(res.data.transactionId)
                                      if (res.data.status === "rm") {
                                        e.target.disabled = false;
                                        mySwal.close();
                                        window.location.href = res.data.data;
                                      } else if (res.data.status === "laundro") {
                                        e.target.disabled = false;
                                        mySwal.close();
                                        history.push("/thankyou");
                                      }
                                    } else {
                                      e.target.disabled = false;
                                      mySwal.fire("Error", "Response Error", "error");
                                    }
                                  })
                                  .catch((err) => {
                                    mySwal
                                      .fire("Error", err.response.data.error, "error")
                                      .then(() => (e.target.disabled = false));
                                  });

                                e.target.disabled = false;
                              },
                            })
                            .then(() => {
                              e.target.disabled = false;
                            });
                        } else {
                          e.target.disabled = false;
                        }
                      });
                  }
                  //}
                } else {
                  e.target.disabled = false;
                }
              });
          } else if (user && user.tokenSetting && !user.epayment) {
            // Only E-Token
            if (parseFloat(user.token) < getPaymentAmount()) {
              if (STORETYPE === "cleanpro") {
                setInsufficient(true);
                e.target.disabled = false;
              }
              // no token or not enough token
              else
                mySwal
                  .fire({
                    title: t("Insufficient e-Tokens"),
                    text: t("Reload your e-token now?"),
                    showCancelButton: true,
                    cancelButtonText: t("Cancel"),
                    confirmButtonText: t("Reload now!"),
                    customClass: {
                      confirmButton: "reload-token",
                    },
                  })
                  .then((result) => {
                    if (result.isConfirmed && result.value)
                      history.push("/user/reload");
                    e.target.disabled = false;
                  });
            } else {
              mySwal
                .fire({
                  title: STORETYPE === "cleanpro" ? t("Payment Confirmation") : t("Confirmation"),
                  html: `${t("You have")} <strong>${user.token}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")}.<br />${t("Pay")} <strong>${getPaymentAmount()}</strong> ${STORETYPE === "cleanpro" ? t("credits") : t("e-Tokens")} ${("to")} <strong>${machine.name}</strong>?`,
                  icon: "question",
                  showCancelButton: true,
                  cancelButtonText: STORETYPE === "cleanpro" ? t("Cancel") : t("No"),
                  confirmButtonText: STORETYPE === "cleanpro" ? t("Pay now") : t("OK"),
                })
                .then((result) => {
                  if (result.isConfirmed && result.value) {
                    mySwal
                      .fire({
                        text: t("Processing ..."),
                        allowOutsideClick: false,
                        didOpen: () => {
                          mySwal.showLoading();
                          const sendThis = {
                            amount,
                            paymentAmount,
                            memberId: user.memberId,
                            machine,
                            token: true,
                            outlet,
                            voucherId,
                            discountCode,
                            duration: renderDuration(),
                            notificationType,
                            email,
                            sms_amount: machine.sms_notification_amount,
                            number
                          };
                          axios
                            .post("/api/user/payment", sendThis)
                            .then((res) => {
                              if (res.status === 200) {
                                saveTransactionId(res.data.transactionId)
                                if (res.data.status === "rm") {
                                  e.target.disabled = false;
                                  mySwal.close();
                                  window.location.href = res.data.data;
                                } else if (res.data.status === "laundro") {
                                  e.target.disabled = false;
                                  mySwal.close();
                                  history.push("/thankyou");
                                }
                              } else {
                                e.target.disabled = false;
                                mySwal.fire("Error", "Response Error", "error");
                              }
                            })
                            .catch((err) => {
                              mySwal
                                .fire("Error", err.response.data.error, "error")
                                .then(() => (e.target.disabled = false));
                            });

                          e.target.disabled = false;
                        },
                      })
                      .then(() => {
                        e.target.disabled = false;
                      });
                  } else {
                    e.target.disabled = false;
                  }
                });
            }
          } else {
            // Only E-payment
            const text = () => {
              if (type && voucherAmount && type === "Flat" && voucherAmount > amount) {
                return `${t("You may under utilized the voucher.")} ${t("Proceed to pay")} ${currency()} ${parseFloat(getPaymentAmount()).toFixed(2)} ${t("to")} ${machine.name}?`;
              } else {
                return `${t("Pay")} ${currency()} ${parseFloat(getPaymentAmount()).toFixed(2)} ${t("to")} ${machine.name}?`;
              }
            };

            let sendThis = {
              amount,
              machine,
              paymentAmount,
              outlet,
              duration: renderDuration(),
              notificationType,
              email,
              sms_amount: machine.sms_notification_amount,
              number
            };
            let endPoint = "/api/nonuser/payment";
            if (user) {
              sendThis.voucherId = voucherId;
              sendThis.memberId = user.memberId;
              sendThis.discountCode = discountCode;
              endPoint = "/api/user/payment";
            }
            // console.log({endPoint, sendThis});
            mySwal
              .fire({
                title: t("Confirmation"),
                text: text(),
                icon: "question",
                showCancelButton: true,
                cancelButtonText: t("No"),
                confirmButtonText: t("OK"),
              })
              .then((result) => {
                if (result.isConfirmed) {
                  // check paymentAmount
                  // console.log({amount , voucherId , paymentAmount , user , machine});
                  mySwal
                    .fire({
                      text: t("Processing ..."),
                      allowOutsideClick: false,
                      didOpen: () => {
                        mySwal.showLoading();
                        axios
                          .post(endPoint, sendThis)
                          .then((res) => {
                            if (res.status === 200) {
                              saveTransactionId(res.data.transactionId)
                              if (res.data.status === "rm") {
                                e.target.disabled = false;
                                mySwal.close();
                                window.location.href = res.data.data;
                              } else if (res.data.status === "laundro") {
                                e.target.disabled = false;
                                mySwal.close();
                                // console.log(res.data);
                                history.push("/thankyou");
                              }
                            } else {
                              e.target.disabled = false;
                              mySwal.fire("Error", "Response Error", "error");
                            }
                          })
                          .catch((err) => {
                            mySwal
                              .fire("Error", err.response.data.error, "error")
                              .then(() => {
                                e.target.disabled = false;
                                if (err.response.data.error === "Please log in to make the payment") {
                                  history.push("/");
                                }
                              });
                          });
                      },
                    })
                    .then(() => {
                      e.target.disabled = false;
                    });
                } else {
                  e.target.disabled = false;
                }
              });
          }
        } else {
          mySwal
            .fire(t("Error"), t("This machine is currently offline, please try again later."), "error")
            .then(() => (e.target.disabled = false));
        }
      }
    }
  };

  // RENDER
  return (
    <div id="washer-payment" className="mt-4">
      {machine.promo.status && promotion ? (
        <div className={STORETYPE === "cleanpro" ? "text-center mb-5 pt-2" : "text-center mb-5"}>
          <img
            src={promotionBanner}
            alt="promotion banner"
            className="img-fluid m-auto"
            style={{ height: "90px", width: "auto" }}
          />
        </div>
      ) : null}
      {STORETYPE !== "cleanpro" && (
        <div className="payment-info">
          <div className="payment-info-box">
            <div className="payment-info-image">
              <img src={washer1} alt="washer1" className="img-fluid" />
            </div>
            <p className="text-secondary font-weight-bold">{t("MACHINE")}</p>
            <p className="font-weight-bold">{machine.name}</p>
          </div>
          <div className="payment-info-box">
            <div className="payment-info-image">
              <img src={washer2} alt="washer2" className="img-fluid" />
            </div>
            <p className="text-secondary font-weight-bold">{t("TYPE")}</p>
            <p className="font-weight-bold">{t(machine.type)}</p>
          </div>
          <div className="payment-info-box">
            <div className="payment-info-image">
              <img src={washer3} alt="washer3" className="img-fluid" />
            </div>
            <p className="text-secondary font-weight-bold">{t("CAPACITY")}</p>
            <p className="font-weight-bold">{machine.capacity}</p>
          </div>
          <div className="payment-info-box">
            <div className="payment-info-image">
              <img src={washer4} alt="washer4" className="img-fluid" />
            </div>
            <p className="text-secondary font-weight-bold">{t("DURATION")}</p>
            <p className="font-weight-bold">
              {renderDuration()} {t("MIN")}
            </p>
          </div>
        </div>
      )}

      {STORETYPE === "cleanpro" ? (
        <h6 style={{ margin: "20px 0 5px 0", padding: "0px 5vw" }}>
          <img src={temp} alt="" style={{ width: "10px" }} />
          <span style={{ marginLeft: "10px", fontWeight: "600", color: "#333333" }}>
            {t("Select temperature")}
          </span>
        </h6>
      ) : (
        <h6 style={{ margin: "20px 0 5px 0" }}>
          <strong className="ml-1">{t("Select Water Temperature")}</strong>
        </h6>
      )}

      <div className="mt-3 selection-buttons">
        <button
          id="cold"
          className={buttonStyle("cold")}
          onClick={(e) =>
            changeTemp(
              !machine.promo.status || (machine.promo.status && machine.promo.priceData.washerRate)
                ? machine.priceData.coldPrice
                : machine.promo.priceData.cp,
              "cpd"
            )
          }
        >
          <i className="bi bi-snow"></i> {STORETYPE === "washup" ? t("Quick") : t("Cold")}
        </button>
        <button
          id="warm"
          className={buttonStyle("warm")}
          onClick={(e) =>
            changeTemp(
              !machine.promo.status || (machine.promo.status && machine.promo.priceData.washerRate)
                ? machine.priceData.warmPrice
                : machine.promo.priceData.wp,
              "wpd"
            )
          }
        >
          <i className="bi bi-sun-fill"></i> {STORETYPE === "washup" ? t("Super") : t("Warm")}
        </button>
        <button
          id="hot"
          className={buttonStyle("hot")}
          onClick={(e) =>
            changeTemp(
              !machine.promo.status || (machine.promo.status && machine.promo.priceData.washerRate)
                ? machine.priceData.hotPrice
                : machine.promo.priceData.hp,
              "hpd"
            )
          }
        >
          <i className="bi bi-fire"></i> {STORETYPE === "washup" ? t("Premium") : t("Hot")}
        </button>
      </div>

      {STORETYPE === "cleanpro" && (
        <div style={{ border: "5px solid #f2f2f2", marginTop: "20px" }}></div>
      )}

      {
        STORETYPE === "cleanpro" && machine.notification && !machine.running ? (
          <div>
            <div style={{ padding: "0px 5vw" }} className="payment-notification-container py-2">
              <p><b>{t("Select how you want to receive a notification when your laundry is finished")}:</b></p>
              <div className="d-flex justify-content-around form-group pt-1">
                <label htmlFor="radio2">
                  <input type="radio" name="notificationType" id="radio2" value="Email" className="with-gap" checked={notificationType === "Email"} onChange={e => setNotificationType(e.target.value)} />
                  <span style={{ color: "black" }}>{t("Email")}</span>
                </label>

                <label htmlFor="radio3">
                  <input type="radio" name="notificationType" id="radio3" value="SMS" className="with-gap" checked={notificationType === "SMS"} onChange={e => setNotificationType(e.target.value)} />
                  <span style={{ color: "black" }}>SMS ({parseFloat(machine.sms_notification_amount).toFixed(2)} {t("credit")}/SMS)</span>
                </label>
              </div>

              {
                notificationType === "Email" ? (
                  <div className="form-group">
                    <input type="email" id="email" className="form-control browser-default" value={email} onChange={e => setEmail(e.target.value)} required placeholder={t("Email Address")} />
                  </div>
                ) : null
              }

              {
                notificationType === "SMS" ? (
                  <div className="form-group">
                    <input type="tel" id="number" className="form-control browser-default" value={number} onChange={e => setNumber(e.target.value)} required placeholder={t("Phone Number")} />
                  </div>
                ) : null
              }
            </div>
            <div style={{ border: "5px solid #f2f2f2" }}></div>
          </div>
        ) : null
      }

      {STORETYPE !== "cleanpro" && (
        <div className="payment-amount">
          <span id="minus-amount" onClick={(e) => minusAmount()}>
            <i className="bi bi-dash-circle-fill"></i>
          </span>

          <div>
            <h6 className="m-0 p-0 font-weight-bold text-secondary">
              {t("AMOUNT TO PAY")}
            </h6>
            <h3 className="m-0 p-0 font-weight-bold">
              {currency()} {/\./g.test(amount.toString()) ? parseFloat(amount).toFixed(2) : amount}
            </h3>
          </div>

          <span id="add-amount" onClick={(e) => addAmount()}>
            <i className="bi bi-plus-circle-fill"></i>
          </span>
        </div>
      )}

      {STORETYPE === "cleanpro" ? (
        <div className="pt-3" style={{ padding: "0px 5vw" }}>
          {
            user && !machine.promo.status ? (
              <div
                id="cleanpro-select-voucher"
                onClick={(e) => setOpen(true)}
                className="checkout-payment py-2"
              >
                <img
                  className="fit-contain"
                  style={{ width: "32px" }}
                  src={ticket}
                  alt=""
                />
                <p className={`ml-1 ${voucherId && "font-weight-bold"}`}>
                  {voucherId ? t("1 voucher selected") : t("No voucher selected")}
                </p>
                <p>
                  <i className="arrow right"></i>
                </p>
              </div>
            ) : null
          }
          {
            user && user.tokenSetting ? (
              <div
                id="cleanpro-reload-token"
                onClick={() => history.push("/user/reload")}
                className="checkout-payment py-2"
              >
                <img style={{ width: "32px" }} src={creditBear} alt="" />
                <p className="ml-1">
                  {user.token} {t("credits")}
                </p>
                <p>
                  <i className="arrow right"></i>
                </p>
              </div>
            ) : null
          }
          {
            user ? <div style={{ border: "1px solid #f2f2f2", margin: "5px -20px" }}></div> : null
          }
        </div>
      ) : (
        <div className="list-group payment-list-group">
          <div className="list-group-item payment-list-group-item">
            <div>
              <p className="text-secondary">{t("PRICE")}</p>
            </div>

            <div>
              <p>
                {currency()} {/\./g.test(amount.toString()) ? amount.toFixed(2) : amount}
              </p>
            </div>
          </div>

          {user && !machine.promo.status ? (
            <div>
              {discountCode || !voucherId ? (
                <div className="list-group-item payment-list-group-item">
                  <div>
                    <p className="text-secondary">{t("DISCOUNT CODE")}</p>
                  </div>

                  <div>
                    <button
                      id="select-voucher"
                      type="button"
                      onClick={(e) => setDiscountCodeModal(true)}
                      className="text-secondary"
                      style={{
                        border: "none",
                        backgroundColor: "white",
                        outline: "none",
                        fontWeight: "bold",
                        paddingRight: "0px",
                        fontSize: "large",
                      }}
                    >
                      {discountCode && voucher
                        ? `${discountCode} (${voucher})`
                        : t("Enter code >")}
                    </button>
                  </div>
                </div>
              ) : null}

              {voucherId || !discountCode ? (
                <div className="list-group-item payment-list-group-item">
                  <div>
                    <p className="text-secondary">{t("VOUCHER")}</p>
                  </div>

                  <div>
                    <button
                      id="select-voucher"
                      type="button"
                      onClick={(e) => setOpen(true)}
                      className="text-secondary"
                      style={{
                        border: "none",
                        backgroundColor: "white",
                        outline: "none",
                        fontWeight: "bold",
                        paddingRight: "0px",
                        fontSize: "large",
                      }}
                    >
                      {voucherId ? voucher : t("Select Voucher >")}
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          ) : machine.promo.status && promotion ? (
            <div className="list-group-item payment-list-group-item">
              <div>
                <p className="text-secondary">{t("DISCOUNT")}</p>
              </div>

              <div>
                <p>{renderPromotionPrize()}</p>
              </div>
            </div>
          ) : null}

          {
            notificationType === "SMS" ? (
              <div className="list-group-item payment-list-group-item">
                <div>
                  <p className="text-secondary">SMS</p>
                </div>

                <div>
                  <p>{currency()} {/\./g.test(machine.sms_notification_amount.toString()) ? parseFloat(machine.sms_notification_amount).toFixed(2) : machine.sms_notification_amount}</p>
                </div>
              </div>
            ) : null
          }

          <div className="list-group-item payment-list-group-item">
            <div>
              <p
                style={{ fontSize: "24px", fontWeight: "bolder" }}
                className="text-secondary"
              >
                {t("TOTAL")}
              </p>
            </div>

            <div>
              <p style={{ fontSize: "24px", fontWeight: "bolder" }}>
                {currency()} {/\./g.test(getPaymentAmount().toString()) ? parseFloat(getPaymentAmount()).toFixed(2) : getPaymentAmount()}
              </p>
            </div>
          </div>
        </div>
      )}

      {STORETYPE === "cleanpro" && voucherId && (
        <div className="py-2 d-flex justify-content-between" style={{ padding: "0px 5vw" }}>
          <h6>{t("Offers")}</h6>
          <h6 style={{ color: "#333333" }}>-{voucherId ? voucher : null}</h6>
        </div>
      )}

      {STORETYPE === "cleanpro" && machine.promo.status && promotion && (
        <div className="py-2 d-flex justify-content-between" style={{ padding: "0px 5vw" }}>
          <h6>{t("Offers")}</h6>
          <h6 style={{ color: "#333333" }}>-{renderPromotionPrize()}</h6>
        </div>
      )}

      {STORETYPE === "cleanpro" && (
        <div className="py-2 d-flex justify-content-between" style={{ padding: "0px 5vw" }}>
          <h6>{t("Amount to Pay")}</h6>
          <h6 className="font-weight-bold blue-t">
            {user && user.tokenSetting ? "" : currency()} {/\./g.test(getPaymentAmount().toString()) ? parseFloat(getPaymentAmount()).toFixed(2) : getPaymentAmount()} {user && user.tokenSetting ? t("credits") : ""}
          </h6>
        </div>
      )}

      {
        STORETYPE !== "cleanpro" && machine.notification && !machine.running ? (
          <div className="px-3 pt-3">
            <h6><strong>{t("Notification")}</strong></h6>
            <p>{t("Do you want to receive notification when wash cycle done?")}</p>

            <div className="d-flex justify-content-around form-group">
              <label htmlFor="radio1">
                <input type="radio" name="notificationType" id="radio1" value="" className="with-gap" checked={notificationType === ""} onChange={e => setNotificationType(e.target.value)} />
                <span style={{ color: "black" }}>{t("No")}</span>
              </label>

              <label htmlFor="radio2">
                <input type="radio" name="notificationType" id="radio2" value="Email" className="with-gap" checked={notificationType === "Email"} onChange={e => setNotificationType(e.target.value)} />
                <span style={{ color: "black" }}>{t("Email")}</span>
              </label>

              <label htmlFor="radio3">
                <input type="radio" name="notificationType" id="radio3" value="SMS" className="with-gap" checked={notificationType === "SMS"} onChange={e => setNotificationType(e.target.value)} />
                <span style={{ color: "black" }}>SMS ({t("pay extra")} {currency()}{parseFloat(machine.sms_notification_amount).toFixed(2)})</span>
              </label>
            </div>

            {
              notificationType === "Email" ? (
                <div className="form-group">
                  <label htmlFor="email">{t("Email Address")}:</label>
                  <input type="email" id="email" className="form-control browser-default" value={email} onChange={e => setEmail(e.target.value)} required />
                </div>
              ) : null
            }

            {
              notificationType === "SMS" ? (
                <div className="form-group">
                  <label htmlFor="number">{t("Phone Number")}:</label>
                  <input type="tel" id="number" className="form-control browser-default" value={number} onChange={e => setNumber(e.target.value)} required />
                </div>
              ) : null
            }

          </div>
        ) : null
      }

      <div id="payment-button-container" className={STORETYPE === "cleanpro" ? "px-3 pt-3" : "mt-5 px-3"}>
        {STORETYPE === "cleanpro" && (
          <p style={{ fontSize: "12px" }} className="mb-2 text-center">
            *{t("Please ensure that the machine door is shut & your laundry is inside.")}
          </p>
        )}
        <button
          id="pay"
          onClick={(e) => handleSubmit(e)}
          className="btn form-control big-button"
        >
          {STORETYPE === "cleanpro" ? t("Pay now") : t("PAY")}
        </button>
      </div>

      {/* CUSTOM VOUCHER MODAL */}
      {user && !machine.promo.status ? (
        <div>
          <div
            onClick={(e) => setOpen(false)}
            className={isOpen ? "custom-overlay isOpen" : "custom-overlay"}
          ></div>
          <div className={isOpen ? "custom-modal isOpen" : "custom-modal"}>
            <div className="custom-modal-header">
              {voucher ? (
                <button
                  id="remove-voucher"
                  onClick={(e) => removeVoucher()}
                  type="button"
                  className="custom-modal-title m-0 p-0"
                  style={{
                    color: "red",
                    border: "none",
                    backgroundColor: "white",
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <i className="bi bi-slash-circle"></i> {t("Remove Voucher")}
                </button>
              ) : (
                <h6 className="custom-modal-title">
                  {STORETYPE === "cleanpro" ? (
                    t("Select voucher to use")
                  ) : (
                    <>
                      <i className="bi bi-tags-fill"></i> {t("Select Your Voucher")}
                    </>
                  )}
                </h6>
              )}
            </div>

            <div className="custom-modal-body">
              <ul className="list-group list-group-flush px-4">
                {renderVouchers()}
              </ul>
            </div>
            {
              STORETYPE === "cleanpro" ? (
                <button onClick={useVoucher} className="mt-5 btn big-button" style={{ width: "90%" }}>
                  {t("Use voucher")}
                </button>
              ) : null
            }
          </div>
        </div>
      ) : null}

      <Modal
        show={discountCodeModal}
        onHide={(e) => setDiscountCodeModal(false)}
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Enter Discount Code")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={checkDiscountCode}>
              {discountCode ? (
                <div className="form-group text-left">
                  <label htmlFor="code">{t("Discount Code")}</label>
                  <input
                    type="text"
                    id="code"
                    name="code"
                    value={discountCode}
                    className="form-control browser-default"
                    readOnly
                  />
                </div>
              ) : (
                <div className="form-group text-left">
                  <label htmlFor="code">{t("Discount Code")}</label>
                  <input
                    type="text"
                    id="code"
                    name="code"
                    value={discountCodeInput}
                    className="form-control browser-default"
                    onChange={(e) =>
                      setDiscountCodeInput(e.target.value.toUpperCase())
                    }
                    required
                  />
                </div>
              )}

              <div className="text-center pt-3">
                {loading ? (
                  <div className="text-center">
                    <div
                      className={`spinner-border text-${STORETYPE === "cuci" ? "warning" : "primary"}`}
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : discountCode ? (
                  <input
                    id="remove-discount-code"
                    type="button"
                    value={t("Remove Code")}
                    onClick={() => removeVoucher()}
                    className="btn btn-primary mx-2"
                  />
                ) : (
                  <input
                    id="submit-discount-code"
                    type="submit"
                    value={t("Submit")}
                    className="btn btn-primary mx-2"
                  />
                )}
                <button
                  id="close-discount-code"
                  type="button"
                  className="btn btn-secondary"
                  onClick={(e) => setDiscountCodeModal(false)}
                >
                  {t("Close")}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={insufficient}
        onHide={(e) => setInsufficient(false)}
        keyboard={false}
        centered
      >
        <Modal.Body>
          <div className={theme}>
            <img
              className="d-block"
              src={insufficientToken}
              style={{ width: "64px", margin: "0 auto" }}
              alt=""
            />
            <h2 className="align-center my-3">{t("Insufficient balance")}</h2>
            <p className="text-center" style={{ fontSize: "16px", fontWeight: 300 }}>{t("Oops. You don't have enough balance to make the payment")}</p>
            <div className="d-flex justify-content-around pt-3">
              <button id="cleanpro-cancel-topup" onClick={() => setInsufficient(false)} style={{ height: "50px", width: "40%" }} className="btn secondary-button">
                {t("Back")}
              </button>
              <button id="cleanpro-topup-now" onClick={() => history.push("/user/reload")} className="btn big-button" style={{ width: "50%" }}>
                {t("Topup now")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default WasherPayment;
