import React, { Component } from "react";

import { connect } from "react-redux";
import { changeCounter, checkToken, grantVoucher } from "../../../redux/actions/userActions";

import { Redirect } from "react-router-dom";

import queryString from "query-string";

import Navigator from "../utilities/Navigation";
import Dashboard from "./Dashboard";
import Coupon from "./Coupon";
import Transaction from "./Transaction";
import News from "./News";
import Camera from "./Camera";

import giftGif from "../../../assets/gif/giftGif.gif";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "i18next";

import { currency } from "../../../dynamicController";

import { subscribeUser } from "../../../subscription";

const STORETYPE = process.env.REACT_APP_STORETYPE;

const mySwal = withReactContent(Swal);

class Main extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	_isMounted = false;

	componentDidMount = () => {
		this._isMounted = true;

		if (this._isMounted) {
			this.props.checkToken();

			if (this.props.isAuthenticated) {
				subscribeUser(this.props.user.id);
				const { combinedSetting, combinedStamps, dryerStamp, washerStamp } = this.props.user;
				const id = this.props.user.id;

				let sendThis = { id };

				if (this.props.user.referral && STORETYPE !== "cleanpro") {
					if (this.props.modalCounter < 3) {
						let counter = this.props.modalCounter;
						counter++;
						this.props.changeCounter(counter);

						const { voucher_setting, token_eligible, stamp_eligible, token_amount, stamp_combined, stamp_washer, stamp_dryer } = this.props.user.referralSetting
						let message = i18n.t("Inviting friends to get more benefits!")
						if (voucher_setting.voucher_eligible && !token_eligible && !stamp_eligible) {
							// only give voucher for referral reward
							message = `${i18n.t("Refer a friend and get")} ${voucher_setting.voucher_type === "Flat" ? currency() : ""} ${voucher_setting.voucher_amount}${voucher_setting.voucher_type === "Rate" ? "%" : ""} ${i18n.t("voucher")}!`
						} else if (!voucher_setting.voucher_eligible && token_eligible && !stamp_eligible && this.props.user.tokenSetting) {
							// only give token for referral reward
							message = `${i18n.t("Refer a friend and get")}} ${token_amount} ${i18n.t("token")}!`
						} else if (!voucher_setting.voucher_eligible && !token_eligible && stamp_eligible && this.props.user.stamp) {
							// only give stamp for referral reward
							if (this.props.user.combinedSetting) {
								message = `${i18n.t("Refer a friend and get")} ${stamp_combined} ${i18n.t("stamp")}!`
							} else {
								if (this.props.user.specificStamp === "both") {
									message = `${i18n.t("Refer a friend and get")} ${stamp_washer} ${i18n.t("washer stamp")} & ${stamp_dryer} ${i18n.t("dryer stamp")}!`
								} else if (this.props.user.specificStamp === "washerOnly") {
									message = `${i18n.t("Refer a friend and get")} ${stamp_washer} ${i18n.t("washer stamp")}!`
								} else if (this.props.user.specificStamp === "dryerOnly") {
									message = `${i18n.t("Refer a friend and get")} ${stamp_dryer} ${i18n.t("dryer stamp")}!`
								}
							}
						} else if (voucher_setting.voucher_eligible && token_eligible && stamp_eligible && this.props.user.tokenSetting && this.props.user.stamp) {
							// give voucher, token, stamp for referral reward
							if (this.props.user.combinedSetting) {
								message = `${i18n.t("Refer a friend and get")} ${voucher_setting.voucher_type === "Flat" ? currency() : ""} ${voucher_setting.voucher_amount}${voucher_setting.voucher_type === "Rate" ? "%" : ""} ${i18n.t("voucher")}, ${token_amount} ${i18n.t("token")}, ${stamp_combined} ${i18n.t("stamp")}!`
							} else {
								if (this.props.user.specificStamp === "both") {
									message = `${i18n.t("Refer a friend and get")} ${voucher_setting.voucher_type === "Flat" ? currency() : ""} ${voucher_setting.voucher_amount}${voucher_setting.voucher_type === "Rate" ? "%" : ""} ${i18n.t("voucher")}, ${token_amount} ${i18n.t("token")}, ${stamp_washer} ${i18n.t("washer stamp")} & ${stamp_dryer} ${i18n.t("dryer stamp")}!`
								} else if (this.props.user.specificStamp === "washerOnly") {
									message = `${i18n.t("Refer a friend and get")} ${voucher_setting.voucher_type === "Flat" ? currency() : ""} ${voucher_setting.voucher_amount}${voucher_setting.voucher_type === "Rate" ? "%" : ""} ${i18n.t("voucher")}, ${token_amount} ${i18n.t("token")}, ${stamp_washer} ${i18n.t("washer stamp")}!`
								} else if (this.props.user.specificStamp === "dryerOnly") {
									message = `${i18n.t("Refer a friend and get")} ${voucher_setting.voucher_type === "Flat" ? currency() : ""} ${voucher_setting.voucher_amount}${voucher_setting.voucher_type === "Rate" ? "%" : ""} ${i18n.t("voucher")}, ${token_amount} ${i18n.t("token")}, ${stamp_dryer} ${i18n.t("dryer stamp")}!`
								}
							}
						} else if (voucher_setting.voucher_eligible && token_eligible && !stamp_eligible && this.props.user.tokenSetting) {
							// give voucher, token for referral reward
							message = `${i18n.t("Refer a friend and get")} ${voucher_setting.voucher_type === "Flat" ? currency() : ""} ${voucher_setting.voucher_amount}${voucher_setting.voucher_type === "Rate" ? "%" : ""} ${i18n.t("voucher")}, ${token_amount} ${i18n.t("token")}!`
						} else if (voucher_setting.voucher_eligible && !token_eligible && stamp_eligible && this.props.user.stamp) {
							// give voucher, stamp for referral reward
							if (this.props.user.combinedSetting) {
								message = `${i18n.t("Refer a friend and get")} ${voucher_setting.voucher_type === "Flat" ? currency() : ""} ${voucher_setting.voucher_amount}${voucher_setting.voucher_type === "Rate" ? "%" : ""} ${i18n.t("voucher")}, ${stamp_combined} ${i18n.t("stamp")}!`
							} else {
								if (this.props.user.specificStamp === "both") {
									message = `${i18n.t("Refer a friend and get")} ${voucher_setting.voucher_type === "Flat" ? currency() : ""} ${voucher_setting.voucher_amount}${voucher_setting.voucher_type === "Rate" ? "%" : ""} ${i18n.t("voucher")}, ${stamp_washer} ${i18n.t("washer stamp")} & ${stamp_dryer} ${i18n.t("dryer stamp")}!`
								} else if (this.props.user.specificStamp === "washerOnly") {
									message = `${i18n.t("Refer a friend and get")} ${voucher_setting.voucher_type === "Flat" ? currency() : ""} ${voucher_setting.voucher_amount}${voucher_setting.voucher_type === "Rate" ? "%" : ""} ${i18n.t("voucher")}, ${stamp_washer} ${i18n.t("washer stamp")}!`
								} else if (this.props.user.specificStamp === "dryerOnly") {
									message = `${i18n.t("Refer a friend and get")} ${voucher_setting.voucher_type === "Flat" ? currency() : ""} ${voucher_setting.voucher_amount}${voucher_setting.voucher_type === "Rate" ? "%" : ""} ${i18n.t("voucher")}, ${stamp_dryer} ${i18n.t("dryer stamp")}!`
								}
							}
						} else if (!voucher_setting.voucher_eligible && token_eligible && stamp_eligible && this.props.user.tokenSetting && this.props.user.stamp) {
							// give token, stamp for referral reward
							if (this.props.user.combinedSetting) {
								message = `${i18n.t("Refer a friend and get")} ${token_amount} ${i18n.t("token")}, ${stamp_combined} ${i18n.t("stamp")}!`
							} else {
								if (this.props.user.specificStamp === "both") {
									message = `${i18n.t("Refer a friend and get")} ${token_amount} ${i18n.t("token")}, ${stamp_washer} ${i18n.t("washer stamp")} & ${stamp_dryer} ${i18n.t("dryer stamp")}!`
								} else if (this.props.user.specificStamp === "washerOnly") {
									message = `${i18n.t("Refer a friend and get")} ${token_amount} ${i18n.t("token")}, ${stamp_washer} ${i18n.t("washer stamp")}!`
								} else if (this.props.user.specificStamp === "dryerOnly") {
									message = `${i18n.t("Refer a friend and get")} ${token_amount} ${i18n.t("token")}, ${stamp_dryer} ${i18n.t("dryer stamp")}!`
								}
							}
						}

						mySwal
							.fire({
								title: message,
								text: i18n.t("Share the love to your friends & family?"),
								icon: "question",
								showCancelButton: true,
								cancelButtonText: i18n.t("No"),
								confirmButtonText: i18n.t("Yes"),
								customClass: {
									confirmButton: "share-referral-yes",
									cancelButton: "share-referral-no"
								}
							})
							.then(click => {
								if (click.isConfirmed && click.value) {
									navigator
										.share({
											title: "Referral Code Sharing",
											text: `Hi, you are invited to register an account with my referral code(${this.props.user.memberId}).`,
											url: `https://${window.location.hostname}/welcome?operatorCode=${this.props.operatorId}&referralCode=${this.props.user.memberId}`
										})
										.then(() => console.log("Successful share"))
										.catch(error => console.log("Error sharing", error));
								}
							});
					}
				}

				if (combinedSetting && combinedStamps >= 10) {
					// check combinedStamps
					sendThis.target = "combined";
					mySwal.fire({
						title: i18n.t("Congratulations!"),
						html: i18n.t("You have completed your Stamp collection.<br />Granted a voucher and stamp collection is refreshed"),
						imageUrl: giftGif,
						imageAlt: "Gift Gif",
						background: "#fbfbfb"
					});
					this.props.grantVoucher(sendThis);
				} else {
					// check washerStamp and dryerStamp
					if (washerStamp >= 10) {
						// give washer voucher
						sendThis.target = "washer";
						mySwal.fire({
							title: i18n.t("Congratulations!"),
							html: i18n.t("You have completed Washer Stamp collection.<br />Granted a voucher and stamp collection is refreshed"),
							imageUrl: giftGif,
							imageAlt: "Gift Gif",
							background: "#fbfbfb"
						});
						this.props.grantVoucher(sendThis);
					} else if (dryerStamp >= 10) {
						// give dryer voucher
						sendThis.target = "dryer";
						mySwal.fire({
							title: i18n.t("Congratulations!"),
							html: i18n.t("You have completed Dryer Stamp collection.<br />Granted a voucher and stamp collection is refreshed"),
							imageUrl: giftGif,
							imageAlt: "Gift Gif",
							background: "#fbfbfb"
						});
						this.props.grantVoucher(sendThis);
					}
				}
			}
		}
	};

	componentWillUnmount = () => (this._isMounted = false);

	render() {
		const renderPage = () => {
			if (this.props.nav === "stamp") {
				return <Dashboard history={this.props.history} />;
			} else if (this.props.nav === "coupon") {
				return <Coupon />;
			} else if (this.props.nav === "transaction") {
				return <Transaction />;
			} else if (this.props.nav === "news") {
				return <News />;
			} else if (this.props.nav === "camera") {
				return <Camera history={this.props.history} />;
			} else {
				return (
					<div className="text-center">
						<h1>{i18n.t("Something went wrong")} :/</h1>
					</div>
				);
			}
		};

		const { payment } = queryString.parse(this.props.location.search);

		if (!this.props.isAuthenticated) {
			return <Redirect to={`/welcome${payment && payment === "success" ? this.props.location.search : ""}`} />;
		} else {
			return (
				<div>
					{renderPage()}
					<Navigator />
				</div>
			);
		}
	}
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.user.isAuthenticated,
		user: state.user.user,
		operatorId: state.user.operatorId,
		nav: state.user.nav,
		modalCounter: state.user.modalCounter
	};
};

const mapDispatchToProps = dispatch => {
	return {
		checkToken: () => dispatch(checkToken()),
		grantVoucher: data => dispatch(grantVoucher(data)),
		changeCounter: data => dispatch(changeCounter(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
