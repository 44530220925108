import { useEffect } from 'react';
import { connect } from 'react-redux';
import ClientLogin from './pages/Login';
import ClientDashboard from './pages/ClientDashboard';
import { checkToken, clientTokenLogin, saveClientRedirectUrl, saveClientUserType } from "../../redux/actions/clientActions";
import queryString from 'query-string';

const Client = ({ isAuthenticated, checkToken, location, tokenLogin, saveClientRedirectUrl, saveClientUserType }) => {

    useEffect(() => {
        const { token, redirectUrl, userType } = queryString.parse(location.search);
        if (redirectUrl) {
            saveClientRedirectUrl(redirectUrl)
        }
        if (userType) {
            saveClientUserType(userType)
        }
        if (token) {
            tokenLogin({ token });
        } else {
            checkToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {
                isAuthenticated
                    ? <ClientDashboard />
                    : <ClientLogin />
            }
        </div>
    );
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.client.isAuthenticated
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkToken: () => dispatch(checkToken()),
        tokenLogin: token => dispatch(clientTokenLogin(token)),
        saveClientRedirectUrl: url => dispatch(saveClientRedirectUrl(url)),
        saveClientUserType: data => dispatch(saveClientUserType(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Client);