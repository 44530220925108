import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Sidebar from "../utilities/Sidebar";
import NewsSlider from "../main/dashboard/NewsSlider";
import { changeNav } from "../../../redux/actions/userActions";
import { connect } from "react-redux";

const STORETYPE = process.env.REACT_APP_STORETYPE;

const News = ({ changeNav }) => {
  const [toggled, setToggled] = useState(false);
  const history = useHistory();

  const { t } = useTranslation();

  return (
    <div id={`${STORETYPE === "cleanpro" ? "support-page" : "news-page"}`}>
      <Sidebar toggled={toggled} setToggled={setToggled} />
      <div id="user-header">
        <h5>
          {STORETYPE === "cleanpro" ? (
            <strong onClick={(e) => changeNav("stamp")}>
              <i className="bi bi-chevron-left me-3"></i>
              {t("Support")}
            </strong>
          ) : (
            <>
              <i
                onClick={(e) => setToggled(true)}
                className="bi bi-list me-3"
              ></i>{" "}
              <strong>{t("Latest News")}</strong>
            </>
          )}
        </h5>
      </div>

      <div id="user-body">
        {STORETYPE !== "cleanpro" ? (
          <NewsSlider page="news" history={history} />
        ) : (
          <div className="px-3 py-2">
            <h1 className="font-weight-bold mt-2">{t("Need help?")}</h1>

            <h4 className="font-weight-bold mt-4">{t("24-Hour Careline Number")}</h4>
            <p>+603-2770-0100</p>
            <a
              id="support-call-now"
              className="mt-2 d-block"
              style={{ textDecoration: "none" }}
              href="tel:+603-2770-0100"
            >
              {t("Call Now")} <i className="bi bi-chevron-right me-3"></i>
            </a>
            <h4 className="font-weight-bold mt-4">{t("Email Us")}</h4>
            <p>info@cleanprolaundry.com</p>
            <a
              id="support-email-now"
              className="mt-2 d-block"
              style={{ textDecoration: "none" }}
              href="mailto:info@cleanprolaundry.com"
            >
              {t("Email Now")} <i className="bi bi-chevron-right me-3"></i>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeNav: (nav) => dispatch(changeNav(nav)),
  };
};

export default connect(null, mapDispatchToProps)(News);
