import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import { currency } from "../../../dynamicController";

import Sidebar from "../utilities/Sidebar";

import ticketBorder from "../../.././assets/images/backgrounds/Vector(11).png";
import { changeNav } from "../../../redux/actions/userActions";

const colorSets = [
  {
    headColor: "#c596c4",
    bodyColor: "linear-gradient(135deg , #c8bfde , #a1c3e9)",
  },
  {
    headColor: "#fbcc7d",
    bodyColor: "linear-gradient(135deg , #f6b997 , #f38695)",
  },
  {
    headColor: "#93c9ed",
    bodyColor: "linear-gradient(135deg , #6599d3 , #77cebb)",
  },
  {
    headColor: "#c7a1aa",
    bodyColor: "linear-gradient(135deg , #f2d0e0 , #c6a9d2)",
  },
  {
    headColor: "#b6da98",
    bodyColor: "linear-gradient(135deg , #cee6a9 , #dbde91)",
  },
];

let index = 0;

const STORETYPE = process.env.REACT_APP_STORETYPE;

const Coupon = ({ user, changeNav }) => {

  const [toggled, setToggled] = useState(false);
  const [past, setPast] = useState(false);

  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    index = 0;

    return function cleanup() {
      index = 0;
    };
  }, []);

  const renderCoupons = () => {
    if (user) {
      return user.vouchers
        .filter((voucher) => voucher.available)
        .map((voucher) => {
          const randomNumber =
            index; /*Math.floor(Math.random() * ((colorSets.length - 1) + 1));*/

          if (index === colorSets.length - 1) {
            index = 0;
          } else {
            index += 1;
          }

          let currentVoucherAmount = voucher.amount

          return (
            <>
              {STORETYPE === "cleanpro" ? (
                <div
                  style={{ borderRadius: "0" }}
                  className="voucher mb-3"
                  key={voucher.id}
                  onClick={() => voucher.from === "Register" ? history.push("/user/news/cleanproWelcome") : null}
                >
                  <img className="voucher-border" src={ticketBorder} alt="" />
                  <div
                    className="voucher-content d-flex"
                    style={{ color: "black", position: "relative", zIndex: "99" }}
                  >
                    <div
                      className="d-flex"
                      style={{ width: "70%", flexDirection: "column", justifyContent: "center", marginLeft: "1em" }}
                    >
                      <h2 className="font-weight-bold">
                        {voucher.type === "Flat" ? <Trans i18nKey="voucherCreditsOff" count={currentVoucherAmount}>{{ currentVoucherAmount }} Credits OFF</Trans> : <Trans i18nKey="voucherPercentageOff" count={currentVoucherAmount}>{{ currentVoucherAmount }}% OFF</Trans>}
                      </h2>

                      <small>{voucher.description}</small>

                      {voucher.expired_date ? (
                        <h6 style={{ color: "grey" }} className="m-0 p-0">
                          <small>{t("Valid Till")}: {new Date(voucher.expired_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</small>
                        </h6>
                      ) : null}

                    </div>
                    <div style={{ width: "30%" }} className="center-content">
                      <p className="text-center font-weight-bold blue-t">
                        {t("Available")}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="voucher mb-3" key={voucher.id}>
                  <div className="voucher-ball left-ball"></div>
                  <div
                    className="voucher-head"
                    style={{
                      backgroundColor: colorSets[randomNumber].headColor,
                    }}
                  >
                    <h4>{t("DISCOUNT")}</h4>
                  </div>

                  <div
                    className="voucher-content"
                    style={{ background: colorSets[randomNumber].bodyColor }}
                  >
                    <div>
                      <h1>
                        {voucher.type === "Flat" ? `${currency()}${voucher.amount}` : `${voucher.amount}%`}
                      </h1>
                    </div>

                    <div>
                      <h5>{voucher.title}</h5>
                      <h6 className="m-0 p-0">{voucher.description}</h6>
                      {voucher.expired_date ? (
                        <h6
                          style={{ color: "red", textAlign: "right" }}
                          className="m-0 p-0"
                        >
                          <small>
                            {t("Expired Date")}: {new Date(voucher.expired_date).getDate()}/{new Date(voucher.expired_date).getMonth() + 1}/{new Date(voucher.expired_date).getFullYear()}
                          </small>
                        </h6>
                      ) : null}
                    </div>
                  </div>
                  <div className="voucher-ball right-ball"></div>
                </div>
              )}
            </>
          );
        });
    }
  };

  const renderUnavailableCoupons = () => {
    if (user) {
      // console.log(user.vouchers);
      return user.vouchers
        .filter((voucher) => !voucher.available)
        .map((voucher) => {
          let currentVoucherAmount = voucher.amount
          return (
            <>
              {STORETYPE === "cleanpro" && (
                <>
                  <div
                    style={{ borderRadius: "0" }}
                    className="voucher mb-3"
                    key={voucher.id}
                  >
                    <img className="voucher-border" src={ticketBorder} alt="" />
                    <div
                      className="voucher-content d-flex"
                      style={{ color: "black", position: "relative", zIndex: "99" }}
                    >
                      <div
                        className="d-flex"
                        style={{ width: "70%", flexDirection: "column", justifyContent: "center", marginLeft: "1em" }}
                      >
                        <h2 className="font-weight-bold">
                          {voucher.type === "Flat" ? <Trans i18nKey="voucherCreditsOff" count={currentVoucherAmount}>{{ currentVoucherAmount }} Credits OFF</Trans> : <Trans i18nKey="voucherPercentageOff" count={currentVoucherAmount}>{{ currentVoucherAmount }}% OFF</Trans>}
                        </h2>

                        <small>{voucher.description}</small>

                        {voucher.status === "Used" ? (
                          <h6 className="m-0 p-0 t-grey">
                            <small>{t("Used On")}: {new Date(voucher.used_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</small>
                          </h6>
                        ) : (
                          <h6 className="m-0 p-0 t-grey">
                            <small>{t("Expired On")}: {new Date(voucher.expired_date).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</small>
                          </h6>
                        )}
                      </div>
                      <div style={{ width: "30%" }} className="center-content">
                        <p className="text-center font-weight-bold t-grey">
                          {t(voucher.status)}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          );
        });
    }
  };

  return (
    <div id="coupon-page">
      {STORETYPE === "cleanpro" ? (
        <div id="user-header">
          <h5>
            <strong onClick={(e) => changeNav("stamp")}>
              <i className="bi bi-chevron-left me-3"></i>
              {t("Rewards")}
            </strong>
          </h5>
        </div>
      ) : (
        <>
          <Sidebar toggled={toggled} setToggled={setToggled} />
          <div id="user-header">
            <h5>
              <i
                onClick={(e) => setToggled(true)}
                className="bi bi-list me-3"
              ></i>{" "}
              <strong>{t("Voucher")}</strong>
            </h5>
          </div>
        </>
      )}

      {STORETYPE === "cleanpro" ? (
        <>
          <div className="mt-3 px-2 d-flex justify-content-between">
            <div
              id="active-rewards"
              onClick={() => setPast(false)}
              style={{ width: "45%" }}
              className={`align-center no-wrap cursor-pointer ${!past ? "active-tab" : "inactive-tab"}`}
            >
              {t("Active Rewards")}
            </div>
            <div
              id="past-rewards"
              onClick={() => setPast(true)}
              style={{ width: "50%" }}
              className={`align-center no-wrap cursor-pointer ${past ? "active-tab" : "inactive-tab"}`}
            >
              {t("Past Rewards")}
            </div>
          </div>
          {!past ? (
            <div className="px-3 py-2">
              <h3 className="font-weight-bold">{t("Get rewarded for your loyalty with Cleanpro Plus Rewards!")}</h3>
              <p className="align-justify">{t("Earn vouchers and redeem them for discounts, freebies and more. The more you use our services the more you save!")}</p>
              <div id="coupon-body">{renderCoupons()}</div>
            </div>
          ) : (
            <div className="px-3 py-2">
              <div id="coupon-body">{renderUnavailableCoupons()}</div>
            </div>
          )}
        </>
      ) : (
        <div id="coupon-body">{renderCoupons()}</div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeNav: (nav) => dispatch(changeNav(nav)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Coupon);
