import { SCANNED_QRCODE, SAVE_CREDS, SAVE_PAYMENT, SAVE_TRANSACTION_ID } from "../actions/type";

const initState = {
	machine: null,
	outlet: null,
	transactionId: localStorage.getItem("transactionId")
};

const paymentReducer = (state = initState, action) => {
	switch (action.type) {
		case SCANNED_QRCODE:
			return {
				...state,
				machine: action.payload
			};
		case SAVE_CREDS:
			return {
				...state,
				outlet: action.payload
			};
		case SAVE_PAYMENT:
			return {
				...state,
				machine: {
					...state.machine,
					selectedDuration: action.payload
				}
			};
		case SAVE_TRANSACTION_ID:
			localStorage.setItem("transactionId", action.payload);
			return {
				...state,
				transactionId: action.payload
			};
		default:
			return state;
	}
};

export default paymentReducer;
