import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchOperators } from '../../../redux/actions/devActions';
import axios from 'axios';

class AddOperator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operatorId: "",
            email: "",
            username: "",
            password: "",
            outlets: [],
            loading: false,
            distributorId: "",
            outletId: "",
            rmStoreId: ""
        };
    };

    componentDidMount = () => this.props.dev.isAuthenticated && this.props.fetchOperators();

    handleSelectOperator = e => {
        const allOperators = this.props.dev.operatorData;
        const selectedOperator = allOperators.filter(operator => operator.operatorId === e.target.value)[0];

        this.setState({
            ...this.state,
            operatorId: e.target.value,
            email: selectedOperator.email,
            username: "",
            password: "",
            outlets: selectedOperator.outlets,
            distributorId: selectedOperator.franchisorId,
            rmStoreId: "",
            outletId: ""
        });
    };

    handleChange = e => {
        if (this.state.operatorId) {
            this.setState({
                ...this.state,
                [e.target.id]: e.target.value
            });
        }
    };

    handleSubmit = e => {
        e.preventDefault();

        const { operatorId, email, username, password, outlets, distributorId, outletId, rmStoreId } = this.state;
        if (operatorId && email && username && password && outlets && outletId && rmStoreId) {
            this.setState({
                ...this.state,
                loading: true
            });

            const sendThis = {
                operatorId,
                email,
                username,
                password,
                outlets,
                distributorId,
                outletId,
                rmStoreId
            };

            axios.post('/api/dev/registerAdmin', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.dev.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        operatorId: "",
                        email: "",
                        username: "",
                        password: "",
                        distributorId: "",
                        outlets: [],
                        loading: false,
                        outletId: "",
                        rmStoreId: ""
                    });
                    alert("Success");
                })
                .catch(err => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    alert(err.response.data.error);
                });
        }
    };

    render() {
        if (!this.props.dev.isLoading && this.props.dev.error) {
            return (
                <div className="text-center pt-5">
                    <h1>{this.props.dev.error}</h1>
                </div>
            )
        } else if (this.props.dev.isLoading || !this.props.dev.operatorData) {
            return (
                <div className="text-center pt-5">
                    <h1>Loading ...</h1>
                </div>
            )
        } else {
            return (
                <div className="pt-5 px-5">
                    <form className="mt-4" onSubmit={this.handleSubmit}>
                        <div className="text-center">
                            <h1>Add Operator</h1>
                        </div>
                        <div className="form-group mt-5">
                            <label htmlFor="operatorId">Operators</label>
                            <select name="operatorId" id="operatorId" className="browser-default form-select" value={this.state.operatorId} onChange={this.handleSelectOperator}>
                                <option value="" disabled>Select an operator</option>
                                {this.props.dev.operatorData.map(operator => <option key={operator.operatorId} data={operator} value={operator.operatorId}>{operator.userName} ({operator.operatorId})</option>)}
                            </select>
                        </div>

                        <hr />

                        <div>
                            <div className="form-row">
                                <div className="form-group col">
                                    <label htmlFor="email">Email:</label>
                                    <input type="email" name="email" id="email" className="browser-default form-control" value={this.state.email} readOnly required />
                                </div>

                                <div className="form-group col">
                                    <label htmlFor="outlets">Number of outlets:</label>
                                    <input type="number" name="outletsNumber" id="outletsNumber" className="browser-default form-control" value={this.state.outlets ? this.state.outlets.length : 0} readOnly required />
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="username">Username: (for admin login)</label>
                                <input type="text" name="username" id="username" className="browser-default form-control" value={this.state.username} onChange={this.handleChange} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="password">Password: (for admin login)</label>
                                <input type="text" name="password" id="password" className="browser-default form-control" value={this.state.password} onChange={this.handleChange} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="outletId">Token Outlet:</label>
                                <select name="outletId" id="outletId" className="browser-default form-select" value={this.state.outletId} onChange={this.handleChange} required>
                                    <option value="" disabled>Select an outlet</option>
                                    {this.state.outlets.map(outlet => <option key={outlet.outletId} data={outlet} value={outlet.outletId}>{outlet.outletName} ({outlet.outletId})</option>)}
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="rmStoreId">Token Store ID:</label>
                                <input type="number" name="rmStoreId" id="rmStoreId" className="browser-default form-control" value={this.state.rmStoreId} onChange={this.handleChange} required />
                            </div>
                        </div>

                        <div>
                            {
                                this.state.loading
                                    ? (
                                        <div className="text-center mt-5">
                                            <p>Loading ...</p>
                                        </div>
                                    )
                                    : <input type="submit" value="SUBMIT" className="btn btn-primary form-control font-weight-bold mt-5" />
                            }
                        </div>
                    </form>
                </div>
            );
        }
    };
};

const mapStateToProps = state => {
    return {
        dev: state.dev
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchOperators: () => dispatch(fetchOperators())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOperator);