import React, { Component } from "react";
import { connect } from "react-redux";
import Sidebar from "../components/Sidebar";
import Topbar from "../components/Topbar";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { Modal, Tooltip, OverlayTrigger } from 'react-bootstrap';

import { addPackage, delPackage, updatePaymentSetting, updateReloadVoucher } from "../../../redux/actions/adminActions";
import { currency } from "../../../dynamicController";

const mySwal = withReactContent(Swal);

const colorSets = [
	{
		headColor: "#c596c4",
		bodyColor: "linear-gradient(135deg , #c8bfde , #a1c3e9)"
	},
	{
		headColor: "#fbcc7d",
		bodyColor: "linear-gradient(135deg , #f6b997 , #f38695)"
	},
	{
		headColor: "#93c9ed",
		bodyColor: "linear-gradient(135deg , #6599d3 , #77cebb)"
	},
	{
		headColor: "#c7a1aa",
		bodyColor: "linear-gradient(135deg , #f2d0e0 , #c6a9d2)"
	},
	{
		headColor: "#b6da98",
		bodyColor: "linear-gradient(135deg , #cee6a9 , #dbde91)"
	}
];

const randomNumber = Math.floor(Math.random() * colorSets.length);
const selectedColor = colorSets[randomNumber];

class TokenSetting extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toggled: false,
			token: false,
			epayment: false,
			nonMemberPayment: false,
			non_member_promotion: true,
			loading: false,
			showModal: false,
			numberOfToken: 0,
			price: 0,
			voucher_eligible: false,
			voucher_amount: 3,
			voucher_type: "Flat",
			voucher_expiry: true,
			voucher_duration: 60,
			voucher_title: "E-TOKEN RELOAD SUCCESSFUL",
			voucher_description: "Your e-token have reloaded successfully!",
			voucher_washer_use: true,
			voucher_dryer_use: false,
			recurring: false,
			recurring_number: 1,
			recurring_period: 60,
			token_package_id: "",
			purchase_limit: false,
			max_purchase: 1,
			notification: false,
			sms_notification_amount: 0.1
		};
	}
	_isMounted = false;

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			if (!this.props.admin.isAuthenticated) {
				return this.props.history.push("/admin/dashboard");
			} else {
				this.setState({
					...this.state,
					token: this.props.admin.admin.token,
					epayment: this.props.admin.admin.epayment,
					nonMemberPayment: this.props.admin.admin.nonMemberPayment,
					non_member_promotion: this.props.admin.admin.non_member_promotion,
					notification: this.props.admin.admin.notification,
					sms_notification_amount: this.props.admin.admin.sms_notification_amount
				});
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleToggle = () => this.setState({ ...this.state, toggled: !this.state.toggled });

	handleNonMemberPayment = e => this.setState({
		...this.state,
		nonMemberPayment: !this.state.nonMemberPayment
	});

	handleChange = e => {
		this.setState({
			...this.state,
			[e.target.id]: e.target.value
		});
	};

	handleCheckbox = e => {
		this.setState({
			...this.state,
			[e.target.id]: !this.state[e.target.id]
		});
	};

	hideModal = () => {
		this.setState({
			...this.state,
			showModal: false,
			numberOfToken: 0,
			price: 0,
			voucher_eligible: false,
			voucher_amount: 3,
			voucher_type: "Flat",
			voucher_expiry: true,
			voucher_duration: 60,
			voucher_title: "E-TOKEN RELOAD SUCCESSFUL",
			voucher_description: "Your e-token have reloaded successfully!",
			voucher_washer_use: true,
			voucher_dryer_use: false,
			recurring: false,
			recurring_number: 1,
			recurring_period: 60,
			token_package_id: "",
			purchase_limit: false,
			max_purchase: 1
		})
	}

	handlePayment = e => {
		e.preventDefault();
		if (
			this.props.admin.admin.token !== this.state.token ||
			this.props.admin.admin.epayment !== this.state.epayment ||
			this.props.admin.admin.nonMemberPayment !== this.state.nonMemberPayment ||
			this.props.admin.admin.notification !== this.state.notification ||
			this.props.admin.admin.sms_notification_amount !== this.state.sms_notification_amount ||
			this.props.admin.admin.non_member_promotion !== this.state.non_member_promotion
		) {
			if (!this.state.token && !this.state.epayment) {
				mySwal.fire("Error", "Please choose at least one payment method", "error");
			} else {
				mySwal.fire({
					title: "Confirmation",
					text: "Are you sure you want to change the payment setting?",
					icon: "question",
					showCancelButton: true,
					cancelButtonText: "No",
					confirmButtonText: "Yes",
					cancelButtonColor: "#d33"
				})
					.then(click => {
						if (click.isConfirmed && click.value) {
							this.setState({
								...this.state,
								loading: true
							});

							const { token, epayment, nonMemberPayment, non_member_promotion, notification, sms_notification_amount } = this.state;

							const sendThis = {
								id: this.props.admin.admin.operatorId,
								role: "operator",
								token,
								epayment,
								nonMemberPayment,
								non_member_promotion,
								notification,
								sms_notification_amount
							};

							axios.post('/api/admin/setting/paymentSetting', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
								.then(res => {
									this.setState({
										...this.state,
										loading: false
									});
									let data = {
										token,
										epayment,
										nonMemberPayment,
										non_member_promotion,
										notification,
										sms_notification_amount
									}
									this.props.updatePaymentSetting(data);
									mySwal.fire("Success", "Your payment setting has updated successfully!", "success");
								})
								.catch(err => {
									this.setState({
										...this.state,
										loading: false
									});
									mySwal.fire("Error", err.response.data.error, "error");
								});
						}
					})
			}
		}
	}

	addPackage = e => {
		e.preventDefault();
		const {
			numberOfToken,
			price,
			voucher_eligible,
			voucher_amount,
			voucher_type,
			voucher_expiry,
			voucher_duration,
			voucher_title,
			voucher_description,
			voucher_washer_use,
			voucher_dryer_use,
			recurring,
			recurring_number,
			recurring_period,
			purchase_limit,
			max_purchase
		} = this.state

		if (parseFloat(numberOfToken) < 0 || parseFloat(price) < 0) {
			mySwal.fire("Invalid input", "The price and token must be more than 0", "info");
		} else if (voucher_eligible && !voucher_washer_use && !voucher_dryer_use) {
			mySwal.fire("Missing Field", "Must select voucher can be used for washer or dryer", "info");
		} else if (purchase_limit && parseInt(max_purchase) <= 0) {
			mySwal.fire("Invalid input", "Maximum number of purchase must be more than 1", "info");
		} else {
			this.setState({
				...this.state,
				loading: true
			})

			const sendThis = {
				id: this.props.admin.admin.operatorId,
				role: "operator",
				price,
				token: numberOfToken,
				voucher_eligible,
				voucher_amount,
				voucher_type,
				voucher_expiry,
				voucher_duration,
				voucher_title,
				voucher_description,
				voucher_washer_use,
				voucher_dryer_use,
				recurring,
				recurring_number,
				recurring_period,
				purchase_limit,
				max_purchase
			}

			axios
				.post("/api/admin/setting/addPackage", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
				.then(res => {
					this.props.addPackage(res.data);
					this.hideModal();
					this.setState({
						...this.state,
						loading: false
					})
					mySwal.fire("Success", "Added a new Token Bundle Package", "success")
				})
				.catch(err => mySwal.fire("Error", err.response.data.error, "error"));

		}
	};

	delPackage = id => {
		mySwal
			.fire({
				title: "Confirmation",
				text: "Delete this entry?",
				icon: "question",
				showCancelButton: true,
				cancelButtonText: "No",
				confirmButtonText: "Yes"
			})
			.then(click => {
				if (click.isConfirmed && click.value) {
					axios
						.delete("/api/admin/setting/delPackage", {
							headers: {
								"Content-Type": "application/json",
								"auth-token": this.props.admin.token
							},
							data: { id }
						})
						.then(res => {
							this.props.delPackage(id);
							mySwal.fire("Success", "Bundle deleted successfully", "success");
						})
						.catch(err => mySwal.fire("Error", err.response.data.error, "error"));
				}
			});
	};

	editPackage = id => {
		const selectedPackage = this.props.admin.admin.tokenPackages.filter(tokenPackage => tokenPackage.id === id)[0]
		this.setState({
			...this.state,
			showModal: true,
			numberOfToken: selectedPackage.token,
			price: selectedPackage.price,
			voucher_eligible: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.voucher_eligible : false,
			voucher_amount: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.voucher_amount : 3,
			voucher_type: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.voucher_type : "Flat",
			voucher_expiry: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.voucher_expiry : true,
			voucher_duration: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.voucher_duration : 60,
			voucher_title: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.voucher_title : "E-TOKEN RELOAD SUCCESSFUL",
			voucher_description: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.voucher_description : "Your e-token have reloaded successfully!",
			voucher_washer_use: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.voucher_washer_use : true,
			voucher_dryer_use: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.voucher_dryer_use : false,
			recurring: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.recurring : false,
			recurring_number: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.recurring_number : 1,
			recurring_period: selectedPackage.voucher_setting ? selectedPackage.voucher_setting.recurring_period : 60,
			purchase_limit: selectedPackage.purchase_limit,
			max_purchase: selectedPackage.max_purchase,
			token_package_id: id
		})
	}

	submitEditVoucher = e => {
		e.preventDefault();
		const {
			token_package_id,
			voucher_eligible,
			voucher_amount,
			voucher_type,
			voucher_expiry,
			voucher_duration,
			voucher_title,
			voucher_description,
			voucher_washer_use,
			voucher_dryer_use,
			recurring,
			recurring_number,
			recurring_period,
			purchase_limit,
			max_purchase
		} = this.state

		if (voucher_eligible && !voucher_washer_use && !voucher_dryer_use) {
			mySwal.fire("Missing Field", "Must select voucher can be used for washer or dryer", "info");
		} else if (purchase_limit && parseInt(max_purchase) <= 0) {
			mySwal.fire("Invalid input", "Maximum number of purchase must be more than 1", "info");
		} else {
			this.setState({
				...this.state,
				loading: true
			})

			const sendThis = {
				id: this.props.admin.admin.operatorId,
				role: "operator",
				token_package_id,
				voucher_eligible,
				voucher_amount,
				voucher_type,
				voucher_expiry,
				voucher_duration,
				voucher_title,
				voucher_description,
				voucher_washer_use,
				voucher_dryer_use,
				recurring,
				recurring_number,
				recurring_period,
				purchase_limit,
				max_purchase
			}

			axios
				.post("/api/admin/setting/editReloadVoucher", sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.admin.token } })
				.then(res => {
					this.props.updateReloadVoucher(res.data);
					this.hideModal();
					this.setState({
						...this.state,
						loading: false
					})
					mySwal.fire("Success", "Reload voucher setting have edited successfully", "success")
				})
				.catch(err => mySwal.fire("Error", err.response.data.error, "error"));
		}
	}

	render() {
		const renderList = () => {
			if (this.props.admin.admin.token) {
				if (this.props.admin && this.props.admin.admin && this.props.admin.admin.tokenPackages && this.props.admin.admin.tokenPackages.length) {
					return (
						<div>
							<ul className="list-group">
								{this.props.admin.admin.tokenPackages.map(pkg => {
									return (
										<li key={pkg.id} className="list-group-item px-5 py-4">
											<div>
												<div style={{ display: "flex", justifyContent: "space-between" }}>
													<h4 className="m-0 p-0">{currency()} {parseFloat(pkg.price).toFixed(2)}</h4>

													<h3 className="m-0 p-0">{pkg.token} e-Tokens</h3>
												</div>

												<div className="mt-3">
													<p className="m-0 p-0" style={{ fontSize: "12px" }}>
														Added on: {new Date(pkg.createdAt).toLocaleString()}{" "}
														<small className="ms-2">
															<strong>
																({" "}
																<span onClick={e => this.delPackage(pkg.id)} style={{ textDecoration: "underline", textDecorationStyle: "dotted", cursor: "pointer" }}>
																	remove
																</span>{" "}
																)
															</strong>
														</small>
														<small className="ms-2">
															<strong>
																({" "}
																<span onClick={e => this.editPackage(pkg.id)} style={{ textDecoration: "underline", textDecorationStyle: "dotted", cursor: "pointer" }}>
																	edit
																</span>{" "}
																)
															</strong>
														</small>
													</p>
												</div>
											</div>
										</li>
									);
								})}
							</ul>
						</div>
					);
				} else {
					return (
						<div className="text-center">
							<h1> - NO PACKAGES ON SALES - </h1>
						</div>
					);
				}
			} else return null;
		};

		return (
			<div className="admin-page-container">
				<Sidebar toggled={this.state.toggled} setToggled={this.handleToggle} />
				<div className="admin-page">
					<Topbar handleToggle={this.handleToggle} />
					<div className="admin-content">
						<div className="card">
							<div className="card-header">
								<h5 className="card-title">Payment Setting</h5>
							</div>

							<div className="card-body">
								<form onSubmit={this.handlePayment}>
									<p>Select Payment Method</p>
									<div className="d-flex">
										<div className="form-group">
											<label>
												<input type="checkbox" id="epayment" name="epayment" checked={this.state.epayment} onChange={this.handleCheckbox} />
												<span>Enable E-Payment?</span>
											</label>
										</div>

										<div className="form-group ms-5">
											<label>
												<input type="checkbox" id="token" name="token" checked={this.state.token} onChange={this.handleCheckbox} />
												<span>Enable Token Currency?</span>
											</label>
										</div>
									</div>

									{
										this.state.epayment ? (
											<div className="form-group mt-3">
												<p>E-payment for?</p>

												<p>
													<label htmlFor="radio2">
														<input type="radio" name="nonMemberPayment" id="radio2" className="with-gap" checked={this.state.nonMemberPayment === true} onChange={this.handleNonMemberPayment} />
														<span>Member & Non-Member Payment</span>
													</label>
												</p>

												<p>
													<label htmlFor="radio1">
														<input type="radio" name="nonMemberPayment" id="radio1" className="with-gap" checked={this.state.nonMemberPayment === false} onChange={this.handleNonMemberPayment} />
														<span>Only Member Payment</span>
													</label>
												</p>

												<p><small>* {this.state.nonMemberPayment ? "User can skip to payment without login to make the payment" : "User have to login only can make the payment"}</small></p>
											</div>
										) : null
									}

									{
										this.state.epayment && this.state.nonMemberPayment ? (
											<div className="form-group mt-5">
												<p>Promotion Setting</p>
												<label>
													<input type="checkbox" id="non_member_promotion" name="non_member_promotion" checked={this.state.non_member_promotion} onChange={this.handleCheckbox} />
													<span>Enable Non Member Promotion?</span>
												</label>
											</div>
										) : null
									}

									<div className="form-group mt-5">
										<p>Notification</p>
										<label>
											<input type="checkbox" id="notification" name="notification" checked={this.state.notification} onChange={this.handleCheckbox} />
											<span>Enable Notification When Cycle Done?</span>
										</label>
									</div>

									{
										this.state.notification ? (
											<div className="form-group">
												<label htmlFor="sms_notification_amount">SMS charges: </label>
												<input type="number" id="sms_notification_amount" name="sms_notification_amount" value={this.state.sms_notification_amount} onChange={this.handleChange} min="0" step="0.01" required />
											</div>
										) : null
									}

									<div>
										<p><small>* If enable notification, user can choose either receive email or sms when complete washing or drying</small></p>
										<p><small>* If user choose sms, user have to pay extra {currency()}{parseFloat(this.state.sms_notification_amount).toFixed(2)} for the sms</small></p>
									</div>

									<div>
										{
											this.state.loading
												? (
													<div className="text-center">
														<div className={`spinner-border text-${process.env.REACT_APP_STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
															<span className="sr-only">Loading...</span>
														</div>
													</div>
												)
												: <input type="submit" value="SUBMIT" className="btn btn-block btn-warning big-button" />
										}
									</div>
								</form>
							</div>
						</div>

						{this.props.admin.admin && this.props.admin.admin.token ? (
							<div className="card">
								<div className="card-header d-flex justify-content-between">
									<h5 className="card-title">Token Setting</h5>

									<div>
										<button type="button" className="btn btn-primary" onClick={e => this.setState({ ...this.state, showModal: true })}>
											Add Package
										</button>
									</div>
								</div>

								<div className="card-body">{renderList()}</div>
							</div>
						) : null}
					</div>
				</div>
				<Modal
					show={this.state.showModal}
					onHide={e => this.hideModal()}
					keyboard={false}
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title>{this.state.token_package_id ? "Edit e-Token Package" : "Add e-Token Package"}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							<form onSubmit={this.state.token_package_id ? this.submitEditVoucher : this.addPackage}>
								<p><small><strong>* Please only input numberic value to prevent any bugs.</strong></small></p>
								{
									this.state.token_package_id ? (
										<div>
											<div className="form-group text-left">
												<label htmlFor="price">Price ({currency()})</label>
												<input type="number" id="price" name="price" value={this.state.price} className="form-control browser-default" min="1" step="0.01" required readOnly />
											</div>

											<div className="form-group text-left">
												<label htmlFor="numberOfToken">Number of e-Token</label>
												<input type="number" id="numberOfToken" name="numberOfToken" value={this.state.numberOfToken} className="form-control browser-default" min="1" required readOnly />
											</div>
										</div>
									) : (
										<div>
											<div className="form-group text-left">
												<label htmlFor="price">Price ({currency()})</label>
												<input type="number" id="price" name="price" value={this.state.price} className="form-control browser-default" min="1" step="0.01" onChange={this.handleChange} required />
											</div>

											<div className="form-group text-left">
												<label htmlFor="numberOfToken">Number of e-Token</label>
												<input type="number" id="numberOfToken" name="numberOfToken" value={this.state.numberOfToken} className="form-control browser-default" min="1" onChange={this.handleChange} required />
											</div>
										</div>
									)
								}

								<div className="form-group">
									<label htmlFor="purchase_limit">
										<input type="checkbox" id="purchase_limit" name="purchase_limit" checked={this.state.purchase_limit} onChange={this.handleCheckbox} />
										<OverlayTrigger
											placement="top"
											overlay={
												<Tooltip>
													If ticked, the system will limit the number of purchase
												</Tooltip>
											}
										>
											<span>Limit Number of Purchase</span>
										</OverlayTrigger>
									</label>
								</div>

								{
									this.state.purchase_limit ? (
										<div className="form-group text-left">
											<label htmlFor="max_purchase">Number of maximum purchase</label>
											<input type="number" id="max_purchase" name="max_purchase" value={this.state.max_purchase} className="form-control browser-default" min="1" step="1" onChange={this.handleChange} required />
										</div>
									) : null
								}

								<div className="card">
									<div className="card-header">
										<h6 className="m-0 p-0">Reload Voucher</h6>
									</div>

									<div className="card-body">
										<div>
											<div>
												{/* voucher preview */}
												<div className="voucher mb-5">
													<div className="voucher-ball left-ball"></div>
													<div className="voucher-head p-0 m-0" style={{ backgroundColor: selectedColor.headColor }}>
														<h4 style={{ width: "140px", height: "140px", padding: "18px 0 0 0" }}>DISCOUNT</h4>
													</div>

													<div className="voucher-content" style={{ background: selectedColor.bodyColor }}>
														<div>
															<h1>{this.state.voucher_type === "Flat" ? (`${currency()}${this.state.voucher_amount}`) : (`${this.state.voucher_amount}%`)}</h1>
														</div>

														<div>
															<h5>{this.state.voucher_title}</h5>
															<h6>{this.state.voucher_description}</h6>
														</div>
													</div>
													<div className="voucher-ball right-ball"></div>
												</div>
											</div>

											<div className="text-center">
												{/* checkboxes */}
												<div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
													<div className="form-group">
														<label htmlFor="voucher_eligible">
															<input type="checkbox" id="voucher_eligible" name="voucher_eligible" checked={this.state.voucher_eligible} onChange={this.handleCheckbox} />
															<OverlayTrigger
																placement="top"
																overlay={
																	<Tooltip>
																		If ticked, the system will give voucher to user when user reload token successfully
																	</Tooltip>
																}
															>
																<span>Eligibility</span>
															</OverlayTrigger>
														</label>
													</div>

													<div className="form-group">
														<label htmlFor="voucher_expiry">
															<input type="checkbox" id="voucher_expiry" name="voucher_expiry" checked={this.state.voucher_expiry} onChange={this.handleCheckbox} />
															<OverlayTrigger
																placement="top"
																overlay={
																	<Tooltip>
																		If ticked, the voucher will have expired date
																	</Tooltip>
																}
															>
																<span>Expiry</span>
															</OverlayTrigger>
														</label>
													</div>

													<div className="form-group">
														<label htmlFor="recurring">
															<input type="checkbox" id="recurring" name="recurring" checked={this.state.recurring} onChange={this.handleCheckbox} />
															<OverlayTrigger
																placement="top"
																overlay={
																	<Tooltip>
																		If ticked, the system will give voucher repeatly
																	</Tooltip>
																}
															>
																<span>Recurring</span>
															</OverlayTrigger>
														</label>
													</div>
												</div>

												<div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
													<div className="form-group">
														<label>
															<input type="checkbox" id="voucher_washer_use" name="voucher_washer_use" checked={this.state.voucher_washer_use} onChange={this.handleCheckbox} />
															<OverlayTrigger
																placement="top"
																overlay={
																	<Tooltip>
																		If ticked, the voucher can apply to washer machine transaction
																	</Tooltip>
																}
															>
																<span>Washer Use</span>
															</OverlayTrigger>
														</label>
													</div>

													<div className="form-group">
														<label>
															<input type="checkbox" id="voucher_dryer_use" name="voucher_dryer_use" checked={this.state.voucher_dryer_use} onChange={this.handleCheckbox} />
															<OverlayTrigger
																placement="top"
																overlay={
																	<Tooltip>
																		If ticked, the voucher can apply to dryer machine transaction
																	</Tooltip>
																}
															>
																<span>Dryer Use</span>
															</OverlayTrigger>
														</label>
													</div>
												</div>
											</div>

											<div>
												{/* text and select */}
												{
													this.state.voucher_expiry
														? (
															<div className="form-group">
																<label htmlFor="voucher_duration">Voucher Lifespan : <small> (days)</small></label>
																<input type="number" id="voucher_duration" name="voucher_duration" value={this.state.voucher_duration} onChange={this.handleChange} min="1" step="1" required />
															</div>
														)
														: (
															null
														)
												}

												{
													this.state.recurring ? (
														<div>
															<div className="form-group">
																<label htmlFor="recurring_number">Total number of voucher given to user : </label>
																<input type="number" id="recurring_number" name="recurring_number" value={this.state.recurring_number} onChange={this.handleChange} min="1" step="1" required />
															</div>

															<div className="form-group">
																<label htmlFor="recurring_period">Period for giving next voucher : <small> (days)</small></label>
																<input type="number" id="recurring_period" name="recurring_period" value={this.state.recurring_period} onChange={this.handleChange} min="1" step="1" required />
															</div>
														</div>
													) : null
												}

												<div className="form-group">
													<label htmlFor="voucher_type">Discount Type : </label>
													<select className="browser-default form-select" id="voucher_type" name="voucher_type" value={this.state.voucher_type} onChange={this.handleChange} required>
														<option value="" disabled>Select a discount type</option>
														<option value="Flat">Flat (- {currency()})</option>
														<option value="Rate">Rate (- %)</option>
													</select>
												</div>

												<div className="form-group">
													<label htmlFor="voucher_title">Voucher Title : </label>
													<input type="text" id="voucher_title" name="voucher_title" placeholder="Voucher Title" value={this.state.voucher_title} onChange={this.handleChange} required />
												</div>

												<div className="form-group">
													<label htmlFor="voucher_description">Voucher Description : </label>
													<input type="text" id="voucher_description" name="voucher_description" placeholder="Voucher Description" value={this.state.voucher_description} onChange={this.handleChange} required />
												</div>

												<div className="form-group">
													<label htmlFor="voucher_amount">Voucher Amount : <small>{this.state.voucher_type === "Flat" ? "(RM)" : "(%)"}</small></label>
													<input type="number" id="voucher_amount" name="voucher_amount" value={this.state.voucher_amount} onChange={this.handleChange} min="0" step="0.01" required />
												</div>
											</div>
										</div>
									</div>
								</div>

								<p className="p-0 m-0"><small>* Token package <b>{currency()}{this.state.price}</b> for <b>{this.state.numberOfToken} tokens</b></small></p>
								{this.state.purchase_limit ? <p className="p-0 m-0"><small>* Each user only can purchase {this.state.max_purchase} times for the token package</small></p> : null}
								{this.state.voucher_eligible ? <p className="p-0 m-0"><small>* <b>Voucher will be given</b> after user reload token successfully</small></p> : <p className="p-0 m-0"><small>* <b>Voucher will not given</b> after user reload tokens successfully</small></p>}
								{this.state.voucher_eligible && this.state.voucher_expiry ? <p className="p-0 m-0"><small>* The voucher will be <b>expired after {this.state.voucher_duration} days</b></small></p> : null}
								{this.state.voucher_eligible && !this.state.voucher_expiry ? <p className="p-0 m-0"><small>* The voucher will <b>not expired</b></small></p> : null}
								{this.state.voucher_eligible && this.state.voucher_washer_use && this.state.voucher_dryer_use ? <p className="p-0 m-0"><small>* The voucher can be used for <b>washer or dryer machine</b></small></p> : null}
								{this.state.voucher_eligible && !this.state.voucher_washer_use && this.state.voucher_dryer_use ? <p className="p-0 m-0"><small>* The voucher only can be used for <b>dryer machine</b></small></p> : null}
								{this.state.voucher_eligible && this.state.voucher_washer_use && !this.state.voucher_dryer_use ? <p className="p-0 m-0"><small>* The voucher only can be used for <b>washer machine</b></small></p> : null}
								{this.state.voucher_eligible && this.state.recurring ? <p className="p-0 m-0"><small>* Total <b>{this.state.recurring_number} vouchers will be given</b> to user and each voucher will discount {this.state.voucher_type === "Flat" ? `${currency()}${this.state.voucher_amount}` : `${this.state.voucher_amount}%`}</small></p> : null}

								<div className="text-center pt-3">
									{this.state.loading ? (
										<div className="text-center">
											<div className={`spinner-border text-${process.env.REACT_APP_STORETYPE === "cuci" ? "warning" : "primary"}`} role="status">
												<span className="sr-only">Loading...</span>
											</div>
										</div>
									) : <input type="submit" value={this.state.token_package_id ? "Edit Package" : "Add Package"} className="btn btn-primary mx-2" />}
									<button type="button" className="btn btn-secondary" onClick={e => this.hideModal()}>
										Close
									</button>
								</div>
							</form>
						</div>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		admin: state.admin
	};
};

const mapDispatchToProps = dispatch => {
	return {
		addPackage: data => dispatch(addPackage(data)),
		delPackage: data => dispatch(delPackage(data)),
		updatePaymentSetting: data => dispatch(updatePaymentSetting(data)),
		updateReloadVoucher: data => dispatch(updateReloadVoucher(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenSetting);
