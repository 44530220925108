import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import navStamp from "../../../assets/images/navIcons/navStamp.png";
import navCoupon from "../../../assets/images/navIcons/navCoupon.png";
import navTransaction from "../../../assets/images/navIcons/navTransaction.png";
import navNews from "../../../assets/images/navIcons/navNews.png";

// cleanpro plus nav
import navHome from "../../../assets/images/navIcons/icn-home.png";
import navReward from "../../../assets/images/navIcons/icn-rewards.png";
import navInbox from "../../../assets/images/navIcons/icn-inbox.png";
import navSupport from "../../../assets/images/navIcons/icn-support.png";
import navScan from "../../../assets/images/navIcons/icn-scan-white.png";

import {
  userNavigatorClassNames,
  navCameraClassName,
} from "../../../dynamicController";
import { changeNav } from "../../../redux/actions/userActions";
import { useHistory } from "react-router";

const STORETYPE = process.env.REACT_APP_STORETYPE;

const Navigator = ({ nav, changeNav, user }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div id="navigator">
      <div className="nav-separator">
        <div
          id="stamp"
          onClick={(e) => { changeNav("stamp"); history.push('/') }}
          className={userNavigatorClassNames(nav, "stamp")}
        >
          <img src={STORETYPE === "cleanpro" ? navHome : navStamp} alt="navStamp" className="img-fluid" />
          <p>{STORETYPE === "cleanpro" ? t("Home") : user.stamp ? t("Stamping") : t("Home")}</p>
        </div>

        <div
          id="coupon"
          onClick={(e) => { changeNav("coupon"); history.push('/') }}
          className={userNavigatorClassNames(nav, "coupon")}
        >
          <img src={STORETYPE === "cleanpro" ? navReward : navCoupon} alt="navCoupon" className="img-fluid" />
          <p>{STORETYPE === "cleanpro" ? t("Rewards") : t("Voucher")}</p>
        </div>
      </div>
      {
        STORETYPE === "cleanpro" ? (
          <div className="camera nav-separator mb-1">
            <div
              style={{ display: "grid", placeItems: "center" }}
              onClick={(e) => { changeNav("camera"); history.push('/') }}
              id="nav-camera"
              className={navCameraClassName}
            >
              <img
                style={{ width: "50%" }}
                src={navScan}
                alt="navCoupon"
                className="img-fluid fit-contain"
              />
            </div>
          </div>
        ) : (
          <div id="nav-camera-container" className="camera">
            <div
              onClick={(e) => changeNav("camera")}
              id="nav-camera"
              className={navCameraClassName}
            >
              <i className="fas bi-camera-fill"></i>
            </div>
          </div>
        )
      }
      <div className="nav-separator">
        <div
          id="transaction"
          onClick={(e) => { changeNav("transaction"); history.push('/') }}
          className={userNavigatorClassNames(nav, "transaction")}
        >
          <img
            src={STORETYPE === "cleanpro" ? navInbox : navTransaction}
            alt="navTransaction"
            className="img-fluid"
          />
          <p>{STORETYPE === "cleanpro" ? t("Inbox") : t("Transaction")}</p>
        </div>

        <div
          id="news"
          onClick={(e) => { changeNav("news"); history.push('/') }}
          className={userNavigatorClassNames(nav, "news")}
        >
          <img src={STORETYPE === "cleanpro" ? navSupport : navNews} alt="navNews" className="img-fluid" />
          <p>{STORETYPE === "cleanpro" ? t("Support") : t("News")}</p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    nav: state.user.nav,
    user: state.user.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeNav: (nav) => dispatch(changeNav(nav)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigator);
