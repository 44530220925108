import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MDBDataTable } from "mdbreact";

class TopOutlet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: "Transactions"
        };
    };

    handleChange = e => {
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        });
    };

    render() {

        let data = {
            columns: [
                {
                    label: "Top Outlets",
                    field: "topOutlets",
                    sort: "asc"
                },
                {
                    label: "Users",
                    field: "users",
                    sort: "asc"
                },
                {
                    label: "Transactions",
                    field: "transactions",
                    sort: "asc"
                }
            ],
            rows: []
        }

        if (this.state.filter === "Users") {

            data.rows = this.props.topOutletData.sortByUsers;

        } else if (this.state.filter === "Transactions") {

            data.rows = this.props.topOutletData.sortByTransactions;
        }

        return (
            <div>
                <div>
                    <h6 className="text-center">Top Outlets</h6>
                    <div>
                        <label htmlFor="filter">Sort by: </label>
                        <select id="filter" name="filter" className="browser-default form-select" value={this.state.filter} onChange={this.handleChange}>
                            <option value="Transactions">Transactions</option>
                            <option value="Users">Users</option>
                        </select>
                    </div>
                </div>

                <div className="text-center mt-3"><MDBDataTable data={data} paging={false} searching={false} sortable={false} noBottomColumns striped hover responsive bordered /></div>

            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        outlets: state.admin.admin.outlets
    };
};

export default connect(mapStateToProps, null)(TopOutlet);