import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { currency } from '../../../dynamicController';

const colorSets = [
    {
        headColor: "#c596c4",
        bodyColor: "linear-gradient(135deg , #c8bfde , #a1c3e9)"
    },
    {
        headColor: "#fbcc7d",
        bodyColor: "linear-gradient(135deg , #f6b997 , #f38695)"
    },
    {
        headColor: "#93c9ed",
        bodyColor: "linear-gradient(135deg , #6599d3 , #77cebb)"
    },
    {
        headColor: "#c7a1aa",
        bodyColor: "linear-gradient(135deg , #f2d0e0 , #c6a9d2)"
    },
    {
        headColor: "#b6da98",
        bodyColor: "linear-gradient(135deg , #cee6a9 , #dbde91)"
    }
];

const randomNumber = Math.floor(Math.random() * colorSets.length);
const selectedColor = colorSets[randomNumber];


const SettingForm = ({ selected, washerSetting, dryerSetting, combinedSetting, handleCheckbox, handleChange, handleSubmit, combined, payAndGetRegVoucher, regVoucherAmount, reg_voucher_method, handlePayAndGetRegVoucher, handleRegVoucherAmount, handleRegVoucherMethod, payAndGetBirthdayVoucher, birthdayVoucherAmount, handlePayAndGetBirthdayVoucher, handleBirthdayVoucherAmount }) => {
    if (selected === "Stamp") {
        // stamp, got combined view and separated view
        if (combined) {
            // stamp combined setting
            return (
                <div>
                    <div>
                        {/* stamp voucher preview */}
                        <div className="voucher mb-5">
                            <div className="voucher-ball left-ball"></div>
                            <div className="voucher-head p-0 m-0" style={{ backgroundColor: selectedColor.headColor }}>
                                <h4 style={{ width: "140px", height: "140px", padding: "18px 0 0 0" }}>DISCOUNT</h4>
                            </div>

                            <div className="voucher-content" style={{ background: selectedColor.bodyColor }}>
                                <div>
                                    <h1>{combinedSetting.voucher_type === "Flat" ? (`${currency()}${combinedSetting.voucher_amount}`) : (`${combinedSetting.voucher_amount}%`)}</h1>
                                </div>

                                <div>
                                    <h5>{combinedSetting.voucher_title}</h5>
                                    <h6>{combinedSetting.voucher_description}</h6>
                                </div>
                            </div>
                            <div className="voucher-ball right-ball"></div>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit} id="setting-form">
                        <div className="text-center">
                            {/* checkboxes */}
                            <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                <div className="form-group">
                                    <label htmlFor="voucher_eligible">
                                        <input type="checkbox" id="voucher_eligible" name="voucher_eligible" checked={combinedSetting.voucher_eligible} onChange={e => handleCheckbox("combinedSetting", e.target)} />
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    If ticked, the system will give voucher to user who completed stamp collection
                                                </Tooltip>
                                            }
                                        >
                                            <span>Eligibility</span>
                                        </OverlayTrigger>
                                    </label>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="voucher_expiry">
                                        <input type="checkbox" id="voucher_expiry" name="voucher_expiry" checked={combinedSetting.voucher_expiry} onChange={e => handleCheckbox("combinedSetting", e.target)} />
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    If ticked, the voucher will have expired date
                                                </Tooltip>
                                            }
                                        >
                                            <span>Expiry</span>
                                        </OverlayTrigger>
                                    </label>
                                </div>
                            </div>

                            <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                <div className="form-group">
                                    <label>
                                        <input type="checkbox" id="voucher_washer_use" name="voucher_washer_use" checked={combinedSetting.voucher_washer_use} onChange={e => handleCheckbox("combinedSetting", e.target)} />
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    If ticked, the voucher can apply to washer machine transaction
                                                </Tooltip>
                                            }
                                        >
                                            <span>Washer Use</span>
                                        </OverlayTrigger>
                                    </label>
                                </div>

                                <div className="form-group">
                                    <label>
                                        <input type="checkbox" id="voucher_dryer_use" name="voucher_dryer_use" checked={combinedSetting.voucher_dryer_use} onChange={e => handleCheckbox("combinedSetting", e.target)} />
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>
                                                    If ticked, the voucher can apply to dryer machine transaction
                                                </Tooltip>
                                            }
                                        >
                                            <span>Dryer Use</span>
                                        </OverlayTrigger>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div>
                            {/* text and select */}
                            {
                                combinedSetting.voucher_expiry
                                    ? (
                                        <div className="form-group">
                                            <label htmlFor="voucher_duration">Voucher Lifespan : <small> (days)</small></label>
                                            <input type="number" id="voucher_duration" name="voucher_duration" value={combinedSetting.voucher_duration} onChange={e => handleChange("combinedSetting", e.target)} required min="0" />
                                        </div>
                                    )
                                    : (
                                        null
                                    )
                            }

                            <div className="form-group">
                                <label htmlFor="voucher_type">Discount Type : </label>
                                <select className="browser-default form-select" id="voucher_type" name="voucher_type" value={combinedSetting.voucher_type} onChange={e => handleChange("combinedSetting", e.target)} required>
                                    <option value="" disabled>Select a discount type</option>
                                    <option value="Flat">Flat (- {currency()})</option>
                                    <option value="Rate">Rate (- %)</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="voucher_title">Voucher Title : </label>
                                <input type="text" id="voucher_title" name="voucher_title" placeholder="Voucher Title" value={combinedSetting.voucher_title} onChange={e => handleChange("combinedSetting", e.target)} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="voucher_description">Voucher Description : </label>
                                <input type="text" id="voucher_description" name="voucher_description" placeholder="Voucher Description" value={combinedSetting.voucher_description} onChange={e => handleChange("combinedSetting", e.target)} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="voucher_amount">Voucher Amount : <small>{combinedSetting.voucher_type === "Flat" ? `(${currency()})` : "(%)"}</small></label>
                                <input type="number" id="voucher_amount" name="voucher_amount" value={combinedSetting.voucher_amount} onChange={e => handleChange("combinedSetting", e.target)} required min="0" />
                            </div>
                        </div>

                        <div className="my-4">
                            <input type="submit" value="CONFIRM" className="btn big-button form-control" />
                        </div>
                    </form>
                </div>
            )
        } else {
            // separated stamp setting
            return (
                <div>
                    <form onSubmit={handleSubmit}>
                        <div className="d-flex justify-content-around">
                            {/* washer */}
                            <div>
                                {/* washer voucher preview */}
                                <h3>Washer</h3>
                                <div className="voucher mb-5">
                                    <div className="voucher-ball left-ball"></div>
                                    <div className="voucher-head p-0 m-0" style={{ backgroundColor: selectedColor.headColor }}>
                                        <h4 style={{ width: "140px", height: "140px", padding: "18px 0 0 0" }}>DISCOUNT</h4>
                                    </div>

                                    <div className="voucher-content" style={{ background: selectedColor.bodyColor }}>
                                        <div>
                                            <h1>{washerSetting.voucher_type === "Flat" ? (`${currency()}${washerSetting.voucher_amount}`) : (`${washerSetting.voucher_amount}%`)}</h1>
                                        </div>

                                        <div>
                                            <h5>{washerSetting.voucher_title}</h5>
                                            <h6>{washerSetting.voucher_description}</h6>
                                        </div>
                                    </div>
                                    <div className="voucher-ball right-ball"></div>
                                </div>
                                {/* washer form */}
                                <div>
                                    <div>
                                        <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                            <div className="form-group">
                                                <label>
                                                    <input type="checkbox" id="voucher_eligible" name="voucher_eligible" checked={washerSetting.voucher_eligible} onChange={e => handleCheckbox("washerSetting", e.target)} />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                If ticked, the system will give voucher to user who completed washer stamp collection
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span>Eligibility</span>
                                                    </OverlayTrigger>
                                                </label>
                                            </div>

                                            <div className="form-group">
                                                <label>
                                                    <input type="checkbox" id="voucher_expiry" name="voucher_expiry" checked={washerSetting.voucher_expiry} onChange={e => handleCheckbox("washerSetting", e.target)} />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                If ticked, the voucher will have expired date
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span>Expiry</span>
                                                    </OverlayTrigger>
                                                </label>
                                            </div>
                                        </div>

                                        <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                            <div className="form-group">
                                                <label>
                                                    <input type="checkbox" id="voucher_washer_use" name="voucher_washer_use" checked={washerSetting.voucher_washer_use} onChange={e => handleCheckbox("washerSetting", e.target)} />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                If ticked, the voucher can apply to washer machine transaction
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span>Washer Use</span>
                                                    </OverlayTrigger>
                                                </label>
                                            </div>

                                            <div className="form-group">
                                                <label>
                                                    <input type="checkbox" id="voucher_dryer_use" name="voucher_dryer_use" checked={washerSetting.voucher_dryer_use} onChange={e => handleCheckbox("washerSetting", e.target)} />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                If ticked, the voucher can apply to dryer machine transaction
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span>Dryer Use</span>
                                                    </OverlayTrigger>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        {
                                            washerSetting.voucher_expiry
                                                ? (
                                                    <div className="form-group">
                                                        <label htmlFor="voucher_duration">Voucher Lifespan : <small> (days)</small></label>
                                                        <input type="number" id="voucher_duration" name="voucher_duration" value={washerSetting.voucher_duration} onChange={e => handleChange("washerSetting", e.target)} required min="0" />
                                                    </div>
                                                )
                                                : (
                                                    null
                                                )
                                        }

                                        <div className="form-group">
                                            <label htmlFor="voucher_type">Discount Type : </label>
                                            <select className="browser-default form-select" id="voucher_type" name="voucher_type" value={washerSetting.voucher_type} onChange={e => handleChange("washerSetting", e.target)} required>
                                                <option value="" disabled>Select a discount type</option>
                                                <option value="Flat">Flat (- {currency()})</option>
                                                <option value="Rate">Rate (- %)</option>
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="voucher_title">Voucher Title : </label>
                                            <input type="text" id="voucher_title" name="voucher_title" placeholder="Voucher Title" value={washerSetting.voucher_title} onChange={e => handleChange("washerSetting", e.target)} required />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="voucher_description">Voucher Description : </label>
                                            <input type="text" id="voucher_description" name="voucher_description" placeholder="Voucher Description" value={washerSetting.voucher_description} onChange={e => handleChange("washerSetting", e.target)} required />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="voucher_amount">Voucher Amount : <small>{washerSetting.voucher_type === "Flat" ? `(${currency()})` : "(%)"}</small></label>
                                            <input type="number" id="voucher_amount" name="voucher_amount" value={washerSetting.voucher_amount} onChange={e => handleChange("washerSetting", e.target)} required min="0" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* dryer */}
                            <div>
                                <h3>Dryer</h3>
                                {/* dryer voucher preview */}
                                <div className="voucher mb-5">
                                    <div className="voucher-ball left-ball"></div>
                                    <div className="voucher-head p-0 m-0" style={{ backgroundColor: selectedColor.headColor }}>
                                        <h4 style={{ width: "140px", height: "140px", padding: "18px 0 0 0" }}>DISCOUNT</h4>
                                    </div>

                                    <div className="voucher-content" style={{ background: selectedColor.bodyColor }}>
                                        <div>
                                            <h1>{dryerSetting.voucher_type === "Flat" ? (`${currency()}${dryerSetting.voucher_amount}`) : (`${dryerSetting.voucher_amount}%`)}</h1>
                                        </div>

                                        <div>
                                            <h5>{dryerSetting.voucher_title}</h5>
                                            <h6>{dryerSetting.voucher_description}</h6>
                                        </div>
                                    </div>
                                    <div className="voucher-ball right-ball"></div>
                                </div>
                                {/* dryer form */}
                                <div>
                                    <div>
                                        <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                            <div className="form-group">
                                                <label>
                                                    <input type="checkbox" id="voucher_eligible" name="voucher_eligible" checked={dryerSetting.voucher_eligible} onChange={e => handleCheckbox("dryerSetting", e.target)} />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                If ticked, the system will give voucher to user who completed dryer stamp collection
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span>Eligibility</span>
                                                    </OverlayTrigger>
                                                </label>
                                            </div>

                                            <div className="form-group">
                                                <label>
                                                    <input type="checkbox" id="voucher_expiry" name="voucher_expiry" checked={dryerSetting.voucher_expiry} onChange={e => handleCheckbox("dryerSetting", e.target)} />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                If ticked, the voucher will have expired date
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span>Expiry</span>
                                                    </OverlayTrigger>
                                                </label>
                                            </div>
                                        </div>

                                        <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                                            <div className="form-group">
                                                <label>
                                                    <input type="checkbox" id="voucher_washer_use" name="voucher_washer_use" checked={dryerSetting.voucher_washer_use} onChange={e => handleCheckbox("dryerSetting", e.target)} />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                If ticked, the voucher can apply to washer machine transaction
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span>Washer Use</span>
                                                    </OverlayTrigger>
                                                </label>
                                            </div>

                                            <div className="form-group">
                                                <label>
                                                    <input type="checkbox" id="voucher_dryer_use" name="voucher_dryer_use" checked={dryerSetting.voucher_dryer_use} onChange={e => handleCheckbox("dryerSetting", e.target)} />
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip>
                                                                If ticked, the voucher can apply to dryer machine transaction
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span>Dryer Use</span>
                                                    </OverlayTrigger>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        {
                                            dryerSetting.voucher_expiry
                                                ? (
                                                    <div className="form-group">
                                                        <label htmlFor="voucher_duration">Voucher Lifespan : <small> (days)</small></label>
                                                        <input type="number" id="voucher_duration" name="voucher_duration" value={dryerSetting.voucher_duration} onChange={e => handleChange("dryerSetting", e.target)} required min="0" />
                                                    </div>
                                                )
                                                : (
                                                    null
                                                )
                                        }

                                        <div className="form-group">
                                            <label htmlFor="voucher_type">Discount Type : </label>
                                            <select className="browser-default form-select" id="voucher_type" name="voucher_type" value={dryerSetting.voucher_type} onChange={e => handleChange("dryerSetting", e.target)} required>
                                                <option value="" disabled>Select a discount type</option>
                                                <option value="Flat">Flat (- {currency()})</option>
                                                <option value="Rate">Rate (- %)</option>
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="voucher_title">Voucher Title : </label>
                                            <input type="text" id="voucher_title" name="voucher_title" placeholder="Voucher Title" value={dryerSetting.voucher_title} onChange={e => handleChange("dryerSetting", e.target)} required />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="voucher_description">Voucher Description : </label>
                                            <input type="text" id="voucher_description" name="voucher_description" placeholder="Voucher Description" value={dryerSetting.voucher_description} onChange={e => handleChange("dryerSetting", e.target)} required />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="voucher_amount">Voucher Amount : <small>{dryerSetting.voucher_type === "Flat" ? `(${currency()})` : "(%)"}</small></label>
                                            <input type="number" id="voucher_amount" name="voucher_amount" value={dryerSetting.voucher_amount} onChange={e => handleChange("dryerSetting", e.target)} required min="0" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <input type="submit" value="CONFIRM" className="btn form-control big-button" />
                        </div>
                    </form>
                </div>
            )
        }
    } else {
        // register and birthday only combined setting
        return (
            <div>
                <div>
                    {/* stamp voucher preview */}
                    <div className="voucher mb-5">
                        <div className="voucher-ball left-ball"></div>
                        <div className="voucher-head p-0 m-0" style={{ backgroundColor: selectedColor.headColor }}>
                            <h4 style={{ width: "140px", height: "140px", padding: "18px 0 0 0" }}>DISCOUNT</h4>
                        </div>

                        <div className="voucher-content" style={{ background: selectedColor.bodyColor }}>
                            <div>
                                <h1>{combinedSetting.voucher_type === "Flat" ? (`${currency()}${combinedSetting.voucher_amount}`) : (`${combinedSetting.voucher_amount}%`)}</h1>
                            </div>

                            <div>
                                <h5>{combinedSetting.voucher_title}</h5>
                                <h6>{combinedSetting.voucher_description}</h6>
                            </div>
                        </div>
                        <div className="voucher-ball right-ball"></div>
                    </div>
                </div>

                <form onSubmit={handleSubmit} id="setting-form">
                    <div className="text-center">
                        {/* checkboxes */}
                        <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                            {
                                selected === "Register" ? (
                                    <div className="form-group">
                                        <label htmlFor="payAndGetRegVoucher">
                                            <input type="checkbox" id="payAndGetRegVoucher" name="payAndGetRegVoucher" checked={payAndGetRegVoucher} onChange={e => handlePayAndGetRegVoucher(e)} />
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        If ticked, the user only can get/use the voucher after make a transaction
                                                    </Tooltip>
                                                }
                                            >
                                                <span>Pay And Get Voucher</span>
                                            </OverlayTrigger>
                                        </label>
                                    </div>
                                ) : selected === "Birthday" ? (
                                    <div className="form-group">
                                        <label htmlFor="payAndGetBirthdayVoucher">
                                            <input type="checkbox" id="payAndGetBirthdayVoucher" name="payAndGetBirthdayVoucher" checked={payAndGetBirthdayVoucher} onChange={e => handlePayAndGetBirthdayVoucher(e)} />
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        If ticked, the system will only give the voucher to user after the user make a transaction
                                                    </Tooltip>
                                                }
                                            >
                                                <span>Pay And Get Voucher</span>
                                            </OverlayTrigger>
                                        </label>
                                    </div>
                                ) : null
                            }

                            <div className="form-group">
                                <label htmlFor="voucher_eligible">
                                    <input type="checkbox" id="voucher_eligible" name="voucher_eligible" checked={combinedSetting.voucher_eligible} onChange={e => handleCheckbox("combinedSetting", e.target)} />
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                If ticked, the system will give voucher to user {selected === "Register" ? "who completed registration process" : selected === "Birthday" ? "on their birthday month" : "who completed stamp collection"}
                                            </Tooltip>
                                        }
                                    >
                                        <span>Eligibility</span>
                                    </OverlayTrigger>
                                </label>
                            </div>

                            <div className="form-group">
                                <label htmlFor="voucher_expiry">
                                    <input type="checkbox" id="voucher_expiry" name="voucher_expiry" checked={combinedSetting.voucher_expiry} onChange={e => handleCheckbox("combinedSetting", e.target)} />
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                If ticked, the voucher will have expired date
                                            </Tooltip>
                                        }
                                    >
                                        <span>Expiry</span>
                                    </OverlayTrigger>
                                </label>
                            </div>
                        </div>

                        <div style={{ maxWidth: "400px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                            <div className="form-group">
                                <label>
                                    <input type="checkbox" id="voucher_washer_use" name="voucher_washer_use" checked={combinedSetting.voucher_washer_use} onChange={e => handleCheckbox("combinedSetting", e.target)} />
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                If ticked, the voucher can apply to washer machine transaction
                                            </Tooltip>
                                        }
                                    >
                                        <span>Washer Use</span>
                                    </OverlayTrigger>
                                </label>
                            </div>

                            <div className="form-group">
                                <label>
                                    <input type="checkbox" id="voucher_dryer_use" name="voucher_dryer_use" checked={combinedSetting.voucher_dryer_use} onChange={e => handleCheckbox("combinedSetting", e.target)} />
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>
                                                If ticked, the voucher can apply to dryer machine transaction
                                            </Tooltip>
                                        }
                                    >
                                        <span>Dryer Use</span>
                                    </OverlayTrigger>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div>
                        {/* text and select */}

                        {
                            selected === "Register" && payAndGetRegVoucher ? (
                                <div className="form-group">
                                    <label htmlFor="regVoucherAmount">Minimum Payment Amount To Get/Use Register Voucher : <small> ({currency()})</small></label>
                                    <input type="number" id="regVoucherAmount" name="regVoucherAmount" value={regVoucherAmount} onChange={e => handleRegVoucherAmount(e)} min="0" required />
                                </div>
                            ) : null
                        }

                        {
                            selected === "Register" && payAndGetRegVoucher ? (
                                <div className="form-group">
                                    <label htmlFor="reg_voucher_method">Voucher Method : </label>
                                    <select className="browser-default form-select" id="reg_voucher_method" name="reg_voucher_method" value={reg_voucher_method} onChange={e => handleRegVoucherMethod(e)} required>
                                        <option value="" disabled>Select a voucher method</option>
                                        <option value="Register">The user directly get the voucher after register but only can use it after make transaction</option>
                                        <option value="Payment">The user only can get voucher after make a transaction</option>
                                    </select>
                                </div>
                            ) : null
                        }

                        {
                            selected === "Birthday" && payAndGetBirthdayVoucher ? (
                                <div className="form-group">
                                    <label htmlFor="birthdayVoucherAmount">Minimum Payment Amount To Get Birthday Voucher : <small> ({currency()})</small></label>
                                    <input type="number" id="birthdayVoucherAmount" name="birthdayVoucherAmount" value={birthdayVoucherAmount} onChange={e => handleBirthdayVoucherAmount(e)} min="0" required />
                                </div>
                            ) : null
                        }

                        {
                            combinedSetting.voucher_expiry
                                ? (
                                    <div className="form-group">
                                        <label htmlFor="voucher_duration">Voucher Lifespan : <small> (days)</small></label>
                                        <input type="number" id="voucher_duration" name="voucher_duration" value={combinedSetting.voucher_duration} onChange={e => handleChange("combinedSetting", e.target)} required min="0" />
                                    </div>
                                )
                                : (
                                    null
                                )
                        }

                        <div className="form-group">
                            <label htmlFor="voucher_type">Discount Type : </label>
                            <select className="browser-default form-select" id="voucher_type" name="voucher_type" value={combinedSetting.voucher_type} onChange={e => handleChange("combinedSetting", e.target)} required>
                                <option value="" disabled>Select a discount type</option>
                                <option value="Flat">Flat (- {currency()})</option>
                                <option value="Rate">Rate (- %)</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="voucher_title">Voucher Title : </label>
                            <input type="text" id="voucher_title" name="voucher_title" placeholder="Voucher Title" value={combinedSetting.voucher_title} onChange={e => handleChange("combinedSetting", e.target)} required />
                        </div>

                        <div className="form-group">
                            <label htmlFor="voucher_description">Voucher Description : </label>
                            <input type="text" id="voucher_description" name="voucher_description" placeholder="Voucher Description" value={combinedSetting.voucher_description} onChange={e => handleChange("combinedSetting", e.target)} required />
                        </div>

                        <div className="form-group">
                            <label htmlFor="voucher_amount">Voucher Amount : <small>{combinedSetting.voucher_type === "Flat" ? `(${currency()})` : "(%)"}</small></label>
                            <input type="number" id="voucher_amount" name="voucher_amount" value={combinedSetting.voucher_amount} onChange={e => handleChange("combinedSetting", e.target)} required min="0" />
                        </div>
                    </div>

                    <div className="my-4">
                        <input type="submit" value="CONFIRM" className="btn big-button form-control" />
                    </div>
                </form>
            </div>
        );
    }
};

export default SettingForm;