import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import washer from "../../../assets/images/machines/paymentWasher.png";
import dryer from "../../../assets/images/machines/paymentDryer.png";
import Sidebar from "../utilities/Sidebar";
import NewsSlider from "./dashboard/NewsSlider";
import { useHistory } from "react-router";
import { changeNav } from "../../../redux/actions/userActions";
import { currency } from "../../../dynamicController";

const colors = ["#bfd0e6", "#f3a68c", "#a0cf99", "#f5dc7b", "#eba0c4"];
let indexCount = 0;

const STORETYPE = process.env.REACT_APP_STORETYPE;

const Transaction = ({ transactions, reloads, transfers, user, changeNav }) => {
  const [toggled, setToggled] = useState(false);
  const [selected, setSelected] = useState(STORETYPE === "cleanpro" ? "All" : "Transaction");
  const [trans, setTrans] = useState(false);
  const [allTrans, setAllTrans] = useState([]);
  const history = useHistory();

  const { t } = useTranslation();

  useEffect(() => {
    let allTransactionHistory = []
    for (let i = 0; i < transactions.length; i++) {
      allTransactionHistory.push({ ...transactions[i], transactionType: "Transaction" })
    }

    for (let i = 0; i < reloads.length; i++) {
      allTransactionHistory.push({ ...reloads[i], transactionType: "Reload" })
    }

    setAllTrans(allTransactionHistory)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderDate = (date, timeOnly) => {
    const ascMonth = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const thisDate = new Date(date);

    let hour24 = "";
    let hour = "";

    if (thisDate.getUTCHours() >= 16) {
      hour24 = thisDate.getUTCHours() + 8 - 24;
    } else {
      hour24 = thisDate.getUTCHours() + 8;
    }

    if (hour24 > 12) {
      hour = hour24 - 12;
      if (hour.toString().length === 1) {
        hour = `0${hour}`
      }
    } else {
      hour = hour24;
      if (hour.toString().length === 1) {
        hour = `0${hour}`
      }
    }

    const minute = thisDate.getUTCMinutes().toString().length === 1 ? `0${thisDate.getUTCMinutes()}` : thisDate.getUTCMinutes().toString();

    const day = thisDate.getDate().toString().length === 1 ? `0${thisDate.getDate()}` : thisDate.getDate().toString();

    const month = ascMonth[thisDate.getMonth()];

    const year = thisDate.getFullYear();

    if (timeOnly) {
      return `${hour}:${minute} ${hour24 >= 12 ? "pm" : "am"}`;
    } else {
      return `${hour}:${minute} ${hour24 >= 12 ? "pm" : "am"} | ${day} ${month} ${year}`;
    }
  };

  return (
    <div id="transaction-page">
      <Sidebar toggled={toggled} setToggled={setToggled} />
      <div id="user-header">
        <h5>
          {
            STORETYPE === "cleanpro" ? (
              <strong onClick={(e) => changeNav("stamp")}><i className="bi bi-chevron-left me-3"></i>{t("Inbox")}</strong>
            ) : (
              <>
                <i onClick={e => setToggled(true)} className="bi bi-list me-3"></i>
                <strong>{selected === "Transaction" ? t("Transaction History") : selected === "Reload" ? t("Reload History") : selected === "Transfer" ? t("Transfer History") : ""}</strong>
              </>
            )
          }
        </h5>
      </div>

      <div>
        {
          STORETYPE === "cleanpro" ? (
            <div className="pt-3 px-2 d-flex justify-content-between" style={{ backgroundColor: "white" }}>
              <div
                id="transaction-news"
                onClick={() => setTrans(false)}
                style={{ width: "45%" }}
                className={`align-center no-wrap cursor-pointer ${!trans ? "active-tab" : "inactive-tab"}`}
              >
                {t("News")}
              </div>
              <div
                id="transaction-activities"
                onClick={() => setTrans(true)}
                style={{ width: "50%" }}
                className={`align-center no-wrap cursor-pointer ${trans ? "active-tab" : "inactive-tab"}`}
              >
                {t("Activities")}
              </div>
            </div>
          ) : (
            <p className="text-center pt-2">{selected === "Transaction" ? t("Only up to last 3 months transaction history") : selected === "Reload" ? t("Only up to last 3 months reload history") : selected === "Transfer" ? t("Only up to last 3 months transfer history") : ""}</p>
          )
        }

        {
          user.tokenSetting && STORETYPE !== "cleanpro" ? (
            <ul className="nav nav-tabs m-auto">
              <li className="nav-item">
                <button id="transaction-page-transaction" onClick={() => setSelected("Transaction")} className={selected === "Transaction" ? "nav-link active font-weight-bold" : "nav-link"} style={{ backgroundColor: "white", outline: "none", borderBottom: selected === "Transaction" ? null : "1px solid #dee2e6" }}>
                  {t("Transaction")}
                </button>
              </li>

              <li className="nav-item">
                <button id="transaction-page-reload" onClick={() => setSelected("Reload")} className={selected === "Reload" ? "nav-link active font-weight-bold" : "nav-link"} style={{ backgroundColor: "white", outline: "none", borderBottom: selected === "Reload" ? null : "1px solid #dee2e6" }}>
                  {t("Reload")}
                </button>
              </li>

              <li className="nav-item">
                <button id="transaction-page-transfer" onClick={() => setSelected("Transfer")} className={selected === "Transfer" ? "nav-link active font-weight-bold" : "nav-link"} style={{ backgroundColor: "white", outline: "none", borderBottom: selected === "Transfer" ? null : "1px solid #dee2e6" }}>
                  {t("Transfer")}
                </button>
              </li>
            </ul>
          ) : null
        }

        {
          STORETYPE === "cleanpro" ? (
            trans ? (
              <div>
                <div
                  style={{ borderTop: "2px solid #eee", alignItems: "center", backgroundColor: "white" }}
                  className="mt-1 d-flex justify-content-between px-4"
                >
                  <p style={{ fontSize: "12px" }}>{t("Up to 3 months history")}</p>
                  <select
                    defaultValue="Filter"
                    onChange={(e) => setSelected(e.target.value)}
                    className="d-block trans-select"
                  >
                    <option value="Selected" hidden>
                      {t("Filter")}
                    </option>
                    <option value="All">{t("All Transactions")}</option>
                    <option value="Transaction">{t("Transactions")}</option>
                    <option value="Reload">{t("Topup")}</option>
                    {/* <option value="Transfer">{t("Transfer")}</option> */}
                  </select>
                </div>

                <div className="pt-3">
                  {
                    selected === "All" ? (
                      allTrans.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                        .map(transaction => {
                          if (transaction.transactionType === "Transaction") {
                            return (
                              <div
                                onClick={() => history.push("user/trans/details", { transaction })}
                                className="px-3"
                                key={transaction.id}
                              >
                                <div
                                  style={{ padding: "20px 10px" }}
                                  className="info-container mb-3 d-flex box-shadow-card"
                                >
                                  <div style={{ width: "75%" }} className="text-right">
                                    <h5 className="font-weight-bold">{t("Transaction")}: {transaction.method === "TOKEN" ? `-${transaction.payment_amount} ${t("Credits")}` : `${currency()}${transaction.payment_amount}`}</h5>
                                    <p className="small-t">
                                      {t("You've just paid")} {transaction.method === "TOKEN" ? `${transaction.payment_amount} ${t("credits")}` : `${currency()}${transaction.payment_amount}`} {t("to")} {transaction.machine_name}, {transaction.outlet.outlet_fullname}
                                    </p>
                                  </div>
                                  <div style={{ width: "25%" }}>
                                    <p className="small-t text-end">{new Date(transaction.createdAt).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</p>
                                    <p className="small-t text-end">{renderDate(transaction.createdAt, true)}</p>
                                  </div>
                                </div>
                              </div>
                            )
                          } else if (transaction.transactionType === "Reload") {
                            return (
                              <div className="px-3" key={transaction.id}>
                                <div
                                  onClick={() => history.push("user/trans/details", { reload: transaction })}
                                  style={{ padding: "20px 10px" }}
                                  className="info-container mb-3 d-flex box-shadow-card"
                                >
                                  <div style={{ width: "75%" }} className="text-right">
                                    <h5 className="font-weight-bold">{t("Topup")}: +{transaction.token} {t("Credits")}</h5>
                                  </div>
                                  <div style={{ width: "25%" }}>
                                    <p className="small-t text-end">{new Date(transaction.createdAt).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</p>
                                    <p className="small-t text-end">{renderDate(transaction.createdAt, true)}</p>
                                  </div>
                                </div>
                              </div>
                            )
                          } else {
                            return (<></>)
                          }
                        })
                    ) : null
                  }

                  {
                    selected === "Transaction" ? (
                      transactions.map(transaction => {
                        return (
                          <div
                            onClick={() => history.push("user/trans/details", { transaction })}
                            className="px-3"
                            key={transaction.id}
                          >
                            <div
                              style={{ padding: "20px 10px" }}
                              className="info-container mb-3 d-flex box-shadow-card"
                            >
                              <div style={{ width: "75%" }} className="text-right">
                                <h5 className="font-weight-bold">{t("Transaction")}: {transaction.method === "TOKEN" ? `-${transaction.payment_amount} ${t("Credits")}` : `${currency()}${transaction.payment_amount}`}</h5>
                                <p className="small-t">
                                  {t("You've just paid")} {transaction.method === "TOKEN" ? `${transaction.payment_amount} ${t("credits")}` : `${currency()}${transaction.payment_amount}`} {t("to")} {transaction.machine_name}, {transaction.outlet.outlet_fullname}
                                </p>
                              </div>
                              <div style={{ width: "25%" }}>
                                <p className="small-t text-end">{new Date(transaction.createdAt).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</p>
                                <p className="small-t text-end">{renderDate(transaction.createdAt, true)}</p>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    ) : null
                  }

                  {
                    selected === "Reload" ? (
                      reloads.map(reload => {
                        return (
                          <div className="px-3" key={reload.id}>
                            <div
                              onClick={() => history.push("user/trans/details", { reload })}
                              style={{ padding: "20px 10px" }}
                              className="info-container mb-3 d-flex box-shadow-card"
                            >
                              <div style={{ width: "75%" }} className="text-right">
                                <h5 className="font-weight-bold">{t("Topup")}: +{reload.token} {t("Credits")}</h5>
                              </div>
                              <div style={{ width: "25%" }}>
                                <p className="small-t text-end">{new Date(reload.createdAt).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</p>
                                <p className="small-t text-end">{renderDate(reload.createdAt, true)}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : null
                  }

                  {
                    selected === "Transfer" ? (
                      transfers.map(transfer => {
                        return (
                          <div className="px-3" key={transfer.id}>
                            <div
                              onClick={() => history.push("user/trans/details", { transfer })}
                              style={{ padding: "20px 10px" }}
                              className="info-container mb-3 d-flex box-shadow-card"
                            >
                              <div style={{ width: "75%" }} className="text-right">
                                <h5 className="font-weight-bold">{t("Transfer")}: {transfer.amount > 0 ? "+" : null}{transfer.amount} {t("Credits")}</h5>
                              </div>
                              <div style={{ width: "25%" }}>
                                <p className="small-t text-end">{new Date(transfer.createdAt).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</p>
                                <p className="small-t text-end">{renderDate(transfer.createdAt, true)}</p>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    ) : null
                  }

                </div>
              </div>
            ) : (
              <div style={{ padding: "10px 4vw 0", minHeight: "100vh" }}>
                <NewsSlider inboxNews={true} history={history} />
              </div>
            )
          ) : selected === "Transaction" ? (
            transactions.map(transaction => {
              if (indexCount >= colors.length) {
                indexCount = 0;
              }
              const color = colors[indexCount];
              indexCount += 1;

              return (
                <div key={transaction.id} className="transaction-list">
                  <div className="img-container" style={{ backgroundColor: color }}>
                    <img src={transaction.machine_type === "Washer" ? washer : dryer} alt="machine" className="img-fluid" />
                  </div>

                  <div className="info-container">
                    <div>
                      <h5 className="font-weight-bold m-0 p-0">{transaction.machine_name}</h5>
                      <p style={{ color: "grey", fontWeight: "bold" }}>{transaction.machine_capacity}</p>
                      <h6 className="font-weight-bold m-0 p-0">{renderDate(transaction.createdAt)}</h6>
                    </div>

                    <div className="text-right">
                      <h5 style={{ color: transaction.status === "Success" ? "green" : transaction.status === "Failed" ? "red" : "#0d6efd", fontWeight: "bold" }}>{t(transaction.status)}</h5>
                      <h5 style={{ color: "#ffd109", fontWeight: "bold" }}>
                        {transaction.method === "TOKEN" ? "" : `${currency()} `}
                        {transaction.amount + transaction.sms_amount}
                        {transaction.method === "TOKEN" ? ` ${t("E-TOKEN")}` : ""}
                      </h5>
                      {transaction.voucherId ? (
                        transaction.voucher.type === "Flat" ? (
                          <h5 className="font-weight-bold">
                            - {transaction.method === "TOKEN" ? "" : `${currency()} `}
                            {transaction.voucher.amount}
                            <small style={{ fontWeight: "" }}>{transaction.method === "TOKEN" ? ` ${t("E-TOKEN")}` : ""}</small>
                          </h5>
                        ) : (
                          <h5 className="font-weight-bold">- {transaction.voucher.amount} %</h5>
                        )
                      ) : transaction.amount !== transaction.payment_amount ? (
                        <h5 className="font-weight-bold">
                          - {transaction.method === "TOKEN" ? "" : `${currency()} `}
                          {(transaction.amount - transaction.payment_amount).toFixed(2)}
                          <small style={{ fontWeight: "bold", fontSize: "10px" }}>{transaction.method === "TOKEN" ? ` ${t("E-TOKEN")}` : ""}</small>
                        </h5>
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            })
          ) : selected === "Reload" ? (
            reloads.map(reload => {
              if (indexCount >= colors.length) {
                indexCount = 0;
              }
              const color = colors[indexCount];
              indexCount += 1;

              return (
                <div key={reload.id} className="transaction-list">
                  <div className="img-container" style={{ backgroundColor: color }}>
                    <i className="fas bi-coin" style={{ fontSize: "30px", marginTop: "15px" }}></i>
                  </div>

                  <div className="info-container">
                    <div>
                      <h5 className="font-weight-bold">{currency()} {reload.amount}</h5>
                      <h6 className="font-weight-bold">{renderDate(reload.createdAt)}</h6>
                    </div>

                    <div className="text-right">
                      <h5 style={{ color: reload.status === "Success" ? "green" : reload.status === "Failed" ? "red" : "#0d6efd", fontWeight: "bold" }}>{t(reload.status)}</h5>
                      <h5 style={{ color: "#ffd109", fontWeight: "bold" }}>
                        {reload.token} {t("E-TOKEN")}
                      </h5>
                    </div>
                  </div>
                </div>
              )
            })
          ) : selected === "Transfer" ? (
            transfers.map(transfer => {
              if (indexCount >= colors.length) {
                indexCount = 0;
              }
              const color = colors[indexCount];
              indexCount += 1;

              return (
                <div key={transfer.id} className="transaction-list">
                  <div className="img-container" style={{ backgroundColor: color }}>
                    <i className="fas bi-arrow-left-right" style={{ fontSize: "30px", marginTop: "15px" }}></i>
                  </div>

                  <div className="info-container">
                    <div>
                      {
                        transfer.amount > 0 ? (
                          <div>
                            <h5 className="font-weight-bold m-0 p-0">{transfer.transfer_from.number}</h5>
                            <p style={{ color: "grey", fontWeight: "bold" }}>{transfer.transfer_from.name}</p>
                          </div>
                        ) : (
                          <div>
                            <h5 className="font-weight-bold m-0 p-0">{transfer.transfer_to.number}</h5>
                            <p style={{ color: "grey", fontWeight: "bold" }}>{transfer.transfer_to.name}</p>
                          </div>
                        )
                      }
                      <h6 className="font-weight-bold">{renderDate(transfer.createdAt)}</h6>
                    </div>

                    <div className="text-right">
                      <h5 style={{ color: transfer.amount > 0 ? "green" : "red", fontWeight: "bold" }}>
                        {transfer.amount} {t("E-TOKEN")}
                      </h5>
                    </div>
                  </div>
                </div>
              );
            })
          ) : null
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    transactions: state.user.user?.transactions || [],
    reloads: state.user.user?.tokenPurchaseHistory || [],
    transfers: state.user.user?.transferToken || [],
    user: state.user.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeNav: (nav) => dispatch(changeNav(nav)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
