import React, { lazy, Suspense } from "react";

const CuciTheme = lazy(() => import("./cuciTheme"));
const LaundroTheme = lazy(() => import("./laundroTheme"));
const CleanProTheme = lazy(() => import("./cleanproTheme"));

const ThemeController = ({ children }) => {
  return (
    <>
      <Suspense fallback={<></>}>
        {process.env.REACT_APP_STORETYPE === "cuci" ? (
          <CuciTheme />
        ) : (
          <LaundroTheme />
        )}
        {process.env.REACT_APP_STORETYPE === "cleanpro" && <CleanProTheme />}
      </Suspense>
      {children}
    </>
  );
};

export default ThemeController;
