import { ProSidebar, Menu, SubMenu, MenuItem, SidebarHeader, SidebarContent } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { logo1Controller } from "../../../../dynamicController";

const Sidebar = ({ toggled, setToggled, themeLogo, client }) => {
	return (
		<ProSidebar breakPoint="xl" toggled={toggled} onToggle={val => setToggled(val)} id="react-pro-sidebar">
			<SidebarHeader id="react-pro-sidebar-header">
				<Link to="/client/dashboard">
					<img src={logo1Controller(themeLogo)} alt="logo" className="img-fluid" />
				</Link>
			</SidebarHeader>

			<SidebarContent id="react-pro-sidebar-content">
				<Menu id="react-pro-sidebar-menu" iconShape="circle" popperArrow={true}>
					<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-house-door-fill"></i>}>
						Dashboard <Link to="/client/dashboard" />
					</MenuItem>

					{
						client.store_performance || client.user_performance || client.voucher_redemption || client.token_report || client.sms_report ? (
							<SubMenu title="Report" icon={<i className="bi bi-file-bar-graph-fill"></i>}>
								{
									client.store_performance ? (
										<MenuItem>
											Store Performance <Link to="/client/outlet/performance" />
										</MenuItem>
									) : <></>
								}
								{
									client.user_performance ? (
										<MenuItem>
											User Performance <Link to="/client/user/performance" />
										</MenuItem>
									) : <></>
								}
								{
									client.voucher_redemption ? (
										<MenuItem>
											Voucher Redemption <Link to="/client/voucher/redemption" />
										</MenuItem>
									) : <></>
								}
								{
									client.token_report && client.token ? (
										<MenuItem>
											Token Report <Link to="/client/token/report" />
										</MenuItem>
									) : <></>
								}
								{
									process.env.REACT_APP_STORETYPE !== "cuci" && client.sms_report ? (
										<MenuItem>
											SMS Report <Link to="/client/sms/report" />
										</MenuItem>
									) : <></>
								}
							</SubMenu>
						) : <></>
					}

					{
						client.user_detail ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-person-lines-fill"></i>}>
								User Details <Link to="/client/user/details" />
							</MenuItem>
						) : <></>
					}

					{
						client.user_reward ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-gift-fill"></i>}>
								User Compensation <Link to="/client/giveaway" />
							</MenuItem>
						) : <></>
					}

					{
						client.voucher_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-gear-fill"></i>}>
								Voucher Setting <Link to="/client/voucher/setting" />
							</MenuItem>
						) : <></>
					}

					{
						client.token_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-coin"></i>}>
								Token Setting <Link to="/client/token/setting" />
							</MenuItem>
						) : <></>
					}

					{
						client.referral_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-people-fill"></i>}>
								Referral Setting <Link to="/client/referral" />
							</MenuItem>
						) : <></>
					}

					{
						client.discount_code ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-percent"></i>}>
								Discount Code <Link to="/client/discountCode" />
							</MenuItem>
						) : <></>
					}

					{process.env.REACT_APP_STORETYPE !== "cuci" && client.customize_color ? (
						<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-palette-fill"></i>}>
							Customize Color <Link to="/client/setting/customize" />
						</MenuItem>
					) : (
						<></>
					)}

					{
						client.faq_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-question-circle-fill"></i>}>
								FAQs <Link to="/client/faq" />
							</MenuItem>
						) : <></>
					}

					{
						client.news_setting ? (
							<MenuItem className="react-pro-sidebar-menu-item" icon={<i className="bi bi-newspaper"></i>}>
								News <Link to="/client/news" />
							</MenuItem>
						) : <></>
					}

				</Menu>
			</SidebarContent>
		</ProSidebar>
	);
};

const mapStateToProps = state => {
	return {
		themeLogo: state.theme.logo,
		client: state.client.client
	};
};

export default connect(mapStateToProps, null)(Sidebar);