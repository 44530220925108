import { distributorLogout } from "../../../../redux/actions/distributorActions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { distributorRedirectUrl, laundroText } from "../../../../dynamicController";

const Topbar = ({ handleToggle, logout, redirectUrl, userType }) => {
	const history = useHistory();
	return (
		<nav className="navbar">
			<div>
				<button onClick={() => handleToggle()}>
					<i style={{ fontSize: "20px" }} className="bi bi-list"></i>
				</button>
			</div>

			<div className="dropdown">
				<button type="button" className="dropdown-toggle" id="dropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
					<i style={{ fontSize: "40px", display: "inline-block" }} className="bi bi-person-circle"></i>
				</button>

				<div style={{ transform: "translate(-70px , 0px)" }} className="dropdown-menu m-0" aria-labelledby="dropdownMenu">
					<div className="dropdown-item">
						<button
							onClick={() => {
								logout();
								history.push("/distributor");
							}}
							className="btn"
						>
							Logout
						</button>
					</div>

					<a href={redirectUrl ? `${redirectUrl}?userType=${userType}` : distributorRedirectUrl()} className="dropdown-item py-3" style={{ color: "black" }}>
						Back To {laundroText()}
					</a>
				</div>
			</div>
		</nav>
	);
};

const mapStateToProps = state => {
	return {
		redirectUrl: state.distributor.redirectUrl,
		userType: state.distributor.userType
	};
};

const mapDispatchToProps = dispatch => {
	return {
		logout: () => dispatch(distributorLogout())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
