import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

class UpdateOperator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            operatorId: "",
            email: "",
            username: "",
            password: "",
            smsCredit: 0,
            loading: true,
            distributorId: "",
            operators: [],
            distributors: []
        };
    };

    componentDidMount() {
        if (this.props.dev.isAuthenticated) {
            axios
                .get("/api/dev/get/updateAdmin", { headers: { "Content-Type": "application/json", "auth-token": this.props.dev.token } })
                .then(res => {
                    this.setState({
                        ...this.state,
                        operators: res.data.admins,
                        distributors: res.data.distributors,
                        loading: false
                    });
                })
                .catch(err => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    alert(err.response.data.error);
                });

        }
    }

    handleSelectOperator = e => {
        const allOperators = this.state.operators;
        const selectedOperator = allOperators.filter(operator => operator.id === e.target.value)[0];

        this.setState({
            ...this.state,
            operatorId: e.target.value,
            email: selectedOperator.email,
            username: selectedOperator.username,
            password: "",
            smsCredit: selectedOperator.sms_credit,
            distributorId: selectedOperator.distributorId
        });
    };

    handleChange = e => {
        if (this.state.operatorId) {
            this.setState({
                ...this.state,
                [e.target.id]: e.target.value
            });
        }
    };

    handleSubmit = e => {
        e.preventDefault();

        const { operatorId, email, username, password, smsCredit, distributorId } = this.state;
        if (operatorId && email && username && smsCredit) {
            this.setState({
                ...this.state,
                loading: true
            });

            const sendThis = {
                operatorId,
                email,
                username,
                password,
                smsCredit,
                distributorId
            };

            axios.post('/api/dev/updateAdmin', sendThis, { headers: { "Content-Type": "application/json", "auth-token": this.props.dev.token } })
                .then(res => {

                    axios
                        .get("/api/dev/get/updateAdmin", { headers: { "Content-Type": "application/json", "auth-token": this.props.dev.token } })
                        .then(res => {
                            this.setState({
                                ...this.state,
                                operatorId: "",
                                email: "",
                                username: "",
                                password: "",
                                smsCredit: 0,
                                distributorId: "",
                                operators: res.data.admins,
                                distributors: res.data.distributors,
                                loading: false
                            });
                            alert("Success");
                        })
                        .catch(err => {
                            this.setState({
                                ...this.state,
                                loading: false
                            });
                            alert(err.response.data.error);
                        });
                })
                .catch(err => {
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    alert(err.response.data.error);
                });
        }
    };

    render() {
        if (!this.props.dev.isLoading && this.props.dev.error) {
            return (
                <div className="text-center pt-5">
                    <h1>{this.props.dev.error}</h1>
                </div>
            )
        } else {
            return (
                <div className="pt-5 px-5">
                    <form className="mt-4" onSubmit={this.handleSubmit}>
                        <div className="text-center">
                            <h1>Update Operator</h1>
                        </div>
                        <div className="form-group mt-5">
                            <label htmlFor="operatorId">Operators</label>
                            <select name="operatorId" id="operatorId" className="browser-default form-select" value={this.state.operatorId} onChange={this.handleSelectOperator}>
                                <option value="" disabled>Select an operator</option>
                                {this.state.operators.map(operator => <option key={operator.id} value={operator.id}>{operator.username} ({operator.id})</option>)}
                            </select>
                        </div>

                        <hr />

                        <div>
                            <div className="form-group">
                                <label htmlFor="email">Email:</label>
                                <input type="email" name="email" id="email" className="browser-default form-control" value={this.state.email} onChange={this.handleChange} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="username">Username: </label>
                                <input type="text" name="username" id="username" className="browser-default form-control" value={this.state.username} onChange={this.handleChange} required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="password">Password: (Optional)</label>
                                <input type="text" name="password" id="password" className="browser-default form-control" value={this.state.password} onChange={this.handleChange} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="password">SMS Credit:</label>
                                <input type="number" name="smsCredit" id="smsCredit" className="browser-default form-control" value={this.state.smsCredit} onChange={this.handleChange} required />
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="distributorId">Distributor</label>
                            <select name="distributorId" id="distributorId" className="browser-default form-select" value={this.state.distributorId} onChange={this.handleChange}>
                                <option value="" disabled>Select a distributor</option>
                                {this.state.distributors.map(distributor => <option key={distributor.id} value={distributor.id}>{distributor.name} ({distributor.id})</option>)}
                            </select>
                        </div>

                        <div>
                            {
                                this.state.loading
                                    ? (
                                        <div className="text-center mt-5">
                                            <p>Loading ...</p>
                                        </div>
                                    )
                                    : <input type="submit" value="SUBMIT" className="btn btn-primary form-control font-weight-bold mt-4" />
                            }
                        </div>
                    </form>
                </div>
            );
        }
    };
};

const mapStateToProps = state => {
    return {
        dev: state.dev
    };
};



export default connect(mapStateToProps, null)(UpdateOperator);