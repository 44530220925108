import { useHistory } from "react-router";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from 'react';

import cleanproStampBanner from "../../../assets/images/news/cleanproStampBanner.jpg";
import washer from "../../../assets/images/machines/icn-washer.png";
import dryer from "../../../assets/images/machines/icn-dryer.png";
import Navigator from "./Navigation";

const StampDetails = ({ user }) => {
  const history = useHistory();

  const { t } = useTranslation();

  useEffect(() => {
    if (!user) {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div id="stamp-details-page">
      <div id="user-header">
        <h5>
          <strong onClick={() => history.push("/")}>
            <i className="bi bi-chevron-left me-3"></i> {t("Back")}
          </strong>
        </h5>
      </div>
      <div className="pb-5">
        <div className="text-center">
          <img src={cleanproStampBanner} className="img-fluid" alt="" />
        </div>
        <div className="pt-3 px-3">
          <p style={{ fontSize: "16px" }}>{t("With every 10 e-stamps that you collected, you will be rewarded with a FREE 5 credits voucher (worth RM5). Make your laundry routine not just hassle-free, but also rewarding.")}</p>
          <p className="pt-2" style={{ fontSize: "16px" }}>{t("A voucher will be instantly added to your Rewards page when you reach 10 washes or drys. Your e-stamp meter will be restored to zero for the next collection. Claim the voucher in the Scan QR page when you do your next laundry.")}</p>
          {
            user && user.combinedSetting ? (
              <div className="box-shadow-card mt-2 py-3">
                <div className="d-flex">
                  <img src={washer} style={{ width: "40px", padding: "3px" }} alt="" />
                  <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Wash")} / {t("Dry")}</p>
                  <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user ? user.combinedStamps : 0}/10</p>
                </div>
                <progress id="progress-bar" value={user ? user.combinedStamps : 0} max="10"></progress>
              </div>
            ) : user && user.specificStamp === "both" ? (
              <div>
                <div className="box-shadow-card mt-2 py-3">
                  <div className="d-flex">
                    <img src={washer} style={{ width: "40px", padding: "3px" }} alt="" />
                    <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Wash")}</p>
                    <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user ? user.washerStamp : 0}/10</p>
                  </div>
                  <progress id="progress-bar" value={user ? user.washerStamp : 0} max="10"></progress>
                </div>
                <div className="box-shadow-card mt-3 py-3">
                  <div className="d-flex">
                    <img src={dryer} style={{ width: "40px" }} alt="" />
                    <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Dry")}</p>
                    <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user ? user.dryerStamp : 0}/10</p>
                  </div>
                  <progress id="progress-bar" value={user ? user.dryerStamp : 0} max="10"></progress>
                </div>
              </div>
            ) : user && user.specificStamp === "washerOnly" ? (
              <div className="box-shadow-card mt-2 py-3">
                <div className="d-flex">
                  <img src={washer} style={{ width: "40px", padding: "3px" }} alt="" />
                  <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Wash")}</p>
                  <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user ? user.washerStamp : 0}/10</p>
                </div>
                <progress id="progress-bar" value={user ? user.washerStamp : 0} max="10"></progress>
              </div>
            ) : user && user.specificStamp === "dryerOnly" ? (
              <div className="box-shadow-card mt-3 py-3">
                <div className="d-flex">
                  <img src={dryer} style={{ width: "40px" }} alt="" />
                  <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Dry")}</p>
                  <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user ? user.dryerStamp : 0}/10</p>
                </div>
                <progress id="progress-bar" value={user ? user.dryerStamp : 0} max="10"></progress>
              </div>
            ) : null
          }
        </div>
        <hr className="my-5" />
        <div className="px-3 pb-5">
          <h6>{t("Terms")} & {t("Conditions")}:</h6>
          <ol>
            <li>{t("To earn ONE e-stamp, you must spend at least 5 credits (RM5) per session.")}</li>
            <li>{t("You will be automatically issued a voucher if you have collected 10 dryer stamps or 10 washer stamps.")}</li>
            <li>{t("The voucher is valid for 60 days from the day voucher be given.")}</li>
            <li>{t("The voucher can be used for dryer or washer machine.")}</li>
            <li>{t("When you applied the voucher during payment, it will give you 5 credits (RM5) discount.")}</li>
            <li>{t("The voucher is non-refundable and cannot be exchanged for cash; in part or in full.")}</li>
            <li>{t("This voucher is valid for single transaction only.")}</li>
            <li>{t("All vouchers is not valid with other ongoing promotions, discounts and offers.")}</li>
            <li>{t("The voucher is for customer use only and not for sale.")}</li>
            <li>{t("Cleanpro reserves the right to change the terms and conditions without prior notice.")}</li>
          </ol>
        </div>
      </div>
      <Navigator />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, null)(StampDetails);
