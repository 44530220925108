import { Line } from 'react-chartjs-2';

const UserByMonthChart = ({ userGraphData }) => {

    let labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let datasets = [];

    function generateRandomColor() {
        let letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }

        return color;
    }

    for (let i = 0; i < userGraphData.data.length; i++) {
        const dataset = {
            label: userGraphData.data[i].label,
            data: userGraphData.data[i].data,
            fill: false,
            borderColor: generateRandomColor(),
            tension: 0.3
        }
        if (userGraphData.data[i].label !== "Total") {
            dataset.hidden = true;
        }
        datasets.push(dataset);
    }

    const data = {
        labels: labels,
        datasets: datasets
    };

    const options = {
        plugins: {
            title: {
                display: true,
                text: "Number of registered users"
            }
        }
    };

    return (<Line data={data} options={options} />);
}

export default UserByMonthChart;