import { useState, useMemo } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Washer from "./dashboard/Washer";
import Dryer from "./dashboard/Dryer";
import Combined from "./dashboard/Combined";
import Sidebar from "../utilities/Sidebar";
import NewsSlider from "./dashboard/NewsSlider";
import cpPlusLogo from "../../.././assets/images/logo/Logo_Cleanproplus-white.png";
import navIcon from "../../.././assets/images/icons/icn-hamburger.png";
import profile from "../../.././assets/images/icons/icn-profile.png";
import creditBearBlue from "../../.././assets/images/icons/icn-credit.png";
import foldWasher from "../../../assets/images/machines/icn-washSticker.png";
import washerIcon from "../../../assets/images/machines/icn-washer.png";
import dryerIcon from "../../../assets/images/machines/icn-dryer.png";

import Timer from "./dashboard/Timer";

const STORETYPE = process.env.REACT_APP_STORETYPE;

const Dashboard = ({ user, history }) => {
  const [selected, setSelect] = useState("washer");
  const [toggled, setToggled] = useState(false);

  const { t } = useTranslation();

  const selectColor = (val) => {
    if (val === selected) {
      return "selected-button";
    } else {
      return null;
    }
  };

  const renderPage = () => {
    if (user.stamp) {
      if (user.combinedSetting) {
        return <Combined />;
      } else {
        if (user.specificStamp === "both") {
          if (selected === "washer") {
            return <Washer />;
          } else if (selected === "dryer") {
            return <Dryer />;
          } else {
            return (
              <div className="text-center">
                <h1>{t("Something went wrong")} :/</h1>
              </div>
            );
          }
        } else {
          if (user.specificStamp === "washerOnly") {
            return <Washer />;
          } else if (user.specificStamp === "dryerOnly") {
            return <Dryer />;
          } else {
            return (
              <div className="text-center">
                <h1>{t("Something went wrong")} :/</h1>
              </div>
            );
          }
        }
      }
    }
  };

  const renderTimer = useMemo(() => {
    let timerTransaction = []
    if (user) {
      const userTransaction = user.transactions.filter(tran => tran.status === "Success" && (tran.machine_type === "Washer" || tran.machine_type === "Dryer") && new Date(tran.updatedAt).setHours(new Date(tran.updatedAt).getHours() + 24) > new Date())
      for (let i = 0; i < userTransaction.length; i++) {
        const machineTran = timerTransaction.filter(tran => tran.machine_name === userTransaction[i].machine_name)
        if (machineTran.length > 0) {
          if (new Date(userTransaction[i].updatedAt) > new Date(machineTran[0].updatedAt)) {
            timerTransaction = timerTransaction.filter(tran => tran.id !== machineTran[0].id)
            timerTransaction.push(userTransaction[i])
          }
        } else {
          timerTransaction.push(userTransaction[i])
        }
      }

      return (
        timerTransaction.map((tran) => {
          return (
            <Timer key={tran.id} tran={tran} history={history} />
          )
        })
      )
    } else {
      return null
    }
  }, [user, history])

  return (
    <div id="user-dashboard">
      <Sidebar toggled={toggled} setToggled={setToggled} />
      <div id="user-header">
        {STORETYPE === "cleanpro" ? (
          <>
            <div className="d-flex justify-content-between">
              <img
                style={{ width: "200px", objectFit: "contain" }}
                src={cpPlusLogo}
                alt=""
              />
              <img
                style={{ width: "28px", objectFit: "contain" }}
                onClick={(e) => setToggled(true)}
                src={navIcon}
                alt=""
              />
            </div>
            <div className="mt-2 d-flex align-items-center">
              <div className="mt-2">
                {user && user.profilePicture ? (
                  <img
                    src={user ? user.profilePicture : ""}
                    alt=""
                    className="img-fluid"
                    style={{ width: "46px", height: "46px", borderRadius: "50%" }}
                  />
                ) : (
                  <img
                    style={{ width: "46px", objectFit: "contain" }}
                    src={profile}
                    alt=""
                  />
                )}
              </div>
              <div className="mx-2">
                <p>{t("Good Evening!")}</p>
                <p
                  style={{ fontSize: "18px", marginTop: "-5px" }}
                  className="font-weight-bold"
                >
                  {user ? user.name : null}
                </p>
                <p className="small-t">{user ? user.memberId : null}</p>
              </div>
            </div>
            <div id="user-info-container">
              <img
                style={{ width: "48px", objectFit: "contain" }}
                src={creditBearBlue}
                alt=""
              />
              <div style={{ width: "40%", marginLeft: "10px" }}>
                <p>{t("Wallet Balance")}</p>
                <span
                  style={{ fontSize: "24px" }}
                  className="t-blk font-weight-bold"
                >
                  {user.token}
                </span>{" "}
                <span>{t("credits")}</span>
              </div>
              <Link
                to="/user/reload"
                style={{ textDecoration: "none" }}
                className="col"
              >
                <div id="reload" className="reload-button">
                  {t("Topup")}
                  <i className="bi bi-plus"></i>
                </div>
              </Link>
            </div>
          </>
        ) : (
          <>
            <h5>
              <i onClick={e => setToggled(true)} className="bi bi-list me-3"></i> <strong>{user.stamp ? t("Stamping Collection") : t("Home")}</strong>
            </h5>
            <div id="user-info-container">
              <div id="user-info-flex">
                {!user.tokenSetting ? (
                  <div
                    id="user-pp"
                    onClick={() => history.push("/user/setting")}
                  >
                    <div id="profile-picture">
                      {/* image */}
                      {user && user.profilePicture ? (
                        <img
                          src={user ? user.profilePicture : ""}
                          alt=""
                          className="img-fluid"
                        />
                      ) : (
                        <i id="temp-pp" className="bi bi-person-fill"></i>
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      height: "70px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-around",
                      borderRight: "1px white solid",
                      paddingRight: "15px",
                    }}
                  >
                    <h6 className="m-0 p-0">{t("Total e-Token")}</h6>
                    <h3 className="m-0 p-0 mx-auto">
                      <strong>{user.token}</strong>
                    </h3>
                  </div>
                )}

                <div id="user-info">
                  <h6 className="m-0 p-0">{user ? user.name : null}</h6>
                  <h5 className="m-0 p-0">
                    <strong>{user ? user.memberId : null}</strong>
                  </h5>
                  <div className="d-flex m-0 p-0">
                    <p className="m-0 p-0">
                      <small>
                        {t("Total Wash")} :{" "}
                        <span style={{ color: "#ffd109" }}>
                          <strong>{user ? user.totalWash : 0}</strong>
                        </span>
                      </small>
                    </p>
                    <p className="m-0 p-0 ms-3">
                      <small>
                        {t("Total Dry")} :{" "}
                        <span style={{ color: "#ffd109" }}>
                          <strong>{user ? user.totalDry : 0}</strong>
                        </span>
                      </small>
                    </p>
                  </div>
                </div>
              </div>

              {user.tokenSetting ? (
                <div /*style={{ marginLeft: "11%", marginTop: "5px", display: "flex", padding: 0 }}*/
                >
                  {/* <p className="m-0 p-0 me-5">
                            e-Token: <span className="font-weight-bold">{user.token}</span>
                        </p> */}
                  <div className="row" style={{ marginBottom: "0px" }}>
                    <Link
                      to="/user/reload"
                      style={{ textDecoration: "none" }}
                      className="col"
                    >
                      <div id="reload" className="reload-button">
                        <i className="bi bi-plus-lg"></i> {t("Reload")}
                      </div>
                    </Link>
                    <Link
                      to="/user/transfer"
                      style={{ textDecoration: "none" }}
                      className="col"
                    >
                      <div id="transfer" className="reload-button">
                        <i className="bi bi-arrow-left-right"></i> {t("Transfer")}
                      </div>
                    </Link>
                  </div>
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>

      {STORETYPE !== "cleanpro" ? (
        <div id="user-body">
          {
            renderTimer
          }

          {!user.stamp ||
            user.combinedSetting ||
            user.specificStamp !== "both" ? null : (
            <div className="px-3 mt-4">
              <div id="type-selector">
                <button
                  id="washer"
                  onClick={(e) => setSelect("washer")}
                  className={selectColor("washer")}
                >
                  {t("Washer")}
                </button>
                <button
                  id="dryer"
                  onClick={(e) => setSelect("dryer")}
                  className={selectColor("dryer")}
                >
                  {t("Dryer")}
                </button>
              </div>
            </div>
          )}

          <div className="mt-3">{renderPage()}</div>

          <div>
            <h5>
              <strong> {STORETYPE === "cleanpro" ? t("Promotions") : t("News")}</strong>
            </h5>
            <div>
              <NewsSlider page="dashboard" history={history} />
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-5">
          {
            renderTimer
          }

          <div>
            {
              user.stamp ? (
                <div className="stamp-component-cp">
                  <div className="d-flex">
                    <p className="px-1 my-2" style={{ fontSize: "18px", fontWeight: 500 }}>{t("Collect 10 e-stamps to earn FREE 5 credits voucher (worth RM5)")}</p>
                    <img
                      src={foldWasher}
                      style={{ width: "56px" }}
                      className="fit-contain pl-1"
                      alt=""
                    />
                  </div>
                  {
                    user.combinedSetting ? (
                      <div className="py-3">
                        <div className="d-flex">
                          <img src={dryerIcon} style={{ width: "40px" }} alt="" />
                          <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Wash")} / {t("Dry")}</p>
                          <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user.combinedStamps}/10</p>
                        </div>
                        <progress id="progress-bar" value={user.combinedStamps} max="10"></progress>
                      </div>
                    ) : user.specificStamp === "both" ? (
                      <div>
                        <div className="py-3">
                          <div className="d-flex">
                            <img src={washerIcon} style={{ width: "40px", padding: "3px" }} alt="" />
                            <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Wash")}</p>
                            <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user.washerStamp}/10</p>
                          </div>
                          <progress id="progress-bar" value={user.washerStamp} max="10"></progress>
                        </div>
                        <div className="py-3">
                          <div className="d-flex">
                            <img src={dryerIcon} style={{ width: "40px" }} alt="" />
                            <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Dry")}</p>
                            <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user.dryerStamp}/10</p>
                          </div>
                          <progress id="progress-bar" value={user.dryerStamp} max="10"></progress>
                        </div>
                      </div>
                    ) : user.specificStamp === "washerOnly" ? (
                      <div className="py-3">
                        <div className="d-flex">
                          <img src={washerIcon} style={{ width: "40px", padding: "3px" }} alt="" />
                          <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Wash")}</p>
                          <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user.washerStamp}/10</p>
                        </div>
                        <progress id="progress-bar" value={user.washerStamp} max="10"></progress>
                      </div>
                    ) : user.specificStamp === "dryerOnly" ? (
                      <div className="py-3">
                        <div className="d-flex">
                          <img src={dryerIcon} style={{ width: "40px" }} alt="" />
                          <p className="ml-1 font-weight-bold" style={{ marginTop: "auto", marginBottom: "auto", fontSize: "16px" }}>{t("Dry")}</p>
                          <p style={{ marginLeft: "auto", marginBottom: "auto", marginTop: "auto", fontSize: "16px" }}>{user.dryerStamp}/10</p>
                        </div>
                        <progress id="progress-bar" value={user.dryerStamp} max="10"></progress>
                      </div>
                    ) : null
                  }
                  <p
                    id="stamp-details"
                    onClick={() => history.push("/user/moreDetails")}
                    className="pb-1"
                    style={{ textAlign: "right", color: "#004fc1" }}
                  >
                    {t("More details")} &gt;
                  </p>
                </div>
              ) : null
            }
          </div>
          <div className="mt-5">
            <h5 className="ml-2">
              <strong>
                {STORETYPE === "cleanpro" ? t("Promotions") : t("News")}
              </strong>
            </h5>
            <div className="ml-1 pb-5">
              <NewsSlider page="dashboard" history={history} />
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, null)(Dashboard);
